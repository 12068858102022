import { defineMessages } from 'react-intl';

export default defineMessages({
    addressApt: {
        id: 'quoteandbind.views.contact-details.Apt',
        defaultMessage: 'Apt./Unit #',
    },
    addressCity: {
        id: 'quoteandbind.views.contact-details.City',
        defaultMessage: 'City',
    },
    addressZipcode: {
        id: 'quoteandbind.views.contact-details.Zip Code',
        defaultMessage: 'Zip Code',
    },
    search: {
        id: 'quoteandbind.views.contact-details.Search',
        defaultMessage: 'Search',
    },
    enterManualAddress: {
        id: 'platform.widgets.findaddress.components.FindAddress.Enter your address manually',
        defaultMessage: 'Enter your address manually',
    },
    backToAddressLookup: {
        id: 'platform.widgets.findaddress.components.FindAddress.Back to Address Search',
        defaultMessage: 'Back to Address Search',
    },
    unsuccessfulMappingTitle: {
        id: 'platform.widgets.findaddress.components.Unsuccessful Mapping From Google Places',
        defaultMessage: 'Unsuccessful Mapping From Google Places',
    },
    unsuccessfulMappingMessage: {
        id: 'platform.widgets.findaddress.components.An error occurred when mapping the address data from google places',
        defaultMessage: 'An error occurred when mapping the address data from google places',
    },
    availabilityLookupErrorTitle: {
        id: 'platform.widgets.findaddress.components.Availability Lookup Error',
        defaultMessage: 'Availability Lookup Error',
    },
    availabilityLookupErrorMessage: {
        id: 'platform.widgets.findaddress.components.You need to enter an address with the same postal code as you started the quote process with',
        defaultMessage: 'You need to enter an address with the same postal code as you started the quote process with',
    },
    invalidAddressTitle: {
        id: 'address.standardization.invalidaddress.title',
        defaultMessage: 'Invalid Address',
    },
    invalidAddressMessage: {
        id: 'address.standardization.invalidaddress.message',
        defaultMessage: 'We are unable to standardize your address.',
    },
    addressCounty: {
        id: 'quoteandbind.views.contact-details.County',
        defaultMessage: 'County',
    },
    addressState: {
        id: 'quoteandbind.views.contact-details.State',
        defaultMessage: 'State',
    },
    noPOBoxAddressMesssage: {
        id: 'quoteandbind.pa.views.address-lookup.noPOBoxesMessage',
        defaultMessage: 'P.O. Boxes are not accepted. Please enter a physical address.',
    },
    zipErrorMessage: {
        id: 'quoteandbind.views.contact-details.zipCode',
        defaultMessage: 'Please enter a valid Alabama zip code',
    }
});
