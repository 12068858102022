import React, {
    useCallback, useEffect, useState, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@jutro/uiconfig';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { TranslatorContext } from '@jutro/locale';

import metadata from './DropDownComponent.metadata.json5';
import styles from './DropDownComponent.module.scss';


function DropDownComponent(props) {
    const {
        value: modelVM,
        availableValues,
        path,
        id,
        placeholder,
        onValidate,
        onValueChange,
        overrideTypeName,
        required,
        showErrors,
        disableCancel,
        allowNew,
        errorPrefix,
        errorDescription
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const [formData, updateFormData] = useState({});
    if (!_.isEmpty(modelVM)) {
        let name = '';
        if (overrideTypeName !== undefined && overrideTypeName !== '') {
            name = overrideTypeName;
        } else {
            if (path === 'trainingType') {
                if (modelVM.name !== undefined && modelVM.name.indexOf('typekey') !== -1) {
                    let courseName = translator({ id: modelVM.name, defaultMessage: modelVM.code });
                    courseName = courseName.replace('Discount', '');
                    name = courseName;
                }
            } else {
                name = (modelVM.name !== undefined && modelVM.name.indexOf('typekey') !== -1) ? translator({ id: modelVM.name, defaultMessage: modelVM.code }) : modelVM.name;
            }
        }
        const modelData = {
            code: modelVM.code,
            name: name
        };
        _.set(formData, path, modelData);
    } else {
        _.set(formData, path, undefined);
    }
    const errorLabel = _.get(placeholder, 'defaultMessage') !== undefined ? _.get(placeholder, 'defaultMessage').toLowerCase() : '';
    const errorMessage = errorDescription !== undefined ? errorDescription : `Please enter ${errorPrefix} ${errorLabel}`;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, modelVM]);

    const handleValueChange = useCallback(
        (value) => {
            if (onValueChange) {
                let newValue = _.cloneDeep(value);
                if (value.__isNew__) {
                    const addedValue = {
                        code: value.value,
                        name: value.label
                    };
                    newValue = _.cloneDeep(addedValue);
                }
                const nextFormData = _.cloneDeep(formData);
                _.set(nextFormData, path, newValue);
                updateFormData(nextFormData);
                onValueChange(newValue, path);
            }
        }, [formData, path, onValueChange]
    );

    const clearTextInput = useCallback(
        () => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, undefined);
            updateFormData(nextFormData);
            onValueChange(undefined, path);
        }, [formData, path, onValueChange]
    );

    const overrideProps = {
        '@field': {
        },
        selectInput: {
            availableValues: availableValues,
            labelPosition: 'top',
            placeholder: placeholder,
            path: path,
            required: required,
            requiredFieldValidationMessage: [errorMessage],
            showErrors: showErrors,
            allowNew: allowNew
        },
        clearButtonIcon: {
            visible: !disableCancel
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, formData, fieldId, fieldPath, overrideProps
        );
    }, [formData, overrideProps]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation,
            onCleartextInput: clearTextInput
        }
    };


    return (
        <ViewModelForm
            model={formData}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />

    );
}

DropDownComponent.propTypes = {
    value: PropTypes.shape({}),
    path: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    availableValues: PropTypes.shape({}).isRequired,
    overrideTypeName: PropTypes.string,
    required: PropTypes.bool,
    showErrors: PropTypes.bool,
    disableCancel: PropTypes.bool,
    allowNew: PropTypes.bool,
    errorPrefix: PropTypes.string,
    errorDescription: PropTypes.string
};
DropDownComponent.defaultProps = {
    value: {},
    placeholder: undefined,
    overrideTypeName: undefined,
    required: undefined,
    showErrors: undefined,
    disableCancel: false,
    allowNew: false,
    errorPrefix: 'a',
    errorDescription: undefined
};
export default DropDownComponent;
