export default class CommonUtil {
static formatPhoneNumber(phoneNumber){
    
    const PHONE_REGEX = /(\d{0,3})(\d{0,3})(\d{0,4})/;

    let [, areaCode, firstThree, lastFour] = phoneNumber
      .replace(/[^0-9]/g, "")
      .match(PHONE_REGEX);
     
    return !firstThree
      ? areaCode
      : `${areaCode}-${firstThree}${lastFour ? `-${lastFour}` : ""}`;
  }
}
