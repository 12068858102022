import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Loader } from '@jutro/components';
import { useValidation } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { getConfigValue } from '@jutro/config';
import cookie from 'js-cookie';
import { RetrieveQuoteService } from 'gw-capability-retrieve-quote-service_alfa';
import { publish } from '@jutro/events';
import moment from 'moment';

import metadata from './RetrieveQuote.metadata.json5';
import styles from './RetrieveQuote.module.scss';
import messages from './RetrieveQuote.messages';

function RetrieveQuote(props) {
    const { onTogglePageState, onRetrieveQuote, onShowRetrieveQuote, updateZipCode} = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate, isComponentValid, initialValidation } = useValidation('RetrieveQuote');

   const quoteRetrievalDTOString = 'edge.capabilities.quote.submission.dto.QuoteRetrievalDTO';
    const quoteRetrievalEmailRequestDTOString = 'alfa.edge.capabilities.retrievequote.dto.QuoteRetrievalEmailRequestDTO';

    const [retrieveQuoteVM, updateRetrieveQuoteVM] = useState(undefined);
    const [quoteNotFound, setQuoteNotFound] = useState(false);
    const [usingEmailAddress, setUsingEmailAddress] = useState(false);
    const [showError, setShowError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(!usingEmailAddress);
    const [dtoToUse, setDtoToUse] = useState(quoteRetrievalDTOString);
    const [showDateError, setShowDateError] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [usingMobileDOB, setUsingMobileDOB] = useState(true);
    const [dateOfBirthMobile, updateDateOfBirthMobile] = useState('');
    const [foundQuotesByEmail, setFoundQuotesByEmail] = useState(false);
    const [retrievedQuotes, setRetrievedQuotes] = useState([]);
    const [isLoading, updateIsLoading] = useState(false);
    const [ssnErrorMsg, setSsnErrorMsg] = useState(false);
    const translator = useContext(TranslatorContext);

    useEffect(
        () => {
            var isIE = false || !!document.documentMode;   
            if(isIE){
                const errorPage = getConfigValue('IE_ERROR_PAGE_URL');
                location.replace(errorPage);
            }
            
            const retrieveQuoteViewModel = viewModelService.create(
                {},
                'pc',
                dtoToUse
            );
            // eslint-disable-next-line react/prop-types
            if (props.isSessionTimedOut || props.retrieveQbQuote) {
                // eslint-disable-next-line react/prop-types
                _.set(retrieveQuoteViewModel, 'quoteID', props.quoteId);
                _.set(retrieveQuoteViewModel, 'postalCode', props.postalCode);
            } else if (!_.isEmpty(cookie.get('quoteID')) && cookie.get('quoteID') !== 'undefined') {
                _.set(retrieveQuoteViewModel, 'quoteID', cookie.get('quoteID'));
                _.set(retrieveQuoteViewModel, 'postalCode', props.postalCode);
            }
            updateRetrieveQuoteVM(retrieveQuoteViewModel);
            
            history.pushState(null, document.title, location.href);
        },
        // Disabling as this its called when a parent is re rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const changeVM = (dtoString) => {
        const retrieveQuoteViewModel = viewModelService.create(
            {},
            'pc',
            dtoString
        );
        updateRetrieveQuoteVM(retrieveQuoteViewModel);
    };

    const writeValue = useCallback((value, path) => {
        const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
        _.set(newRetrieveQuoteVM, path, value);
        updateRetrieveQuoteVM(newRetrieveQuoteVM);
        setSsnErrorMsg(false);
    }, [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]);

    const writeDOBValue = useCallback((value, path) => {
        // Handle DOB date changes - not on phone/portrait view
        setUsingMobileDOB(false);
        const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
        _.set(newRetrieveQuoteVM, path, value);
        updateRetrieveQuoteVM(newRetrieveQuoteVM);
    }, [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]);

    const writeDOBMobileValue = useCallback((value) => {
        // Handle DOB date changes - with phone/portrait view
        setUsingMobileDOB(true);
        updateDateOfBirthMobile(value);
    }, []);

    const isFieldValid = (path) => {        
        const field = _.get(retrieveQuoteVM, path);
        let result = true;
        if (field === undefined || field.aspects === undefined) { return false; }
        result = field.aspects.valid && field.aspects.subtreeValid;        
        return result;
    };

    const captchaSuccess = (captchaIsGood) => {
        // console.log('Setting captchaValid: ', captchaIsGood);
        if (!captchaIsGood) {
            setValidated(false);
        }
        setCaptchaDone(captchaIsGood);
        return captchaIsGood;
    };

    const isDOBValid = () => {
        const dateOfBirthVM = _.get(retrieveQuoteVM, 'dateOfBirth');

        if (dateOfBirthVM.value === undefined && (dateOfBirthMobile === undefined || dateOfBirthMobile === '')) {
            setDateErrorMessage('Please enter Date of Birth');
            setShowDateError(true);
            return false;
        }

        if (usingMobileDOB) {
            if (dateOfBirthMobile === undefined || dateOfBirthMobile === '') {
                setDateErrorMessage('Please enter Date of Birth');
                setShowDateError(true);
                return false;
            }
            if (dateOfBirthMobile !== undefined && dateOfBirthMobile !== '') {
                // split and copy the mobile DOB fields to the actual DOB fields
                const dob = dateOfBirthMobile.split('/');
                _.set(dateOfBirthVM, 'value', {});
                _.set(dateOfBirthVM, 'month', dob[0]);
                _.set(dateOfBirthVM, 'day', dob[1]);
                _.set(dateOfBirthVM, 'year', dob[2]);
            }
        }
        const dateOfBirth = dateOfBirthVM.value;
        if ((dateOfBirth.day === undefined || dateOfBirth.day === '')
            || (dateOfBirth.month === undefined || dateOfBirth.month === '')
            || (dateOfBirth.year === undefined && dateOfBirth.year === '')) {
            setDateErrorMessage('Please enter Date of Birth');
            setShowDateError(true);
            return false;
        }
        const newMonth = (dateOfBirth.month.toString().length === 1) ? '0'.concat(dateOfBirth.month) : dateOfBirth.month;
        const day = (dateOfBirth.day.toString().length === 1) ? '0'.concat(dateOfBirth.day) : dateOfBirth.day;
        const { year } = dateOfBirth;
        const dateToValidate = ''.concat(newMonth, '/', day, '/', year);

        const today = new Date();
        const todayMonth = ((today.getMonth() + 1).toString().length === 1) ? '0'.concat((today.getMonth() + 1)) : (today.getMonth() + 1);
        const todayDate = (today.getDate().toString().length === 1) ? '0'.concat(today.getDate()) : today.getDate();
        const todayYear = today.getFullYear();
        const currentDate = ''.concat(todayMonth, '/', todayDate, '/', todayYear);

        if ((year < 1901) || (moment(dateToValidate).isAfter(currentDate))) {
            setDateErrorMessage('Please enter a valid date of birth');
            setShowDateError(true);
            return false;
        }

        const isDateValid = moment(dateToValidate, 'MM/DD/YYYY', true).isValid();

        if (!isDateValid) {
            setDateErrorMessage('Please enter a valid date of birth');
            setShowDateError(true);
            return false;
        }

        // Date is valid
        setDateErrorMessage('');
        setShowDateError(false);
        writeDOBMobileValue(dateToValidate);
        return true;
    };

    const isLastFourSSNValid = () => {
        const lastFourSSN = _.get(retrieveQuoteVM, 'ssn');
        const lastFourIsDefined = (lastFourSSN != undefined && lastFourSSN.aspects.typedValue != undefined)
        if (lastFourIsDefined){
            return !(lastFourSSN.aspects.typedValue.length < 4);            
        } else{
            return (lastFourIsDefined);
        }       
    }

    const validate = () => {
        // Validation of UI fields before attempting to retrieve quote
        setShowError(true);
        let inputsAreValid = true;
        if (!usingEmailAddress) {
            if (isFieldValid('postalCode') === false) { inputsAreValid = false; }
            if (isFieldValid('quoteID') === false) { inputsAreValid = false; }
            if (isLastFourSSNValid() === false) { inputsAreValid = false; }
           
        } else {
            if (isFieldValid('emailAddress') === false) { inputsAreValid = false; }
            if (isDOBValid() === false) { inputsAreValid = false; }
            if (isLastFourSSNValid() === false) { inputsAreValid = false; }
        }
        if (inputsAreValid) { setShowError(false); }
        setValidated(inputsAreValid);
        return inputsAreValid;
    };

    const retrieveMultiQuotes = () => {
        // retrieve quote(s) by email address, DOB, Last4 SSN
        const quoteRetrievalByEmailRequestDTO = {
            ssn: _.get(retrieveQuoteVM, 'value.ssn'),
            emailAddress: _.get(retrieveQuoteVM, 'value.emailAddress'),
            dateOfBirth: {
                year: _.get(retrieveQuoteVM, 'value.dateOfBirth.year'),
                month: _.get(retrieveQuoteVM, 'value.dateOfBirth.month') - 1,
                day: _.get(retrieveQuoteVM, 'value.dateOfBirth.day')
            }
        };

        setQuoteNotFound(false);
        updateIsLoading(true);
        RetrieveQuoteService.retrieveQuoteByEmail(quoteRetrievalByEmailRequestDTO)
            .then((response) => {
                if (response.length === 0) {
                    // Results had 0 items - no matching quotes were found by email address
                    setQuoteNotFound(true);
                } else if (response.length === 1) {
                    // If there is only 1 quote returned, just retrieve that quote
                    const quoteToResume = {
                        postalCode: response[0].postalCode,
                        quoteID: response[0].jobNumber,
                        ssn: response[0].ssn
                    };
                    // retrieve quote with QuoteID and ZIPCode
                    onRetrieveQuote(quoteToResume, setQuoteNotFound);
                } else {
                    setFoundQuotesByEmail(true);
                    // More than 1 matching quote was found - present the most recent 10 list
                    // sort by date (as the QB10 portal does)
                    let filteredQuotes = response;
                    filteredQuotes.forEach((a) => {
                        // eslint-disable-next-line no-param-reassign
                        a.dateObj = new Date(''.concat(a.createDate.month + 1, '/', a.createDate.day, '/', a.createDate.year)); // months are stored with 0 as January
                    });
                    filteredQuotes.sort((a, b) => { return b.dateObj - a.dateObj; });
                    // keep only the first 10 (as the QB10 portal does)
                    if (response.length > 10) { filteredQuotes = response.splice(0, 10); }

                    const quotesToShow = [];
                    filteredQuotes.forEach((item) => {
                        const itemDate = item.createDate;
                        const displayMonth = itemDate.month + 1; // months are stored with 0 as January
                        const quoteMM = (displayMonth.toString().length === 1) ? '0'.concat(displayMonth) : displayMonth.toString();
                        const quoteDD = (itemDate.day.toString().length === 1) ? '0'.concat(itemDate.day) : itemDate.day.toString();
                        const quoteYY = itemDate.year.toString();
                        const quoteDate = ''.concat(quoteMM, '/', quoteDD, '/', quoteYY);
                        const quoteItem = {
                            quoteId: item.jobNumber,
                            ssn: item.ssn,
                            quoteDate: quoteDate,
                            quoteAmount: item.totalCost === undefined ? 0.00 :item.totalCost.amount.toFixed(2),
                            quotePostalCode: item.postalCode
                        };
                        quotesToShow.push(quoteItem);
                    });
                    setRetrievedQuotes(quotesToShow);
                }
                updateIsLoading(false);
            })
            .catch((error) => {
                setQuoteNotFound(true);
                updateIsLoading(false);
                throw error;
            });
    };

    const retrieveQuote = async () => {
        // validate fields before attempting to retrieve quote
        const screenIsValid = validate();        
        if (retrieveQuoteVM.ssn.value !== undefined && retrieveQuoteVM.ssn.value.length !== 4) {
            setSsnErrorMsg(true);
            return false;
        }
        if (screenIsValid) {
            if (!usingEmailAddress) {
                // retrieve quote with QuoteID and ZIPCode


                const checkSubmissionDTO = {
                    quoteID: retrieveQuoteVM.quoteID.value,
                    postalCode: retrieveQuoteVM.postalCode.value,
                    ssn: retrieveQuoteVM.ssn.value
                };
                const response = await RetrieveQuoteService.checkSubmission(checkSubmissionDTO);

                if (response) {
                    if (response === true) {
                        setQuoteNotFound(false);
                        onRetrieveQuote(retrieveQuoteVM.value, setQuoteNotFound);
                    } else {
                        setQuoteNotFound(true);
                    }
                } else {
                    setQuoteNotFound(true);
                }
                // setQuoteNotFound(false);
                // onRetrieveQuote(retrieveQuoteVM.value, setQuoteNotFound);
            } else {
                // retrieve quote(s) by email address, DOB, Last4 SSN
                retrieveMultiQuotes();
            }
        } else {
            setShowError(true);
        }
    };

    const useQuoteId = () => {
        if (usingEmailAddress) {
            setUsingEmailAddress(false);
            setShowCaptcha(true);
            setDtoToUse(quoteRetrievalDTOString); // change to the 'normal' DTO
            changeVM(quoteRetrievalDTOString);
            setDateErrorMessage('');
            setShowDateError(false);
            setShowError(false);
            setFoundQuotesByEmail(false);
            setRetrievedQuotes([]);
        }
    };

    const useEmailAddress = () => {
        if (!usingEmailAddress) {
            setUsingEmailAddress(true);
            setShowCaptcha(false);
            setDtoToUse(quoteRetrievalEmailRequestDTOString); // change to the Email Request DTO
            changeVM(quoteRetrievalEmailRequestDTOString);
            setDateErrorMessage('');
            setShowDateError(false);
            setFoundQuotesByEmail(false);
            setShowError(false);
        }
    };

    const resetRetrieveQuote = () => {
        setUsingEmailAddress(false);
        setShowCaptcha(true);
        setDtoToUse(quoteRetrievalDTOString); // change to the 'normal' DTO
        changeVM(quoteRetrievalDTOString);
        setDateErrorMessage('');
        setShowDateError(false);
        setShowError(false);
        setFoundQuotesByEmail(false);
        onShowRetrieveQuote();
        setRetrievedQuotes([]);
    };

    const handleSelectQuoteRow = (item, event, index, property) => {
        // pull the retrieved quote from the list
        const quoteToResume = {
            postalCode: retrievedQuotes[index].quotePostalCode,
            quoteID: retrievedQuotes[index].quoteId,
            ssn: retrievedQuotes[index].ssn
        };
        // retrieve quote with QuoteID and ZIPCode
        onRetrieveQuote(quoteToResume, setQuoteNotFound);
    };

    const getQuoteIDCell = (item, index, property) => {
        const displayValue = item.quoteId;
        return (
            <span
                onClick={event => { handleSelectQuoteRow(item, event, index, property) }}
                className={styles.multiQuoteQuoteIdCell}
            >{displayValue}</span>);
    };

    const getDateCell = (item, index, property) => {
        const displayValue = item.quoteDate;
        return (
            <span
                onClick={event => { handleSelectQuoteRow(item, event, index, property) }}
                className={styles.multiQuoteDateCell}
            >{displayValue}</span>);
    };

    const getAmountCell = (item, index, property) => {
        let displayValue = '$';
        if (item.quoteAmount === undefined || item.quoteAmount === 0) {
            displayValue = displayValue.concat('--.--');
        } else {
            const amtString = (item.quoteAmount.toString().indexOf('.') === -1) ? item.quoteAmount + '.00' : item.quoteAmount;
            displayValue = displayValue.concat(amtString);
        }
        return (
            <span
                onClick={event => { handleSelectQuoteRow(item, event, index, property) }}
                className={styles.multiQuoteAmountCell}
            >{displayValue}</span>);
    };

    const getPageTitle = () => {
        // eslint-disable-next-line react/prop-types
        if (props.isSessionTimedOut) {
            return translator(messages.sessionExpiredTitle);
        }
        return translator(messages.welcomeBack_Alfa);
    };

    const onRetrieveTogglePageState = () => {
        const zipCode = _.get(retrieveQuoteVM, 'postalCode.value');
        onTogglePageState();
        updateZipCode(zipCode);
    };

    const getPageSubTitle = () => {
        // eslint-disable-next-line react/prop-types
        if (props.isSessionTimedOut) {
            return translator(messages.sessionExpiredSubTitle);
        }
        return translator(messages.happyToSeeYouAgain_Alfa);
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
        },
        welcomeBackTitleDiv: {
            visible: (foundQuotesByEmail === false)
        },
        quotesFoundTitleDiv: {
            visible: (foundQuotesByEmail === true)
        },
        pageSubTitleDiv: {
            visible: (foundQuotesByEmail === false)
        },
        retrieveQuoteDescription1: {
            visible: !foundQuotesByEmail
        },
        byQuoteIdLink: {
            className: usingEmailAddress ? 'retrieveQuoteLinkInactive' : 'retrieveQuoteLinkActive',
            visible: !foundQuotesByEmail
        },
        retrieveQuoteDescription2: {
            visible: !foundQuotesByEmail
        },
        byQuoteEmailLink: {
            className: usingEmailAddress ? 'retrieveQuoteLinkActive' : 'retrieveQuoteLinkInactive',
            visible: !foundQuotesByEmail
        },
        quotesFoundDescription: {
            visible: foundQuotesByEmail
        },
        retrieveQuoteErrorMsgContainer: {
            visible: quoteNotFound
        },
        retrieveQuoteInputQuoteIdDiv: {
            visible: !usingEmailAddress
        },
        retrieveQuoteSubmissionIDInput: {
            onValueChange: writeValue,
            showErrors: showError && !(isFieldValid('quoteID')),
            className: showError && !(isFieldValid('quoteID')) ? 'floatInput floatInputError' : 'floatInput',
            valueChanged: true,
            customErrorMessage: 'Please enter Quote ID',
            visible: !foundQuotesByEmail
        },
        retrieveQuoteInputZipCodeDiv: {
            visible: !usingEmailAddress
        },
        retrieveQuoteZipCodeInput: {
            onValueChange: writeValue,
            showErrors: showError && !(isFieldValid('postalCode')),
            className: showError && !(isFieldValid('postalCode')) ? 'floatInput floatInputError' : 'floatInput',
            valueChanged: true,
            customErrorMessage: 'Please enter 5 digit Zip Code',
            visible: !foundQuotesByEmail
        },
        retrieveQuoteEmailAddrDiv: {
            visible: usingEmailAddress && !foundQuotesByEmail
        },
        retrieveQuoteEmailAddress: {
            onValueChange: writeValue,
            showErrors: showError && !(isFieldValid('emailAddress')),
            className: showError && !(isFieldValid('emailAddress')) ? 'floatInput floatInputError' : 'floatInput',
            valueChanged: true,
            customErrorMessage: 'Please enter Email Address',
            visible: !foundQuotesByEmail
        },
        retrieveQuoteDOBDiv: {
            visible: usingEmailAddress && !foundQuotesByEmail
        },
        retrieveQuoteDOBField: {
            onValueChange: writeDOBValue,
            showErrors: showDateError,
            errorMessage: dateErrorMessage,
            visible: !foundQuotesByEmail
        },
        retrieveQuoteDOBMobileDiv: {
            visible: usingEmailAddress && !foundQuotesByEmail
        },
        retrieveQuoteDOBMobileField: {
            onValueChange: writeDOBMobileValue,
            showErrors: showDateError,
            className: showDateError ? 'floatInput floatInputError' : 'floatInput',
            customErrorMessage: dateErrorMessage,
            visible: !foundQuotesByEmail
        },
        retrieveQuoteDOBMobileError: {
            content: dateErrorMessage,
            visible: dateErrorMessage && dateOfBirthMobile !== ''
        },
        retrieveQuoteLast4SsnDiv: {
            visible: usingEmailAddress && !foundQuotesByEmail
        },
        retrieveQuoteByIdLast4SsnDiv: {
            visible: !usingEmailAddress
        },
        retrieveQuoteByIdLast4Ssn: {
            onValueChange: writeValue,
            showErrors: showError && !(isFieldValid('ssn')),
            className: !(isLastFourSSNValid()) ? 'floatInput floatInputError' : 'floatInput',
            valueChanged: true,
            customErrorMessage: 'Invalid. Please re-type a valid 4-digit SSN.'
        },
        ssnError: {
            visible: ssnErrorMsg,
            className: 'ssnError'
        },
        retrieveQuoteLast4Ssn: {
            onValueChange: writeValue,
            showErrors: showError && !(isFieldValid('ssn')),
            className: !(isLastFourSSNValid()) ? 'floatInput floatInputError' : 'floatInput',
            valueChanged: true,
            customErrorMessage: 'Invalid. Please re-type a valid 4-digit SSN.',
            visible: !foundQuotesByEmail
        },
        retrieveQuoteuttonContainer: {
            visible: !foundQuotesByEmail
        },
        multiQuoteTable: {
            visible: foundQuotesByEmail,
            data: retrievedQuotes
        },
        multiQuoteTableGrid: {
            data: retrievedQuotes,
            visible: foundQuotesByEmail && retrievedQuotes.length > 0
        },
        quoteIdColumn: {
            cell: getQuoteIDCell
        },
        quoteIdColumnMobile: {
            cell: getQuoteIDCell
        },
        quoteDateColumn: {
            cell: getDateCell
        },
        quoteAmountColumn: {
            cell: getAmountCell
        },
        quoteAmountColumnMobile: {
            cell: getAmountCell
        },
        quoteDateColumnMobile: {
            cell: getDateCell
        },
        multiQuoteLinksContainer: {
            visible: foundQuotesByEmail
        },
        pageTitle: {
            content: getPageTitle()
        },
        pageSubTitle: {
            content: getPageSubTitle()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRetrieveTogglePageState: onRetrieveTogglePageState,
            onTogglePageState: onTogglePageState,
            onShowRetrieveQuote: onShowRetrieveQuote,
            onRetrieveQuote: retrieveQuote,
            onUseQuoteId: useQuoteId,
            onUseEmailAddress: useEmailAddress,
            onValidate: onValidate,
            isDOBValid: isDOBValid,
            resetRetrieveQuote: resetRetrieveQuote
        },
        resolveComponentMap: {
           
        }
    };

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={retrieveQuoteVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

RetrieveQuote.propTypes = {
    onTogglePageState: PropTypes.func.isRequired,
    onRetrieveQuote: PropTypes.func.isRequired,
    onShowRetrieveQuote: PropTypes.func.isRequired,
    showErrors: PropTypes.bool,
    onValidate: PropTypes.func
};

RetrieveQuote.defaultProps = {
    onValidate: undefined,
    showErrors: false
};

export default RetrieveQuote;
