import React, {
    useContext, useCallback, useEffect
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';

import { BreakpointTrackerContext, Table, TableColumn } from '@jutro/layout';
import {
    Button, Tooltip, IconButton, Image
} from '@jutro/components';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import _ from 'lodash';
import appConfig from 'app-config';

import { LoadSaveService } from 'gw-capability-quoteandbind';
import { WizardContext } from '../WizardContext';
import styles from './PAWizardActionbar.module.scss';
import messages from './PAWizardActionbar.messages';


const buildSteps = (steps, currentStepIndex) => {
    if (['/policy-information', '/payment-details', '/success'].includes(steps[currentStepIndex].path)) {
        return steps.map((step) => {
            if (step.path === '/policy-information' || step.path === '/payment-details' || step.path === '/success') {
                return step;
            }
            return undefined;
        });
    }
    if (steps[currentStepIndex].path === '/driverhistory') {
        return steps.map((step, index) => {
            if (index === currentStepIndex || (step.path !== '/drivers' && step.path !== '/moreinfo' && step.path !== '/addl-information' && step.path !== '/policy-information' && step.path !== '/payment-details'
                && step.path !== '/success')) {
                return step;
            }
            return undefined;
        });
    }
    if (steps[currentStepIndex].path === '/moreinfo') {
        return steps.map((step, index) => {
            if (index === currentStepIndex || (step.path !== '/quote' && step.path !== '/driverhistory' && step.path !== '/policy-information' && step.path !== '/payment-details'
                && step.path !== '/success')) {
                return step;
            }
            return undefined;
        });
    }
    return steps.map((step) => {
        if (step.path !== '/moreinfo' && step.path !== '/driverhistory' && step.path !== '/policy-information' && step.path !== '/payment-details' && step.path !== '/success') {
            return step;
        }
        return undefined;
    });
};

function applySecondWizard(step) {
    if (step === '/policy-information') {
        return styles.finalDetailClass;
    }
    return '';
}

function renderSteps(translator, steps, currentStepIndex, isTablet) {
    const updatedSteps = buildSteps(steps, currentStepIndex);
    return updatedSteps.map((step, index) => {
        if (step) {
            const wizardSecondClass = applySecondWizard(step.path);
            const liClassName = classNames(wizardSecondClass, {
                [styles.active]: index === currentStepIndex,
                [styles.notVisited]: index > currentStepIndex,
                [styles.visited]: index < currentStepIndex
            });

            const title = translator(step.title);
            return (
                <li className={liClassName} key={step.id} title={title} alt={title}>
                    {title}
                </li>
            );
        }
        return '';
    });
}

function PAWizardActionbar(props) {
    const wizardContext = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const {
        steps, currentStepIndex, jumpTo, stepsWithErrors, wizardData
    } = wizardContext;
    const { customMessageModal } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const isTablet = breakpoint === 'tablet';
    const discounts = _.get(wizardData, 'baseData.discount.value');
    let manualFlag = false;
    const newDiscounts = [];
    const address = _.get(wizardData, 'baseData.accountHolder.primaryAddress');
    const driverState = address.state.value.code;

    const manualMessagePath = 'quoteandbind.common.directives.QuoteDiscount.manualDiscount';
    const footerMessage = translator(_.find(messages, { id: `${manualMessagePath}` }));


    if (!_.isUndefined(discounts)) {
        if (discounts.isResponsiblePlayer) {
            newDiscounts.push('isResponsiblePlayer');
        }
        if (discounts.hasHomeOwnerDiscount) {
            newDiscounts.push('hasHomeOwnerDiscount');
        }
        if (discounts.hasRelationshipDiscount) {
            newDiscounts.push('hasRelationshipDiscount');
                manualFlag = true;
        }
        if (discounts.hasMultiCarDiscount) {
            if (steps[currentStepIndex].path !== '/vehicles') {
                newDiscounts.push('hasMultiCarDiscount');
            }
        }
        if (discounts.hasExcessVehicleDiscount) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                newDiscounts.push('hasExcessVehicleDiscount');
            }
        }
        if (discounts.isYouthDefensiveDriver) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                newDiscounts.push('isYouthDefensiveDriver');
                manualFlag = true;
            }
        }
        if (discounts.isDefensiveDriver) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                newDiscounts.push('isDefensiveDriver');
                manualFlag = true;
            }
        }
        if (!_.isUndefined(discounts.isYouthSafetyDriver)) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                if (discounts.isYouthSafetyDriver) {
                    newDiscounts.push('isyouthSafety');
                    manualFlag = true;
                }
            }
        }
        if (discounts.isYouthHonorStudent) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                newDiscounts.push('isYouthHonorStudent');
                manualFlag = true;
            }
        }
        if (discounts.hasDriverTrainingDiscount) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                newDiscounts.push('hasDriverTrainingDiscount');
                manualFlag = true;
            }
        }
        if (discounts.hasOccupationDiscount) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                newDiscounts.push('hasOccupationDiscount');
            }
        }
        if (discounts.hasAffinityDiscount) {
            if (steps[currentStepIndex].path !== '/vehicles' && steps[currentStepIndex].path !== '/drivers') {
                newDiscounts.push('hasAffinityDiscount');
            }
        }
    }


    useEffect(() => {
        if (steps[currentStepIndex].path !== '/quote') {
            document.getElementById('buttonContainer').style.display = 'none';
        }
        if (steps[currentStepIndex].path === '/your-info' || steps[currentStepIndex].path === '/policy-information'
            || steps[currentStepIndex].path === '/payment-details') {
            document.getElementById('discountContainer').style.display = 'none';
        }
    }, [wizardData]);
    const getHeader = useCallback(() => {
        return (
            <div>
                <Image id="Image" src="alfa-images/piggy_bank_32.png" className={styles.gwsocialimage} ariaLabel="Discounts Applied!" title="Discounts Applied!" />
                <p className={styles.headerText}>Discounts Applied!</p>
            </div>
        );
    }, []);

    const getCell = (items) => {
        const messagePath = 'quoteandbind.common.directives.QuoteDiscount.';
        const discountLabel = String(items);
        const discountMssg = translator(_.find(messages, { id: `${messagePath}${discountLabel}` }));
        let message = `${messagePath}${discountLabel}Description`;
        if (driverState === 'GA' && ['isDefensiveDriver', 'hasOccupationDiscount'].includes(discountLabel)) {
            message = `${messagePath}${discountLabel}DescriptionGa`;
        }
        return (
            <Tooltip
                animation="fade"
                content={translator(_.find(messages, { id: message }))}
                delay={[
                    0,
                    40
                ]}
                duration={[
                    300,
                    300
                ]}
                flipBehavior={[
                    'right',
                    'bottom',
                    'top',
                    'left',
                    'right'
                ]}
                followCursor={false}
                hideOnClick
                id="tooltip"
                placement="left-start"
                showOnInit={false}
                sticky
                trigger="mouseenter"
            >
                <div>
                    <IconButton icon="dollar-sign" aria-haspopup="true" iconClassName={styles.darkColor} />
                    <u className={styles.darkContent}>{discountMssg}</u>
                </div>
            </Tooltip>
        );
    };
    const getdiscountStyle = useCallback(() => {
        if (steps[currentStepIndex].path === '/quote') {
            return styles.discountDivQuote;
        }
        return styles.discountdiv;
    }, []);
    const getdiscountStyleDesc = useCallback(() => {
        if (!manualFlag) {
            return styles.discountDescHide;
        }
        return styles.manualDiscount;
    }, []);

    const printQuote = useCallback(() => {
        const quoteID = _.get(wizardData, 'quoteID.value');
        dataLayer.push({'event':'gtm.click', 'gtm.elementId': 'qb_quote_printquote'});
        if (steps[currentStepIndex].path === '/quote') {
            const responsePromise = LoadSaveService.generatePrintDocumentToken();
            responsePromise.then((response) => {
                const sessionToken = response;
                const mockURI = appConfig.servers.pc.url;
                const effURI = mockURI.substring(mockURI.indexOf('/pc'));
                const xCenterDownloadURI = effURI.replace('unauthenticated/', '');
                const documentUrl = xCenterDownloadURI.concat('document/document/policyDocument/?token=', sessionToken, '&submissionNumber=', quoteID);
                const printDocument = window.open(documentUrl, '_blank');
                if (_.isNull(printDocument)) {
                    printDocument.focus();
                    printDocument.print();
                }
            });
        }
    }, [wizardData]);

    const progressbarStyle = () => {
        if (['/policy-information', '/payment-details'].includes(steps[currentStepIndex].path)) {
            return classNames(styles.progressbar, styles.progressbarStyle);
        }
        return classNames(styles.progressbar);
    }
    
    return (
        <div className={styles.container}>
            <ul className={progressbarStyle()}>
                {renderSteps(
                    translator,
                    steps,
                    currentStepIndex,
                    isTablet,
                    stepsWithErrors,
                    jumpTo,
                    wizardContext,
                    customMessageModal
                )}
            </ul>
            <div id="buttonContainer" className={styles.buttonContainer}>
                <Button
                    id="printquote"
                    onClick={printQuote}
                    iconPosition="left"
                    icon="print"
                    type="primary"
                    className={styles.buttonStyle}


                >
                    Print Quote
                </Button>
            </div>
            <div id="discountContainer" className={getdiscountStyle()}>
                <DataTable

                    data={newDiscounts}
                    id="discountsTable"
                    showPagination={false}
                    showSearch={false}
                    noDataText= " "
                >
                    <DisplayColumn
                        onCell={getCell}
                        columnProportion={1}
                        sortable={false}
                        id="discount"
                        header={getHeader()}
                        headerClassName={styles.gwDiscountHeading}
                        textAlign="left"
                    />
                </DataTable>
                <div id="discountFooter" className={getdiscountStyleDesc()}>
                    {footerMessage}
            </div>

        </div>
        </div>
    );
}

PAWizardActionbar.propTypes = {
    customMessageModal: PropTypes.shape({})
};
PAWizardActionbar.defaultProps = {
    customMessageModal: {}
};
export default PAWizardActionbar;
