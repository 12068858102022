import { defineMessages } from 'react-intl';

export default defineMessages({
    helpText: {
        id: 'quoteandbind.common.directives.AgentInfo.Need Help? Contact me at:',
        defaultMessage: 'Need Help? Contact me at:'
    },
    searchPlaceholder: {
        id: 'quoteandbind.common.directives.AgentInfo.Search by Name, Zip',
        defaultMessage: 'Type a Name or Zip'
    }
});