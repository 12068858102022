import { defineMessages } from 'react-intl';

export default defineMessages({
    unsuccessfulMappingTitle: {
        id: 'platform.widgets.googleplaceslookup.components.Unsuccessful Mapping From Google Places',
        defaultMessage: 'Unsuccessful Mapping From Google Places',
    },
    unsuccessfulMappingMessage: {
        id: 'platform.widgets.googleplaceslookup.components.An error occurred when mapping the address data from google places',
        defaultMessage: 'An error occurred when mapping the address data from google places',
    },
    availabilityLookupErrorTitle: {
        id: 'platform.widgets.googleplaceslookup.components.Availability Lookup Error',
        defaultMessage: 'Availability Lookup Error',
    },
    availabilityLookupErrorMessage: {
        id: 'platform.widgets.googleplaceslookup.components.You need to enter an address with the same postal code as you started the quote process with',
        defaultMessage: 'You need to enter an address with the same postal code as you started the quote process with',
    }
});
