import { defineMessages } from 'react-intl';

export default defineMessages({
    noCoverage: {
        id: 'quoteandbind.bop.directives.bop-building-directive.No Coverages',
        defaultMessage: 'No Coverages'
    },
    noneSelected: {
        id: 'policycommon.directives.coverages.select.None Selected',
        defaultMessage: 'Not Included'
    },
    selectedValue: {
        id: 'policycommon.The value must be between',
        defaultMessage: 'The value must be between {minValue} and {maxValue}'
    },
    minValue: {
        id: 'policycommon.The minimum allowed value is',
        defaultMessage: 'The minimum allowed value is {minValue}'
    },
    maxValue: {
        id: 'policycommon.The maximum allowed value is',
        defaultMessage: 'The maximum allowed value is {maxValue}'
    },
    updatingSelection: {
        id: 'policycommon.directives.coverages.Updating selection...',
        defaultMessage: 'Updating selection...'
    },
    PACollisionCov: {
        id: 'policycommon.directives.coverages.PACollisionCov',
        defaultMessage: 'Comprehensive coverage is required to select Collision coverage'
    },
    PALossOfUseCov_alfa: {
        id: 'policycommon.directives.coverages.PALossOfUseCov_alfa',
        defaultMessage: 'Comprehensive and Collision coverage are required to select Loss of Use coverage.'
    },
    PALossOfIncome_alfa: {
        id: 'policycommon.directives.coverages.PALossOfIncome_alfa',
        defaultMessage: 'Medical Payments coverage is required to select Loss of Income coverage.'
    },
    PALeaseLoan_alfa: {
        id: 'policycommon.directives.coverages.PALeaseLoan_alfa',
        defaultMessage: 'Comprehensive and Collision coverage is required to select Lease/Loan coverage.'
    },
    PAUninsuredMotoristBI_alfa: {
        id: 'policycommon.directives.coverages.PAUninsuredMotoristBI_alfa',
        defaultMessage: 'Uninsured Motorists Bodily Injury limits cannot be greater than Bodily Injury limits.'
    },
    PABI_alfa: {
        id: 'policycommon.directives.coverages.PABI_alfa',
        defaultMessage: 'Uninsured Motorists Bodily Injury limits cannot be greater than Bodily Injury limits.'
    },
    PAComprehensiveCov: {
        id: 'policycommon.directives.coverages.PAComprehensiveCov',
        defaultMessage: 'Lease/Finance companies require Comprehensive coverage and Collision coverage'
    },
    ClauseIncluded: {
        id: 'policycommon.directives.coverages.Included',
        defaultMessage: 'Included'
    },
    ClauseNotIncluded: {
        id: 'policycommon.directives.coverages.Not Included',
        defaultMessage: 'Not Included'
    },
    PAUninsuredMotoristPD_alfa: {
        id: 'policycommon.directives.coverages.PAUninsuredMotoristPD_alfa',
        defaultMessage: 'Uninsured Motorists Property Damage limit cannot be greater than Property Damage limit.'
    }
});
