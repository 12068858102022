
import { defineMessages } from 'react-intl';

export default defineMessages({
    vinPopupTitlew: {
        id: 'quoteandbind.views.vin.Vin Title',
        defaultMessage: 'Where\'s my VIN?'
    },
    vinCaptionPopUpw: {
        id: 'quoteandbind.views.vin.Vin Caption',
        defaultMessage: 'A VIN is a vehicle identification number that is specific to each vehicle. A VIN is composed of 17 characters and can usually be found on the driver\'s-side interior dash, on the title or on the vehicle registration.'
    }
});
