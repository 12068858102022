import React, {
    useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { useAuthentication } from 'gw-digital-auth-react';
import { PolicyDiffService } from 'gw-capability-policyjob';
import styles from './PolicyDiffComponent.module.scss';

const MIN_HEADER_LEVEL = 2;

const renderPolicyHeader = (label, children, headerNumber) => {
    const HeaderComponent = `h${headerNumber}`;
    return (
        <div>
            <HeaderComponent>{label}</HeaderComponent>
            <Grid>
                {children.map((item) => {
                    // eslint-disable-next-line no-use-before-define
                    return renderChildren(item, headerNumber + 1);
                })}
            </Grid>
        </div>
    );
};

const renderPolicyValues = (values) => {
    const { label, value1: beforeValue, value2: afterValue } = values;

    return (
        <div className={styles.policyValues}>
            <span>{label}</span>
            <div>
                <span className={styles.oldValue}>{beforeValue}</span>
                <span className={styles.newValue}>{afterValue}</span>
            </div>
        </div>
    );
};

const renderChildren = (items, headerNumber) => {
    const { label, children } = items;

    if (children) {
        const sortedChildren = _.sortBy(children, [(child) => (child.children ? 1 : -1)]);
        return renderPolicyHeader(label, sortedChildren, headerNumber);
    }
    return renderPolicyValues(items);
};

function PolicyDiffComponent(props) {
    const { value: jobNumber } = props;
    const { authHeader } = useAuthentication();
    const [policyDiffData, setPolicyDiffData] = useState([]);

    useEffect(() => {
        PolicyDiffService.getPolicyDiffWithPrevious(jobNumber, authHeader).then(setPolicyDiffData);
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const policyDifference = useMemo(() => (
        policyDiffData.map((item) => renderChildren(item, MIN_HEADER_LEVEL))
    ), [policyDiffData]);

    return (
        <Loader loaded={!_.isEmpty(policyDiffData)}>
            <Grid vgap="xxlarge">
                {policyDifference}
            </Grid>
        </Loader>
    );
}

PolicyDiffComponent.propTypes = {
    value: PropTypes.string.isRequired
};

export default PolicyDiffComponent;
