import DateComponent from './DateComponent/DateComponent';
import DropDownComponent from './DropDownComponent/DropDownComponent';
import FloatComponent from './FloatComponent/FloatComponent';
import NumberInputComponent from './NumberInputComponent/NumberInputComponent';
import AutoCompleteInput from './AutoCompleteInput/AutoCompleteInput';
import VinPopUp from './VinPopUp/VinPopUp';
// import TooltipComponent from './TooltipComponent/TooltipComponent';

const WidgetComponentMap = {
    dateComponent: { component: 'DateComponent' },
    dropDownComponent: { component: 'DropDownComponent' },
    floatComponent: { component: 'FloatComponent' },
    numberInputComponent: { component: 'NumberInputComponent' },
    AutoCompleteInput: { component: 'AutoCompleteInput' },
    VinPopUp: { component: 'VinPopUp' }
    // tooltipComponent: { component: 'TooltipComponent' }
};

const WidgetComponents = {
    DateComponent,
    DropDownComponent,
    FloatComponent,
    NumberInputComponent,
    AutoCompleteInput,
    VinPopUp
        // TooltipComponent
};

export {
    WidgetComponentMap,
    WidgetComponents,
    DateComponent,
    DropDownComponent,
    FloatComponent,
    NumberInputComponent,
    AutoCompleteInput,
    VinPopUp
    // TooltipComponent
};
