import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';

import messages from './CommonQuoteInfo.messages';
import commonQuoteStyles from '../../quoteandbind-common-styles.module.scss';

function CommonQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const location = _.get(submissionVM, 'baseData.policyAddress.displayName.value');

    if (breakpoint === 'phone') {
        return (
            <div />
        );
    }
    return (
        <div />
    );
}

CommonQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired
};

export default CommonQuoteInfo;
