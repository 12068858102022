import { defineMessages } from 'react-intl';

export default defineMessages({
    isResponsiblePlayer: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isResponsiblePlayer',
        defaultMessage: 'Responsible Payer',
    },
    isYouthHonorStudent: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isYouthHonorStudent',
        defaultMessage: 'Youth Honor Student*',
    },
    hasDriverTrainingDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasDriverTrainingDiscount',
        defaultMessage: 'Driver Training*',
    },
    isYouthDefensiveDriver: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isYouthDefensiveDriver',
        defaultMessage: 'Youth Defensive Driver*',
    },
    isDefensiveDriver: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isDefensiveDriver',
        defaultMessage: 'Defensive Driver*',
    },
    isyouthSafety: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isyouthSafety',
        defaultMessage: 'Youth Safety*',
    },
    isyouthSafetyDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isyouthSafetyDescription',
        defaultMessage: 'For an unmarried driver, aged 16-20, who has completed a qualified course and has has no chargeable accidents or violations in the past three years.',
    },
    hasOccupationDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasOccupationDiscount',
        defaultMessage: 'Occupation',
    },
    hasAffinityDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasAffinityDiscount',
        defaultMessage: 'Affinity',
    },
    manualDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.manualDiscount',
        defaultMessage: '* These discounts will require a few more steps in order to keep them once you purchase. Click each discount for more details.',
    },
    isResponsiblePlayerDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isResponsiblePlayerDescription',
        defaultMessage: 'Available for new Alfa® auto policies and will remain on the policy if there are no late payments or a lapse in coverage for that policy.',
    },
    isYouthHonorStudentDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isYouthHonorStudentDescription',
        defaultMessage: 'Driver must be a full-time student (12 hours or more) with a 3.0 GPA or higher. In order to keep this discount, scholastic records must be provided. Age requirement is unmarried between 16-24.',
    },
    hasDriverTrainingDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasDriverTrainingDiscountDescription',
        defaultMessage: 'Available for drivers aged 16-20 with classroom instruction and behind-the-wheel training by a certified instructor.',

    },
    isYouthDefensiveDriverDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isYouthDefensiveDriverDescription',
        defaultMessage: 'For an unmarried driver, aged 16-20, who has completed a qualified course and has had no chargeable accidents or violations in the past three years.',
    },
    isDefensiveDriverDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isDefensiveDriverDescription',
        defaultMessage: 'For a driver, aged 16-20 or age 55 and over, who has completed a qualified course and has had no chargeable accidents or violations in the past three years.',
    },
    isDefensiveDriverDescriptionGa: {
        id: 'quoteandbind.common.directives.QuoteDiscount.isDefensiveDriverDescriptionGa',
        defaultMessage: 'For a driver of any age who has completed a qualified course and has had no chargeable accidents or violations in the past three years.',
    },
    hasOccupationDiscountDescriptionGa: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasOccupationDiscountDescriptionGa',
        defaultMessage: 'Available if the primary named insured or spouse is in the military.',
    },
    hasOccupationDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasOccupationDiscountDescription',
        defaultMessage: 'Available if the primary named insured or spouse is an educator, farmer, minister, first responder or in the military.',
    },
    hasAffinityDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasAffinityDiscountDescription',
        defaultMessage: 'Available if any member of the household is a state or federal employee.',
    },
    hasMultiCarDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasMultiCarDiscount',
        defaultMessage: 'Multi-Car',
    },
    hasMultiCarDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasMultiCarDiscountDescription',
        defaultMessage: 'Available when two or more private passenger vehicles are insured with Alfa.',
    },
    hasExcessVehicleDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasExcessVehicleDiscount',
        defaultMessage: 'Excess-Vehicle',
    },
    hasExcessVehicleDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasExcessVehicleDiscountDescription',
        defaultMessage: 'Available if the number of vehicles on the policy is greater than the number of drivers on the policy (not including excluded drivers).',
    },
    hasHomeOwnerDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasHomeOwnerDiscount',
        defaultMessage: 'Homeownership',
    },
    hasHomeOwnerDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasHomeOwnerDiscountDescription',
        defaultMessage: 'If you own a home, with or without a mortgage, you qualify for this discount.',
    },
    hasRelationshipDiscount: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasRelationshipDiscount',
        defaultMessage: 'Relationship*',
    },
    hasRelationshipDiscountDescription: {
        id: 'quoteandbind.common.directives.QuoteDiscount.hasRelationshipDiscountDescription',
        defaultMessage: 'Available when you choose to insure multiple policies with Alfa®.'
    },
});
