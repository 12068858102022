import React, { useContext, useCallback, useRef } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import _ from 'lodash';
import { WizardContext } from '../WizardContext';
import styles from './CRCInfoComponent.module.scss';

function CRCInfoComponent(props) {
    const breakpoint = useContext(BreakpointTrackerContext); //phone, tablet, desktop
    const wizardContext = useContext(WizardContext);
    const { steps, currentStepIndex, wizardData, updateWizardData } = wizardContext;
    const submissionVM = wizardData;
    const selectedAgentAssignment = useRef(submissionVM.basedata && submissionVM.baseData.agentAssignment
        && submissionVM.baseData.agentAssignment.value && submissionVM.baseData.agentAssignment.value.code)
        ? submissionVM.baseData.agentAssignment.value.code : "";
    const paths = ['/quote', '/policy-information', '/payment-details'];
    let mobileView = false;

    if (breakpoint !== 'desktop') {
        mobileView = true;
    }

    function ShowAndHideInfo() {
        var x = document.getElementById('crcInfo');
        var y = document.getElementById('crcContIcon');

        if (x.style.display == 'none') {
            x.style.display = 'block';
            y.style.display = 'none';
        } else {
            x.style.display = 'none';
            y.style.display = 'block';
        }
    }

    function ShowAndHideIcon() {
        var y = document.getElementById('crcContIcon');
        var x = document.getElementById('crcInfo');
        if (y.style.display == 'none') {
            y.style.display = 'block';
            x.style.display = 'none';
        } else {
            y.style.display = 'none';
            x.style.display = 'block';
        }
    }

    if (paths.includes(steps[currentStepIndex].path) && selectedAgentAssignment === 'crcdefault') {
        return (
            <div id="crcContainer" className={mobileView ? styles.crcStyleMobile : styles.crcStyle}>
                <div id="crcContIcon" style={!mobileView ? { display: "block" } : {}} className={mobileView ? styles.crcImageMobileStyle : styles.crcImageStyle}>
                    {mobileView ?
                        (<span>
                            <i id="singleChevron" onClick={() => ShowAndHideIcon()} className="fa fa-chevron-left" style={{ color: '#4D9CBC', margin: '0px 5px' }} />
                        </span>) : (<span></span>)}
                    <span>
                        <img alt="crcIcon" src="alfa-images/jpgcrc_Icon.png" className={mobileView ? styles.crcIconMobileClass : ''}
                        />
                    </span>
                </div>
                <div className={mobileView ? '' : styles.crcPointer}></div>
                <div id="crcInfo" style={!mobileView ? { display: "block" } : {}} className={mobileView ? styles.mobileCrcDivQuote : styles.crcDivQuote}>
                    <div class="crcTxtContainer">
                        <div className={styles.cardHeader}>
                            <img className={styles.gwUserimage} alt="crcIcon" src="alfa-images/user_Icon.svg" />
                            <p className={mobileView ? styles.headerTextMobile : styles.headerText}>Would you like to talk with an Alfa team member?</p>
                            {mobileView ?
                                (<span id="tripleChevron" onClick={() => ShowAndHideInfo()} className={styles.chevronStyle}>
                                    <i className="fa fa-chevron-right" style={{ color: '#4D9CBC' }} />
                                    <i className="fa fa-chevron-right" style={{ color: 'rgb(138, 192, 219)' }} />
                                    <i className="fa fa-chevron-right" style={{ color: 'rgb(215, 233, 244)' }} />
                                </span>) : (<span></span>)
                            }
                        </div>
                        <div className={styles.crcMargin}>
                            <div className={styles.phonetxt}>Call us at:</div>
                            <img alt="crcIcon" src="alfa-images/crc_phone.svg" className={styles.phoneStyle} />
                            { mobileView ? <a href="tel:+18009642532" className={styles.phoneContact}>1-800-964-2532</a> 
                                : <span className={styles.phoneContact}>1-800-964-2532</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return '';
}
export default CRCInfoComponent;