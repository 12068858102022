import React, {
    useContext, useEffect
} from 'react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import cookie from 'js-cookie';
import { EmailQuoteService } from 'gw-capability-quoteandbind';
import ErrorHandlingUtil from '../../../../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil';

function PaymentRedirect(props) {
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        const { search } = props.location;
        const params = new URLSearchParams(search);
        const policyNumberLocal = params.get('policyNumber');
        const transactionId = params.get('transactionId');
        const event = params.get('event');
        const confirmationNumber = params.get('confirmationNumber');
        const pacIncluded = params.get('pacIncluded');
        const docusignEvent = params.get('docusignEvent');
        let quoteID = params.get('quoteID');
        let postalCode = params.get('postalCode');
        const retrieveQbQuote = params.get('retrieveQbQuote');
        const agentId = params.get('agentId');
        const agentName = params.get('agentName');
        const page = 'Payment Redirect Page'

        if (retrieveQbQuote && retrieveQbQuote !== null) {
            if (_.isEmpty(quoteID)) {
                quoteID = '';
                postalCode = '';
            }
            props.history.push('/', { quoteId: quoteID, postalCode: postalCode, retrieveQbQuote: retrieveQbQuote });
        } else if ((agentId && agentId !== null) || (postalCode && postalCode !== null)) {
            if (agentId && agentId !== null) {
                const expireDate = new Date();
                expireDate.setDate(expireDate.getDate() + 30);
                cookie.set('agentId', agentId, {
                    expires: expireDate
                });
                cookie.set('agentName', agentName, {
                  expires: expireDate,
                });
            }

            quoteID = '';
            if (_.isEmpty(postalCode)) {
                postalCode = '';
            }
            const agentUrl_alfa = (agentId && agentId !== null) ?  window.location.href :  null
            props.history.push('/', { quoteId: quoteID, postalCode: postalCode, cmsAgentId: agentId, fromCMS: true, agentUrl_alfa });
        }

        if (policyNumberLocal && event == null) {
            EmailQuoteService.retrieveSubmissionConfirmation(policyNumberLocal)
                .then((submissionData) => {
                    const submissionVM = viewModelService.create(submissionData, 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO');
                    const submission = submissionVM.value;
                    if (transactionId !== null && confirmationNumber !== null) {
                        if ( submission.baseData.periodStatus !== 'Bound') {
                        const paymentusPromise = EmailQuoteService.validateTransaction(submissionVM.quoteID.value, transactionId);
                        paymentusPromise.then((response) => {
                            if (response) {
                                submission.confirmationNumber = confirmationNumber;
                                submission.pacIncluded = pacIncluded;
                                props.history.push('/payment-confirmation', { submission });
                            }
                            else {
                                ErrorHandlingUtil.processErrorResponse(props, {errorDescription: 'Failed while validating the Payment Transaction'}, submission, page);
                            }
                        },
                            (error) => {
                                console.log(error);
                            });
                        } else {
                            props.history.push('/payment-confirmation', { submission });
                        }
                    }


                    if (docusignEvent != null) {
                        if (docusignEvent.includes('complete')) {
                            props.history.push('/qnbconfirmation', { submission });
                        } else {
                            submission.baseData.quoteStatus = 'DocuSign';
                            const error = {
                                docuSignInd: true,
                                quoteStatus: 'docusign',
                                errorDescription: 'Failed at DocuSign Page'
                            };
                            ErrorHandlingUtil.processErrorResponse(props, error, submission, page);
                        }
                    }
                });
        }

        if (event !== null) {
            const replaceURL = window.location.origin.concat('/quote-and-buy/ampquoteentry?policyNumber=', policyNumberLocal, '&docusignEvent=', event);
            window.top.location.replace(replaceURL);
        }
    }, []);

    return true;
}
export default PaymentRedirect;
