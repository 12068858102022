import { defineMessages } from 'react-intl';

export default defineMessages({
    frequentlyAskedQuestions: {
        id: 'portal.index.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ',
    },
    faqs: {
        id: 'portal.index.FAQs',
        defaultMessage: 'FAQs',
    },
    checkAnswers: {
        id: 'portal.index.Check Answers',
        defaultMessage: 'Check Answers',
    },
    needAssistance: {
        id: 'portal.index.Need Assistance?',
        defaultMessage: 'Need Assistance?',
    },
    printQuote: {
        id: 'portal.index.printQuote',
        defaultMessage: 'Print Quote',
    },
    liveChatNow: {
        id: 'portal.index.Live Chat Now',
        defaultMessage: 'Live Chat Now',
    },
    furtherQuestions: {
        id: 'portal.index.Further Questions?',
        defaultMessage: 'Further Questions?',
    },
    saveEmail: {
        id: 'portal.index.saveEmail',
        defaultMessage: 'Save & Email',
    },
    manualDiscount: {
        id: 'portal.index.manualDiscount',
        defaultMessage: '* These discounts will require a few more steps in order to keep them once you purchase. Click each discount for more details.',
    },
    privacyPolicy: {
        id: 'footer.left_items.Privacy Policy',
        defaultMessage: 'Privacy Policy',
    },
    privacyPolicy_alfa: {
        id: 'footer.left_items.PRIVACY POLICY',
        defaultMessage: 'Privacy Policy'
    },
    termsOfUse: {
        id: 'footer.left_items.TERMS OF USE',
        defaultMessage: 'Terms Of Use'
    },
    faq_alfa: {

        id: 'footer.left_items.FAQ',
        defaultMessage: 'FAQ'
    },
    legalNotes: {
        id: 'footer.left_items.Legal Notes',
        defaultMessage: 'Legal Notes',
    },
    copyRight: {
        id: 'footer.right_items.Copyright &copy; 2020 Alfa Corporation',
        defaultMessage: 'Copyright © {value} Alfa Corporation'
    },
    home: {
        id: 'portal.index.Home',
        defaultMessage: 'Home'
    },
    mobileDiscountsHeader: {
        id: 'portal.index.discounts',
        defaultMessage: 'Discounts Applied!'
    },
    quoteID: {
        id: 'portal.index.quoteID',
        defaultMessage: 'QUOTE ID : '
    },
    contactUS: {
        id: 'footer.left_items.CONTACT US',
        defaultMessage: 'Contact Us'
    }
});
