import { defineMessages } from 'react-intl';

export default defineMessages({
    PopoverTitle: {
        id: 'address.standardization.popover.title',
        defaultMessage: 'Address'
    },
    PopoverInstructions: {
        id: 'address.standardization.popover.instructions',
        defaultMessage: 'Please select an address from below.'
    },
    AddressAsEntered: {
        id: 'address.standardization.popover.address.entered',
        defaultMessage: 'Address Entered'
    },
    StandardizedAddress: {
        id: 'address.standardization.popover.address.standardized',
        defaultMessage: 'Suggested Address'
    },
    NoAddressFound: {
        id: 'address.standardization.popover.address.NoAddressFound',
        defaultMessage: 'No Address Found'
    },
    NoAddressFoundInstruction: {
        id: 'address.standardization.popover.address.NoAddressFound.Instruction',
        defaultMessage: 'We are unable to standardize your address. Please select address entered.'
    },
    PopoverCancelButton: {
        id: 'address.standardization.popover.Cancel',
        defaultMessage: 'Cancel'
    },
    PopoverSelectButton: {
        id: 'address.standardization.popover.Select',
        defaultMessage: 'OK'
    }
});
