import React, { Component } from 'react';
import { Panel, withModal } from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import metadata from './VehicleCoveragePopover.metadata.json5';
import styles from './VehicleCoveragePopover.module.scss';

class VehicleCoveragePopover extends Component {
    state = {};

    handleDismiss = (evt) => {
        // eslint-disable-next-line react/prop-types
        const { dismissAction } = this.props;
        evt.stopPropagation();
        dismissAction();
    }

    render() {
        // eslint-disable-next-line react/prop-types

        const overrides = {

            cancel: {
                content: 'Got It',
                visible: 'true',

            },
            // ToDo remove this logic once 1/7/2023 has passed.
            roadSide: {
                content: this.props.flag ? " offers 24/7 towing service, up to one hour of mechanical assistance, locksmith labor and delivery of gasoline (up to two gallons), jump of battery or change of tire. Policy limits are up to $200 per day with a maximum based on the option chosen. The cost of a tire, battery, oil and other such items are not covered." :
                    " offers 24/7 towing service, up to one hour of mechanical assistance, locksmith labor and delivery of gasoline (up to two gallons), oil, jump of battery or change of tire. Policy limits are $100 per day with a maximum based on the option chosen. The cost of a tire, battery, oil and other such items are not covered."
            }

        };
        const resolvers = {
            resolveCallbackMap: {
                onCancel: this.handleDismiss,

            },
            resolveClassNameMap: styles
        };
        return (
            <Panel id="standardizedPanel" className={styles.modalWidth}>
                {renderContentFromMetadata(metadata.componentContent, overrides, resolvers)}
            </Panel>
        );
    }
}

export default withModal(VehicleCoveragePopover);
