import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentToggleMonthly: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Monthly',
        defaultMessage: 'Monthly'
    },
    paymentToggleAnnually: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.defaultQuoteView.Pay In Full',
        defaultMessage: 'Pay In Full'
    },
    quoteAmountMonthly: {
        id: 'quoteandbind.common.directives.common.Monthly',
        defaultMessage: '/ mo.'
    },
    quoteAmountAnually: {
        id: 'quoteandbind.common.directives.common.Annually',
        defaultMessage: '/ pa.'
    },
    buyNow: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Buy Now',
        defaultMessage: 'Buy Now',
    },
    dueTodayTooltip: {
        id: 'quoteandbind.common.directives.common.dueTodayTooltip',
        defaultMessage: 'An Alabama Farmers Federation membership is required to purchase and maintain an insurance policy. The amount due today includes the $33.00 annual membership dues and $3.00 voluntary  FarmPAC contribution.',
    },
    customize: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Customize',
        defaultMessage: 'Customize',
    },
    viewCoverage: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.View Coverages',
        defaultMessage: 'View Coverages',
    },
    hideCoverage: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Hide Coverages',
        defaultMessage: 'Hide Coverages',
    },
    recalculate: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Recalculate',
        defaultMessage: 'Recalculate',
    },
    resetCoverages: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Reset Coverages',
        defaultMessage: 'Reset Quote',
    },
    moreInfo: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.mobileQuoteView.mobile-coverage-summary.mobile-coverage-summary.More info / Change options',
        defaultMessage: 'More info / Change options',
    },
    quoteError: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Quote Error',
        defaultMessage: 'Oops, something went wrong. Please correct any errors indicated below and try again.'
    },
    quoteClauseTableCaption: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Quote Clause Table Caption',
        defaultMessage: 'Not all coverage options, limits and deductibles are shown online. Please contact us if you do not see options that fit your needs.'
    }
});
