import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { useAuthentication } from './AuthenticationContext';

function getDestination(from, loginPath) {
    if (from === loginPath) {
        return loginPath;
    }
    return from;
}

function RedirectAfterLogin({ successPath, loginPath }) {
    // retain previous state
    const [wasLoggedIn, updateWasLoggedIn] = useState(false);
    const { isLoggedIn } = useAuthentication();
    useEffect(() => {
        updateWasLoggedIn(isLoggedIn);
    }, [isLoggedIn]);

    if (isLoggedIn && !wasLoggedIn) {
        const dest = getDestination(successPath, loginPath);
        return <Redirect to={dest} />;
    }

    return null;
}

RedirectAfterLogin.propTypes = {
    successPath: PropTypes.string,
    loginPath: PropTypes.string
};

RedirectAfterLogin.defaultProps = {
    successPath: '/',
    loginPath: '/login-page'
};

export default withRouter(RedirectAfterLogin);
