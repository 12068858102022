import StringUtil from 'gw-portals-util-js/StringUtil';

/**
 * Get string value of Address Line 1
 * @param {object} addrData
 * @returns {String}
 */
function getAddrLine1Str(addrData) {
    let result = '';
    if (addrData.searchText !== undefined && addrData.searchText !== null) {
        if (addrData.searchText.value !== undefined && addrData.searchText.value !== null) {
            result = addrData.searchText.value.toString();
        } else {
            result = addrData.searchText.toString();
        }
    } else if (addrData.addressLine1 !== undefined && addrData.addressLine1 !== null) {
        if (addrData.addressLine1.value !== undefined && addrData.addressLine1.value !== null) {
            result = addrData.addressLine1.value.toString();
        } else {
            result = addrData.addressLine1.toString();
        }
    } else {
        result = '';
    }
    return result;
}

/**
 * Get string value of Address Line 2
 * @param {object} addrData
 * @returns {String}
 */
function getAddrLine2Str(addrData) {
    let result = '';
    if (addrData.addressLine2 !== undefined && addrData.addressLine2 !== null) {
        if (addrData.addressLine2.value !== undefined && addrData.addressLine2.value !== null) {
            result = addrData.addressLine2.value.toString();
        } else {
            result = addrData.addressLine2.toString();
        }
    }
    return result;
}

/**
 * Get string value of City
 * @param {object} addrData
 * @returns {String}
 */
function getAddrCityStr(addrData) {
    let result = '';
    if (addrData.city !== undefined && addrData.city !== null) {
        if (addrData.city.value !== undefined && addrData.city.value !== null) {
            result = addrData.city.value.toString();
        } else {
            result = addrData.city.toString();
        }
    }
    return result;
}

/**
 * Get string value of State abbreviation
 * @param {object} addrData
 * @returns {String}
 */
function getStateString(addrData) {
    let result = '';
    if (addrData.state !== undefined && addrData.state !== null) {
        if (addrData.state.value !== undefined && addrData.state.value !== null) {
            if (addrData.state.value && addrData.state.value.code !== undefined && addrData.state.value.code !== null) {
                result = addrData.state.value.code;
            } else {
                result = addrData.state.value.toString();
            }
        } else {
            result = addrData.state.toString();
        }
    }
    return result;
}

/**
 * Get 5 Digit string value of ZIP code
 * @param {object} addrData
 * @returns {String}
 */
function getZIP5Str(addrData) {
    let result = '';
    if (addrData.postalCode !== undefined && addrData.postalCode !== null) {
        if (addrData.postalCode.value !== undefined && addrData.postalCode.value !== null) {
            result = addrData.postalCode.value.toString().substring(0, 5);
        } else {
            result = addrData.postalCode.toString().substring(0, 5);
        }
    }
    return result;
}

/**
 * Address utility functions
 */
export default {
//    /**
//     * Capitalizes a first letter of the string.
//     * @param {String} str
//     * @returns {string}
//     */
//    capitalizeFirstLetter: (str) => {
//        return str.charAt(0).toUpperCase() + str.substring(1);
//    },

    /**
     * Compare Addresses
     * @param {object} addr1
     * @param {object} addr2
     * @returns {boolean}
     */
    addressesMatch: (addr1, addr2) => {
        if ((addr1 === undefined) || (addr2 === undefined)) { return false; }

        const addr1Line1 = getAddrLine1Str(addr1);
        const addr1Line2 = getAddrLine2Str(addr1);
        const addr1City = getAddrCityStr(addr1);
        const addr1State = getStateString(addr1);
        const addr1PostalCode = getZIP5Str(addr1);

        const addr2Line1 = getAddrLine1Str(addr2);
        const addr2Line2 = getAddrLine2Str(addr2);
        const addr2City = getAddrCityStr(addr2);
        const addr2State = getStateString(addr2);
        const addr2PostalCode = getZIP5Str(addr2);

        const lines1Match = StringUtil.stringCompare(addr1Line1, addr2Line1, true);
        const lines2Match = StringUtil.stringCompare(addr1Line2, addr2Line2, true);
        const citiesMatch = StringUtil.stringCompare(addr1City, addr2City, true);
        const stateMatch = StringUtil.stringCompare(addr1State, addr2State, true);
        const postalCodesMatch = StringUtil.stringCompare(addr1PostalCode, addr2PostalCode, true);

        return (lines1Match && lines2Match && citiesMatch && stateMatch && postalCodesMatch);
    },

    /**
     * Check to see if address is complete
     * @param {object} addr
     * @returns {boolean}
     */
    addressIsComplete: (addr) => {
        const addrLine1 = getAddrLine1Str(addr);
        const city = getAddrCityStr(addr);
        const state = getStateString(addr);
        const postalCode = getZIP5Str(addr);
        return ((addrLine1.length > 0) && (city.length > 0) && (state.length > 0) && (postalCode.length > 0));
    },

};
