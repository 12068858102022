import React, { useCallback, useMemo, useEffect, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { Tooltip, IconButton, Loader, ModalService } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';

import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { AddCoverablesService } from 'gw-capability-policycommon-alfa';
import trainingCoursePopOver from '../DriverPopup/TrainingCoursePopover';
import metadata from './DriverComponent.metadata.json5';
import styles from './DriverComponent.module.scss';

import messages from './DriverComponent.messages';


function DriverComponentTooltip(messageType) {
    const translator = useContext(TranslatorContext);
    const messagePath = 'quoteandbind.pa.directives.templates.pa-edit-driver.';
    const message = `${messagePath}${messageType.id}`;

    const loadPopup = useCallback(() => {
        const comp = {};
        if (messageType.id === 'tooltipCourse') {
            return ModalService.showPopover(trainingCoursePopOver, comp);
        }
        return '';
    }, []);
    return (
        <Tooltip
            animation="fade"
            content={translator(_.find(messages, { id: message }))}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton icon="fa-info-circle" aria-haspopup="true" onClick={loadPopup} className={styles.gwToolTip} aria-hidden="true" aria-label="Information" />
        </Tooltip>
    );
}

function DriverComponent(props) {
    const {
        data: driverVM,
        readOnlyFields,
        labelPosition,
        showOptional,
        path,
        id,
        onValidate,
        onValueChange,
        createDriverVM,
        addDriver,
        showError,
        quoteId,
        commodityFlag,
        driverState,
        updateOpenAccValidInd,
        isPolicyHolderFarmer,
        isPNIHasCommodity,
        driverAtleastOneCommodity,
        dateOfBirthInValid,
        drivers,
        triggerDateError
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    // eslint-disable-next-line max-len
    const honor = driverVM.driverAge.value !== undefined ? ((driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 24)) : false;
    // eslint-disable-next-line max-len
    const driverTraining = driverVM.driverAge.value !== undefined ? ((driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 20)) : false;
    const [ifHonorStudent, setIfHonorStudent] = useState(honor);
    const [hasDriverTraining, setHasDriverTraining] = useState(driverTraining);
    // eslint-disable-next-line max-len
    const defensiveDriver = driverVM.driverAge.value !== undefined ? driverVM.driverAge.value >= 55 : false;
    const [ifDefensiveDriver, setIfDefensiveDriver] = useState(defensiveDriver);
    const [showAddError, setShowAddError] = useState(false);
    const [showDateError, setShowDateError] = useState(false);
    const [triggerInlineDateError, setTriggerInlineDateError] = useState(false);
    const [resetField, setResetField] = useState(false);
    const [showCourseError, setShowCourseError] = useState(false);
    const [dateErrorMessage, updateDateErrorMessage] = useState('');
    const [clonedInitialData, setClonedInitialData] = useState({});
    const [cleanData, setCleanData] = useState(false);
    const [clearDate, setClearDate] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [commoditiesArr, updateCommoditiesArr] = useState([]);
    const [noTicketViolationAdded, setNoTicketViolationAdded] = useState(false);
    const commodityCloseInd = useRef(false);
    const breakpoint = useContext(BreakpointTrackerContext);


    const translator = useContext(TranslatorContext);

    let month;
    let prevDay;
    let prevYear;
    let mobileDate = '';
    if (driverVM.person.dateOfBirth.value !== undefined) {
        month = (driverVM.person.dateOfBirth.month.value + 1);
        prevDay = driverVM.person.dateOfBirth.day.value;
        prevYear = driverVM.person.dateOfBirth.year.value;
        mobileDate = `${month}/${prevDay}/${prevYear}`;
    }

    const [dateOfBirth, updateDateOfBirth] = useState({
        month: month,
        day: prevDay,
        year: prevYear
    });

    const [dateOfBirthMobile, updateDateOfBirthMobile] = useState(mobileDate);

    useEffect(() => {
        const clonedDate = _.cloneDeep(driverVM.value);
        setClonedInitialData(clonedDate);
        setCleanData(false);
        setClearDate(false);
    }, [setClonedInitialData, setCleanData, driverVM, setClearDate]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [driverVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            let fullPath = path;
            if (changedPath !== '') {
                fullPath = `${path}.${changedPath}`;
                setClearDate(false);
            }
            if (changedPath === 'occupation' && value !== 'Farmer') {
                _.set(driverVM, 'commodities', []);
            }
            if (onValueChange) {
                onValueChange(value, fullPath);
                setCleanData(false);
                updateOpenAccValidInd(path);
            }
        },
        [onValueChange, path, updateOpenAccValidInd, driverVM]
    );
    const addcommodities = useCallback(
        (value, changedPath) => {
            if (value.length <= 3) {
                updateCommoditiesArr(value);
                _.set(driverVM, changedPath, value);
                commodityCloseInd.current = value.length === 0;
            }
        },
        [driverVM, commoditiesArr, updateCommoditiesArr]
    );

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const validateAge = (dateToValidate) => {
        const ageToValidate = moment().diff(moment(dateToValidate, 'MM/DD/YYYY'), 'years');
        if (ageToValidate <= 15 && driverVM.isPolicyHolder.value) {
            setShowDateError(true);
            updateDateErrorMessage('Primary Named Insured\'s Date of Birth is ineligible to continue online.');
            return false;
        }else if (ageToValidate <= 15 && !driverVM.isPolicyHolder.value) {
            setShowDateError(true);
            updateDateErrorMessage('Driver\'s Date of Birth is ineligible to continue online.');
            return false;
        }
        _.set(driverVM, 'driverAge', ageToValidate);
        if (driverVM.driverAge.value !== undefined) {
            if (driverVM.driverAge.value >= 55) {
                setIfDefensiveDriver(true);
                setIfHonorStudent(false);
                setHasDriverTraining(false);
            } else {
                setIfDefensiveDriver(false);
                _.set(driverVM, 'defensiveDriver', false);
            }
            if ((driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 24)) {
                setIfDefensiveDriver(false);
            }
            if ((driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 24)) {
                setIfHonorStudent(true);
            } else {
                setIfHonorStudent(false);
            }
            if ((driverVM.driverAge.value >= 21) && driverState === 'GA') {
                setIfDefensiveDriver(true);
            }
            if ((driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 20)) {
                const eligibleTraining = driverTrainingFlag()
                setHasDriverTraining(eligibleTraining);
                setIfDefensiveDriver(false);
            }
        }


        if (ageToValidate < 16 && !driverVM.isPolicyHolder.value) {
            _.set(driverVM, 'driverTrainingCourse', false);
            _.set(driverVM, 'isHonorStudent', false);
            _.set(driverVM, 'youthDefensiveDriver', false);
        }
        return true;
    };
    const driverTrainingFlag = useCallback(() => {
        const state = ['AL', 'MS'];
        const driverAge = (driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 20);
        if (driverVM.maritalStatus.value !== undefined && driverAge && state.includes(driverState)) {
            if (driverVM.maritalStatus.value.code === 'S') {
                return true;
            }
            if (driverVM.maritalStatus.value.code === 'M' && driverVM.gender.value) {
                return true;
            }
        }
        return false;
    }, [driverVM, driverState]);

    const driverTrainingFlagGa = useCallback(() => {
        const driverAge = (driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 20);
        if (driverAge && driverState === 'GA') {
            return true;
        }
        return false;
    }, [driverVM, driverState]);

    const dateChecker = useCallback(
        (isMobile) => {
            if (dateOfBirthMobile !== undefined && dateOfBirthMobile !== '' && isMobile) {
                const dob = dateOfBirthMobile.split('/');
                _.set(dateOfBirth, 'month', dob[0]);
                _.set(dateOfBirth, 'day', dob[1]);
                _.set(dateOfBirth, 'year', dob[2]);
            }
            if (isMobile) {
                if (dateOfBirthMobile === undefined || dateOfBirthMobile === '') {
                    updateDateErrorMessage('Please enter a date of birth');
                    setShowDateError(true);
                    return true;
                }
            }
            if ((dateOfBirth.day === undefined || dateOfBirth.day === '')
                || (dateOfBirth.month === undefined || dateOfBirth.month === '')
                || (dateOfBirth.year === undefined || dateOfBirth.year === '')) {
                setShowDateError(true);
                updateDateErrorMessage('Please enter a date of birth');
                return true;
            }
            const newMonth = (dateOfBirth.month.toString().length === 1) ? '0'.concat(dateOfBirth.month) : dateOfBirth.month;
            const day = (dateOfBirth.day.toString().length === 1) ? '0'.concat(dateOfBirth.day) : dateOfBirth.day;
            const { year } = dateOfBirth;

            const dateToValidate = ''.concat(newMonth, '/', day, '/', year);
            const currentDate = _.get(driverVM, 'baseData.sysDate.value');
            if (year < 1900) {
                setShowDateError(true);
                updateDateErrorMessage('Please enter a date of birth');
                return true;
            }
            if (moment(dateToValidate).isAfter(currentDate)) {
                setShowDateError(true);
                updateDateErrorMessage('Please enter a valid date of birth');
                return true;
            }

            const isDateValid = moment(dateToValidate, 'MM/DD/YYYY', true).isValid();

            if (!isDateValid) {
                setShowDateError(true);
                updateDateErrorMessage('Please enter a valid date of birth');
                return true;
            }
            if (!validateAge(dateToValidate, currentDate)) {
                return true;

            }
            setShowDateError(false);

            updateDateErrorMessage('');
            const adjustMonth = dateOfBirth.month - 1;
            _.set(driverVM, 'person.dateOfBirth.value.day', day);
            _.set(driverVM, 'person.dateOfBirth.value.month', adjustMonth);
            _.set(driverVM, 'person.dateOfBirth.value.year', year);
            _.set(driverVM, 'dateOfBirth.value.day', day);
            _.set(driverVM, 'dateOfBirth.value.month', adjustMonth);
            _.set(driverVM, 'dateOfBirth.value.year', year);
            return false;
        }, [driverVM, dateOfBirthMobile, dateOfBirth]
    );

    const courseVisible = useCallback(() => {
        if (driverState === 'GA' && !_.isUndefined(driverVM.driverTrainingCourse.value)) {
            if (driverVM.driverAge.value > 15 && driverVM.driverAge.value < 21 && driverVM.driverTrainingCourse.value) {
                return true;
            }
        }
        return false;
    }, [driverState, driverVM.driverTrainingCourse.value]);

    const courseError = useCallback(() => {
        if (_.isUndefined(driverVM.trainingType.value) && driverState === 'GA' && courseVisible()) {
            return true;
        }
        return false;
    }, [courseVisible, driverState, driverVM.trainingType.value]);

    const commoditiesFlag = useCallback(() => {
        if (driverState === 'AL' && driverVM.occupation.value !== undefined) {
            if (driverVM.occupation.value.code === 'Farmer' && driverVM.commodities.value && !driverVM.commodities.value.every(_.isNull)) {
                return true;
            }
            if (!isPNIHasCommodity && !driverAtleastOneCommodity && driverVM.isPolicyHolder.value && driverVM.occupation.value.code === 'Farmer') {
                return true;
            }
            if (!isPolicyHolderFarmer && driverVM.relationshipToPNI.value && driverVM.relationshipToPNI.value.code === 'SP' && driverVM.occupation.value && driverVM.occupation.value.code === 'Farmer') {
                return true;
            }
            if (driverVM.occupation.value.code === 'Farmer' && commodityCloseInd.current) {
                return true;
            }
        }
        return false;
    }, [driverState, driverVM, isPolicyHolderFarmer, isPNIHasCommodity, driverAtleastOneCommodity]);

    const disableAddDriver = useCallback(() => {
        if (!driverVM.aspects.subtreeValid) {
            if (_.isUndefined(driverVM.dateOfBirth.value) && showDateError && (!_.isUndefined(dateOfBirth.day) && !_.isUndefined(dateOfBirth.month)
                && !_.isUndefined(dateOfBirth.year))) {
                const index = path.split('.').pop();
                let invalidChild = false;
                const filteredInvalid = driverVM.baseVMNode.container.aspects.invalidChildProperties.filter(
                    (property) => {
                        return property.includes(`.lobData.personalAuto.coverables.drivers[${index}]`);
                    });
                _.forEach(filteredInvalid, (driver) => {
                    const lastProperty = driver.split('.').pop();
                    if (lastProperty !== 'dateOfBirth' && lastProperty !== 'driverAge') {
                        invalidChild = true;
                    }
                })
                if (invalidChild) {
                    return true;
                }
            } else {
                return true;
            }
        }
        if (courseError() || (!_.isUndefined(driverVM.occupation.value)
            && driverVM.occupation.value.code === 'Farmer' && _.isEmpty(driverVM.commodities.value)
            && driverState === 'AL') && commoditiesFlag()) {
            return true;
        }
        return false;
    }, [commoditiesFlag, courseError, driverVM, showDateError, dateOfBirth]);

    const writeDateValue = useCallback(
        (value) => {
            _.set(dateOfBirth, 'month', value.month);
            _.set(dateOfBirth, 'day', value.day);
            _.set(dateOfBirth, 'year', value.year);
            updateDateOfBirth(dateOfBirth);

            setTriggerInlineDateError(false);
            const inValid = dateChecker(false);
            if (!driverVM.isPrefillDriver.value && !driverVM.isSelectedDriver.value
                && !driverVM.isPolicyHolder.value && dateOfBirthInValid) {
                dateOfBirthInValid(inValid);
            }
        },
        [dateOfBirth, dateChecker, dateOfBirthInValid, setTriggerInlineDateError]

    );
    const writeMobileDateValue = useCallback(
        (newValue) => {
            const normalisedValue = newValue.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d/]/g, '');
            updateDateOfBirthMobile(normalisedValue);
        }, [updateDateOfBirthMobile]
    );
    const checkMobileDateValue = useCallback(
        (evt) => {
            const inValid = dateChecker(true);
            if (!driverVM.isPrefillDriver.value && !driverVM.isSelectedDriver.value
                && !driverVM.isPolicyHolder.value && dateOfBirthInValid) {
                dateOfBirthInValid(inValid);
            }
        }, [dateChecker, dateOfBirthInValid]
    );


    /**
    *  const driverYearFirstLicencedAvailableValues = useMemo(() => {
        const currentYear = new Date().getUTCFullYear();
        const { ageAllowedToDrive } = config.personalAutoConfig;
        const dateOfBirth = _.get(driverVM, 'dateOfBirth.value');

        const earliestYear = _.isNil(dateOfBirth)
            ? currentYear - ageAllowedToDrive
            : dateOfBirth.year + ageAllowedToDrive;

        if (earliestYear > currentYear) {
            // return an empty object instead of just an empty array so that
            // the dropdown menu will show "No options"
            return [{}];
        }

        // _.range is not inclusive. Need to add -1 to get correct years
        const yearRange = _.range(currentYear, earliestYear - 1);

        return yearRange.map(_.toString).map((year) => ({
            code: year,
            name: year
        }));
    }, [driverVM]);
    **/

    const moveArrayItem = (fromIndex, toIndex, arrayVal) => {
        const element = arrayVal[fromIndex];
        arrayVal.splice(fromIndex, 1);
        arrayVal.splice(toIndex, 0, element);
    }

    const getAvailableList = (model) => {
        let field = _.get(driverVM, `${model}.aspects.availableValues`);
        if (model === 'relationshipToPNI') {
            field = driverVM.relationshipToPNI.aspects.availableValues.filter((typeCode) => {
                return typeCode.code !== 'self_alfa';
            });
        }
        if (model === 'course') {
            field = driverVM.trainingType.aspects.availableValues.filter((typeCode) => {
                return ['DriversTraining', 'DefensiveDriver_alfa', 'YouthSaftey_alfa'].includes(typeCode.code);
            });
        }
        const availableList = field.map((key) => {
        if (model === 'course') {
                let courseName = translator({ id: key.name, defaultMessage: key.code });
                courseName = courseName.replace('Discount', '');
                return {
                    code: key.code,
                    name: courseName
                };
        }
            return {
                code: key.code,
                name: translator({ id: key.name, defaultMessage: key.code })
            };
        });
        if (model === 'suffix') {
            let suffixList = [...field];
            moveArrayItem(5, 0, suffixList);
            moveArrayItem(5, 0, suffixList);
            suffixList = suffixList.map((key) => {
                return {
                    code: key.code,
                    name: translator({ id: key.name, defaultMessage: key.code })
                };
            });
            return suffixList;
        }
        return availableList;
    };

    const getAge = (dateOfBirth) => {
        if (dateOfBirth.year !== null) {
            const today = new Date();
            const birthDate = new Date(String(dateOfBirth.year), String(dateOfBirth.month), String(dateOfBirth.day));
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            _.set(driverVM, 'driverAge', age);
            return age;
        }
        return null;
    };


    const cancelDriver = useCallback(() => {
        const { removeDriver } = props;
        driverVM.value = { ...clonedInitialData };
        if (driverVM.value && !driverVM.value.isPrefillDriver && !driverVM.value.isSelectedDriver && !driverVM.value.isPolicyHolder) {
            driverVM.value.person = {};
            updateDateOfBirth({
                month: undefined,
                day: undefined,
                year: undefined
            });
            updateDateOfBirthMobile('');
            _.set(driverVM, 'person.firstName.person.firstName', undefined);
            _.set(driverVM, 'person.lastName.person.lastName', undefined);
            setClearDate(true);
            setResetField(true);
        }
        setTriggerInlineDateError(false);
        setCleanData(true);
        setShowDateError(false);
        removeDriver('', driverVM.value, true);
    }, [clonedInitialData, driverVM, updateDateOfBirth, updateDateOfBirthMobile, setResetField]);

    const updateHonorStudent = () => {
        if (driverVM.maritalStatus.value !== undefined && driverVM.maritalStatus.value.code === 'S') {
            if (driverVM.driverAge.value >= 16 && driverVM.driverAge.value <= 24) {
                if (_.get(driverVM, 'isHonorStudent.value')) {
                    _.set(driverVM, 'isHonorStudent', true);
                } else {
                    _.set(driverVM, 'isHonorStudent', false);
                }
            } else {
                _.set(driverVM, 'isHonorStudent', false);
            }
        } else {
            _.set(driverVM, 'isHonorStudent', false);
        }
    }

    const updateTrainingCourse = () => {
        if (driverState=== 'GA') {
            if (driverVM.driverAge.value > 15 && driverVM.driverAge.value < 21) {
                if (!_.get(driverVM, 'driverTrainingCourse.value')) {
                    _.set(driverVM, 'driverTrainingCourse', false);
                    _.set(driverVM, 'youthSafety', false);
                } else {
                    if (driverVM.trainingType.value.code === 'DriversTraining') {
                        _.set(driverVM, 'driverTrainingCourse', true);
                        _.set(driverVM, 'defensiveDriver', false);
                        _.set(driverVM, 'youthSafety', false);
                    } else if (driverVM.trainingType.value.code === 'DefensiveDriver_alfa') {
                        _.set(driverVM, 'defensiveDriver', true);
                        _.set(driverVM, 'youthSafety', false);
                        _.set(driverVM, 'driverTrainingCourse', true);
                    } else if (driverVM.trainingType.value.code === 'YouthSaftey_alfa') {
                        _.set(driverVM, 'youthSafety', true);
                        _.set(driverVM, 'defensiveDriver', false);
                        _.set(driverVM, 'driverTrainingCourse', true);
                    }
                }
            } else {
                _.set(driverVM, 'driverTrainingCourse', false);
                _.set(driverVM, 'youthSafety', false);
            }
        }
    }

    const addDriverVM = () => {
        if (driverVM.isCurrentCarrierExpired.value) {
            // eslint-disable-next-line max-len
            driverVM.hadContServInPast6MOs_alfa.value = driverVM.haveCurrentInsurance.value ? true : undefined;
        }
        if (showDateError) {
            setTriggerInlineDateError(true);
            return false;
        }

        if ((driverState === 'GA') || (driverState !== 'GA' && (driverVM.driverTrainingCourse.value || 
        driverVM.driverAge.value < 16 || driverVM.driverAge.value > 20 || driverVM.maritalStatus.value.code !== 'S' ))) {
           _.set(driverVM, 'youthDefensiveDriver', false);
        }
        if (driverState !== 'GA' && (driverVM.driverAge.value < 16 || driverVM.driverAge.value > 20)) {
            _.set(driverVM, 'driverTrainingCourse', false);
        }
        if (driverState !== 'GA' && driverVM.driverTrainingCourse.value && (driverVM.driverAge.value >= 16) && (driverVM.driverAge.value <= 20)) {
            const hasDriverTraining = driverTrainingFlag()
            setHasDriverTraining(hasDriverTraining);
            _.set(driverVM, 'driverTrainingCourse', hasDriverTraining);
        }
        updateHonorStudent();
        updateTrainingCourse();
        _.set(driverVM, 'dateOfBirth', driverVM.person.dateOfBirth);

        if (driverVM.tktsOrViolationsInLastThreeYears.value && driverVM.accidents === '0' && driverVM.violations === '0') {
            setShowAddError(true);
            return false;
        }
        // eslint-disable-next-line max-len
        const accidentVal = driverVM.numberOfMajorViolations.aspects.availableValues[driverVM.accidents];
        // eslint-disable-next-line max-len
        const violationVal = driverVM.numberOfMinorViolations.aspects.availableValues[driverVM.violations];
        _.set(driverVM, 'numberOfMajorViolations', accidentVal);
        _.set(driverVM, 'numberOfMinorViolations', violationVal);

        // Creating add driver request object
        const addDriverRequestDTO = {
            quoteNumber: quoteId,
            driver: driverVM.value
        };
        updateIsLoading(true);
        // Calling Add Driver service
        const responsePromise = AddCoverablesService.addDriver(addDriverRequestDTO);
        responsePromise.then(() => {
            if (!driverVM.isPrefillDriver.value && !driverVM.isSelectedDriver.value
                && !driverVM.isPolicyHolder.value) {
                createDriverVM(false);
            }
            _.set(driverVM, 'isSelectedDriver', true);
            _.set(driverVM, 'isOpen', false);
            const clonedDate = _.cloneDeep(driverVM.value);
            setClonedInitialData(clonedDate);
            addDriver();
            updateIsLoading(false);
        }, (error) => {
            updateIsLoading(false);
            if (!_.isUndefined(error.baseError) && error.baseError.includes('Invalid Recaptcha')) {
                throw error;
            }
        });
        return true;
    };

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition
        },
        nameComponent: {
            visible: !driverVM.isPrefillDriver.value && !driverVM.isPolicyHolder.value
        },
        driverSuffix: {
            availableValues: getAvailableList('suffix')
        },
        driverGender: {
            labelPosition: 'left'
        },
        driverGenderError: {
            visible: showError && !driverVM.gender.aspects.subtreeValid
        },
        driverFirstName: {
            showErrors: showError,
            required: showError,
            triggerReset: resetField
        },
        lastName: {
            showErrors: showError,
            required: showError,
            triggerReset: resetField
        },
        dateOfBirth: {
            labelPosition: 'top',
            onValueChange: writeDateValue,
            showErrors: triggerInlineDateError || triggerDateError
                && ((dateOfBirth.day === undefined && dateOfBirth.month === undefined && dateOfBirth.year === undefined) || showDateError),
            errorMessage: triggerDateError && !showDateError ? 'Please enter a date of birth' : dateErrorMessage,
            prevDate: dateOfBirth,
            clearDate: clearDate
        },
        dateOfBirthMobile: {
            onBlur: checkMobileDateValue,
            onValueChange: writeMobileDateValue,
            showErrors: showDateError || triggerDateError,
            errorMessage: triggerDateError && !showDateError ? 'Please enter a date of birth' : dateErrorMessage,
            requiredFieldValidationMessage: [dateErrorMessage],
            value: dateOfBirthMobile
        },
        dateError: {
            visible: showDateError || (triggerDateError && dateOfBirthMobile.length < 10),
            content: triggerDateError && !showDateError ? 'Please enter a date of birth' : dateErrorMessage
        },
        driverRelationshiptoPNI: {
            visible: !driverVM.isPolicyHolder.value,
            availableValues: getAvailableList('relationshipToPNI'),
            showErrors: showError,
            required: showError
        },
        course: {
            availableValues: getAvailableList('course'),
            showErrors: showError,
            required: showError,
            errorDescription: 'Please select completed course'
        },

        courseComponent: {
            visible: courseVisible()
        },
        driverRelationshipDropDown: {
            visible: !driverVM.isPolicyHolder.value
        },
        driverMaritalStatus: {
            availableValues: getAvailableList('maritalStatus'),
            showErrors: showError,
            required: showError,
            errorDescription: translator(messages.driverMaritalError)
            //allowNew: false
            //breakpoint !== 'phone'
        },
        driverOccupationDropDown: {
            visible: driverVM.isPolicyHolder.value || (driverVM.relationshipToPNI.value !== undefined && driverVM.relationshipToPNI.value.code === 'SP')
        },
        driverOccupation: {
            availableValues: getAvailableList('occupation'),
            showErrors: showError,
            required: showError
        },
        driverCommodities: {
            visible: commoditiesFlag(),
            availableValues: getAvailableList('commoditiesTypeList'),
            onValueChange: addcommodities,
            required: showError,
            requiredFieldValidationMessage: ' '

        },
        driverCommoditiesError: {
            visible: showError && (!_.isUndefined(driverVM.occupation.value) && driverVM.occupation.value.code === 'Farmer' && _.isEmpty(driverVM.commodities.value) && driverState === 'AL') && commoditiesFlag()

        },
        driverDontWish: {
            visible: !driverVM.isPolicyHolder.value
        },
        driverExcluded: {
            // eslint-disable-next-line max-len
            visible: driverVM.dontWishToAddDriver.value !== undefined && driverVM.dontWishToAddDriver.value
        },
        driverHonor: {
            visible: (driverVM.maritalStatus.value !== undefined && driverVM.maritalStatus.value.code === 'S') && ifHonorStudent
        },
        driverHonorError: {
            visible: showError && !driverVM.isHonorStudent.aspects.subtreeValid
        },
        driverTraining: {
            visible: driverTrainingFlag()
        },
        driverTrainingGa: {
            visible: driverTrainingFlagGa()
        },
        driverTrainingError: {
            visible: showError && !driverVM.driverTrainingCourse.aspects.subtreeValid
        },
        driverYouthDefense: {
            visible: (driverVM.driverAge.value > 15 && driverVM.driverAge.value < 21) && (driverVM.maritalStatus.value !== undefined && driverVM.maritalStatus.value.code === 'S') && driverVM.driverTrainingCourse.value !== undefined && !driverVM.driverTrainingCourse.value && ['AL', 'MS'].includes(driverState)
        },
        driverYouthDefenseError: {
            visible: showError && !driverVM.youthDefensiveDriver.aspects.subtreeValid
        },
        driverDefense: {
            visible: (ifDefensiveDriver && driverState !== 'GA') || (driverVM.driverAge.value > 20 && driverState === 'GA') || (driverVM.driverAge.value <= 20 && driverState === 'GA' && (driverVM.driverTrainingCourse.value !== undefined && !driverVM.driverTrainingCourse.value))
        },
        driverDefenseError: {
            visible: showError && !driverVM.defensiveDriver.aspects.subtreeValid
        },
        discountsAndViolationsContainer: {
            visible: !driverVM.dontWishToAddDriver.value
        },
        driverTicketViolationError: {
            visible: showAddError && (driverVM.accidents === '0' && driverVM.violations === '0')
        },
        driverMajorViolationMain: {
            // eslint-disable-next-line max-len
            visible: driverVM.tktsOrViolationsInLastThreeYears.value && !driverVM.dontWishToAddDriver.value
        },
        driverNumberOfAccidents: {
            onValueChange: writeStepperValue,
            value: driverVM.accidents
        },
        driverMajorViolationInfo: {
            // eslint-disable-next-line max-len
            visible: driverVM.tktsOrViolationsInLastThreeYears.value && !driverVM.dontWishToAddDriver.value
        },
        driverMinorViolationMain: {
            // eslint-disable-next-line max-len
            visible: driverVM.tktsOrViolationsInLastThreeYears.value && !driverVM.dontWishToAddDriver.value
        },
        driverNumberOfViolations: {
            onValueChange: writeStepperValue,
            value: driverVM.violations
        },
        driverMinorViolationInfo: {
            // eslint-disable-next-line max-len
            visible: driverVM.tktsOrViolationsInLastThreeYears.value && !driverVM.dontWishToAddDriver.value
        },
        addButton: {
            disabled: disableAddDriver()
        },
        addButtonMobile: {
            disabled: !driverVM.aspects.subtreeValid || courseError() || (!_.isUndefined(driverVM.occupation.value) && driverVM.occupation.value.code === 'Farmer' && _.isEmpty(driverVM.commodities.value) && driverState === 'AL') && commoditiesFlag()
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => {
            return readViewModelValue(
                metadata.pageContent,
                driverVM,
                fieldId,
                fieldPath,
                overrideProps
            );
        },
        [driverVM, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            tooltipcomponent: DriverComponentTooltip
        },
        resolveCallbackMap: {
            addDriver: addDriverVM,
            cancelDriver: cancelDriver
        },
    };

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={driverVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

DriverComponent.propTypes = {
    data: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    id: PropTypes.string,
    createDriverVM: PropTypes.func.isRequired,
    addDriver: PropTypes.func.isRequired,
    quoteId: PropTypes.string.isRequired,
    showError: PropTypes.bool,
    dateOfBirthInValid: PropTypes.func,
    triggerDateError: PropTypes.bool,
    commodityFlag: PropTypes.bool,
    driverState: PropTypes.string.isRequired,
};
DriverComponent.defaultProps = {
    data: {},
    labelPosition: 'left',
    path: undefined,
    readOnlyFields: [],
    showOptional: true,
    id: undefined,
    showError: false,
    commodityFlag: true,
    dateOfBirthInValid: undefined,
    triggerDateError: false
};
export default DriverComponent;
