import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class VehicleInfoLookupService {
    static lookupVehicleInfoBasedOnVin(vin, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnVIN',
            [vin, quoteID],
            additionalHeaders
        );
    }

    static lookupMakes(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupMakes',
            [dto],
            additionalHeaders
        );
    }

    static lookupModels(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupModels',
            [dto],
            additionalHeaders
        );
    }

    static autofillBasedOnPartialDto(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnPartialDTO',
            [dto],
            additionalHeaders
        );
    }

    static reverseLookup(year, make, model, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'reverseLookup',
            [year, make, model, quoteID],
            additionalHeaders
        );
    }
}
