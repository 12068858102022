import { RecaptchaService } from 'gw-capability-captcha_alfa';
import ErrorHandlingUtil from '../../../pages/Utils/ErrorHandlingUtil';
import { getConfigValue } from '@jutro/config';

export default class RecaptchaUtil {

    static async fetchRecaptchaToken() {
        return await new Promise(function(resolve, reject) {
            const recaptchaKey = getConfigValue('RECAPTCHA_KEY');
            grecaptcha.ready( function() {
            grecaptcha.execute(recaptchaKey, { action: 'submit' })
                .then((token) => {
                    resolve(token);
                   return token;
                }, (error) => {
                    reject(false);
                    ErrorHandlingUtil.getErrorFromWizard('InvalidRecaptcha');
                });
            });
        });
    }
}
