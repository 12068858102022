import { defineMessages } from 'react-intl';

export default defineMessages({
    paAccountHolderContactInfo: {
        id: 'quoteandbind.pa.views.pa-additional-information.Account Holder Contact Info',
        defaultMessage: 'Account Holder Contact Info',
    },
    paDriver: {
        id: 'quoteandbind.pa.views.pa-additional-information.Driver',
        defaultMessage: 'Driver',
    },
    paDriverName: {
        id: 'quoteandbind.pa.views.pa-driver-details.Name',
        defaultMessage: 'Name',
    },
    paLicenseNumber: {
        id: 'quoteandbind.pa.views.pa-additional-information.License Number',
        defaultMessage: 'License Number',
    },
    paVehicle: {
        id: 'quoteandbind.pa.views.pa-additional-information.Vehicle',
        defaultMessage: 'Vehicle',
    },
    paCostNew: {
        id: 'quoteandbind.pa.views.pa-additional-information.Cost New',
        defaultMessage: 'Cost New',
    },
    paUseOfVehicle: {
        id: 'quoteandbind.pa.views.pa-policy-info.Use of Vehicle',
        defaultMessage: 'Use of Vehicle',
    },
    paCDistanceDriven: {
        id: 'quoteandbind.pa.views.pa-additional-information.Distance Driven',
        defaultMessage: 'Distance Driven',
    }
});
