import { defineMessages } from 'react-intl';

export default defineMessages({
    sorryInformation: {
        id: 'quoteandbind.views.knockout-page.Sorry, based on the information provided, we cannot insure this customer.',
        defaultMessage: 'Sorry, based on the information provided, we cannot insure this customer.',
    },
    thankyouInterest: {
        id: 'quoteandbind.views.knockout-page.Thank you for your interest in our insurance.',
        defaultMessage: 'Thank you for your interest in our insurance.'
    },
    forMoreInfo: {
        id: 'quoteandbind.views.knockout-page.For more information, please contact your supervisor on',
        defaultMessage: 'For more information, please contact your supervisor on {phoneNumber}'
    },
    giveUsCall: {
        id: 'quoteandbind.views.knockout-page.For more information, Give us a call...',
        defaultMessage: 'Give us a call at {phoneNumber} and one of our agents will be happy to help you.'
    }
});
