/* eslint-disable class-methods-use-this */
import { service } from '@jutro/services';
import queryString from 'query-string';

export const QUERY_PARAMS_SEGMENT_SERVICE_ID = 'QueryParamsSegmentation';

export class QueryParamsSegmentationImpl {
    retrieveExperimentVariant() {
        return Promise.resolve(queryString.parse(window.location.search));
    }
}

export const QueryParamsSegmentation = (
    service(QUERY_PARAMS_SEGMENT_SERVICE_ID)(QueryParamsSegmentationImpl)
);
