import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@jutro/uiconfig';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import metadata from './FloatComponent.metadata.json5';

import styles from './FloatComponent.module.scss';


function FloatComponent(props) {
    const {
        value: modelVM,
        path,
        id,
        label,
        readOnly,
        onValidate,
        onValueChange,
        showErrors,
        disableFloat,
        restriction,
        placeholder,
        required,        
        maxLength,
        valueChanged,
        customErrorMessage,
        triggerReset,
        onBlur,
        fromVehiclePage
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [showLabel, setShowLabel] = useState(false);
    const { _dtoName } = modelVM;
    let errorLabel = _.get(label, "defaultMessage") !== undefined ? (fromVehiclePage ? _.get(label, 'defaultMessage') : _.get(label, 'defaultMessage').toLowerCase()) : '';
    if (errorLabel === 'email address') {
        errorLabel = `valid ${errorLabel}`;
    }
    const errorMessage = (customErrorMessage !== undefined) ? customErrorMessage : `Please enter a ${errorLabel}`;
    const floatOverride = triggerReset ? 'floatInput floatOverride' : 'floatInput';
    const defaultClass = readOnly ? 'floatReadOnly' : floatOverride;
    if (valueChanged) {
        const newVal = modelVM.value;
        _.set(modelVM, path, newVal);
    }

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, modelVM]);

    const handleValueChange = useCallback((newValue) => {
        if (onValueChange) {
            if (newValue !== undefined && !_.isEmpty(newValue)) {
                setShowLabel(true);
            } else {
                setShowLabel(false);
            }
        }
        let normalizedValue = newValue;

        if (restriction === 'textOnly' && !_.isNil(newValue)) {
            normalizedValue = newValue.replace(/[^A-Za-z\s]/ig, '');
        } else if (restriction === 'date') {
            normalizedValue = newValue.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d/]/g, '');
        } else if (restriction === 'alphaNumeric') {
            normalizedValue = newValue.replace(/[^A-Za-z0-9]/ig, '');
        } else if (restriction === 'number') {
            normalizedValue = newValue.replace(/[^0-9]/ig, '');            
        } else if (restriction === 'email') {
            normalizedValue = newValue.replace(/[^0-9a-zA-Z-_@.]/ig, '');
        } else if (restriction === 'lastFourOfSSN'){    
            normalizedValue = newValue.replace(/^[0\D]{4}$/ig, '');       
        }

        if (onValueChange) {
            onValueChange(normalizedValue, path);
            if (normalizedValue !== _.get(modelVM, path)) {
                _.set(modelVM, path, normalizedValue);
            }
        }
    }, [modelVM, onValueChange, path, restriction]);

    const isFieldValid = useCallback(() => {
        const modelValue = _.get(modelVM, path);
        return !_.isNil(modelValue);
    }, [modelVM, path]);

    const showFocus = () => {
        setShowLabel(true);
    };
    const checkValue = useCallback((evt) => {
        const floatValue = evt.currentTarget.value;
        if (floatValue !== undefined && !_.isEmpty(floatValue)) {
            setShowLabel(true);
        } else {
            setShowLabel(false);
        }
        if (onBlur) onBlur(floatValue);
    }, []);

    const overrideProps = {
        '@field': {
        },
        floatInput: {
            label: undefined,
            placeholder: !showLabel || triggerReset ? placeholder : undefined,
            onFocus: showFocus,
            onBlur: checkValue,
            readOnly: readOnly,
            path: path,
            required: required,            
            maxLength: maxLength,
            requiredFieldValidationMessage: [errorMessage],
            showErrors: showErrors,
            className: showErrors && (restriction === 'date' || !isFieldValid()) ? 'floatInput floatInputError' : defaultClass,
            defaultValue: (_dtoName !== undefined && (_dtoName.indexOf('typekey') !== -1) && modelVM.value !== undefined) ? modelVM.value.code : modelVM.value
        },
        floatText: {
            content: (!_.isEmpty(modelVM.value) || showLabel) && !disableFloat && !triggerReset ? _.get(label, 'defaultMessage') : undefined
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => {
        const result = readViewModelValue(metadata.pageContent, modelVM, fieldId, fieldPath, overrideProps);
        return result;
    }, [modelVM, overrideProps]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };


    return (
        <ViewModelForm
            model={modelVM}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />

    );
}

FloatComponent.propTypes = {
    value: PropTypes.shape({}),
    path: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    disableFloat: PropTypes.bool,
    restriction: PropTypes.string,
    required: PropTypes.bool,    
    maxLength: PropTypes.number,
    valueChanged: PropTypes.bool,
    customErrorMessage: PropTypes.string,
    triggerReset: PropTypes.bool
};
FloatComponent.defaultProps = {
    value: {},
    path: undefined,
    label: undefined,
    placeholder: undefined,
    readOnly: false,
    showErrors: false,
    disableFloat: false,
    restriction: undefined,
    required: false,    
    maxLength: undefined,
    valueChanged: false,
    customErrorMessage: undefined,
    triggerReset: false
};
export default FloatComponent;
