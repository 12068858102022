import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver',
        defaultMessage: 'Add Your Driver(s)',
    },
    paDriverTitleNumber: {
        id: 'quoteandbind.pa.views.pa-driver-details.Driver:',
        defaultMessage: 'Driver:',
    },
    paDriver: {
        id: 'quoteandbind.views.quote.Personal Auto Driver',
        defaultMessage: 'Personal Auto Driver',
    },
    removeDriverTitle: {
        id: 'quoteandbind.pa.controllers.PADriverDetailsCtrl.Remove Driver?',
        defaultMessage: 'Remove Driver?',
    },
    removeDriverDescription: {
        id: 'quoteandbind.pa.drivers.remove-driver.message',
        defaultMessage: 'Are you sure you want to remove this driver from the list of drivers?',
    },
    paAddAnotherDriverTitle: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Another Driver?',
        defaultMessage: 'Add Another Driver?',
    },
    paAddDriverButtonText: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Driver',
        defaultMessage: 'Add Driver',
    },
    paDriverPageInfo: {
        id: 'quoteandbind.pa.views.pa-driver-details.Include Additional Drivers',
        defaultMessage: 'Please include everyone 15 years or older in your household or away at school',
    },
    paPNIDriverPageNextError: {
        id: 'quoteandbind.pa.views.pa-PNI-driver-details.PNI Driver Error',
        defaultMessage: 'Please add yourself as a driver before proceeding',
    },
    paDriverPageNextError: {
        id: 'quoteandbind.pa.views.pa-driver-details.Add Driver Error',
        defaultMessage: 'Please add a driver to continue',
    },
    paDriverPageSpouseError: {
        id: 'quoteandbind.pa.views.pa-driver-details.Spouse Not Added',
        defaultMessage: 'You have indicated that you are married.  Please add your spouse or update your marital status.',
    },
    paDriverPageSeperateError: {
        id: 'quoteandbind.pa.views.pa-driver-details.Spouse Not Added for Seperated',
        defaultMessage: 'You have indicated that you are Seperated.  Please add your spouse or update your marital status.',
    },
    paDriverPageMultipleSpouseError: {
        id: 'quoteandbind.pa.views.pa-driver-details.Multiple Spouse Added for Seperated',
        defaultMessage: 'You have identified more than 1 driver as spouse. Please update driver’s relationship and click Next to continue.',
    },
    paDriverMaritalError: {
        id: 'quoteandbind.pa.views.pa-driver-details.Incorrect Marital Status',
        defaultMessage: 'You have indicated a driver is your spouse, but your marital status is ',
    },
    paDriverMaritalUpdate: {
        id: 'quoteandbind.pa.views.pa-driver-details.Marital Status Update',
        defaultMessage: '. Please update your marital status.',
    },
    paDriverTitleTooltip: {
        id: 'quoteandbind.pa.directives.templates.pa-driver-details.paDriverTitleTooltip',
        defaultMessage: 'Drivers listed on your policy should include everyone who drives a quoted vehicle and anyone who lives in your household aged 15 or older.',
    },
    paDriverAffinity: {
        id: 'quoteandbind.pa.views.pa-driver-details.Affinity',
        defaultMessage: 'Is anyone in your household employed by one of these groups?',
    },
    paDriverAffinityGroup: {
        id: 'quoteandbind.pa.views.pa-driver-details.Select Affinity Group',
        defaultMessage: 'Select Affinity Group',
    },
    paDriverAffinityLabel: {
        id: 'quoteandbind.pa.views.pa-driver-details.AffinityLabel',
        defaultMessage: 'Affinity Discount'
    }  
});
