import React, {
    useContext, useCallback, useEffect, useState, useRef
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Tooltip, IconButton, Loader } from '@jutro/components';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { EmailQuoteService } from 'gw-capability-quoteandbind';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { MockUpUtil } from 'gw-portals-util-js';
import { publish } from '@jutro/events';
import cookie from 'js-cookie';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';

import metadata from './QuotePage.metadata.json5';
import moment from "moment";
import ErrorHandlingUtil from '../../../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil';

const PATH_TO_MOCK_DATA = 'quote.pa';
const MOCK_DATA_TO_SET = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];
const CURRENT_PAGE = 'Quote Page';

const VEHICLE_COVERAGE = ['PALeaseLoan_alfa',
    'PALossOfIncome_alfa',
    'PALossOfUseCov_alfa',
    'PATowingLaborCov',
    'PACollisionCov',
    'PAComprehensiveCov'];
let LINE_COVERAGE = ['PABI_alfa',
    'PAPropertyDamage_alfa',
    'PAMedicalPayments_alfa',
    'PAUninsuredMotoristBI_alfa',
    'PAADD_alfa',
    'PAAccidentWaiver_alfa'];
let VEHICLELINE_COVERAGE = ['PABI_alfa',
    'PAPropertyDamage_alfa',
    'PAMedicalPayments_alfa',
    'PAUninsuredMotoristBI_alfa'];
let VEHICLE_SEQ = ['PABI_alfa', 'PALeaseLoan_alfa',
    'PAPropertyDamage_alfa',
    'PAMedicalPayments_alfa',
    'PAUninsuredMotoristBI_alfa',
    'PAComprehensiveCov',
    'PACollisionCov',
    'PATowingLaborCov',
    'PALossOfUseCov_alfa', 'PALossOfIncome_alfa'];


function structureClauseTableData(coverage, index = '') {
    // putting ID into an object as the Jutro table component expects an object
    return {
        coverageUniqueID: `${coverage.publicID}${index}`
    };
}

const formatPublicID = (publicID) => _.toInteger(publicID.replace(/pc:/, ''));

function setOfferingLabels(standardOfferings) {
    //const translator = useContext(TranslatorContext);
    return standardOfferings.map((offering) => {
        return (`quoteandbind.controllers.QuoteDetails.${offering.branchCode}`);
    });
}
function QuoteTooltip(messageType) {
    const translator = useContext(TranslatorContext);
    const messagePath = 'quoteandbind.pa.directives.templates.pa-quote.';
    const message = `${messagePath}${messageType.id}`;

    return (
        <Tooltip
            animation="fade"
            content={translator(_.find(messages, { id: message }))}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton icon="fa-info-circle" aria-haspopup="true" className={styles.gwToolTip} />
        </Tooltip>
    );
}

function getVehicleSequence(columnData) {
    if (columnData) {
        let vehicleSeq = [];
        for (let data = 0; data < columnData.length; data++) {
            if(columnData[data].code === 'PABasic') {
                vehicleSeq = ['PABI_alfa', 'PALeaseLoan_alfa',
                'PAPropertyDamage_alfa',
                'PAMedicalPayments_alfa',
                'PAUninsuredMotoristBI_alfa',
                'PAComprehensiveCov',
                'PACollisionCov',
                'PATowingLaborCov',
                'PALossOfUseCov_alfa', 'PALossOfIncome_alfa'];
                break;
            } else if(columnData[data].code === 'PABasicGA' || columnData[data].code === 'PABasicMS') {
                vehicleSeq = ['PABI_alfa', 'PALeaseLoan_alfa',
                'PAPropertyDamage_alfa',
                'PAMedicalPayments_alfa',
                'PAUninsuredMotoristBI_alfa',
                'PAComprehensiveCov',
                'PACollisionCov',
                'PATowingLaborCov',
                'PALossOfUseCov_alfa', 'PALossOfIncome_alfa',
                'PAUninsuredMotoristPD_alfa',
                'PAUninsuredMotoristCommon_alfa']
                break;
            }
        }
        VEHICLE_SEQ = vehicleSeq;
    }
}

function generateClauseData(columnData, coveragePath, vehiclePublicID, branchCode) {
    getVehicleSequence(columnData);
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehiclePublicID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.publicID === vehiclePublicID
            );
            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }
        const clause = _.get(lob.data, completeCoveragePath.replace(/\.children/, ''));

        if (clause !== undefined) {
            clause.sort((a, b) => {
                return VEHICLE_SEQ.indexOf(a.publicID) - VEHICLE_SEQ.indexOf(b.publicID);
            });
        }

        const selectedClause = [];
        _.forEach(clause, (c) => {
            if (VEHICLE_COVERAGE.includes(c.publicID)) {
                selectedClause.push(c);
            }
        });

        return {
            code: code,
            path: `${lob.path}.${completeCoveragePath}`,
            type: 'vehicle',
            fixedId: vehiclePublicID.split(':')[1],
            clauses: clause
        };
    });
}

function getLineCoverage(columnData) {
    if (columnData) {
        let lineCoverage = [];
        for (let data = 0; data < columnData.length; data++) {
            if(columnData[data].code === 'PABasic') {
                lineCoverage =  ['PABI_alfa',
                'PAPropertyDamage_alfa',
                'PAMedicalPayments_alfa',
                'PAUninsuredMotoristBI_alfa',
                'PAADD_alfa',
                'PAAccidentWaiver_alfa'];
                break;
            } else if(columnData[data].code === 'PABasicGA' || columnData[data].code === 'PABasicMS') {
                lineCoverage =  ['PABI_alfa',
                    'PAPropertyDamage_alfa',
                    'PAMedicalPayments_alfa',
                    'PAUninsuredMotoristBI_alfa',
                    'PAADD_alfa',
                    'PAAccidentWaiver_alfa',
                    'PAUninsuredMotoristPD_alfa',
                    'PAUninsuredMotoristCommon_alfa'
                ];
                break;
            }
        }
        VEHICLELINE_COVERAGE = lineCoverage;
    }
   
}

function generateSingleClauseData(columnData, coveragePath, vehiclePublicID) {
    getLineCoverage(columnData);
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehiclePublicID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.publicID === vehiclePublicID
            );
            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }

        const clause = _.get(lob.data, completeCoveragePath.replace(/\.children/, ''));
        const selectedClause = [];
        _.forEach(clause, (c) => {
            if (LINE_COVERAGE.includes(c.publicID)) {
                selectedClause.push(c);
            }
        });

        return {
            code: code,
            path: `${lob.path}.${completeCoveragePath}`,
            type: 'line',
            clauses: selectedClause
        };
    });
}

function getVehicleLinecoverage(columnData) {
    if (columnData) {
        let vehicleLineCoverage = [];
        for (let data = 0; data < columnData.length; data++) {
            if(columnData[data].code === 'PABasic') {
                vehicleLineCoverage = ['PABI_alfa',
                'PAPropertyDamage_alfa',
                'PAMedicalPayments_alfa',
                'PAUninsuredMotoristBI_alfa'];
                break;
            } else if(columnData[data].code === 'PABasicGA' || columnData[data].code === 'PABasicMS') {
                vehicleLineCoverage = ['PABI_alfa',
                    'PAPropertyDamage_alfa',
                    'PAMedicalPayments_alfa',
                    'PAUninsuredMotoristBI_alfa',
                    'PAUninsuredMotoristPD_alfa',
                    'PAUninsuredMotoristCommon_alfa'
                ];
                break;
            }
        }
        VEHICLELINE_COVERAGE = vehicleLineCoverage;
    }
}

function generateVehicleLineClauseData(columnData, coveragePath, vehiclePublicID) {
    getVehicleLinecoverage(columnData);
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehiclePublicID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.publicID === vehiclePublicID
            );
            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }

        const clause = _.get(lob.data, completeCoveragePath.replace(/\.children/, ''));
        const selectedClause = [];
        _.forEach(clause, (c) => {
            if (VEHICLELINE_COVERAGE.includes(c.publicID)) {
                selectedClause.push(c);
            }
        });

        return {
            code: code,
            type: 'vehicleLine',
            fixedId: vehiclePublicID.split(':')[1],
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: clause
        };
    });
}


const structureCustomQuote = (submissionVM, affectedQuote, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
};

const getCoveragesUniqueID = (submissionVM) => {
    const offerings = _.get(submissionVM, 'lobData.personalAuto.offerings.value');
    const lineCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .lineCoverages.map((coverage) => structureClauseTableData(coverage))),
        'coverageUniqueID'
    );
    const vehicleCoverages = _.uniqBy(
        offerings.flatMap((offering) => offering.coverages
            .vehicleCoverages.flatMap(({ coverages }, index) => coverages
                .map((coverage) => structureClauseTableData(coverage, `__${index}`)))),
        'coverageUniqueID'
    );
    return {
        lineCoverages,
        vehicleCoverages
    };
};


const setBaseOffering = (standardOfferings) => {
    if (Array.isArray(standardOfferings) && standardOfferings.length === 1) {
        return standardOfferings[0];
    } else if (Array.isArray(standardOfferings) && standardOfferings.length > 1) {
        return standardOfferings[1];
    }
};

const generateQuoteData = (submissionVM) => {
    const { offeredQuotes } = submissionVM.quoteData.value;
    const page = {
        offeredQuotes,
        offeringLabels: setOfferingLabels(offeredQuotes),
        baseOffering: setBaseOffering(offeredQuotes),
        submission: submissionVM,
        currentStep: 'PAQuotePage'
    };
    return page;
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.personalAuto.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode && qdOffering.branchName === lobOffering.branchName
            );
            const quoteData = quoteOfferings[quoteDataIndex];
            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children.${quoteDataIndex}`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children.${lobIndex}`,
                    data: lobOffering
                }
            };
        });
    // .filter(({ code }) => code !== 'CUSTOM');
    return _.sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    getVehicleLinecoverage(generateColumnData(vm));
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    const { coverages } = lobOffering;
    const firstCoverage = coverages.vehicleCoverages[0];
    coverages.vehicleCoverages.map((vehicleObject, vehicleIndex) => {
        vehicleObject.coverages.map((coverage, coverageIndex) => {

            if (VEHICLELINE_COVERAGE.includes(coverage.publicID)) {
                const firstCover = firstCoverage.coverages.filter((cover) => cover.publicID === coverage.publicID)[0];
                coverages.vehicleCoverages[vehicleIndex].coverages[coverageIndex].selected = firstCover.selected;
                coverages.vehicleCoverages[vehicleIndex].coverages[coverageIndex].terms = firstCover.terms;
            }
        });
    });

    let clausesToUpdate = {
        [lobName]: coverages // lobOffering.coverages
    };


    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateTableData = (submissionVM, columnData, translator) => {
    const unorderVehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
    const vehicles = _.sortBy(unorderVehicles, ['publicID']);
    const uniqueID = getCoveragesUniqueID(submissionVM);
    const { code } = columnData;
    const vehicleLineCoverages = vehicles.map((vehicle) => ({
        header: `${translator(messages.vehicleSpecificCoverage)}:${vehicle.year} ${vehicle.make} ${vehicle.model
            }`,
        type: 'vehicleLine',
        data: uniqueID.vehicleCoverages.filter(
            (coverage) => coverage.coverageUniqueID.endsWith(`__${vehicles.findIndex((section) => section === vehicle)}`)
        ),
        tableContent: generateVehicleLineClauseData(columnData, 'vehicleCoverages', vehicle.publicID, code)
    }));
    const lineCoverages = {
        header: translator(messages.generalCoverages),
        type: 'line',
        data: uniqueID.lineCoverages,
        tableContent: generateSingleClauseData(columnData, 'lineCoverages')
    };

    const vehicleCoverages = vehicles.map((vehicle) => ({
        header: `${translator(messages.vehicleSpecificCoverage)}:${vehicle.year} ${vehicle.make} ${vehicle.model
            }`,
        type: 'vehicle',
        data: uniqueID.vehicleCoverages.filter(
            (coverage) => coverage.coverageUniqueID.endsWith(`__${vehicles.findIndex((section) => section === vehicle)}`)
        ),
        tableContent: generateClauseData(columnData, 'vehicleCoverages', vehicle.publicID, code)
    }));

    return [...vehicleLineCoverages, lineCoverages, ...vehicleCoverages];
};

function QuotePage(props) {
    const {
        wizardSnapshot, wizardData: submissionVM, updateWizardData, jumpTo, editInfo,
        setEditInfo, isPremiumChanged, setIsPremiumChanged, setQuoteCalled, quoteCalled,
        oldPremium, setOldPremium, chosenQuote, setChosenQuote, activeQuote, setActiveQuote, selectedVersion,
        setSelectedVersion, firstQuote, setFirstQuote
    } = props;
    const initial = () => Promise.resolve(editInfo && !quoteCalled);
    const skipPage = editInfo && !quoteCalled;
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { authHeader } = useAuthentication();
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const { onValidate, disregardFieldValidation } = useValidation('QuotePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);
    const [quoteUpdate, setQuoteUpdate] = useState(false);
    const [jumpFlag, setJumpFlag] = useState(false);
    const [quoteError, updateQuoteError] = useState([]);
    const quoteErrorRef = useRef([]);
    const showErrorRef = useRef(false);
    const [isLoading, updateIsLoading] = useState(false);
    const addError = useCallback((id) => {
        const qError = quoteError;
        const existError = qError.includes(id);
        if (!existError) {
            qError.push(id);
            updateQuoteError(qError);
            if(!quoteErrorRef.current.includes(id)) quoteErrorRef.current.push(id);
        }
    }, [updateQuoteError, quoteError]);

    const removeError = useCallback((id) => {
        if (quoteError.length > 0) {
            quoteErrorRef.current = quoteErrorRef.current.filter((q) => q !== id);
        }
    }, [updateQuoteError, quoteError]);

    const validateDependency = useCallback((changedCover, cover, vehicleId, error, vehicleCoverages, addUI) => {
        let valid = true;
        const id = `${changedCover}__${vehicleId}`;
        const { selected } = vehicleCoverages.find((vehicleCoverage) => vehicleCoverage.publicID === changedCover);
        if (selected) {
            for (let i = 0; i < cover.length; i++) {
                valid = vehicleCoverages.find((vehicleCoverage) => vehicleCoverage.publicID === cover[i]).selected;
                if (!valid && addUI) {
                    addError(id);
                    break;
                }
            }
        }
        if (valid && quoteError.includes(id)) {
            removeError(id);
        }
        return valid;
    }, [removeError, addError, quoteError]);

    const validateVehicleLease = useCallback((changedCover, vehicleId, vehicleCoverages, addUI) => {
        let valid = true;
        const id = `${changedCover}__${vehicleId}`;
        const { selected } = vehicleCoverages.coverages.find((vehicleCoverage) => vehicleCoverage.publicID === changedCover);
        if (vehicleCoverages.leaseOrRent && !selected) {
            valid = false;
            if (!valid && addUI) {
                addError(id);
            }
        }

        if (valid && quoteError.includes(id)) {
            removeError(id);
        }
        return valid;
    }, [removeError, addError, quoteError]);

    const validateLineCovers = useCallback((changedCover, cover, vehicleId, error, vehicleCoverages, addUI) => {
        let valid = true;
        const changedCoverCell = vehicleCoverages.find((vehicleCoverage) => vehicleCoverage.publicID === changedCover);
        const coverCell = vehicleCoverages.find((vehicleCoverage) => vehicleCoverage.publicID === cover);
        const changedValue = changedCoverCell.terms[0].options.find((option) => option.code === changedCoverCell.terms[0].chosenTerm).name;
        const coverValue = coverCell.terms[0].options.find((option) => option.code === coverCell.terms[0].chosenTerm).name;
        let changeLimitOne, changeLimitTwo, coverLimitOne, coverLimitTwo;
        if (changedValue.includes('/')) {
            const changedValueArray = changedValue.split('/');
            // eslint-disable-next-line radix
            changeLimitOne = parseInt(changedValueArray[0].replace(',', '').trim());
            changeLimitTwo = parseInt(changedValueArray[1].replace(',', '').trim());
        } else {
            changeLimitOne = parseInt(changedValue.replace(',', '').trim());
        }
        if (coverValue.includes('/')) {
            const coverValueArray = coverValue.split('/');
            // eslint-disable-next-line radix
            coverLimitOne = parseInt(coverValueArray[0].replace(',', '').trim());
            coverLimitTwo = parseInt(coverValueArray[1].replace(',', '').trim());
        } else {
            coverLimitOne = parseInt(coverValue.replace(',', '').trim());
        }
        if (coverValue.includes('/') && changedValue.includes('/')) {
            if (changeLimitOne > coverLimitOne) {
                valid = false;
            } else if (changeLimitOne === coverLimitOne) {
                if (changeLimitTwo > coverLimitTwo) {
                    valid = false;
                }
            }
        } else {
            if (changeLimitOne > coverLimitOne) {
                valid = false;
            }
        }
        const id = `${changedCover}__${vehicleId}`;
        if (!valid && addUI) {
            addError(id, error);
        }
        if (valid && quoteError.includes(id)) {
            removeError(id);
        }
        return valid;
    }, [addError, quoteError]);

    const validateStateCategory = (columnData) => {
        let stateName = false;
        if (columnData) {
            for (let data of columnData) {
                if (data.code === 'PABasicGA' || data.code === 'PABasicMS') {
                    stateName = true;
                    break;
                } else if (data.code === 'PABasic') {
                    stateName = false;
                    break;
                }
            }
        }
        return stateName;
    }

    const validCoverge = useCallback((publicID, vehicleFixedId, vehicleCoverages, addUI) => {
        let error;
        switch (publicID) {
            case 'PACollisionCov':
                error = 'Collision';
                return (validateDependency('PACollisionCov', ['PAComprehensiveCov'], vehicleFixedId, error, vehicleCoverages.coverages, addUI) &&
                    validateVehicleLease('PACollisionCov', vehicleFixedId, vehicleCoverages, true));

            case 'PALossOfUseCov_alfa':
                error = 'LossOfUse';
                return validateDependency('PALossOfUseCov_alfa', ['PACollisionCov', 'PAComprehensiveCov'], vehicleFixedId, error, vehicleCoverages.coverages, addUI);

            case 'PALossOfIncome_alfa':
                error = 'LossOfIncome';
                return validateDependency('PALossOfIncome_alfa', ['PAMedicalPayments_alfa'], vehicleFixedId, error, vehicleCoverages.coverages, addUI);
            case 'PALeaseLoan_alfa':
                error = 'LoanLease';
                return validateDependency('PALeaseLoan_alfa', ['PACollisionCov', 'PAComprehensiveCov'], vehicleFixedId, error, vehicleCoverages.coverages, addUI);
            case 'PAUninsuredMotoristBI_alfa':
                error = 'BiPDUninsured';
                return validateLineCovers('PAUninsuredMotoristBI_alfa', 'PABI_alfa', vehicleFixedId, error, vehicleCoverages.coverages, addUI);
            case 'PAUninsuredMotoristPD_alfa':
                error = 'BiPDUninsured';
                return validateLineCovers('PAUninsuredMotoristPD_alfa', 'PAPropertyDamage_alfa', vehicleFixedId, error, vehicleCoverages.coverages, addUI);
            case 'PAPropertyDamage_alfa' && validateStateCategory(generateColumnData(submissionVM)):
                error = 'BiPDUninsured';
                return validateLineCovers('PAUninsuredMotoristPD_alfa', 'PAPropertyDamage_alfa', vehicleFixedId, error, vehicleCoverages.coverages, addUI);
            case 'PABI_alfa':
                error = 'BiPDUninsured';
                return validateLineCovers('PAUninsuredMotoristBI_alfa', 'PABI_alfa', vehicleFixedId, error, vehicleCoverages.coverages, addUI);
            case 'PAComprehensiveCov':
                return validateVehicleLease('PAComprehensiveCov', vehicleFixedId, vehicleCoverages, true);
            default:
                return true;
        }
    }, [validateVehicleLease, validateLineCovers, validateDependency, submissionVM]);


    const validateCustomQuote = useCallback((customQuote) => {
        const { vehicleCoverages } = customQuote.coverages.personalAuto;
        let publicId;
        let coverage;
        const addUI = true;
        let valid;
        let inValid = false;
        for (let i = 0; i < vehicleCoverages.length; i++) {
            for (let j = 0; j < vehicleCoverages[i].coverages.length; j++) {
                coverage = vehicleCoverages[i].coverages[j];
                publicId = coverage.publicID;
                valid = validCoverge(publicId, vehicleCoverages[i].fixedId, vehicleCoverages[i], addUI);
                if (!valid) {
                    inValid = true;
                }
            }
        }
        if (!inValid) quoteErrorRef.current = [];
        updateQuoteError(showErrorRef.current ? quoteErrorRef.current : []);
        return inValid;
    }, [validCoverge, updateQuoteError]);

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);
            if (validateCustomQuote(customQuote)) {
                return false;
            }
            return CustomQuoteService.updateCustomQuoteCoverages(customQuote, authHeader).then(
                (response) => {
                    // const updatedClauses = _.get(response, `coverages.${lobName}`);
                    // const newSubmissionVM = viewModelService.clone(submissionVM);
                    // Update the offering status to stale
                    setStaleQuoteBranchCode(response.quote.branchCode);
                    // Update local offering with new one from xcenter
                    // _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                    // Update local quote status with new one from xcenter
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(submissionVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    // _.set(submissionVM, `${quotePath}.premium`, response.quote.premium);
                    /**
                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.lineCoverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.vehicleCoverages`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages
                    ];
                    disregardFieldValidation(allRemovedFields); **/
                    updateWizardData(submissionVM);
                    showErrorRef.current = false;
                }
            );
        },
        [
            submissionVM,
            CustomQuoteService,
            authHeader,
            viewModelService,
            disregardFieldValidation,
            updateWizardData
        ]
    );

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );


    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {

            changeSubmission(value, changedPath);
            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            updateQuoteError([]);
            showErrorRef.current = false;
            quoteErrorRef.current = [];
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.forceUpdateCustomQuoteCoverages(customQuote, authHeader).then(
                (response) => {
                    const updatedClauses = _.get(response, `coverages.${lobName}`);
                    // Update local offering with new one from xcenter
                    _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                    // Update local quote status with new one from xcenter
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(submissionVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    _.set(submissionVM, `${quotePath}.premium`, response.quote.premium);
                    updateWizardData(submissionVM);
                }
            );
        },
        [initialSubmissionVM, submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            showErrorRef.current = true;
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);
            if (validateCustomQuote(customQuote)) {
                return false;
            }
            return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
                (response) => {
                    setStaleQuoteBranchCode(undefined);
                    updateQuoteError([]);
                    const updatedClauses = _.get(response, `coverages.${lobName}`);
                    updatedClauses.vehicleCoverages[0].coverages.sort((a, b) => {
                        return VEHICLE_SEQ.indexOf(a.publicID) - VEHICLE_SEQ.indexOf(b.publicID);
                    });
                    // Update local offering with new one from xcenter
                    _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(submissionVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    _.set(submissionVM, `${quotePath}.premium`, response.quote.premium);
                    _.set(submissionVM, 'baseData.selectedVersion', 'CUSTOM');
                    setSelectedVersion('CUSTOM');
                    updateWizardData(submissionVM);
                    showErrorRef.current = false;
                    return response;
                }
            );
        },
        [submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const updateStep = useCallback(() => {
        setEditInfo(true);
        setIsPremiumChanged(false);
        setFirstQuote(false);
        const oldOffering = submissionVM.quoteData.offeredQuotes.value
            .find((offerQuote) => offerQuote.branchCode === activeQuote);
        setOldPremium(oldOffering.premium.total.amount);
        setChosenQuote(oldOffering.branchName);
        jumpTo(0);
    }, []);




    const buyNow = useCallback(
        async (lobPath, quotePath) => {
            const lobOffering = _.get(submissionVM, `${lobPath}.value`);
            const quoteOffering = _.get(submissionVM, `${quotePath}.value`);
            const quoteID = _.get(submissionVM, 'quoteID.value');
            const sessionUUID = _.get(submissionVM, 'sessionUUID.value');

            // await CustomQuoteService.setSelectedVersionOnSubmission(
            //     quoteID,
            //     lobOffering.branchName,
            //     sessionUUID,
            //     authHeader
            // );
            _.set(submissionVM, 'baseData.selectedVersion', lobOffering.branchCode);
            _.set(submissionVM, 'bindData.chosenQuote.value', quoteOffering.publicID);
            updateWizardData(submissionVM);
            // set chosen quote
            if (submissionVM.baseData.isPremiumChanged.value) {
                setFirstQuote(false);
                updateIsLoading(true);
                await LoadSaveService.getTwoDownVersion(submissionVM.value).then((response) => {
                    submissionVM.value = response;
                    submissionVM.baseData.isPremiumChanged.value = false;
                    if (_.isUndefined(submissionVM.lobData.personalAuto.coverables.mvrIncidents.value)) {
                        _.set(submissionVM, 'lobData.personalAuto.coverables.mvrIncidents.value', []);
                    }
                    updateWizardData(submissionVM);
                    updateIsLoading(false);
                    console.log(submissionVM);
                    setJumpFlag(true);
                }, (error) => {
                    ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, CURRENT_PAGE);
                });
            }
        },
        [submissionVM, updateWizardData, CustomQuoteService, authHeader]
    );

    const getRootClass = (name) => {
        if (name && name.length > 14 && breakpoint === 'desktop') {
            return styles.paSubHeaderLongText;
        } else if ((name && name.length > 4 && breakpoint === 'tablet') || breakpoint === 'phone') {
            return `${styles.paSubHeader} ${styles.hideFlex}`;
        }
        return styles.paSubHeader;
    }

    const getHolderName = (name) => {
        const convertedName = name.toLowerCase();
        const driverName = 'Thanks, '.concat(convertedName, '.');
        if (convertedName && convertedName.length > 4 && breakpoint === 'tablet') {
        return (
            <div>
                    <span className={styles.capitalizeName}>{driverName}
                        &nbsp;
                        <br />
                    </span>
                </div>
            );
        } else if (convertedName && convertedName.length > 14) {
            return (
                <div>
                    <span className={styles.capitalizeName}>{driverName}
                        &nbsp;
                        <br />
                    </span>
                </div>
            );
        }
        return (
            <div>
                <span className={styles.capitalizeName}>{driverName}&nbsp;</span>
            </div>
        );
    };

    function getStatecategory(columnData) {
        if (columnData) {
            let stateName = '';
            for (let data of columnData) {
                if(data.code === 'PABasicGA') {
                    stateName = 'PABasicGA';
                    break;
                } else if(data.code === 'PABasic' || data.code === 'PABasicMS') {
                    stateName =  'PABasicAndPABasicMS';
                    break;
                }
            }
            return stateName;
        }
       
    }

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(submissionVM);
        //const quoteData = generateQuoteData(submissionVM);
        const { quoteID } = submissionVM;
        // const { underwritingIssues } = props;
        const accountHolder = submissionVM.baseData.accountHolder.value;

        return {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
            },
            quoteTable: {
                columnData: columnData,
                tableData: generateTableData(submissionVM, columnData, translator),
                quoteID: quoteID.value,
                quoteUpdate: quoteUpdate,
                submissionVM: submissionVM,
                quoteError: quoteError,
                setActiveQuote: setActiveQuote,
                selectedVersion: selectedVersion,
                quoteErrorLoaderRef: quoteErrorRef.current
            },
            startDate: {
                visible: showQuoteStartDate
            },
            print: {
                // visible: !showQuoteStartDate
            },
            paQuoteHeader: {
                visible: !quoteUpdate
            },
            paSubQuoteHeader: {
                className: getRootClass(accountHolder.firstName)
            },
            paQuotePageNameTitle: {
                content: getHolderName(accountHolder.firstName)
            },
            paQuoteUpdateHeader: {
                visible: quoteUpdate
            },
            paQuoteUpdateSubHeader: {
                visible: quoteUpdate && firstQuote
            },
            qb_quote_EditInfo: {
                visible: quoteUpdate,
                onClick: updateStep
            },
            paQuoteCaption: {
                content: getStatecategory(columnData) === 'PABasicGA' ? translator(messages.quoteCaptionGA) : translator(messages.paQuoteCaption) 
            }

        };
        // prevent complaining props and showQuoteStartDate is missing in the dependencies list
        // watch props will cause refresh function ref multiple time,
        // showQuoteStartDate is generated from build time, so won't change in the runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, submissionVM, translator, quoteError, staleQuoteBranchCode]);

    useEffect(() => {
        if (!editInfo) {
            setQuoteCalled(true);
        }

        const offerings = _.get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = _.filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = _.map(draftOffers, 'branchCode');
        setStaleQuoteBranchCode(draftBranchCodes);
        // Store the initial SubmissionVM when component is mounted
        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));

        // inject mock data to prevent wizardData.subtreeValid is invalid
        submissionVM.value = MockUpUtil.setMockData(
            submissionVM.value,
            PATH_TO_MOCK_DATA,
            ...MOCK_DATA_TO_SET
        );
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);
        var agentInfo = submissionVM.baseData.value.agentInfo;
        if (!_.isEmpty(agentInfo) && !_.isEmpty(agentInfo.agentInfo) && !_.isEmpty(agentInfo.agentInfo[0].producerCode)) {
            cookie.set('agentId', agentInfo.agentInfo && agentInfo.agentInfo[0].producerCode.replace(/^0+/, ''), {
                expires: expireDate
            });
        }
        if (submissionVM.baseData.value.firstQuote) {
            const eventLabel = 'First Quote';
            setFirstQuote(true);
            const submissionNum = submissionVM.quoteID.value;
            publish('customizeButtonClicked', {
                eventLabel,
                submissionNum
            }); 
            const date = _.get(submissionVM, "baseData.sysDate.value");
            _.set(submissionVM,"baseData.sysDate",moment(date).format("YYYY-MM-DD"));
            let assignedAgentId = '';
            let assignedAgentName = '';

                //Determine the agent assignment
                if (submissionVM.baseData.value.agentAssignment.toLowerCase() === 'crcdefault') {
                    assignedAgentName = 'crcdefault';
                }
                else {
                    assignedAgentId =  submissionVM.baseData.value.agentInfo.agentInfo[0].producerCode.replace(/^0+/, '');
                    assignedAgentName = `${submissionVM.baseData.value.agentInfo.agentInfo[0].firstName}${submissionVM.baseData.value.agentInfo.agentInfo[0].lastName}`;
                }
                
                //Push the information to DataLayer
                window.dataLayer.push({
                    event: 'AgentInformation',
                    QuoteId: submissionNum,
                    AgentId: assignedAgentId,
                    AgentName: assignedAgentName
                });           
            EmailQuoteService.sendQuoteBindEmail(submissionVM.value);
            submissionVM.baseData.firstQuote.value = false;
        }
        if (editInfo && !_.isUndefined(submissionVM.baseData.isPremiumChanged.value)) {
            setIsPremiumChanged(submissionVM.baseData.isPremiumChanged.value);
        }
        if (isPremiumChanged !== undefined && isPremiumChanged === true) {
            setQuoteUpdate(true);
        }

        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM]);

    const onStaleQuoteBranchCode = useCallback(() => {
        return staleQuoteBranchCode;
    }, [staleQuoteBranchCode]);

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <WizardPage showNext={false} skipWhen={initial} userSkip={skipPage} showPrevious={!quoteUpdate} showCancel={false} previousLabel="Back">
            {({ onNext }) => {
                const resolvers = {
                    resolveClassNameMap: styles,
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onStaleQuoteBranchCode: onStaleQuoteBranchCode,
                        onRecalculate: recalculate,
                        onResetQuote: resetQuote,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onPrint: handlePrint
                    },
                    resolveComponentMap: {
                        tooltipcomponent: QuoteTooltip
                    }
                };

                return (
                        <ViewModelForm
                            uiProps={metadata.pageContent}
                            model={submissionVM}
                            overrideProps={generateOverrides()}
                            onModelChange={updateWizardData}
                            classNameMap={resolvers.resolveClassNameMap}
                            callbackMap={resolvers.resolveCallbackMap}
                            onValidationChange={onValidate}
                            componentMap={resolvers.resolveComponentMap}
                        />
                    );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default withRouter(QuotePage);
