import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { withTracking } from 'gw-portals-tracking-react';
import headerLinkComponentStyles from './HeaderLinkComponent.module.scss';

class HeaderLinkComponent extends Component {
    /**
     * @memberof gw-components-platform-react.HeaderLinkComponent
     * @prop {string} propTypes.icon - Font awesome icon name
     * @prop {string} propTypes.title - Title of the link
     * @prop {string} propTypes.description - Short descrition about the title
     * @prop {boolean} propTypes.link - URL link
     */

    static propTypes = {
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    };

    render() {
        const {
            title, icon, link, description
        } = this.props;

        return (
            <TranslatorContext.Consumer>
                {(translator) => (
                    <div className={headerLinkComponentStyles.headerLinkcomponent}>
                        <span className={headerLinkComponentStyles.gwDescription}>
                            {translator(description)}
                        </span>
                        <Link to={link}>
                            <p className={headerLinkComponentStyles.gwTitle}>
                                <Icon icon={icon} />
                                {translator(title)}
                            </p>
                        </Link>
                    </div>
                )}
            </TranslatorContext.Consumer>
        );
    }
}

export default withTracking(HeaderLinkComponent);
