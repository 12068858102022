import { setComponentMapOverrides } from '@jutro/uiconfig';

import { PaymentDetailsPage } from 'gw-capability-quoteandbind-common-react';

import PAYourInfoPage from './pages/YourInfo/YourInfoPage';
import PAQualificationPage from './pages/Qualification/QualificationPage';
import PADriversPage from './pages/Drivers/DriversPage';
import PADriverSummaryPage from './pages/Drivers/DriverSummary';
import PAMoreInfo from './pages/MoreInfo/MoreInfo';
import PAVehiclesPage from './pages/Vehicles/VehiclesPage';
import PAQuotePage from './pages/Quote/QuotePage';
import PAAdditionalInformation from './pages/AdditionalInformation/AdditionalInformationPage';
import PAPolicyInformationPage from './pages/PolicyInformation/PolicyInformationPage';
import PASuccessPage from './pages/Success/SuccessPage';

setComponentMapOverrides(
    {
        PAYourInfoPage: { component: 'PAYourInfoPage' },
        PAQualificationPage: { component: 'PAQualificationPage' },
        PADriversPage: { component: 'PADriversPage' },
        PADriverSummaryPage: { component: 'PADriverSummaryPage' },
        PAMoreInfo: { component: 'PAMoreInfo' },
        PAVehiclesPage: { component: 'PAVehiclesPage' },
        PAQuotePage: { component: 'PAQuotePage' },
        PAAdditionalInformation: { component: 'PAAdditionalInformation' },
        PAPolicyInformationPage: { component: 'PAPolicyInformationPage' },
        PAPaymentDetailsPage: { component: 'PAPaymentDetailsPage' },
        PASuccessPage: { component: 'PASuccessPage' }
    },
    {
        PAYourInfoPage,
        PAQualificationPage,
        PADriversPage,
        PAMoreInfo,
        PADriverSummaryPage,
        PAVehiclesPage,
        PAQuotePage,
        PAAdditionalInformation,
        PAPolicyInformationPage,
        PAPaymentDetailsPage: PaymentDetailsPage,
        PASuccessPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PAWizard } from './PAWizard';
