import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class PrefillLookupService {
    static getPrefillData(prefillInfoLookupRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('prefilllookup'),
            'getPrefill',
            [prefillInfoLookupRequestDTO]
            //additionalHeaders
        );
    }
}
