import { Launcher } from '@jutro/app';
import { LocaleService } from 'gw-portals-i18n-react';
import digitalTheme from 'gw-digital-theme-styles';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import {
    publish,
    subscribe,
    unsubscribeAll,
    JUTRO_TOPICS,
    loadGA,
    DefaultDictionaryProvider,
    DefaultWhitelistProvider
} from '@jutro/events';
import { getConfigValue } from '@jutro/config';
import _ from 'lodash';

import 'regenerator-runtime';

import App from './app/App';
import cookie from 'js-cookie';

const { trackingConfig = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const ga = loadGA(getConfigValue('GA_TRACKING_ID'));
// const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;


if (gaTrackingId) {
    // initDefaultGA(trackingConfig);
    const { location } = window;
    subscribe([
        'customizeButtonClicked'
    ], (event, topic) => {
                //GA4 Support - DataLayer
                window.dataLayer.push({
                    'page': location.pathname,
                    'event': 'Get New Quote',
                    'quoteId': event.submissionNum !== undefined ? event.submissionNum : cookie.get('quoteID')
                });  
    });
    const PageMapping = [
        { id: 'your-info', title: 'Your Info Page' },
        { id: 'vehicles', title: 'Vehicle Details Page' },
        { id: 'drivers', title: 'Driver Details Page' },
        { id: 'driverhistory', title: 'Driver History Page' },
        { id: 'moreinfo', title: 'More Details Page' },
        { id: 'quote', title: 'Quote Page' },
        { id: 'policy-information', title: 'Additional Details Page' },
        { id: 'payment-details', title: 'Payment Details Page' },
        { id: 'payment-confirmation', title: 'Payment Confirmation Page' },
        { id: 'qnbconfirmation', title: 'Final Confirmation Page' }
    ];

    const fetchTitle = (data) => {
        const pathName = data.page;
        const applicationPath = !_.isEmpty(pathName) ? pathName.split('/')[1] : undefined;
        if (!_.isEmpty(applicationPath)) {
            const wizardPath = pathName.split('quote-pa/')[1];
            if (!_.isEmpty(wizardPath)) {
                const pageName = _.find(PageMapping, { id: wizardPath });
                if (!_.isEmpty(pageName)) {
                    return pageName.title;
                }
            }

            if (applicationPath.includes('contact-us')) {
                return data.customContact !== undefined && data.customContact ? 'Technical Difficulties Page' : 'Contact Us Page';
            }

            if (applicationPath.includes('payment-confirmation')) {
                return 'Payment Confirmation Page';
            }
            if (pathName.includes('qnbconfirmation')) {
                return 'Final Confirmation Page';
            }
            if (pathName.includes('ampquoteentry')) {
                return 'Transition Page';
            }
        } else {
            const dataPath = data.pathname;
            if (data.state === 'POP' && !_.isUndefined(dataPath) && dataPath.includes('ampquoteentry')) {
                return 'Transition Page';
            } else if (data.state === 'POP' && !_.isUndefined(dataPath) && (
                dataPath.includes('quote-pa') || dataPath.includes('contact-us') ||
                dataPath.includes('qnbconfirmation') || dataPath.includes('payment-confirmation')
            )) {
                return undefined;
            }
            return 'Quote Landing Page';
        }
        return undefined;
    };
    subscribe([
        JUTRO_TOPICS.ROUTE_CHANGED
    ], (data, event) => {
        const title = fetchTitle(data);
        if (title !== undefined) {
            // EH-23186 : passing Quote&Buy title to dataLayer
            window.dataLayer.push({
                event:'PageTitleForHistoryChanges',
                pageTitle: title,
                location: location.origin,
                page: location.pathname,
                quoteState: localStorage.getItem("gaState")
            });

            // EH-23195 : passing state to dataLayer
            if (title !== "Quote Landing Page") {
                window.dataLayer.push({
                    event: "State",
                    quoteState: localStorage.getItem("gaState"),
                });
            }

        }
    });

    unsubscribeAll([JUTRO_TOPICS.COMPONENT_LOADED, JUTRO_TOPICS.VALUE_CHANGED, JUTRO_TOPICS.FIELD_BLUR, JUTRO_TOPICS.UNKNOWN_ERROR]);
}

new Launcher()
    .withRootId('root')
    .withInitialization(() => {
        LocaleService.register();
    })
    .withTheme({ themeConfig: digitalTheme })
    .launch(App, {
        messageLoader: LocaleService.loadMessages,
        coreMessageLoader: LocaleService.loadCoreMessages,
        refreshOnLocaleChange: false,
    });