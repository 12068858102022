import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class RetrieveQuoteService {
    static retrieveQuoteByEmail(quoteRetrievalByEmailRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'retrieveQuotesByEmailId',
            [quoteRetrievalByEmailRequestDTO],
            additionalHeaders
        );
    }

    static checkSubmission(checkSubmissionDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'checkSubmission',
            [checkSubmissionDTO],
            additionalHeaders
        );
    }
}
