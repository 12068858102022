import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { InputField } from '@jutro/components';
import FaqItemComponent from '../FaqItemComponent/FaqItemComponent';
import styles from './FaqSectionComponent.module.scss';

import metadata from './FaqSectionComponent.metadata.json5';

function FaqSectionComponent(props) {
    const translator = useContext(TranslatorContext);
    const { value, searchText, messages } = props;

    const readValue = useCallback(
        (id, compPath) => {
            let effectivePath = compPath;

            if (compPath === undefined) {
                effectivePath = id;
            }

            const currentSection = _.get(value, `${effectivePath}`);

            return currentSection;
        },
        [value]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveComponentMap: {
            faqitemcomponent: FaqItemComponent
        }
    };

    const generateTopicOverrides = useCallback(() => {
        const faqTopics = _.get(value, 'topics');
        const overrides = faqTopics.map((_section, index) => {
            return { [`faqTopicComponent${index}`]: { searchText: searchText, messages } };
        });

        return Object.assign({}, ...overrides);
    }, [messages, searchText, value]);

    const overrideProps = {
        faqTopicsIterable: {
            accordionStates: ['accordion_gw-faq-deductible-payment'],
            data: _.get(value, 'topics')
        },
        ...generateTopicOverrides()
    };

    return (
        <div className={styles.gwSectionContainer}>
            <InputField
                id={value.id}
                readOnly
                hideLabel
                className={styles.gwSectionTitle}
                value={translator(value.title)}
            />
            {renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers)}
        </div>
    );
}

FaqSectionComponent.propTypes = {
    value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
    }).isRequired,
    searchText: PropTypes.string.isRequired,
    messages: PropTypes.shape({}).isRequired
};
export default FaqSectionComponent;
