import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
// Quote & Buy v10 code for this is in the v10 file 'AddressStandardizationService.js'

export default class AddressStandardizationService {
    static requestStandardizedAddress(addressStdRequestDTO, debugging, additionalHeaders = {}) {
        if (debugging) { console.log('Calling standardizeAddress in the backend(AddressLookupHandler)...'); }
        return JsonRPCService.send(
            getProxiedServiceUrl('addressLookup'),
            'standardizeAddress',
            [addressStdRequestDTO],
            additionalHeaders
        );
    }
}
