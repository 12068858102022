module.exports = function (e) {
  var r = {};
  function t(n) {
    if (r[n]) return r[n].exports;
    var i = r[n] = {
      i: n,
      l: !1,
      exports: {}
    };
    return e[n].call(i.exports, i, i.exports, t), i.l = !0, i.exports;
  }
  return t.m = e, t.c = r, t.d = function (e, r, n) {
    t.o(e, r) || Object.defineProperty(e, r, {
      enumerable: !0,
      get: n
    });
  }, t.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, t.t = function (e, r) {
    if (1 & r && (e = t(e)), 8 & r) return e;
    if (4 & r && "object" == typeof e && e && e.__esModule) return e;
    var n = Object.create(null);
    if (t.r(n), Object.defineProperty(n, "default", {
      enumerable: !0,
      value: e
    }), 2 & r && "string" != typeof e) for (var i in e) t.d(n, i, function (r) {
      return e[r];
    }.bind(null, i));
    return n;
  }, t.n = function (e) {
    var r = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return t.d(r, "a", r), r;
  }, t.o = function (e, r) {
    return Object.prototype.hasOwnProperty.call(e, r);
  }, t.p = "", t(t.s = "./jutro/packages/jutro-visualization/index.js");
}({
  "./jutro/packages/jutro-visualization/index.js": function _jutro_packages_jutroVisualization_indexJs(e, r, t) {
    "use strict";

    t.r(r);
    var n = t("react"),
      i = t.n(n),
      o = t("react-vega"),
      a = t.n(o),
      u = t("prop-types"),
      c = t.n(u);
    function p(e) {
      return i.a.createElement(a.a, e);
    }
    p.propTypes = {
      background: c.a.string,
      className: c.a.string,
      data: c.a.string,
      enableHover: c.a.bool,
      height: c.a.number,
      width: c.a.number,
      logLevel: c.a.number,
      onNewView: c.a.func,
      onParseError: c.a.func,
      padding: c.a.number,
      renderer: c.a.string,
      spec: c.a.object,
      style: c.a.object,
      tooltip: c.a.func
    }, p.__docgenInfo = {
      description: "Jutro wrapper for Vega visualization component. Detailed props descriptions can be found here -\nhttps://github.com/vega/react-vega/tree/master/packages/react-vega#props, specification -\nhttps://vega.github.io/vega/docs/specification/.",
      displayName: "Vega",
      props: {
        background: {
          type: {
            name: "string"
          },
          required: !1,
          description: "The background color of the entire view"
        },
        className: {
          type: {
            name: "string"
          },
          required: !1,
          description: ""
        },
        data: {
          type: {
            name: "string"
          },
          required: !1,
          description: ""
        },
        enableHover: {
          type: {
            name: "bool"
          },
          required: !1,
          description: "Highlight data rectangle on hover"
        },
        height: {
          type: {
            name: "number"
          },
          required: !1,
          description: "The height in pixels of the data rectangle"
        },
        width: {
          type: {
            name: "number"
          },
          required: !1,
          description: "The width in pixels of the data rectangle"
        },
        logLevel: {
          type: {
            name: "number"
          },
          required: !1,
          description: "Sets the current log level"
        },
        onNewView: {
          type: {
            name: "func"
          },
          required: !1,
          description: ""
        },
        onParseError: {
          type: {
            name: "func"
          },
          required: !1,
          description: ""
        },
        padding: {
          type: {
            name: "number"
          },
          required: !1,
          description: "The distance in pixels between the data rectangles"
        },
        renderer: {
          type: {
            name: "string"
          },
          required: !1,
          description: ""
        },
        spec: {
          type: {
            name: "object"
          },
          required: !1,
          description: ""
        },
        style: {
          type: {
            name: "object"
          },
          required: !1,
          description: ""
        },
        tooltip: {
          type: {
            name: "func"
          },
          required: !1,
          description: ""
        }
      }
    }, p.__importInfo = {
      componentName: "Vega",
      packageName: "@jutro/visualization"
    }, t.d(r, "Vega", function () {
      return p;
    });
  },
  "prop-types": function propTypes(e, r) {
    e.exports = require("prop-types");
  },
  react: function react(e, r) {
    e.exports = require("react");
  },
  "react-vega": function reactVega(e, r) {
    e.exports = require("react-vega");
  }
});