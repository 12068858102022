import React, { useContext, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { GeoCodeService } from 'gw-portals-google-maps-js';
import { EmailQuoteService } from 'gw-capability-quoteandbind';
import { WizardContext } from 'gw-portals-wizard-react';
import styles from './AgentInfoComponent.module.scss';
import messages from './AgentInfoComponent.messages';
import { useEffect } from 'react';

function AgentInfoComponent(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const wizardContext = useContext(WizardContext);
    const {
        steps, currentStepIndex, wizardData, updateWizardData
    } = wizardContext;
    const submissionVM = wizardData;
    const [modalClose, setModalClose] = useState(true);
    const modalCloseRef = useRef(true);
    const agentsArr = useRef([]);
    const [isAgentLocator, setAgentLocator] = useState(false);
    const [isAgentVisible, setAgentVisible] = useState(true);
    const [isError, setErrorMessage] = useState(false);
    const [isLocatorError, setLocatorError] = useState(false)
    const agentLocatorRef = useRef(false);
    const selectedAgentInfoRef = useRef(
        (submissionVM.baseData.agentInfo && submissionVM.baseData.agentInfo.agentInfo
            && submissionVM.baseData.agentInfo.agentInfo.value
            && submissionVM.baseData.agentInfo.agentInfo.value[0]) || {}
    );
    const paths = ['/quote', '/policy-information', '/payment-details'];

    useEffect(() => {
        const width = window.innerWidth;
        if (breakpoint == 'phone' || breakpoint == 'tablet') {
            let element = breakpoint == 'phone' ? 'agentInfoMobileDisplay' : 'agentInfoTabletDisplay';
            var x = document.getElementById(element);
            var y = document.getElementById('agentIcon');
            if (!isError) {
                if (x !== null && y !== null) {
                    if (x.style.display == "none") {
                        x.style.display = "none";
                        y.style.display = "block";
                        x.style.position = "fixed";
                    } else {
                        x.style.display = "none";
                        y.style.display = "block";
                        x.style.position = "fixed";
                    }
                    const agentContainerWidth = document.getElementById(element) !== null
                        ? document.getElementById(element).offsetWidth : 0;
                    document.getElementById(element) !== null
                        ? (document.getElementById(element).style.left = width - agentContainerWidth + "px") : 0;
                }
            }

            let agentSearchContainerWidth = document.getElementById("agentLocatorContainer") !== null
                ? document.getElementById("agentLocatorContainer").offsetWidth : 0;
            document.getElementById("agentLocatorContainer") !== null
                ? (document.getElementById("agentLocatorContainer").style.left = width - agentSearchContainerWidth + "px") : "";
        }
    })

    const getSpanClassNames = (spanName) => {
        let agentSpanMargin;
        if (spanName === 'building') {
            agentSpanMargin = classNames({
                'fa fa-building': true,
                [styles.agentSpanMargin]: true
            });
        } else if (spanName === 'phone') {
            agentSpanMargin = classNames({
                'fa fa-phone fa-flip-horizontal': true,
                [styles.agentSpanMargin]: true,
                [styles.agentPhoneIconStyle]: true
            });
        } else if (spanName === 'envelope') {
            agentSpanMargin = classNames({
                'fa fa-envelope-square': true,
                [styles.agentSpanMargin]: true
            });
        }
        return agentSpanMargin;
    };

    const toggleModal = () => {
        if (modalClose) {
            modalCloseRef.current = false;
            setModalClose(false);
        } else {
            modalCloseRef.current = true;
            setModalClose(true);
        }
    };

    const searchAgent = (type) => {
      return event => {
        event.preventDefault();
        const searchCriteria = event.target.elements[0].value;
        let inputParam = '';
        if (!searchCriteria || searchCriteria.length === 0) {
            event.target.classList.add(styles.inputErrorStyle);
            return false;
        }
        inputParam = searchCriteria;
        const state = _.get(submissionVM.value, 'baseData.accountHolder.primaryAddress.state');
        const isPostal = !/\D/.test(searchCriteria);
        if (isPostal) {
            GeoCodeService.getGeoCodeStateBasedOnPostalCode(searchCriteria)
                .then((address) => {
                    if (address.stateCode === state) {
                        setAgentLocator(false);
                        EmailQuoteService.getAgentInfo(inputParam, state)
                            .then((response) => {
                                if (response && response.agentInfo) {
                                    agentsArr.current = response.agentInfo;
                                    agentLocatorRef.current = true;
                                    setAgentLocator(true);
                                    setErrorMessage(false);
                                    setLocatorError(false);
                                } else {
                                    agentsArr.current = [];
                                    agentLocatorRef.current = false;
                                    setAgentLocator(false);
                                    setErrorMessage(true);
                                }
                                if (response.errorCode !== undefined) {
                                    console.log('Error: ', response.errorCode);
                                    console.log('Error Description: ', response.errorDescription);
                                }
                            });
                    } else {
                        if (isAgentLocator) {
                            agentLocatorRef.current = true;
                            setAgentLocator(true);
                            setErrorMessage(true);
                            setLocatorError(true);
                        } else {
                            agentLocatorRef.current = false;
                            setAgentLocator(false);
                            setErrorMessage(true);
                        }

                        return false;
                    }
                    return false;
                });
        } else {
            agentLocatorRef.current = false;
            setAgentLocator(false);
            EmailQuoteService.getAgentInfo(inputParam, state).then((response) => {
                if (response && response.agentInfo) {
                    agentsArr.current = response.agentInfo;
                    agentLocatorRef.current = true;
                    setAgentLocator(true);
                    setErrorMessage(false);
                    setLocatorError(false)
                } else {
                    if (type === "locator") {
                      agentLocatorRef.current = true;
                      setAgentLocator(true);
                      setErrorMessage(false);
                      setLocatorError(true);
                    } else {
                      agentLocatorRef.current = false;
                      setAgentLocator(false);
                      setErrorMessage(true);
                    }
                }
                if (response.errorCode !== undefined) {
                    console.log('Error: ', response.errorCode);
                    console.log('Error Description: ', response.errorDescription);
                }
            }, () => {

            });
        }
        return false;
      } 
    };

    const closeLocatorModal = () => {
        if (isAgentLocator) {
            setAgentLocator(false);
            agentLocatorRef.current = false;
            setErrorMessage(false);
        } else {
            setAgentLocator(true);
            agentLocatorRef.current = true;
        }
    };

    const getAgentLocPhone = (phone) => {
        if (phone) {
            return `tel:${phone}`;
        }
        return phone;
    };

    const updateInputStyle = (e) => {
        if (e.target.value && e.target.value.length > 0) {
            e.currentTarget.classList.remove(styles.inputErrorStyle);
        }
    };

    const onKeyPress = (event) => {
        if (!((event.charCode === 13 || event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)
            || (event.charCode > 47 && event.charCode < 59) || (event.charCode === 32))) {
            event.preventDefault();
            return false;
        }
    };

    const showSearchForPath = () => {
        if (steps[currentStepIndex].path !== '/payment-details') {
            return (
                <div>
                    <p style={{ fontSize: '11px', textAlign: 'center' }}>Need a different agent? Search below</p>
                    <div className={styles.agentSearch}>
                        <form name="agentInfoForm" id="agentInfoForm" onChange={updateInputStyle} onSubmit={searchAgent('widget')}>
                            <input
                                id="agentSearchText"
                                type="text"
                                name="inputValue"
                                placeholder={translator(messages.searchPlaceholder)}
                                onKeyPress={event => onKeyPress(event)}
                            />
                            <button type="submit" className={styles.agentsearchIcon}>
                                <i className="fa fa-search" />
                            </button>
                        </form>
                    </div>
                    {isError ? (
                        <div style={{textAlign:'center',marginTop:'10px'}}>
                            <span className={styles.noResultStyle}>
                                No results found
                            </span>
                        </div>
                    ) : null}
                </div>
            );
        }
        return '';
    };

    const updateAgentInfo = (event, agent) => {
        event.preventDefault();
        if (agent !== null) {
            const quoteId = _.get(submissionVM, 'quoteID.value');
            const accountNumber = _.get(submissionVM.value, 'baseData.accountNumber');
            const agentRequestDTO = {
                submission: quoteId,
                serviceCenter: agent.serviceCenter,
                producerCode: agent.producerCode,
                accountNumber: accountNumber
            };
            EmailQuoteService.updateAgent(agentRequestDTO).then((response) => {
                if (response.successResponse && !response.errorCode) {
                    const agentDetail = {
                        agentCellNumber: agent.agentCellNumber,
                        email: agent.email,
                        firstName: agent.firstName,
                        imageURL: agent.imageURL,
                        lastName: agent.lastName,
                        phoneNumber: agent.phoneNumber,
                        producerCode: agent.producerCode,
                        serviceCenter: agent.serviceCenter,
                        address: {
                            addressLine1: agent.address.addressLine1,
                            addressLine2: agent.address.addressLine2,
                            city: agent.address.city,
                            country: agent.address.country,
                            state: agent.address.state,
                            county: agent.address.county,
                            postalCode: agent.address.postalCode
                        }
                    };
                    selectedAgentInfoRef.current = agentDetail;
                    agentLocatorRef.current = false;
                    _.set(submissionVM, 'baseData.agentInfo.agentInfo.value', _.cloneDeep([agentDetail]));
                    updateWizardData(submissionVM);
                    setAgentLocator(false);
                    setErrorMessage(false);
                } else if (response.errorCode && response.errorCode === '001') {
                    selectedAgentInfoRef.current = {};
                    setAgentVisible(false);
                }
                if (response.errorCode !== undefined) {
                    console.log('Error:', response.errorCode);
                    console.log('Error Description: ', response.error);
                    selectedAgentInfoRef.current = {};
                    setAgentVisible(false);
                }
            }, () => {

            });
        }
    };

    const processAgentArr = () => {
        return agentsArr.current.map((agent) => {
            return (
                <li>
                    <div className={styles.agentLocatordetails} onClick={(e) => updateAgentInfo(e, agent)} onKeyDown="" role="button" tabIndex="-1">
                        <img src={agent.imageURL} alt="Agent" draggable="false" />
                        <div className={styles.locatorContent}>
                            <h2>
                                {agent.firstName}
                                &nbsp;
                                {agent.lastName}
                            </h2>
                            <span style={{ lineHeight: '14px' }}>
                                {agent.address.addressLine1}
                                {','}
                                <br />
                                {agent.address.city}
                                {', '}
                                {agent.address.state}
                                {' '}
                                {agent.address.postalCode.substring(0, 5)}
                                <br />
                                Phone:
                                {' '}
                                <a
                                    className={styles.locatorPhoneStyle}
                                    href={getAgentLocPhone(agent.phoneNumber)}
                                >
                                    {agent.phoneNumber}
                                </a>
                                <br />
                                Email:
                                {' '}
                                {agent.email}
                                <br />
                            </span>
                        </div>
                    </div>
                </li>
            );
        });
    };

    const getAgentsList = () => {
        if (agentsArr.current && agentsArr.current.length > 0) {
            return (
                <ul>
                    {processAgentArr()}
                </ul>
            );
        }
    };

    const agentLocImageMobileView = () => {
        if (breakpoint !== 'phone') {
            return (
                <p className={styles.pImageStyle}>
                    <label className={styles.agentBtn} htmlFor="agent_info" onClick={toggleModal}>
                        <img
                            className={mobileView ? styles.agentImgMobile : styles.agentImg}
                            src={selectedAgentInfoRef.current.imageURL}
                            draggable="false"
                            alt="Agent Info"
                            title="Agent Info"
                            aria-label="Agent Info"
                        />
                    </label>
                </p>
            );
        }
        return '';
    };

    const getAgentLocStyle = () => {
        if (agentsArr.current && agentsArr.current.length === 0) {
            return styles.displayAgentLocStyle;
        }
        return styles.displayStyle;
    };

    const showAgentDetails = () => {
        let element = breakpoint == 'phone' ? 'agentInfoMobileDisplay' : 'agentInfoTabletDisplay';
        document.getElementById("agentIcon") !== null
            ? document.getElementById("agentIcon").style.display = 'none' : '';
        document.getElementById(element) !== null
            ? document.getElementById(element).style.display = 'block' : ''
        const width = window.innerWidth;
        const agentContainerWidth = document.getElementById(element).offsetWidth;
        document.getElementById(element).style.left = width - agentContainerWidth + 'px';
    }


    const hideAgentDetails = () => {
        let element = breakpoint == 'phone' ? 'agentInfoMobileDisplay' : 'agentInfoTabletDisplay';
        document.getElementById("agentIcon") !== null
            ? (document.getElementById("agentIcon").style.display = "block") : "";
        document.getElementById(element) !== null
            ? (document.getElementById(element).style.display = "none") : "";
    };

    let mobileView = false;

    if (breakpoint === 'phone' || breakpoint === 'tablet') {
        mobileView = true;
    }

    const agentIcon = () => {
        if (!agentLocatorRef.current && (breakpoint === 'phone' || breakpoint === 'tablet')) {
            return (
                <div className={styles.imgContainerTablet} id="agentIcon" onClick={showAgentDetails}>
                    <div className={styles.agentMobileImg}>
                        <span style={{ margin: '0px 5px 0px 5px' }}>
                            <i className="fa fa-chevron-left" style={{ color: '#4D9CBC' }} />
                        </span>
                        <span>
                            <img
                                className={mobileView ? styles.agentImgMobile : styles.agentImg}
                                src={selectedAgentInfoRef.current.imageURL}
                                draggable="false"
                                alt="Agent Info"
                                title="Agent Info"
                                aria-label="Agent Info"
                            />
                        </span>
                    </div>
                </div>
            );
        }
    }

    const agentLocator = () => {
        if (agentLocatorRef.current) {
            return (
                <div id="agentLocatorTemplate" className={getAgentLocStyle()}>
                    <input className={styles.agentModalStateLocator} id="agent_locator" type="checkbox" checked={agentLocatorRef.current} />
                    <div className={styles.agentLocator} id='agentLocatorContainer'>
                        <h2 className={styles.headerStyle}>Agent Locator</h2>
                        <label className={styles.locatorModalClose} htmlFor="agent_locator" onClick={closeLocatorModal} onKeyDown="" role="button" tabIndex="0" />
                         { isLocatorError ? <div className={styles.noResultLocator}>
                            <span>No results found</span>
                                </div> : null }
                        <div className={styles.agentLocatorSearch}>
                            <form name="agentLocatorForm" onSubmit={searchAgent('locator')} onChange={updateInputStyle}>
                                <input type="text" id="agentSearchText" placeholder="Type a Name or Zip" />
                                <button type="submit" className={styles.agentLocatorSearchIcon}>
                                    <i className="fa fa-search" />
                                </button>
                            </form>
                        </div>
                        <div className={styles.locatorModalInner}>
                            <span className={styles.lineSeperator} />
                            {getAgentsList()}
                        </div>            
                    </div>
                </div>
            );
        }
        return '';
    };

    const showYourAgentText = () => {
        return 'Your Agent: ';
    };

    const getAgentInfo = () => {
        const { address } = selectedAgentInfoRef.current || {};
        if (!agentLocatorRef.current && breakpoint !== 'phone' && breakpoint !== 'tablet') {
            return (
                <div className={styles.defaultDisplay}>
                    <div>
                        <p className={styles.pImageStyle}>
                            <label
                                className={styles.agentBtn}
                                htmlFor="agent_info"
                                onClick={toggleModal}
                            >
                                <img
                                    className={
                                        mobileView ? styles.agentImgMobile : styles.agentImg
                                    }
                                    src={selectedAgentInfoRef.current.imageURL}
                                    draggable="false"
                                    alt="Agent Info"
                                    title="Agent Info"
                                    aria-label="Agent Info"
                                />
                            </label>
                        </p>
                    </div>
                    <div className={styles.agentPointer}></div>
                    <div>
                        <input
                            className={styles.agentModalState}
                            id="agent_info"
                            checked={modalCloseRef.current}
                            type="checkbox"
                        />
                        <div id="modal" className={styles.agentModalFix}>{/*agentModal*/}
                            <div id="innerModal" className={styles.agentModalInner}>
                                <div className={styles.agentDetails}>
                                    <div className={styles.agentName}>
                                        <div>
                                            <span>
                                                <img
                                                    className={styles.agentIconUser}
                                                    alt="agentlocationIcon"
                                                    src="alfa-images/agentUser.svg"
                                                />
                                            </span>
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: "bold" }}>
                                                {showYourAgentText()}
                                            </span>
                                            <br />
                                            <span>
                                                {selectedAgentInfoRef.current.firstName}
                                                &nbsp;
                                                {selectedAgentInfoRef.current.lastName}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.agentContentLeft}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginTop: "5px" }}>
                                                <img style={{ width: '30px' }}
                                                    className={styles.agentIcon}
                                                    alt="agentlocationIcon"
                                                    src="alfa-images/building.svg"
                                                />
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                <span className={styles.agentInfoFontStyle}>
                                                    {address && address.addressLine1}
                                                </span>
                                                {","}

                                                {breakpoint !== "phone" ? <>&ensp;</> : ""}
                                                <span
                                                    className={
                                                        (styles.agentInfoAddressSpan,
                                                            styles.spanSpaceStyle)
                                                    }
                                                >
                                                    {address && address.city}
                                                    {", "}
                                                    {address && address.state}{" "}
                                                    {address && address.postalCode.substring(0, 5)}
                                                </span>
                                            </div>
                                        </div>

                                        <span>
                                            <img
                                                className={styles.agentIcon}
                                                alt="agentlocationIcon"
                                                src="alfa-images/phoneRight.svg"
                                            />
                                        </span>
                                        <span
                                            className={
                                                (styles.agentInfoFontStyle, styles.spanSpaceStyle)
                                            }
                                        >
                                            <a
                                                className={styles.agentPhoneStyle}
                                                href={getAgentLocPhone(
                                                    selectedAgentInfoRef.current.phoneNumber
                                                )}
                                            >
                                                {selectedAgentInfoRef.current.phoneNumber}
                                            </a>
                                        </span>
                                        <br />
                                        <span>
                                            <span>
                                                <img
                                                    className={styles.agentIcon}
                                                    alt="agentlocationIcon"
                                                    src="alfa-images/emailAgent.svg"
                                                />
                                            </span>
                                            {selectedAgentInfoRef.current.email}
                                        </span>
                                        <br />
                                        <hr style={{ borderColor: "#8AC0DB" }} />
                                        {showSearchForPath()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return '';
    };

    const showSearchForPathMobile = () => {
        if (steps[currentStepIndex].path !== '/payment-details') {
            return (
                <div>
                    <div className={styles.agentSearch}>
                        <p>Need a different agent? Search below</p>
                        <form name="agentInfoForm" id="agentInfoFormMobile" onChange={updateInputStyle} onSubmit={searchAgent}>
                            <input type="text" placeholder={translator(messages.searchPlaceholder)} />
                            <button type="submit" className={styles.agentsearchIcon}>
                                <i className="fa fa-search" />
                            </button>
                        </form>
                    </div>
                </div>
            );
        }
        return '';
    };

    const getAgentInfoMobile = () => {
        if (!agentLocatorRef.current && (breakpoint === 'phone' || breakpoint === 'tablet')) {
            const { firstName, lastName, email, phoneNumber, address, imageURL } = selectedAgentInfoRef.current;
            return (
                <div
                    id={
                        breakpoint === "phone"
                            ? "agentInfoMobileDisplay"
                            : "agentInfoTabletDisplay"
                    }
                    className={
                        breakpoint === "phone"
                            ? styles.agentInfoMobileDisplay
                            : styles.agentInfoTabletDisplay
                    }
                >
                    <div id="agentMobile">
                        <input
                            className={styles.agentModalState}
                            id="agent_info"
                            checked={modalCloseRef.current}
                            type="checkbox"
                        />
                        <div id="modal" className={styles.agentModal}>{/*agentModal*/}
                            <div id="innerModal" className={styles.agentModalInner}>
                                <div className={styles.agentDetails}>
                                    <div className={styles.agentName}>
                                        <div>
                                            <span>
                                                <img
                                                    className={styles.agentIconUser}
                                                    alt="agentlocationIcon"
                                                    src="alfa-images/agentUser.svg"
                                                />
                                            </span>
                                        </div>
                                        <div>
                                            <span style={{ fontWeight: "bold" }}>
                                                {showYourAgentText()}
                                            </span>
                                            <br />
                                            <span>
                                                {selectedAgentInfoRef.current.firstName}
                                                &nbsp;
                                                {selectedAgentInfoRef.current.lastName}
                                            </span>
                                        </div>
                                        <div
                                            className={styles.agentClose}
                                            onClick={hideAgentDetails}
                                        >
                                            <i className="fa fa-chevron-right" />
                                            <i
                                                className="fa fa-chevron-right"
                                                style={{ color: "#8AC0DB" }}
                                            />
                                            <i
                                                className="fa fa-chevron-right"
                                                style={{ color: "#D7E9F4" }}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.agentContentLeft}>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginTop: "5px" }}>
                                                <img
                                                    style={{ width: "30px" }}
                                                    className={styles.agentIcon}
                                                    alt="agentlocationIcon"
                                                    src="alfa-images/building.svg"
                                                />
                                            </div>
                                            <div style={{ marginLeft: "10px" }}>
                                                <span className={styles.agentInfoFontStyle}>
                                                    {address && address.addressLine1}
                                                </span>
                                                {","}

                                                {breakpoint !== "phone" ? <>&ensp;</> : ""}
                                                <span
                                                    className={
                                                        (styles.agentInfoAddressSpan,
                                                            styles.spanSpaceStyle)
                                                    }
                                                >
                                                    {address && address.city}
                                                    {", "}
                                                    {address && address.state}{" "}
                                                    {address && address.postalCode.substring(0, 5)}
                                                </span>
                                            </div>
                                        </div>

                                        <span>
                                            <img
                                                className={styles.agentIcon}
                                                alt="agentlocationIcon"
                                                src="alfa-images/phoneRight.svg"
                                            />
                                        </span>
                                        <span className={styles.agentPhonenumStyle}>
                                            <a
                                                className={styles.agentPhoneStyle}
                                                href={getAgentLocPhone(
                                                    selectedAgentInfoRef.current.phoneNumber
                                                )}
                                            >
                                                {selectedAgentInfoRef.current.phoneNumber}
                                            </a>
                                        </span>
                                        <br />
                                        <span>
                                            <span>
                                                <img
                                                    className={styles.agentIcon}
                                                    alt="agentlocationIcon"
                                                    src="alfa-images/emailAgent.svg"
                                                />
                                            </span>
                                            {selectedAgentInfoRef.current.email}
                                        </span>
                                        <br />
                                        <hr style={{ borderColor: "#8AC0DB" }} />
                                        {showSearchForPath()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return '';
    };

    const getAgentPositionStyle = () => {
        if (steps[currentStepIndex].path === '/payment-details') {
            if ((breakpoint === 'desktop' || breakpoint === 'tablet') && document.getElementById('paymentInformationDiv')) {
                return classNames(styles.clearSpaceAlignStyle);
            }
        }
        return '';
    };

    if (paths.includes(steps[currentStepIndex].path) && selectedAgentInfoRef.current
        && selectedAgentInfoRef.current.firstName) {
        return (
            <div style={{ textAlign: "left" }} className={getAgentPositionStyle()}>
                {getAgentInfo()}
                {getAgentInfoMobile()}
                {agentLocator()}
                {agentIcon()}
            </div>
        );
    }
    return '';
}

export default AgentInfoComponent;