import { defineMessages } from 'react-intl';

export default defineMessages({
    searchForTopic: {
        id: 'faq.directive.Search for a topic',
        defaultMessage: 'Search for a topic',
    },
    generalBilling: {
        id: 'faq.sections.General Billing',
        defaultMessage: 'General Billing',
    },
    claim: {
        id: 'faq.sections.Claim',
        defaultMessage: 'Claim',
    },
    customizeFAQContent: {
        id: 'faq.sections.How to customize the FAQ content',
        defaultMessage: 'How to customize the FAQ content',
    },
    frequentlyAskedQuestions: {
        id: 'faq.directive.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    differentWaysToPay: {
        id: 'faq.topics.payment_methods.question',
        defaultMessage: 'What are the different ways to pay for my insurance policy?',
    },
    creditCardPayment: {
        id: 'faq.topics.payment_methods.link.To make a one time credit card payment, please click here.',
        defaultMessage: 'To make a one time credit card payment, please click here.',
    },
    correctBillingMistake: {
        id: 'faq.topics.billing_mistake.question',
        defaultMessage: 'How can I correct a billing mistake made in the last three months?',
    },
    reportBillingMistake: {
        id: 'faq.topics.billing_mistake.link.To report a billing mistake on your last statement, please click here.',
        defaultMessage: 'To report a billing mistake on your last statement, please click here.',
    },
    claimProcess: {
        id: 'faq.topics.when_to_claim.question',
        defaultMessage: 'When is it best to begin the claim process after something happened to my house?',
    },
    renewlaCostInFuture: {
        id: 'faq.topics.claim_rate_increase.question',
        defaultMessage: 'In the event of a claim against the insurance policy, will my insurance costs increase at renewal or at any time in the future?',
    },
    deductible: {
        id: 'faq.topics.deductible_payment.question',
        defaultMessage: 'Under what circumstances would I have to pay a deductible when making a claim?',
    },
    FaqTopic: {
        id: 'faq.topics.show_video.question',
        defaultMessage: 'How to make a youtube video appear in the answer for a FAQ topic?',
    },
    clickableLink: {
        id: 'faq.topics.show_link.question',
        defaultMessage: 'How can I show a clickable link appear in the answer for a FAQ topic?',
    },
    customText: {
        id: 'faq.topics.show_link_text.question',
        defaultMessage: 'How can I show custom text that can be clicked to access a link in the answer for a FAQ topic?',
    },
    clickedText: {
        id: 'faq.topics.show_link_text.This text can be clicked.',
        defaultMessage: 'This text can be clicked.',
    },
    formattingQuestion: {
        id: 'faq.topics.formatting.question',
        defaultMessage: 'How can I customize the format of the question and answer elements for a given topic?',
    },
    bankTransfer: {
        id: 'faq.topics.payment_methods.answer.long',
        defaultMessage: 'Payments can be made using a major credit/debit card or a bank transfer.',
    },
    billngMistake: {
        id: 'faq.topics.billing_mistake.answer.long',
        defaultMessage: 'Billing mistakes should be brought to our attention as soon as they are found to ensure that they are promptly corrected. Please make sure to gather as much information about the mistake such as the date and amounts. You may then contact us through email, chat or phone.',
    },
    eventClaim: {
        id: 'faq.topics.when_to_claim.answer.long',
        defaultMessage: 'In order for us to best serve you in time of need, please notify us of any event that may lead to a claim as soon as you can reasonably expect to make a claim against your insurance policy.',
    },
    increasedRates: {
        id: 'faq.topics.claim_rate_increase.answer.long',
        defaultMessage: 'Filing a claim will not necessarily result in increased rates. Several factors are considered when rating a policy renewal.',
    },
    deductibleAmountasedRates: {
        id: 'faq.topics.deductible_payment.answer.long',
        defaultMessage: 'If the policy stipulates a deductible amount, the deductible amount must be covered when making a claim against the policy.',
    },
    navigateToVideo: {
        id: 'faq.topics.show_video.answer.long',
        defaultMessage: 'Using your browser, navigate to the youtube video that you would like to show. Copy the hash part of the url, for example in the following URL https://www.youtube.com/watch?v=XHB609to9dg the video hash is XHB609to9dg. Insert the video hash inside the topic object under a key named videoId.',
    },
    navigateToExternalLink: {
        id: 'faq.topics.show_link.answer.long',
        defaultMessage: 'Using your browser, navigate to the external link that you would like to show. Copy the url from the browser and insert into a new object as the value for the key url.',
    },
    navigateToExternalLinkText: {
        id: 'faq.topics.show_link_text.answer.long',
        defaultMessage: 'Using your browser, navigate to the external link that you would like to show. Copy the url from the browser and insert into a new object as the value for the key url.',
    },
    customFaq: {
        id: 'faq.topics.formatting.answer.long',
        defaultMessage: 'You can use html markup to customize the FAQ such as:<h2>A list example</h2><ul><li><a href="http://example.com">The first item is a link</a></li><li>The second item is <strong>an emphasis</strong></li><li>The third item is a spinner image <img src="throbber.gif" /></li><li>The fourth item is an escape of the tag &lt;div&gt;</li></ul>',
    },
    typeQuestion: {
        id: 'faq.directive.Start typing your question...',
        defaultMessage: 'Start typing your question...',
    },
    sections: {
        id: 'faq.directive.Sections',
        defaultMessage: 'Sections',
    },
    noMatchingError: {
        id: 'faq.directive.No matching results found',
        defaultMessage: 'No matching results found',
    }
});
