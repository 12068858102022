import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
    useMemo
} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import classNames from 'classnames';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides, renderContentFromMetadata } from '@jutro/uiconfig';
import {
    Main,
    Icon,
    Chevron,
    ModalService,
    IconButton,
    Tooltip,
    Image
} from '@jutro/components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { routeConfirmationModal } from '@jutro/router';
import _ from 'lodash';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
// eslint-disable-next-line import/no-unresolved
import productMetadata from 'product-metadata';
import vmTranslator from 'gw-platform-translations';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { brandingData, setBranding } from 'gw-portals-branding-js';
import { RoutingTracking } from 'gw-portals-tracking-react';

import 'gw-capability-quoteandbind-react';
import commonMessages from 'gw-capability-quoteandbind-common-react/quoteandbind.messages';
import {
    segmentationMap,
    segmentationComponentMap,
    Segmentation,
    SegmentationOption
} from 'gw-portals-segmentation-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponents,
    platformComponentMap,
    ImageComponent,
} from 'gw-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import { WidgetComponentMap, WidgetComponents } from 'gw-custom-widgets-alfa';
import { getConfigValue } from '@jutro/config';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { GooglePlacesLookupComponentMap, GooglePlacesLookupComponents } from 'gw-capability-googleplaces-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { LoadSaveService, CustomQuoteService } from 'gw-capability-quoteandbind';
import { PropertyCodeLookupService } from 'gw-capability-propertycode';

import { GTPWizard } from 'gw-capability-quoteandbind-gtp-react';
import { BOPWizard } from 'gw-capability-quoteandbind-bop-react';
import { HOWizard } from 'gw-capability-quoteandbind-ho-react';
import { PAWizard } from 'gw-capability-quoteandbind-pa-react';
import { SLQWizard } from 'gw-capability-quoteandbind-slq-react';
import FaqPage from 'gw-capability-faq-react';
import { AgentInfoComponentMap, AgentInfoComponents } from 'gw-capability-agentinfo-react';
import { SessionTimeoutComponentMap, SessionTimeoutComponents } from 'gw-capability-sessiontimeout-react';

import ErrorHandlingUtil from '../pages/Utils/ErrorHandlingUtil';
import PaymentRedirect from '../../../../common/capabilities-react/gw-capability-quoteandbind-common-react/pages/PaymentDetails/Components/PaymentRedirect';
import LandingPage from '../pages/LandingPage/LandingPage';
import CustomLandingPage from '../pages/CustomLandingPage/CustomLandingPage';
import SaveAndEmail from './EmailPopover/SaveEmail';
import PaymentConfirmationPage from '../customer/capabilities-react/gw-capability-payment-confirmation-react/PaymentConfirmationPage/PaymentConfirmationPage';
import ConfirmationPage from '../../../../common/capabilities-react/gw-capability-quoteandbind-pa-react/pages/QnBConfirmation/Confirmation';

import ContactUs from '../pages/ContactUs/ContactUs';
import metadata from './App.metadata.json5';
import messages from './EmailPopover/SaveEmail.messages';
import styles from './EmailPopover/SaveEmail.module.scss';

import appMessages from './App.messages';
import cookie from 'js-cookie';

export default function App() {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [activeLanguage, setActiveLanguage] = useState(localStorage.selectedLanguage);
    const [viewModelService, setViewModelService] = useState(undefined);
    const [showPhoneMenu, setShowPhoneMenu] = useState(false);
    const [phoneMenuRef, setPhoneMenuRef] = useState(false);
    const [submissionVM, setsubmissionVM] = useState({});
    const localeService = ServiceManager.getService('locale-service');
    const [emailFlag, setemailFlag] = useState(false);
    const [mobileDiscountsFlag, setmobileDiscountsFlag] = useState(false);
    const [mobileDiscountsViewFlag, setmobileDiscountsViewFlag] = useState(false);
    const [discountData, updatediscountData] = useState([]);
    const [mobilediscountData, updatemobilediscountData] = useState([]);
    const [shakeFlag, setShakeFlag] = useState(false);
    const [discountDivFlag, setdiscountDivFlag] = useState(false);
    const [printFlag, setPrintFlag] = useState(false);
    const [email, updateEmail] = useState('');
    const [isDocusign, setIsDocusign] = useState(false);
    const [pageLoad, setpageLoad] = useState(true);
    const faqLink = getConfigValue('FAQ_LINK');
    let manualFlag = false;
    let prevDiscount = [];

    const togglePhoneMenu = useCallback(() => {
        setShowPhoneMenu(!showPhoneMenu);
        setmobileDiscountsFlag(false);
    }, [showPhoneMenu]);

    const updateMockSub = useCallback((submission) => {
        setsubmissionVM(submission);
        const quoteId = _.get(submissionVM.submission, 'quoteID.value');

        if (!_.isEmpty(submission)) {
            const effloc = submission.location.pathname.replace('/quote-pa/', '');
            setpageLoad(submission.isLoading);
            if ((effloc !== 'your-info') && (effloc !== '') && submission.location.pathname !== '/quote-pa') {
                setemailFlag(true);
                setdiscountDivFlag(true);
                setmobileDiscountsViewFlag(true);
            } else {
                setemailFlag(false);
                setdiscountDivFlag(false);
                setmobileDiscountsViewFlag(false);
            }
            if (effloc === 'quote') {
                setPrintFlag(true);
            }
            if (['policy-information', 'payment-details', 'success', 'qnbConfirmation', 'quote', ''].includes(effloc)) {
                setdiscountDivFlag(false);
            }
        }

        const newDiscounts = [];
        let discounts = _.get(submissionVM.submission, 'baseData.discount.value');
        const path = submission.location.pathname.replace('/quote-pa/', '');
        async function discountRibbon() {
            const disco = await LoadSaveService.getQuoteDiscounts(quoteId);
            discounts = disco;
            if (discounts.isResponsiblePlayer) {
                newDiscounts.push('isResponsiblePlayer');
            }
            if (!_.isUndefined(discounts.isYouthSafetyDriver)) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    if (discounts.isYouthSafetyDriver) {
                        newDiscounts.push('isyouthSafety');
                    }
                }
            }
            if (discounts.hasHomeOwnerDiscount) {
                newDiscounts.push('hasHomeOwnerDiscount');
            }
            if (discounts.hasRelationshipDiscount) {
                newDiscounts.push('hasRelationshipDiscount');
            }
            if (discounts.hasMultiCarDiscount) {
                if (path !== 'vehicles') {
                    newDiscounts.push('hasMultiCarDiscount');
                }
            }
            if (discounts.hasExcessVehicleDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasExcessVehicleDiscount');
                }
            }
            if (discounts.isYouthDefensiveDriver) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('isYouthDefensiveDriver');
                    manualFlag = true;
                }
            }
            if (discounts.isDefensiveDriver) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('isDefensiveDriver');
                    manualFlag = true;
                }
            }
            if (discounts.isYouthHonorStudent) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('isYouthHonorStudent');
                    manualFlag = true;
                }
            }
            if (discounts.hasDriverTrainingDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasDriverTrainingDiscount');
                    manualFlag = true;
                }
            }
            if (discounts.hasOccupationDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasOccupationDiscount');
                }
            }
            if (discounts.hasAffinityDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasAffinityDiscount');
                }
            }
            prevDiscount = discountData;
            let newDiscountArr = [];
            const finDiscountObject = newDiscounts.map((object, index) => {
                if (count === 1) {
                    newDiscountArr.push(
                        {
                            name: newDiscounts[index],
                        }
                    );
                    return newDiscountArr;
                }
                if ((index + 1) < count) {
                    newDiscountArr.push(
                        {
                            name: newDiscounts[index],
                            name2: newDiscounts[index + 1]
                        }
                    );
                } else if (index !== count - 1) {
                    newDiscountArr.push(
                        {
                            name: newDiscounts[index],
                        }
                    );
                }
                return newDiscountArr;
            });
            updatediscountData(newDiscountArr);
        }
        if (_.isUndefined(discounts) && !_.isUndefined(quoteId) && path === 'vehicles') {
            discountRibbon();


        }
        if (!_.isUndefined(discounts)) {
            if (discounts.isResponsiblePlayer) {
                newDiscounts.push('isResponsiblePlayer');
            }
            if (!_.isUndefined(discounts.isYouthSafetyDriver)) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    if (discounts.isYouthSafetyDriver) {
                        newDiscounts.push('isyouthSafety');
                    }
                }
            }
            if (discounts.hasHomeOwnerDiscount) {
                newDiscounts.push('hasHomeOwnerDiscount');
            }
            if (discounts.hasRelationshipDiscount) {
                newDiscounts.push('hasRelationshipDiscount');
            }
            if (discounts.hasMultiCarDiscount) {
                if (path !== 'vehicles') {
                    newDiscounts.push('hasMultiCarDiscount');
                }
            }
            if (discounts.hasExcessVehicleDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasExcessVehicleDiscount');
                }
            }
            if (discounts.isYouthDefensiveDriver) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('isYouthDefensiveDriver');
                    manualFlag = true;
                }
            }
            if (discounts.isDefensiveDriver) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('isDefensiveDriver');
                    manualFlag = true;
                }
            }
            if (discounts.isYouthHonorStudent) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('isYouthHonorStudent');
                    manualFlag = true;
                }
            }
            if (discounts.hasDriverTrainingDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasDriverTrainingDiscount');
                    manualFlag = true;
                }
            }
            if (discounts.hasOccupationDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasOccupationDiscount');
                }
            }
            if (discounts.hasAffinityDiscount) {
                if (path !== 'vehicles' && path !== 'drivers') {
                    newDiscounts.push('hasAffinityDiscount');
                }
            }
        }
        prevDiscount = discountData;
        console.log(prevDiscount);
        console.log(newDiscounts);
        updatemobilediscountData(newDiscounts);

        const count = newDiscounts.length;
        let discountArr = [];
        const finDiscountObject = newDiscounts.map((object, index) => {
            if (count === 1) {
                discountArr.push(
                    {
                        name: newDiscounts[index],
                    }
                );
                return discountArr;
            }
            if (((index + 1) < count) && (index % 2 === 0)) {
                discountArr.push(
                    {
                        name: newDiscounts[index],
                        name2: newDiscounts[index + 1]
                    }
                );
            } else if ((index === count - 1) && (index % 2 === 0)) {
                discountArr.push(
                    {
                        name: newDiscounts[index],
                    }
                );
            }
            return discountArr;
        });
        console.log(discountArr);
        console.log(finDiscountObject);
        const strPrevDiscount = JSON.stringify(prevDiscount);
        const strDiscountArr = JSON.stringify(discountArr);
        if (strPrevDiscount !== strDiscountArr) {
            setShakeFlag(true);
        } else {
            setShakeFlag(false);
        }
        updatediscountData(discountArr);
    }, [submissionVM, emailFlag]);

    const openSeal = () => {
        window.open(appConfig.sealUrl, '', 'width=600,height=500');
    };

    const shouldTogglePhoneMenu = useCallback((e) => {
        if (phoneMenuRef === e.target) {
            togglePhoneMenu();
        }
    }, [phoneMenuRef, togglePhoneMenu]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        setComponentMapOverrides({
            ...platformComponentMap,
            ...AddressLookupComponentMap,
            ...WidgetComponentMap,
            ...VehicleInfoLookupComponentMap,
            ...GooglePlacesLookupComponentMap,
            ...segmentationMap,
            ...policyJobComponentMap,
            ...AgentInfoComponentMap,
            ...SessionTimeoutComponentMap,
            Icon: { component: 'Icon' },
            Chevron: { component: 'Chevron' },
            // replace the native IMG component with a proxied version
            img: { component: 'img' }
        }, {
            ...platformComponents,
            ...AddressLookupComponents,
            ...WidgetComponents,
            ...VehicleInfoLookupComponents,
            ...GooglePlacesLookupComponents,
            ...segmentationComponentMap,
            ...policyJobComponents,
            ...AgentInfoComponents,
            ...SessionTimeoutComponents,
            Icon,
            Chevron,
            img: ImageComponent
        });
        const translatorFn = vmTranslator(translator);
        setViewModelService(
            ViewModelServiceFactory.getViewModelService(
                productMetadata,
                translatorFn
            )
        );

        if (brandingData.BRANDING) {
            setBranding();
        }
        const languageToSet = `${localeService.getPreferredLocale()}_${appConfig.localisation.languageVariant}`;
        localStorage.setItem('selectedLanguage', languageToSet);

        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            // a listener so we to the phone menu so we can
            // close the menu if there's a click outside the menu
            document.addEventListener('mousedown', shouldTogglePhoneMenu, false);
            // If a function is returned from a useEffect,
            // it will be used to clean up when component is unmounted
            return () => document.removeEventListener('mousedown', shouldTogglePhoneMenu, false);
        }
    }, [breakpoint, shouldTogglePhoneMenu, togglePhoneMenu, translator]);

    const updateLanguage = useCallback((language) => {
        let selectedActiveLanguage = language;

        if (language === undefined) {
            selectedActiveLanguage = localeService.getPreferredLocale();
        }

        localStorage.setItem('selectedLanguage', selectedActiveLanguage);
        setActiveLanguage(selectedActiveLanguage);
    }, [localeService]);

    const getMenuRef = useCallback((node) => {
        setPhoneMenuRef(node);
    }, []);

    const getmobileDiscount = useCallback(() => {
        setmobileDiscountsFlag(!mobileDiscountsFlag);
    }, [mobileDiscountsFlag]);

    const metadataToRender = useMemo(() => {
        if (!isDocusign && (breakpoint === 'phone' || breakpoint === 'tablet')) {
            return metadata.pageContent.content.filter((item) => item.id === 'phoneHeader');
        }
        if (!isDocusign) { return metadata.pageContent.content.filter((item) => item.id === 'desktopHeader'); }
        return {};

    }, [breakpoint, isDocusign]);

    const handleError = useCallback((error = {}) => {
        if (!_.isUndefined(error.baseError) && error.baseError.includes('Invalid Recaptcha')
            && !_.isEmpty(submissionVM) && !_.isEmpty(submissionVM.history)) {
            const submission = _.get(submissionVM, 'submission.value');
            return ErrorHandlingUtil.processErrorResponse(submissionVM, error, submission);
        }
        return ErrorHandlingUtil.getErrorFromWizard(error);
    }, [submissionVM]);

    const getHeader = useCallback(() => {
        return (
            <div id="discountHeader" className={styles.headerTextContainer}>
                <Image id="Image" src="alfa-images/piggy_bank_32.png" className={styles.gwsocialimage} ariaLabel="Discounts Applied!" title="Discounts Applied!" />
                <h3 className={styles.headerText}>Discounts Applied!</h3>
            </div>
        );
    }, []);
    const getMobileCell = (items) => {
        const messagePath = 'quoteandbind.common.directives.QuoteDiscountApp.';
        const discountLabel = String(items);
        const discountMssg = translator(_.find(messages, { id: `${messagePath}${discountLabel}` }));
        let message = `${messagePath}${discountLabel}Description`;
        const address = _.get(submissionVM.submission, 'baseData.accountHolder.primaryAddress');
        const driverState = address.state.value.code;
        if (driverState === 'GA' && ['isDefensiveDriver', 'hasOccupationDiscount'].includes(discountLabel)) {
            message = `${messagePath}${discountLabel}DescriptionGa`;
        }

        return (
            <Tooltip
                animation="fade"
                content={translator(_.find(messages, { id: message }))}
                delay={[
                    0,
                    40
                ]}
                duration={[
                    300,
                    300
                ]}
                flipBehavior={[
                    'right',
                    'bottom',
                    'top',
                    'left',
                    'right'
                ]}
                followCursor={false}
                hideOnClick
                id="tooltip"
                placement="right-start"
                showOnInit={false}
                sticky
                trigger="mouseenter"
            >
                <div>
                    <IconButton icon="dollar-sign" aria-haspopup="true" iconClassName={styles.darkColorApp} />
                    <u className={styles.darkContentApp}>{discountMssg}</u>
                </div>
            </Tooltip>
        );
        // return items;
    };

    const getCell = useCallback((items, index, { id }) => {
        const messagePath = 'quoteandbind.common.directives.QuoteDiscountApp.';
        const discountLabel = String(items[id]);
        const discountMssg = translator(_.find(messages, { id: `${messagePath}${discountLabel}` }));
        let message = `${messagePath}${discountLabel}Description`;
        const address = _.get(submissionVM.submission, 'baseData.accountHolder.primaryAddress');
        const driverState = address.state.value.code;
        if (driverState === 'GA' && ['isDefensiveDriver', 'hasOccupationDiscount'].includes(discountLabel)) {
            message = `${messagePath}${discountLabel}DescriptionGa`;
        }

        return (
            <Tooltip
                animation="fade"
                content={translator(_.find(messages, { id: message }))}
                delay={[
                    0,
                    40
                ]}
                duration={[
                    300,
                    300
                ]}
                flipBehavior={[
                    'right',
                    'bottom',
                    'top',
                    'left',
                    'right'
                ]}
                followCursor={false}
                hideOnClick
                id="tooltip"
                placement="right-start"
                showOnInit={false}
                sticky
                trigger="mouseenter"
            >
                <div>
                    <IconButton icon="dollar-sign" aria-haspopup="true" iconClassName={styles.darkColorApp} />
                    <u className={styles.darkContentApp}>{discountMssg}</u>
                </div>
            </Tooltip>
        );
        // return items;
    }, [submissionVM]);

    const setDocusignFlag = useCallback((showDocusign) => {
        setIsDocusign(showDocusign);
    }, []);

    const printQuote = useCallback(() => {
        const quoteID = _.get(submissionVM.submission, 'quoteID.value');
        const printDocument = window.open();
        const responsePromise = LoadSaveService.generatePrintDocumentToken();
        responsePromise.then((response) => {
            const sessionToken = response;
            const mockURI = appConfig.servers.pc.url;
            const effURI = mockURI.substring(mockURI.indexOf('/pc'));
            const xCenterDownloadURI = effURI.replace('unauthenticated/', '');
            const documentUrl = xCenterDownloadURI.concat('document/document/policyDocument/?token=', sessionToken, '&submissionNumber=', quoteID);
            printDocument.location = documentUrl;
            printDocument.target = '_blank';

            if (_.isNull(printDocument)) {
                printDocument.focus();
                printDocument.print();
            }
        });
    }, [submissionVM]);

    const sendEmail = useCallback(async () => {
        console.log(submissionVM);
        const path = submissionVM.location.pathname.replace('/quote-pa/', '');
        setShowPhoneMenu(!showPhoneMenu);
        let quoteId = '';
        let emailAddress1 = '';
        let sessionID = '';
        if (email !== '' && path !== 'quote') {
            _.set(submissionVM.submission, 'baseData.accountHolder.emailAddress1', email);
            emailAddress1 = email;
        } else {
            emailAddress1 = _.get(submissionVM.submission, 'baseData.accountHolder.emailAddress1.value');
        }

        if (!_.isEmpty(submissionVM)) {
            quoteId = _.get(submissionVM.submission, 'quoteID.value');
            sessionID = _.get(submissionVM.submission, 'sessionUUID.value');
        }

        if (path !== 'quote') {
            await ModalService.showPopover(SaveAndEmail,
                { componentProps: submissionVM.submission }).result.then(
                    (result) => {
                        console.log(result.emailData.emailAddress);
                        updateEmail(result.emailData.emailAddress);
                        emailAddress1 = result.emailData.emailAddress;
                        return (
                            {}
                        );
                    }
                );
        }
        const emailObj = { quoteID: quoteId, emailAddress1: emailAddress1, sessionID: sessionID };
        await LoadSaveService.getEmailQuote(emailObj);
        const toastMessage = 'Your email is on the way for Quote ID: ' + quoteId;

        const mssg = <React.Fragment>
            <IconButton icon="envelope" size="large" iconClassName={styles.darkColor} />
            {toastMessage}
        </React.Fragment>;
        toast.configure();
        toast.success(mssg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 15000,
            style: { width: 'auto', marginTop: '6px', minHeight: '10px' },
            closeButton: false,
            bodyClassName: 'alfabody',
            className: 'mockalfa'
        });
    }, [submissionVM, showPhoneMenu]);

    const getQuoteId = useMemo(() => {
        if (_.get(submissionVM, 'location.pathname') && _.get(submissionVM, 'location.pathname') !== '/') {
            if (_.get(submissionVM.submission, 'quoteID.value')) {
                if (document.getElementById('quoteIdSpan')) {
                    document.getElementById('quoteIdSpan').style.visibility = 'visible';
                }
                return `${translator(appMessages.quoteID)}${_.get(submissionVM.submission, 'quoteID.value')}`;
            }
        }
        else if (window.location.pathname.substring(14) === '/contact-us') {
            return `${translator(appMessages.quoteID)}${cookie.get('quoteID')}`;
        }
        return ' ';
    }, [submissionVM, translator]);

    const buildContactNumber = () => {
        return (

            <a id="qb_mobile_phonecall" href="tel:+1-800-964-2532" className="gw-contactNumber">
                {appConfig.phoneNumber}
            </a>

        );
    };
    const manualDiscountFlag = useCallback(() => {
        const flag = mobilediscountData.filter((item) => {
            return ['hasRelationshipDiscount', 'isYouthHonorStudent', 'hasDriverTrainingDiscount', 'isYouthDefensiveDriver', 'isDefensiveDriver', 'isyouthSafety'].includes(item);
        });
        return flag.length > 0;
    }, [mobilediscountData]);

    const getQuoteIdStyle = () => {
        let style = {
            position: 'absolute',
            left: '31rem',
            fontWeight: 'bold',
            fontSize: '14px',
            top: '17px',
            color: '#000'
        };
        if (breakpoint === 'phone') {
            style = {
                position: 'absolute',
                left: '0',
                fontWeight: 'bold',
                fontSize: '12px',
                top: '5rem',
                color: '#000',
                marginLeft: '2.5rem'
            };
        } else if (breakpoint === 'tablet') {
            style = {
                position: 'absolute',
                left: '28rem',
                fontWeight: 'bold',
                fontSize: '14px',
                top: '2.5rem',
                color: '#000'
            };
        }
        return style;
    };

    const overrideProps = {
        globalizationChooserField: {
            onLanguageValueChange: updateLanguage,
            languageValue: activeLanguage
        },
        phoneMenu: {
            ref: getMenuRef,
            visible: showPhoneMenu,
            className: classNames('gw-phone-header', {
                'gw-phone-header-open': showPhoneMenu
            })
        },
        discountdiv: {
            visible: discountDivFlag && !pageLoad
        },
        mobilediscountdivfoot: {
            visible: discountDivFlag && !pageLoad
        },
        mobilediscountdiv: {
            visible: mobileDiscountsFlag && !pageLoad && mobileDiscountsViewFlag
        },
        mobilePhoneNumberText: {
            value: buildContactNumber()
        },
        qb_saveandemail: {
            onClick: sendEmail,
            visible: emailFlag
        },
        qb_mobile_printquote: {
            onClick: printQuote,
            visible: printFlag
        },
        mobilePrintSeperator1: {
            visible: printFlag
        },
        qb_mobile_saveandemail: {
            onClick: sendEmail,
            visible: emailFlag
        },
        manualdiscountdivFoot: {
            visible: manualDiscountFlag()
        },
        manualdiscountdiv: {
            visible: manualDiscountFlag()
        },
        mobileManualDiscountDiv: {
            visible: manualDiscountFlag()
        },
        mobileSeperatorEmail1: {
            visible: emailFlag
        },
        quoteIdSpan: {
            content: getQuoteId,
            style: getQuoteIdStyle()
        },
        discountsTableApp: {
            data: discountData,
        },
        discountColumn: {
            header: getHeader()
        },
        mobilediscountsTableApp: {
            data: mobilediscountData
        },
        mobilediscountsTableAppfoot: {
            data: discountData
        },
        minimobilediscountsTableAppfoot: {
            data: mobilediscountData
        },
        mobilediscountColumn: {
            onCell: getMobileCell
        },
        mobilefootdiscountColumn: {
            onCell: getMobileCell,
            header: getHeader()
        },
        piggyLogo: {
            className: styles.gwsocialimageHeader
        },
        mobilePiggyLink: {
            onClick: getmobileDiscount
        },
        qb_faq: {
            href: faqLink
        },
        footerNavFAQ: {
            href: faqLink
        },
        footerNavCopyright: {
            messageProps: {
                copyrightMessage: translator(appMessages.copyRight, { value: new Date().getFullYear() })
            }
        },
        qb_mobile_faq: {
            href: faqLink
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            togglePhoneMenu: togglePhoneMenu,
            openSeal: openSeal,
            getCell: getCell



        },
    };

    const footerMetadataToRender = useMemo(() => {
        if (breakpoint === 'phone') {
            return metadata.pageContent.content.filter((item) => item.id === 'phoneFooter');
        }
        return metadata.pageContent.content.filter((item) => item.id === 'footer');
    }, [breakpoint]);

    const hideQuoteId = () => {
        const urlStr = window.location.pathname.substring(14);
        if (document.getElementById('quoteIdSpan') && (urlStr === '/' || urlStr === '/contact-us')) {
            document.getElementById('quoteIdSpan').style.visibility = 'hidden';
        } else {
            document.getElementById('quoteIdSpan').style.visibility = 'visible';
        }
    };

    return (
        <DependencyProvider value={{
            LoadSaveService,
            CustomQuoteService,
            PropertyCodeLookupService
        }}
        >
            <ViewModelServiceContext.Provider value={viewModelService}>
                <Segmentation type="query-params">
                    <SegmentationOption defaultOption>
                        <Router basename="/quote-and-buy" getUserConfirmation={routeConfirmationModal}>
                            <Main className="qnbMain" contentClassName="qnbMainContent" fluid>
                                {renderContentFromMetadata(
                                    metadataToRender, overrideProps, resolvers
                                )}
                                <RoutingTracking />
                                <div className="qnbContent">
                                    <ErrorBoundary onError={handleError}>
                                        <Switch>
                                            <Route path="/contact-us" render={(props) => (<ContactUs {...props} />)} />
                                            <Route path="/ampquoteentry" component={PaymentRedirect} />
                                            <Route path="/payment-confirmation" render={(props) => (<PaymentConfirmationPage setDocusign={setDocusignFlag} {...props} />)} />
                                            <Route path="/qnbconfirmation" component={ConfirmationPage} />
                                            <Route exact path="/" render={() => (<LandingPage hideQuoteId={hideQuoteId} />)} />
                                            <Route path="/faq" component={FaqPage} />
                                            <Route path="/gtp" component={GTPWizard} />
                                            <Route path="/quote-bop" component={BOPWizard} />
                                            {/* <Route path="/quote-pa" component={PAWizard}
                                            getSubmissionValue={updateMockSub} /> */}
                                            <Route path="/quote-ho" component={HOWizard} />
                                            <Route path="/quote-pa" render={(props) => (<PAWizard getSubmissionValue={updateMockSub} {...props} />)} />
                                            <Route path="/slq-pa" component={SLQWizard} />
                                            <Route render={() => <Redirect to="/" />} />
                                        </Switch>
                                    </ErrorBoundary>
                                </div>
                                {renderContentFromMetadata(footerMetadataToRender, overrideProps,
                                    resolvers)}
                            </Main>
                        </Router>
                    </SegmentationOption>
                    <SegmentationOption is={[{ LP: '1' }, { LP: '2' }]}>
                        <CustomLandingPage />
                    </SegmentationOption>
                </Segmentation>
            </ViewModelServiceContext.Provider>
        </DependencyProvider>
    );
}
