import React, {
    useCallback, useEffect, useState, useContext
} from 'react';
import _ from 'lodash';

import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { MockUpUtil } from 'gw-portals-util-js';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { messages as commonMessages } from 'gw-platform-translations';
import { FormattedMessage } from 'react-intl';
import { TranslatorContext, formatLongDate, formatShortDate } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';

import CommonUtil from "../../../../../applications/quote-and-buy/src/pages/Utils/CommonUtil";

import { Tooltip, IconButton, ModalService } from '@jutro/components';

// eslint-disable-next-line import/no-unresolved

import { LoadSaveService } from 'gw-capability-quoteandbind';
import styles from './MoreInfo.module.scss';
import metadata from './MoreInfo.metadata.json5';
import messages from './MoreInfo.messages';


function MoreInfoToolTip() {
    const translator = useContext(TranslatorContext);
    const modmessage = 'quoteandbind.views.more-Info.contactInfo';

    return (
        <Tooltip
            animation="fade"
            content={translator(_.find(messages, { id: modmessage }))}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton icon="fa-info-circle" aria-haspopup="true" className={styles.gwToolTip} />

        </Tooltip>
    );
}
function MoreInfo(props) {
    const {
        wizardData: submissionVM, jumpTo, updateWizardData, setQuoteCalled, editInfo, setEditInfo
    } = props;
    const { onValidate, initialValidation } = useValidation('MoreInfo');
    const [emailErrorMessage, updateEmailErrorMessage] = useState(false);
    const [phoneErrorMessage, updatePhoneErrorMessage] = useState(false);
    const [phoneNumber, updatePhoneNumber] = useState('');
 
    useEffect(() => {
        if (!_.isUndefined(submissionVM.baseData.accountHolder.cellNumber.value)) {
            const cellNumber = submissionVM.baseData.accountHolder.cellNumber.value;
            if (cellNumber.length === 10) {
                updatePhoneNumber(cellNumber);
            }
        }
        async function discountRibbon() {
            const quoteId = _.get(submissionVM, 'quoteID.value');
            const disco = await LoadSaveService.getQuoteDiscounts(quoteId);
            _.set(submissionVM, 'baseData.discount', disco);
        }
        discountRibbon();
    }, []);

    const generateOverrides = () => {
        if (submissionVM.baseData.accountHolder.emailAddress1.value === 'dummy@email.dummy') {
            _.set(submissionVM, 'baseData.accountHolder.emailAddress1', '');
        }
    };

    const getEmail = (value, path) => {
        let normalisedValue = value;
        if (!_.isNil(value)) {
            normalisedValue = value.replace(/[^0-9a-zA-Z-_@.]/ig, '');
        }
        _.set(submissionVM, path, normalisedValue);
        updateWizardData(submissionVM);
    };
    const validateEmail = (value) => {
        let reg = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm);
        if (reg.test(value)) {
            updateEmailErrorMessage(false);
        }
    }
    const getPhoneNumber = (value, path) => {
        let normalisedValue = value;
        if (!_.isNil(value)) {
            normalisedValue = value.replace(/[^0-9]/ig, '');
        }
        updatePhoneNumber(normalisedValue);
        const mobileNumber = normalisedValue;
        if (mobileNumber.length === 10) {
            updatePhoneErrorMessage(false);
            const effpath = 'baseData.accountHolder.cellNumber';
            _.set(submissionVM, effpath, mobileNumber);
        }
        updateWizardData(submissionVM);
    };

    const getClick = () => {
        return (
            <FormattedMessage
                {...messages.paFirstCaption}
                values={{
                    link:
                        (<a
                            href="https://www.alfainsurance.com/about-alfa/privacy-policy"
                            style={{ color: '#287D9F' }}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                        )
                }}
            />
        );
    };

    const onNext = useCallback(async () => {
        updateEmailErrorMessage(false);
        updatePhoneErrorMessage(false);
        dataLayer.push({ 'event': 'gtm.click', 'gtm.elementId': 'qb_next' });
        const path = 'baseData.accountHolder.cellNumber';
        let stopFlag = false;
        
        if (phoneNumber.length === 10) {
            const toUpdateMobile = phoneNumber.length === 0 ? undefined : phoneNumber;
            _.set(submissionVM, path, toUpdateMobile);
            updateWizardData(submissionVM);
        } else {
            updatePhoneErrorMessage(true);
            stopFlag = true;
        }

        let reg = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm);
        if (!(reg.test(submissionVM.baseData.accountHolder.emailAddress1.value))) {
            updateEmailErrorMessage(true);
            stopFlag = true;
        }
        if (((!submissionVM.baseData.accountHolder.emailAddress1.aspects.valid)
            || (!submissionVM.baseData.accountHolder.emailAddress1.aspects.subtreeValid))) {
            updateEmailErrorMessage(true);
            stopFlag = true;
        }
        if (!(submissionVM.baseData.accountHolder.cellNumber.aspects.subtreeValid)) {
            updatePhoneErrorMessage(true);
            stopFlag = true;
        }

        if (stopFlag) {
            return false;
        }
        if (editInfo === undefined) {
            setEditInfo(false);
        }
        setQuoteCalled(false);
        const policyStartDate = _.get(submissionVM.value, 'baseData.periodStartDate');
        const month = policyStartDate.month + 1;
        const dateString = ''.concat(month, '/', policyStartDate.day, '/', policyStartDate.year);
        const formattedPolicyStartDate = new Date(dateString);
        const datePC = _.get(submissionVM.value, 'baseData.sysDate').substring(0, 10).replaceAll('-', '/');
        const sysDate = new Date(datePC);
        if (formattedPolicyStartDate.getTime() < sysDate.getTime()) {
            const newPolicyDate = {
                year: sysDate.getFullYear(),
                month: sysDate.getMonth(),
                day: sysDate.getDate()
            };
            _.set(submissionVM, 'baseData.periodStartDate.value', newPolicyDate);
        }
        const selectedVersion = submissionVM.baseData.selectedVersion.value;
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(submissionVM.value);
        const quoteId = _.get(submissionVM, 'quoteID.value');
        const discounts = await LoadSaveService.getQuoteDiscounts(quoteId);
        if (selectedVersion !== undefined) {
            submissionVM.baseData.selectedVersion.value = selectedVersion;
        }
        _.set(submissionVM, 'baseData.discount', discounts);
        if (_.isUndefined(submissionVM.lobData.personalAuto.coverables.mvrIncidents.value)) {
            _.set(submissionVM, 'lobData.personalAuto.coverables.mvrIncidents.value', []);
        }
        updateWizardData(submissionVM);
        return submissionVM;
    }, [submissionVM, phoneNumber]);

    const onPrevious = useCallback(() => {
        const emailAddr = _.get(submissionVM, 'baseData.accountHolder.emailAddress1.value');
        if (emailAddr || phoneNumber) {
            return ModalService.showWarning({
                title: commonMessages.wantToJump,
                message: commonMessages.wantToJumpMessage,
                messageProps: {
                    ok: commonMessages.yes,
                    close: commonMessages.cancelModel
                }
            }).result.then(
                () => {
                    const clue = submissionVM.lobData.personalAuto.coverables.driverHistory.value;
                    if (_.isUndefined(clue) || clue.length === 0) {
                        jumpTo(2);
                    }
                    return submissionVM;
                }
            );
        } else {
            const clue = submissionVM.lobData.personalAuto.coverables.driverHistory.value;
            if (_.isUndefined(clue) || clue.length === 0) {
                jumpTo(2);
            }
            return submissionVM;
        }

    }, [submissionVM, phoneNumber]);

    const overrideProps = {
        paFirstCaption: {
            content: getClick()
        },
        numberPhone: {
            value: phoneNumber.length <= 10 ? CommonUtil.formatPhoneNumber(phoneNumber) : phoneNumber,
            showErrors: phoneErrorMessage,
            controlClassName: phoneErrorMessage ? styles.mockError : styles.mock
        },
        phoneError: {
            visible: phoneErrorMessage
        },
        emailError: {
            visible: emailErrorMessage
        },
        email: {
            showErrors: emailErrorMessage,
            onBlur: validateEmail

        },


        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onValidate,
            getEmail: getEmail,
            getPhoneNumber: getPhoneNumber
        },
        resolveComponentMap: {
            tooltipcomponent: MoreInfoToolTip
        }
    };
    const skipMoreDetails = async () => {
        const emailAddress = submissionVM.value.baseData.accountHolder.emailAddress1;
        const emailMockData = MockUpUtil.getMockData('baseData.accountHolder.emailAddress1');
        return emailAddress && submissionVM.value.baseData.periodStatus === 'Quoted'
            && emailAddress !== emailMockData;
    };

    return (
        <WizardPage onNext={onNext} onPrevious={onPrevious} skipWhen={skipMoreDetails} showCancel={false} previousLabel="Back" nextLabel="Next : Quote">
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

MoreInfo.propTypes = wizardProps;
export default MoreInfo;