
import { defineMessages } from 'react-intl';

export default defineMessages({
    popupTitle: {
        id: 'quoteandbind.views.payment-details.AFF and Membership Dues Information',
        defaultMessage: 'AFF and Membership Dues Information'
    },
    popupSpanTitleOne: {
        id: 'quoteandbind.views.payment-details.FarmPAC Contribution:',
        defaultMessage: 'FarmPAC Contribution:'
    },
    popupSpanTitleTwo: {
        id: 'quoteandbind.views.payment-details.AFF Membership Dues:',
        defaultMessage: 'AFF Membership Dues:'
    },
    popupSpanTextOne: {
        id: 'quoteandbind.views.payment-details.FarmPAC is the state political action committee of the AFF. The purpose of FarmPAC is to achieve good government and economic improvement for all people in the state of Alabama, and to that end, raise funds in order to make contributions to support issues and political candidates at the state and local level with an interest in promoting the welfare of the taxpayers, farmers, rural, and agricultural interests in Alabama. Your contribution to FarmPAC is voluntary. A contribution to FarmPAC is not a condition of Membership in the AFF and you have the right to contribute without reprisal. The AFF will not favor or disadvantage any member by reason of the amount of his or her contribution or his or her decision not to contribute. Contributions made to FarmPAC are not deductible as charitable contributions for income tax purposes',
        defaultMessage: 'FarmPAC is the state political action committee of the AFF. The purpose of FarmPAC is to achieve good government and economic improvement for all people in the state of Alabama, and to that end, raise funds in order to make contributions to support issues and political candidates at the state and local level with an interest in promoting the welfare of the taxpayers, farmers, rural, and agricultural interests in Alabama. Your contribution to FarmPAC is voluntary. A contribution to FarmPAC is not a condition of Membership in the AFF and you have the right to contribute without reprisal. The AFF will not favor or disadvantage any member by reason of the amount of his or her contribution or his or her decision not to contribute. Contributions made to FarmPAC are not deductible as charitable contributions for income tax purposes'
    },
    popupSpanTextTwo: {
        id: 'quoteandbind.views.payment-details.Alfa® Insurance was founded by the Alabama Farmers Federation (AFF) to provide coverage for members. AFF Membership is required for Alfas property and casualty insurance products in Alabama. Allocation of annual Membership Dues are applied as follows: County Farmers Federation, $12; AFF, $16; American Farm Bureau Federation, $5; and Voluntary FarmPAC Contribution, $3, for a total of $36. Membership includes a variety of valuable benefits; learn more at AlfaFarmers.org/perks. Membership Dues are not insurance premium. Please refer to your invoice for further explanation and order of payment.',
        defaultMessage: 'Alfa® Insurance was founded by the Alabama Farmers Federation (AFF) to provide coverage for members. AFF Membership is required for Alfas property and casualty insurance products in Alabama. Allocation of annual Membership Dues are applied as follows: County Farmers Federation, $12; AFF, $16; American Farm Bureau Federation, $5; and Voluntary FarmPAC Contribution, $3, for a total of $36. Membership includes a variety of valuable benefits; learn more at AlfaFarmers.org/perks. Membership Dues are not insurance premium. Please refer to your invoice for further explanation and order of payment.'
    }
});
