import React, { useContext, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { QuestionSetComponent } from 'gw-components-platform-react';
import { useValidation } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './QualificationPage.metadata.json5';


function QualificationPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData, history } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const {
        registerInitialComponentValidation,
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('QualificationPage');

    const redirectToKnockoutPage = useCallback(() => {
        const UWIssues = _.get(submissionVM.value, 'errorsAndWarnings.underwritingIssues', {});

        if (UWIssues.length) {
            const hasRejected = UWIssues.some(
                (uwIssue) => uwIssue.currentBlockingPoint === 'Rejected'
            );
            if (hasRejected) {
                history.push('/knockoutpage', { UWIssues });
            }
        }
    }, [submissionVM, history]);

    const onNext = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');
        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );

        redirectToKnockoutPage();

        return submissionVM;
    }, [LoadSaveService, authHeader, submissionVM, redirectToKnockoutPage]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        }
    };

    const resolvers = {
        resolveComponentMap: { questionset: QuestionSetComponent },
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    const hasLicenseNumber = useCallback(() => {
        const licenseNumber = _.get(
            submissionVM,
            'lobData.personalAuto.coverables.drivers.value[0].licenseNumber'
        );

        if (config.persona === 'producer' || config.persona === 'csr') {
            return !_.isEmpty(licenseNumber);
        }
        return true;
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(hasLicenseNumber);
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WizardPage onNext={onNext} skipWhen={initialValidation} disableNext={!isComponentValid}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

QualificationPage.propTypes = wizardProps;
export default withRouter(QualificationPage);
