import DriverComponent from './components/DriverComponent/DriverComponent';
import VehicleComponent from './components/VehicleComponent/VehicleComponent';
import PolicyChangeQuoteComponent from './components/PolicyChangeQuoteComponent/PolicyChangeQuoteComponent';
import PolicyChangeDetailsComponent from './components/PolicyChangeDetailsComponent/PolicyChangeDetailsComponent';
import PolicyDiffComponent from './components/PolicyDiffComponent/PolicyDiffComponent';

const policyJobComponentMap = {
    drivercomponent: { component: 'DriverComponent' },
    vehiclecomponent: { component: 'VehicleComponent' },
    PolicyChangeQuoteComponent: { component: 'PolicyChangeQuoteComponent' },
    PolicyDiffComponent: { component: 'PolicyDiffComponent' },
};

const policyJobComponents = {
    DriverComponent,
    VehicleComponent,
    PolicyChangeQuoteComponent,
    PolicyDiffComponent
};

export {
    DriverComponent,
    VehicleComponent,
    PolicyChangeQuoteComponent,
    PolicyChangeDetailsComponent,
    PolicyDiffComponent,
    policyJobComponentMap,
    policyJobComponents
};
