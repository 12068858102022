import { ModalService } from '@jutro/components';
import messages from './LogoutWarning.messages';

function LogoutWarningModal(resetInactivityTimer) {
    const modelInstance = ModalService.showWarning({
        title: messages.sessionTimeout,
        message: messages.warningAboutToExpire,
        messageProps: {
            ok: messages.continueSession
        },
        icon: 'warning',
        showCancelBtn: false,
        showCloseBtn: false
    });
    modelInstance.result.then(() => {
        resetInactivityTimer();
    });
    return modelInstance;
}

export default LogoutWarningModal;
