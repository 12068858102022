import React, { useContext } from 'react';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { WizardContext } from 'gw-portals-wizard-react';
import styles from './QuickQuoteNavigation.module.scss';

function renderSteps(translator, steps, currentStepIndex, stepsWithErrors, jumpTo) {
    return steps.map((step, index) => {
        const buttonClassName = classNames(styles.navigationButton, {
            [styles.active]: index === currentStepIndex
        });
        return (
            <button
                key={step.id}
                aria-label={translator(step.title)}
                className={buttonClassName}
                onClick={() => jumpTo(index)}
                disabled={!step.visited}
                type="button"
            />
        );
    });
}

function QuickQuoteNavigation() {
    const wizardContext = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const {
        steps, currentStepIndex, jumpTo, stepsWithErrors
    } = wizardContext;

    return (
        <div id="slqNavigationButtons" className={styles.stepsNav} role="navigation">
            {renderSteps(translator, steps, currentStepIndex, stepsWithErrors, jumpTo)}
        </div>
    );
}

export default QuickQuoteNavigation;
