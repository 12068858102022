import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './QuoteTableAccordionCardIterableComponent.metadata.json5';
import styles from './QuoteTableAccordionCardIterableComponent.module.scss';

function QuoteTableAccordionCardIterableComponent(props) {
    const { tableData, renderCellContent } = props;

    const coveragesData = { tableData };

    const generatePhoneTableOverrides = useCallback(() => {
        const overrides = tableData.map((tableInfo, index) => ({
            [`quoteTable${index}`]: {
                data: tableInfo.data.clauses,
                title: tableInfo.header
            },
            [`quoteTableColumn${index}`]: {
                onCell: renderCellContent,
                data: tableInfo.data
            }
        }));

        return Object.assign({}, ...overrides);
    }, [tableData, renderCellContent]);

    if (!tableData) {
        return null;
    }

    const overrideProps = {
        ...generatePhoneTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coveragesData}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

QuoteTableAccordionCardIterableComponent.propTypes = {
    tableData: PropTypes.shape([]).isRequired,
    renderCellContent: PropTypes.func.isRequired
};

export default QuoteTableAccordionCardIterableComponent;
