import React, {
    useContext, useCallback, useState, useEffect, useRef
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Tooltip, IconButton, Loader, ModalService } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { EmailQuoteService } from 'gw-capability-quoteandbind';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { messages as commonMessages } from 'gw-platform-translations';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import classNames from 'classnames';
import { AddressLookupComponent } from 'gw-capability-address-react';
import ErrorHandlingUtil from '../../../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil';
import PaymentPopup from './PaymentPopup/PaymentPopup';
import metadata from './PaymentDetailsPage.metadata.json5';
import messages from './PaymentDetailsPage.messages';
import styles from './PaymentDetailsPage.module.scss';
import moment from 'moment';

function isBillingAddPresent(submissionVM) {
    if (submissionVM.bindData.billingAddress.value) {
        return false;
    }
    return true;
}

function PaymentDetailsPage(props) {
    const { wizardData: submissionVM, updateWizardData, wizardSnapshot, jumpTo } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isLoading, updateIsLoading] = useState(false);
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { onValidate, isComponentValid, registerComponentValidation } = useValidation('PaymentDetailsPage');
    const selectedOption = useRef({});
    const [selectedPayOption, setSelectedPayOption] = useState({});
    const translator = useContext(TranslatorContext);
    const [triggerStdProcess, setTriggerStdProcess] = useState(false);
    const [addressStandardized, setAddressStandardized] = useState(false);
    const [billingAddrStdProcessRan, setBillingAddrStdProcessRan] = useState(false);
    const { primaryAddress } = submissionVM.baseData.accountHolder;
    const addressLine2 = _.isEmpty(primaryAddress.addressLine2.value) ? '' : primaryAddress.value.addressLine2;
    const addressDTO = {
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        state: primaryAddress.state.value && primaryAddress.state.value.code,
        postalCode: '',
        country: 'US',
        standardizedType: '',
        county: undefined
    };
    const billingAddress = viewModelService.create(addressDTO, 'pc', 'edge.capabilities.address.dto.AddressDTO');
    const [billingAddInd, setBillingAddInd] = useState(isBillingAddPresent(submissionVM));
    const baseState = useRef(_.get(primaryAddress, 'state.value.code'));
    const [pacIncluded, setPacIncluded] = useState(baseState.current === 'AL');
    const paymentGroupArr = [
        {
            branchName: 'OneDownPayment',
            monthDown: '1-Month Down',
            numOfPay: '+5 payments',
            savings: '$',
            type: '1-Month',
            dueToday: '',
            payment: '',
            total: ''
        },
        {
            branchName: 'TwoDownPayment',
            monthDown: '2-Months Down',
            numOfPay: '+4 payments',
            savings: '$$',
            type: '2-Month',
            dueToday: '',
            payment: '',
            total: ''
        },
        {
            branchName: 'PaidInFull',
            monthDown: 'Pay in Full',
            numOfPay: '1 payment',
            savings: '$$$',
            type: 'full-Month',
            dueToday: '',
            payment: '',
            total: ''
        }
    ];
    const [paymentGroup, setPaymentGroupArr] = useState(paymentGroupArr);
    const [paymentURL, setPaymentURL] = useState('');
    const [amountCheckBoxValue, setAmountCheckBoxValue] = useState(true);
    const [showError, setShowError] = useState(false);
    const [stdAddress, setStdAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        county: '',
        standardizedType: ''
    });
    const [invalidZip, setInvalidZip] = useState(false);
    const isFullPaymentAmtPresent = useRef(undefined);
    const [showCounty, setCounty] = useState(false);
    const isReadyToMoveNext = useRef(false);
    const currentPage = 'Payment Details Page';

    const updatePaymentValues = (payment, quote) => {
        payment.dueToday = Number(quote.premium.dueToday);
        payment.payment = quote.premium.paymentPlan.installment.amount;
        payment.total = quote.premium.paymentPlan.total.amount;
    }

    const setPaymentGroup = (isAmtSelected) => {
        _.each(submissionVM.quoteData.offeredQuotes.value, (quote) => {
            _.each(paymentGroup, (payment) => {
                if(baseState.current === 'AL') {
                    if (payment.branchName === quote.branchName) {
                        payment.dueToday = Number(quote.premium.dueToday);
                        payment.payment = quote.premium.paymentPlan.installment.amount;
                        payment.total = quote.premium.paymentPlan.total.amount + 33;
                        if (isAmtSelected && payment.total === quote.premium.paymentPlan.total.amount + 33) {
                            payment.total += 3;
                        } else if (!isAmtSelected) {
                            payment.dueToday -= 3;
                        }
                    } else if (payment.branchName === 'OneDownPayment' && quote.branchName === 'CUSTOM' && quote.hasToDisplayInPayment) {
                        payment.dueToday = Number(quote.premium.dueToday);
                        payment.payment = quote.premium.paymentPlan.installment.amount;
                        payment.total = quote.premium.paymentPlan.total.amount + 33;
                        if (isAmtSelected && payment.total === quote.premium.paymentPlan.total.amount + 33) {
                            payment.total += 3;
                        } else if (!isAmtSelected) {
                            payment.dueToday -= 3;
                        }
                    } else if (payment.branchName === 'OneDownPayment' && quote.branchName === 'Basic Program' && quote.hasToDisplayInPayment) {
                        payment.dueToday = Number(quote.premium.dueToday);
                        payment.payment = quote.premium.paymentPlan.installment.amount;
                        payment.total = quote.premium.paymentPlan.total.amount + 33;
                        if (isAmtSelected && payment.total === quote.premium.paymentPlan.total.amount + 33) {
                            payment.total += 3;
                        } else if (!isAmtSelected) {
                            payment.dueToday -= 3;
                        }
                    }
                } else {
                    if (payment.branchName === quote.branchName) {
                        updatePaymentValues(payment, quote);
                    } else if (payment.branchName === 'OneDownPayment' && quote.branchName === 'CUSTOM' && quote.hasToDisplayInPayment) {
                        updatePaymentValues(payment, quote);
                    } else if (payment.branchName === 'OneDownPayment' && quote.branchName === 'Basic Program' && quote.hasToDisplayInPayment) {
                        updatePaymentValues(payment, quote);
                    }
                }
            });
        });
        setPaymentGroupArr([...paymentGroup]);
    };

    const setSelectedOption = (payment) => {
        selectedOption.current = {
            branchName: payment.branchName,
            monthDown: payment.monthDown,
            dueToday: payment.dueToday,
            numOfPay: payment.numOfPay,
            payment: payment.payment,
            total: payment.total,
            isSelected: true
        };
    };

    const getPayment = async (payment) => {
        if (payment.branchName === 'PaidInFull') {
            const twoDownBranch = _.find(submissionVM.quoteData.offeredQuotes.value, { branchName: 'TwoDownPayment' });
            if (isFullPaymentAmtPresent.current === undefined) {
                if (payment.total === '' || (twoDownBranch.branchCode === 'CUSTOM')) {
                    updateIsLoading(true);
                    await LoadSaveService.getPaidInFullVersion(submissionVM.value, authHeader).then((response) => {
                        submissionVM.value = response;
                        isFullPaymentAmtPresent.current = response;
                        setPaymentGroup(pacIncluded);
                        const currentPayment = paymentGroup.find((group) => {
                            return group.branchName === payment.branchName;
                        });
                        setSelectedOption(currentPayment);
                        updateIsLoading(false);
                    }, (error) => {
                        ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, currentPage);
                    });
                } else {
                    setSelectedOption(payment);
                }
            } else {
                setSelectedOption(payment);
            }
        } else {
            setSelectedOption(payment);
        }
        setSelectedPayOption(selectedOption.current);
    };

    const setAmount = (isAmtConfirmed) => {
        if (isAmtConfirmed) {
            setPaymentGroup(true);
            setPacIncluded(true);
            setAmountCheckBoxValue(isAmtConfirmed);
            const currentPayment = paymentGroup.find((group) => {
                return group.branchName === selectedPayOption.branchName;
            });
            getPayment(currentPayment);
        } else {
            setPaymentGroup(false);
            setPacIncluded(false);
            setAmountCheckBoxValue(isAmtConfirmed);
            const currentPayment = paymentGroup.find((group) => {
                return group.branchName === selectedPayOption.branchName;
            });
            getPayment(currentPayment);
        }
    };

    const getTooltipText = (payment) => {
        switch (payment.monthDown) {
            case '1-Month Down':
                return 'Pay the smallest amount today and have higher monthly installments. There are no additional savings with this payment plan.';
            case '2-Months Down':
                return 'Pay more today and have lower monthly installments. This allows you to save on your total six-month premium total.';
            default:
                return 'Paying in full today provides you with the most savings on your six-month premium total.';
        }
    };

    const setClassNames = (payment) => {
        if (selectedPayOption.isSelected && selectedPayOption.monthDown === payment.monthDown) {
            return classNames(styles.divColumnStyle, styles.activeDivStyle);
        }
        return classNames(styles.divColumnStyle);
    };

    const showTip = () => {
        if (breakpoint === 'phone') {
            return 'click';
        }
        return 'mouseenter';
    };

    const handleTabKeyPress = (e) => {
        if (e.which === 9) { e.preventDefault(); }
    };

    const tdStyle = (index) => {
        if (index === 0) {
            return styles.tdTabletStyle;
        }
        return styles.tdStyle;
    };

    const checkStyle = (payment) => {
        if (selectedPayOption.branchName === payment.branchName) {
            return styles.checkMark;
        }
        return styles.checkMarkHide;
    };

    const getTableData = () => {
        return paymentGroup.map((payment, index) => {
            return (
                <tr style={{ paddingLeft: '30px' }} className={tdStyle(index)}>
                    <div className={setClassNames(payment)} role="button" tabIndex="0" onKeyUp={(e) => handleTabKeyPress(e)} onClick={() => getPayment(payment)}>
                        <div className={styles.mobileStyle}>
                            <div className={checkStyle(payment)}>
                                <i className="fa fa-check" aria-hidden="true" role="img" />
                            </div>
                            <span className={styles.spanStyle}>{payment.monthDown}</span>
                            <Tooltip
                                animation="fade"
                                content={getTooltipText(payment)}
                                placement="top"
                                delay={[
                                    0,
                                    40
                                ]}
                                flipBehavior={[
                                    'right',
                                    'bottom',
                                    'top',
                                    'left',
                                    'right'
                                ]}
                                followCursor={false}
                                hideOnClick
                                id="tooltip"
                                showOnInit={false}
                                sticky
                                trigger={showTip()}
                            >
                                <IconButton icon="fa-info-circle" aria-haspopup="true" iconClassName={styles.iconFontStyle} aria-hidden="true" aria-label="Information" />
                            </Tooltip>
                            <p className={styles.paraStyle}>{payment.numOfPay}</p>
                            <h2 className={styles.h2Style}>
                                Savings:
                                {' '}
                                <span style={{ color: 'green', fontSize: '16px' }}>{payment.savings}</span>
                            </h2>
                        </div>
                    </div>
                </tr>
            );
        });
    };

    const buildPaymentPlan = () => {
        return (
            <table style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                <tbody>
                    {getTableData()}
                </tbody>
            </table>
        );
    };

    const convertToAmount = (convertAmt) => {
        const amount = `${convertAmt}`;
        if (!_.isEmpty(amount)) {
            const decimalPoint = amount.split('.')[1];
            if (_.isEmpty(decimalPoint)) {
                return `${amount}.00`;
            }
            if (!_.isEmpty(decimalPoint) && decimalPoint.length === 1) {
                return `${amount}0`;
            }
        }
        return `${amount}`;
    };

    const getNumPayMobileText = (payment) => {
        return `${selectedPayOption.numOfPay} of ${payment}`;
    };

    const getDueTextMobile = (dueToday) => {
        return `Due Today: $${dueToday}`;
    };

    const getTotalTextMobile = (total) => {
        return `Total: $${total}`;
    };

    const getPaymentText = () => {
        const dueToday = convertToAmount(selectedPayOption.dueToday);
        const total = convertToAmount(selectedPayOption.total);
        const payment = convertToAmount(selectedPayOption.payment);

        if (breakpoint === 'phone') {
            if (selectedPayOption.monthDown !== 'Pay in Full') {
                return (
                    <div>
                        <div className={styles.lineHeightStyle}>{getDueTextMobile(dueToday)}</div>
                        <div className={styles.lineHeightStyle}>{getNumPayMobileText(payment)}</div>
                        <div className={styles.lineHeightStyle}>{getTotalTextMobile(total)}</div>
                    </div>
                );
            }
            return ` Due Today: $${dueToday}`;
        }
        if (selectedPayOption.monthDown !== 'Pay in Full') {
            return `Due Today: $${dueToday} ${selectedPayOption.numOfPay}
            of ${payment} Total: $${total}`;
        }
        return ` Due Today: $${dueToday}`;
    };

    const getPaymentDetails = () => {
        if (selectedPayOption.monthDown !== 'Pay in Full') {
            return (
                <div class={styles.textFontStyle}>
                    <div><b>{selectedPayOption.monthDown}</b></div>
                    <div className={styles.selectedOptionSpace}>{getPaymentText()}</div>
                </div>
            );
        }
        return (
            <div class={styles.textFontStyle}>
                <b>{selectedPayOption.monthDown}</b>
                <br />
                {getPaymentText()}
            </div>
        );
    };

    const callModelPopup = () => {
        return ModalService.showPopover(PaymentPopup, {
            translator: translator,
            setAmount: setAmount,
            amountCheckBoxValue: amountCheckBoxValue
        }).result.then((result) => { });
    };

    const getDivTooltipText = () => {
        return (
            <div className={styles.tooltipDivStyle} role="button" tabIndex="0" onKeyDown={(e) => handleTabKeyPress(e)} onClick={() => callModelPopup()}>
                {translator(messages.toolTipTitle)}
                <Tooltip
                    animation="fade"
                    placement="top"
                    delay={[
                        0,
                        40
                    ]}
                    flipBehavior={[
                        'right',
                        'bottom',
                        'top',
                        'left',
                        'right'
                    ]}
                    followCursor={false}
                    hideOnClick
                    id="tooltip"
                    showOnInit={false}
                    sticky
                    trigger="mouseenter"
                />
                <IconButton icon="fa-info-circle" aria-haspopup="true" iconClassName={styles.iconFontStyle} aria-hidden="true" aria-label="Information" />
            </div>
        );
    };

    const setBillingInd = useCallback((value) => {
        if (!value) {
            submissionVM.bindData.billingAddress.value = { ...billingAddress.value };
        } else {
            _.set(submissionVM, 'bindData.billingAddress', undefined);
            if (showError) setShowError(false);
        }
        setBillingAddInd(value);
    }, [billingAddress, submissionVM, showError]);


    useEffect(() => {
        updateIsLoading(true);
        async function discountRibbon() {
            const quoteId = _.get(submissionVM, 'quoteID.value');
            const date = _.get(submissionVM, "baseData.sysDate.value");
            _.set(submissionVM,"baseData.sysDate",moment(date).format("YYYY-MM-DD"));
            const discounts = await LoadSaveService.getQuoteDiscounts(quoteId);
            _.set(submissionVM, 'baseData.discount', discounts);
            updateWizardData(submissionVM);
        }
        delete submissionVM['[object Object]'];
        LoadSaveService.getTwoDownVersion(
            submissionVM.value,
            authHeader
        ).then((result) => {
            submissionVM.value = result;
            setPaymentGroup(true);
            getPayment(paymentGroup[0]);
        }, (error) => {
            ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, currentPage);
        }).finally(() => {
            updateIsLoading(false);
            discountRibbon();
        });
    }, []);

    const writeAddressValue = useCallback((value, path) => {
        if (path !== 'bindData.billingAddress.state') {
            if (addressStandardized) { // If the address was standardized, and were address changes, reset Address Standardization flag 
                if (path === 'bindData.billingAddress.addressLine1') {
                    if (value !== stdAddress.addressLine1) { setAddressStandardized(false); }
                }
                if (path === 'bindData.billingAddress.addressLine2') {
                    if (value !== stdAddress.addressLine2) { setAddressStandardized(false); }
                }
                if (path === 'bindData.billingAddress.city') {
                    if (value !== stdAddress.city) { setAddressStandardized(false); }
                }
                if (path === 'bindData.billingAddress.postalCode') {
                    if (value !== stdAddress.postalCode) { setAddressStandardized(false); }
                }
                if (path === 'bindData.billingAddress.county') {
                    if (value !== stdAddress.county) { setAddressStandardized(false); }
                }
            }
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        }
    }, [addressStandardized, stdAddress, submissionVM, updateWizardData]);

    const doTheRemainingWork = useCallback(() => {
        if (selectedPayOption.branchName === 'OneDownPayment') {
            _.each(submissionVM.quoteData.offeredQuotes.value, (quote) => {
                if (quote.hasToDisplayInPayment && (quote.branchName === 'CUSTOM' || quote.branchName === 'Basic Program')) {
                    submissionVM.bindData.chosenQuote.value = quote.publicID;
                }
            });
        } else {
            _.each(submissionVM.quoteData.offeredQuotes.value, (quote) => {
                if (quote.branchName === selectedPayOption.branchName) {
                    submissionVM.bindData.chosenQuote.value = quote.publicID;
                }
            });
        }
        // Ensure the Address Standardization value is set on the billing address
        const testBillingAddress = _.get(submissionVM.bindData.billingAddress, 'value');

        if (testBillingAddress !== undefined && testBillingAddress !== null) {
            if (addressStandardized) {
                _.set(submissionVM.bindData.billingAddress, 'standardizedType', 'Standardized');
            } else {
                _.set(submissionVM.bindData.billingAddress, 'standardizedType', 'NotStandardized');
            }
        }
        LoadSaveService.saveQuotedSubmission(submissionVM.value, authHeader)
            .then((response) => {
                LoadSaveService.getMembershipNumber(submissionVM.quoteID.value, authHeader)
                    .then((response) => {
                        if (response && response.errorCode) {
                            return ErrorHandlingUtil.processErrorResponse(props, response, submissionVM.value, currentPage);
                        }
                        EmailQuoteService.getPaymentusToken(submissionVM.quoteID.value, pacIncluded)
                            .then((result) => {
                                if (result) {
                                    setPaymentURL(result.paymentusUrl);
                                    updateIsLoading(false);
                                }
                            }).catch((error) => {
                                if (error) {
                                    return ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, currentPage);
                                }
                            });
                    }).catch((error) => {
                        if (error) {
                            return ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, currentPage);
                        }
                    });
            });
    }, [LoadSaveService, addressStandardized, authHeader, pacIncluded, selectedPayOption.branchName, submissionVM.bindData.billingAddress, submissionVM.bindData.chosenQuote.value, submissionVM.quoteData.offeredQuotes.value, submissionVM.quoteID.value, submissionVM.value, props]);

    const updateStandardizationRan = (stdProcessDidRun, updatedAddress) => { // called by AddressLookupComponent once Standardization process has run
        if (stdProcessDidRun === true) {
            setTriggerStdProcess(false); // turn off the Address Standardization trigger
            // Check results
            if ((updatedAddress !== undefined || updatedAddress !== null) && updatedAddress.standardizedType === 'Standardized') {
                setAddressStandardized(true);
                setBillingAddrStdProcessRan(true);
                setStdAddress(updatedAddress);

                _.set(submissionVM.bindData.billingAddress, 'addressLine1.value', updatedAddress.addressLine1);
                _.set(submissionVM.bindData.billingAddress, 'addressLine2.value', updatedAddress.addressLine2);
                _.set(submissionVM.bindData.billingAddress, 'city.value', updatedAddress.city);
                _.set(submissionVM.bindData.billingAddress, 'county.value', updatedAddress.county);
                _.set(submissionVM.bindData.billingAddress, 'postalCode.value', updatedAddress.postalCode);
                _.set(submissionVM.bindData.billingAddress, 'state.value', updatedAddress.stateTC);
                _.set(submissionVM.bindData.billingAddress, 'displayName.value', updatedAddress.displayName);
                _.set(submissionVM.bindData.billingAddress, 'standardizedType.value', updatedAddress.standardizedType);
                _.set(submissionVM.bindData.billingAddress, 'spatialPoint.value', updatedAddress.spatialPoint);
                _.set(submissionVM.bindData.billingAddress, 'censusBlock.value', updatedAddress.censusBlock);

                // Do the remaining work after Standardization runs
                doTheRemainingWork();
            } else if (showCounty && _.get(submissionVM, 'bindData.billingAddress.county.value')) {
                if (!isReadyToMoveNext.current) {
                    isReadyToMoveNext.current = true;
                    doTheRemainingWork();
                }
            } else {
                setCounty(true);
                setShowError(true);
                setAddressStandardized(false);
            }
        }
    };

    const showInvalidZip = (invalidZip) => {
        setInvalidZip(invalidZip);
    };

    const isToolTipDivVisible = () => {
        const baseState = _.get(submissionVM, 'baseData.accountHolder.primaryAddress.state.value');
        return baseState && baseState.code === 'AL';
    };

    const getIframeClass = () => {
        if (baseState.current === 'AL') {
            return `iframeStyle iframeStyleAL`;
        }
        return `iframeStyle iframeStyleGAMS`;
    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        paymentDetailsPageText: {
            visible: breakpoint !== 'phone'
        },
        paymentPlanTable: {
            content: buildPaymentPlan()
        },
        payentDetailsDiv: {
            content: getPaymentDetails()
        },
        tooltipDiv: {
            content: getDivTooltipText(),
            visible: isToolTipDivVisible()
        },
        addressFirst: {
            content: `${primaryAddress.value.addressLine1} ${addressLine2}`
        },
        addressSecond: {
            content: `${primaryAddress.value.city}, ${primaryAddress.value.state}, ${primaryAddress.postalCode.value}`
        },
        billingAddress: {
            visible: !billingAddInd,
            triggerStdProcess: triggerStdProcess,
            addressStandardized: addressStandardized,
            showErrors: showError,
            updateStandardizationRan: updateStandardizationRan,
            lockAddressState: true,
            showPOBoxError: false,
            showInvalidZip: showInvalidZip,
            showCountyField: showCounty
        },
        billingAddressToggle: {
            value: billingAddInd,
            onValueChange: setBillingInd
        },
        paymentPlansContainer: {
            visible: paymentURL === '' && paymentURL.length <= 0
        },
        paymentIframe: {
            src: paymentURL,
            className: getIframeClass()
        },
        paymentInformationDiv: {
            visible: paymentURL.length > 0
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onValidate,
        },
        resolveComponentMap: {
            addressLookupComponent: AddressLookupComponent
        }
    };
    const handlePrevious = useCallback(() => {
        if (submissionVM.aspects.subtreeValid && !(invalidZip && !billingAddInd)) {
        jumpTo(5);
        } else {
            
            return ModalService.showWarning({
                title: commonMessages.wantToJump,
                message: commonMessages.wantToJumpMessage,
                messageProps: {
                    ok: commonMessages.yes,
                    close: commonMessages.cancelModel
                }
            }).result.then(
                () => {
                    setBillingAddInd(true);
                    _.set(submissionVM, 'bindData.billingAddress', undefined);
                    updateWizardData(submissionVM);
                    jumpTo(5);
                    return;
                }
            );
        }
    }, [jumpTo]);

    const handleNext = useCallback(() => {
        dataLayer.push({ 'event': 'gtm.click', 'gtm.elementId': 'qb_next' });
        updateIsLoading(true);
        if (!submissionVM.aspects.subtreeValid || (invalidZip && !billingAddInd)) {
            setShowError(true);
            updateIsLoading(false);
            return false;
        }
        if (!billingAddInd && !addressStandardized && !billingAddrStdProcessRan) {
            const address = _.get(submissionVM, 'bindData.billingAddress');
            if (address.aspects.valid && address.aspects.subtreeValid) {
                // The following will trigger the address standardization in the child component
                //setAddressStandardized(false);
                setTriggerStdProcess(true);
                updateIsLoading(false);
                return false;
            }
        }
        doTheRemainingWork();
        return false;
    }, [submissionVM, billingAddInd, addressStandardized, billingAddrStdProcessRan, doTheRemainingWork, invalidZip]);

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <WizardPage
            nextLabel={messages.paymentBuyNow}
            onNext={handleNext}
            onPrevious={handlePrevious}
            showCancel={false}
            showNext={paymentURL === '' && paymentURL.length <= 0}
            showPrevious={paymentURL === '' && paymentURL.length <= 0}
            previousLabel="Back"
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default withRouter(PaymentDetailsPage);
