import { Component } from 'react';
import PropTypes from 'prop-types';

export default class SegmentationOption extends Component {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        is: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.string,
            PropTypes.array
        ]),
        // eslint-disable-next-line react/no-unused-prop-types
        defaultOption: PropTypes.bool,
        children: PropTypes.node.isRequired
    }

    static defaultProps = {
        defaultOption: false,
        is: []
    };

    render() {
        const { children } = this.props;

        return children;
    }
}
