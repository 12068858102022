import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, TableColumn } from '@jutro/layout';
import { RadioColumn } from '@jutro/datatable';
import { CurrencyField } from '@jutro/components';
import styles from './PaymentList.module.scss';
import messages from './PaymentList.messages';

export default class PaymentList extends Component {
    /**
     * @memberof gw-components-platform-react.PaymentList
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {array} propTypes.value - object containing selectedPaymentPlan and the list of plans
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {bool} propTypes.path - path in the data object to retrieve the value
     */

    static propTypes = {
        value: PropTypes.shape({
            paymentPlans: PropTypes.arrayOf(PropTypes.shape({})),
            selectedPaymentPlan: PropTypes.string
        }),
        onValueChange: PropTypes.func.isRequired,
        path: PropTypes.string,
        isTitleVisible: PropTypes.bool
    };

    static defaultProps = {
        value: {},
        path: undefined,
        isTitleVisible: true
    };

    generateDataForRadioButton = (row) => {
        return {
            name: row.name,
            code: row.billingId
        };
    };

    generateDownPayment = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`downpayment_${rowIndex}`}
                value={row.downPayment}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );
    };

    generateInstallmentPayment = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`installment_${rowIndex}`}
                value={row.installment}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );
    };

    generateTotalPayment = (row, rowIndex) => {
        return (
            <CurrencyField
                id={`totalpayment_${rowIndex}`}
                value={row.total}
                dataType="object"
                showFractions
                readOnly
                hideLabel
                className={styles.currencyStyle}
            />
        );
    };

    handleValueChange = (value) => {
        const { onValueChange, path } = this.props;
        if (onValueChange) {
            return onValueChange(value, `${path}.selectedPaymentPlan`);
        }
        return null;
    };

    render() {
        const { value, isTitleVisible } = this.props;
        const { paymentPlans, selectedPaymentPlan } = value;
        return (
            <Table
                title={isTitleVisible && messages.paymentPlans}
                data={paymentPlans}
                columnsProportion={[3, 1, 1, 1]}
                placeholder={messages.emptyPaymentPlans}
            >
                <RadioColumn
                    id="plan"
                    textAlign="left"
                    header={messages.name}
                    option={this.generateDataForRadioButton}
                    cellClassName={styles.paymentListTableCell}
                    value={selectedPaymentPlan}
                    onValueChange={this.handleValueChange}
                />
                <TableColumn
                    id="downPayment"
                    textAlign="left"
                    header={messages.downPayment}
                    cellClassName={styles.paymentListTableCell}
                    cell={this.generateDownPayment}
                />
                <TableColumn
                    id="installment"
                    textAlign="left"
                    header={messages.installment}
                    cellClassName={styles.paymentListTableCell}
                    cell={this.generateInstallmentPayment}
                />
                <TableColumn
                    id="total"
                    textAlign="left"
                    header={messages.total}
                    cellClassName={styles.paymentListTableCell}
                    cell={this.generateTotalPayment}
                />
            </Table>
        );
    }
}
