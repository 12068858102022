import React, { useContext } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

const wizardContext = React.createContext(null);

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withWizardContext(WrappedComponent) {
    function WithWizardContext(props) {
        const currentContext = useContext(wizardContext);
        return <WrappedComponent {...currentContext} {...props} />;
    }
    hoistNonReactStatic(WithWizardContext, WrappedComponent);
    WithWizardContext.displayName = `WithWizardContext(${getDisplayName(WrappedComponent)})`;
    return WithWizardContext;
}

export const WizardContext = wizardContext;
