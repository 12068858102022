import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentOptions: {
        id: 'quoteandbind.views.payment-details.Payment Options',
        defaultMessage: 'Payment Options',
    },
    averageMonthlyPremium: {
        id: 'quoteandbind.views.payment-details.Average Monthly Premium',
        defaultMessage: 'Average Monthly Premium:',
    },
    paymentDetails: {
        id: 'quoteandbind.views.payment-details.Payment Details',
        defaultMessage: 'Payment Details',
    },
    totalPremium: {
        id: 'quoteandbind.views.payment-details.Total Premium:',
        defaultMessage: 'Total Premium:',
    },
    paymentBuyNow: {
        id: 'quoteandbind.views.payment-details.Enter Payment Info',
        defaultMessage: 'Enter Payment Info'
    },
    toolTipTitle: {
        id: 'quoteandbind.views.payment-details.Alabama Farmers Federation Members Click Here for AFF and Membership Dues Information',
        defaultMessage: 'Alabama Farmers Federation Members Click Here for AFF and Membership Dues Information'
    }
});
