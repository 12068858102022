import React, {
    useContext, useCallback, useMemo, useState, useEffect
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { getConfigValue } from '@jutro/config';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import { DriverLicenseService } from 'gw-capability-driverlicense';
import { useValidation } from 'gw-portals-validation-react';
import { EntityUtil, MockUpUtil } from 'gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { Tooltip, IconButton, Button, ModalService } from '@jutro/components';
import moment from 'moment';
import cookie from 'js-cookie';
import { publish } from "@jutro/events";

// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
// actually using the Customer version (segmented changes) - see customer-config.js
import { AddressLookupComponent } from 'gw-capability-address-react';
import { AddressStandardizationService } from 'gw-capability-addressstandardization_alfa';
import { AddressStdPopover } from 'gw-capability-addressstdpopover-react_alfa';
import AddressUtil from '../../../../../applications/quote-and-buy/src/customer/Utils/AddressUtil';
import ErrorHandlingUtil from '../../../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil';

import metadata from './YourInfoPage.metadata.json5';
import styles from './YourInfo.module.scss';
import messages from './YourInfoPage.messages';

function YourInfoTooltip() {
    const translator = useContext(TranslatorContext);

    return (
        <Tooltip
            animation="fade"
            content={translator(messages.yourInfoTooltip)}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            placement="top"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton
                icon="fa-info-circle"
                aria-haspopup="true"
                className={styles.gwToolTip}
            />
        </Tooltip>
    );
}

function HomeRentersTooltip() {
    const translator = useContext(TranslatorContext);
    return (
        <Tooltip
            animation="fade"
            content={translator(messages.homeRentersTooltip)}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltipOwnBundle"
            placement="top"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton
                icon="fa-info-circle"
                aria-haspopup="true"
                className={styles.gwToolTip}
            />
        </Tooltip>
    );
}

function YourInfoPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData, steps, jumpToSuccessPage } = props
    const date = _.get(submissionVM, 'baseData.sysDate.value');
    _.set(submissionVM, 'baseData.sysDate', moment(date).format("YYYY-MM-DD"));
    const { onValidate, isComponentValid, initialValidation } = useValidation('YourInfoPage');
    const [showError, setShowError] = useState(false);
    const [showDateError, setShowDateError] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState('');

    const [nextStep, setNextStep] = useState('Next: Vehicles');
    const [validated, setValidated] = useState(false);
    const [captchaDone, setCaptchaDone] = useState(false);
    const [invalidZip, setInvalidZip] = useState(false);

    const translator = useContext(TranslatorContext);
    let month;
    let prevDay;
    let prevYear;
    let mobileDate = '';
    const currentAccountHolder = _.get(submissionVM, 'baseData.accountHolder', {});
    if (currentAccountHolder.dateOfBirth.value !== undefined) {
        month = (currentAccountHolder.dateOfBirth.month.value + 1);
        prevDay = currentAccountHolder.dateOfBirth.day.value;
        prevYear = currentAccountHolder.dateOfBirth.year.value;
        mobileDate = `${month}/${prevDay}/${prevYear}`;
    }

    const [dateOfBirth, updateDateOfBirth] = useState({
        month: month,
        day: prevDay,
        year: prevYear
    });
    const stateName = translator({ id: currentAccountHolder.primaryAddress.state.value.name, defaultMessage: currentAccountHolder.primaryAddress.state.value.code });
    const [dateOfBirthMobile, updateDateOfBirthMobile] = useState(mobileDate);
    const enableRecaptcha = getConfigValue('ENABLE_RECAPTCHA');
    const captchaEnabled = (enableRecaptcha === 'true' || enableRecaptcha === true) ? true : false;

    useEffect(() => {
        let agentId = cookie.get('agentId');
        let agentName = cookie.get('agentName');

        const fromAgentBio = !_.isEmpty(agentId) && !_.isUndefined(agentId) && !_.isEmpty(agentName) && !_.isUndefined(agentName);

        cookie.set('quoteID', submissionVM.quoteID.value);        

        if (!_.isEmpty(agentId) && _.isEmpty(submissionVM.baseData.agentId.value)) {
            submissionVM.baseData.agentId = agentId;
        }

        //EH-23370: sending Quote ID in Your Info Page
        const eventLabel = "Get new quote";
        const submissionNum = submissionVM.quoteID.value;

        publish("customizeButtonClicked", {
            eventLabel,
            submissionNum,
        });


        if (!_.isEmpty(submissionVM.baseData.agentInfo.value) && !submissionVM.baseData.agentInfo.value.hasOwnProperty("errorCode")){
            let newAgentId = submissionVM.baseData.agentInfo.value.agentInfo[0].producerCode.replace(/^0+/,"");
            let newAgentName = `${submissionVM.baseData.agentInfo.value.agentInfo[0].firstName}${submissionVM.baseData.agentInfo.value.agentInfo[0].lastName}`;
            
            /* Is Quote assigned to different Agent?
             * In rare cases Customer may launch Engage from different Agent bio page
             * and retrieve an existing quote already assigned to different agent.
             */ 
            if (newAgentId !== agentId || newAgentName !== agentName){
                //Update the Agent information to pass to DataLayer
                agentId = newAgentId;
                agentName = newAgentName;
            }
        }

        /* EH-23357: GA4 Migration - Pass Initial Agent Information to the Data Layer
         * Pass the AgentInfo on YourInfo Page also on retrieval if Agent was already assigned to the quote
         */
        if (!_.isEmpty(agentId) && !_.isEmpty(agentName)){
            window.dataLayer.push({
                event: 'AgentInformation',
                QuoteId: submissionVM.quoteID.value,
                AgentId: agentId,
                AgentName: agentName,
            });
        }

        // EH-24077 GA4 Migration - Engage - Agent Bio Page Custom Dimension
        window.dataLayer.push({
          event: "fromAgentBio",
          value: fromAgentBio
        });

    }, []);

    const driverIndex = useMemo(() => {
        const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value', {});
        const driversPath = 'lobData.personalAuto.coverables.drivers.value';
        const driverList = _.get(submissionVM, driversPath, []);

        return driverList.findIndex(({ person }) => person.publicID === accountHolder.publicID);
    }, [submissionVM]);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const writeDateValue = useCallback(
        (value) => {
            _.set(dateOfBirth, 'month', value.month);
            _.set(dateOfBirth, 'day', value.day);
            _.set(dateOfBirth, 'year', value.year);
            updateDateOfBirth(dateOfBirth);
        },
        [dateOfBirth]

    );
    const writeMobileDateValue = useCallback(
        (value) => {
            updateDateOfBirthMobile(value);
        }, []
    );

    // In the case of Quote Retrieval - see if there already is an address and if it has been standardized
    const acctPrimaryAddress = _.get(submissionVM, 'baseData.accountHolder.primaryAddress.value');
    let acctPrimaryAddressStandardized = false;
    if (acctPrimaryAddress !== undefined) {
        if (acctPrimaryAddress.standardizedType !== undefined) {
            if (acctPrimaryAddress.standardizedType === 'Standardized') {
                acctPrimaryAddressStandardized = true;
            }
        }
    }

    // blank address object
    let stateTC;
    const blankAddressObj = {
        addressLine1: '',
        city: '',
        state: '',
        postalCode: '',
        county: '',
        censusBlock: '',
        standardizedType: '',
        spatialPoint: {
            latitude: '',
            longitude: ''
        },
        stateTC
    };
    const initialStdAddress = acctPrimaryAddressStandardized ? acctPrimaryAddress : blankAddressObj;
    const [stdAddress, setStdAddress] = useState(initialStdAddress);
    const [addressStandardized, setAddressStandardized] = useState(acctPrimaryAddressStandardized);
    const [triggerStdProcess, setTriggerStdProcess] = useState(false); // Next button will trigger this, AddressLookupComponent will reset trigger
    const [showPOBoxError, setShowPOBoxError] = useState(false); // Shown when a POBox address is specified

    const writeAddressValue = useCallback((value, path) => {
        if (path !== 'baseData.accountHolder.primaryAddress.state') {

            if (addressStandardized) {
                // If the address was standardized, and now the user is changing the address, reset Address Standardization flag
                // also clear out the County field (it will be reset by Address Standardization)
                if (path === 'baseData.accountHolder.primaryAddress.addressLine1') {
                    if (value !== stdAddress.addressLine1) {
                        setAddressStandardized(false);
                        // Address Line 1 was changed from the standardized value - clear the county field
                        _.set(submissionVM, 'baseData.accountHolder.primaryAddress.county', undefined);
                    }
                }
                if (path === 'baseData.accountHolder.primaryAddress.addressLine2') {
                    if (value !== stdAddress.addressLine2) { setAddressStandardized(false); }
                }
                if (path === 'baseData.accountHolder.primaryAddress.city') {
                    if (value !== stdAddress.city) {
                        setAddressStandardized(false);
                        // Address City was changed from the standardized value - clear the county field
                        _.set(submissionVM, 'baseData.accountHolder.primaryAddress.county', undefined);
                    }
                }
                if (path === 'baseData.accountHolder.primaryAddress.postalCode') {
                    if (value !== stdAddress.postalCode) {
                        setAddressStandardized(false);
                        // Address Postal Code was changed from the standardized value - clear the county field
                        _.set(submissionVM, 'baseData.accountHolder.primaryAddress.county', undefined);
                    }
                }
                if (path === 'baseData.accountHolder.primaryAddress.county') {
                    if (value !== stdAddress.county) { setAddressStandardized(false); }
                }
            }
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        }
    }, [addressStandardized, stdAddress.addressLine1, stdAddress.addressLine2, stdAddress.city, stdAddress.county, stdAddress.postalCode, submissionVM, updateWizardData]);

    const [addrStandardizationRan, setAddrStandardizationRan] = useState(false); // Did address standardization run? (initially false)
    const updateStandardizationRan = (stdProcessDidRun, updatedAddress) => { // called by AddressLookupComponent once Standardization process has run
        console.log('Yourinfo: Address Standardization call returned');
        if (stdProcessDidRun === true) {
            setTriggerStdProcess(false); // turn off the Address Standardization trigger
            // Check results
            if (updatedAddress !== undefined || updatedAddress !== null) {
                if (updatedAddress.standardizedType === 'Standardized') {
                    _.set(submissionVM, 'baseData.accountHolder.primaryAddress.spatialPoint', updatedAddress.spatialPoint);
                    _.set(submissionVM, 'baseData.accountHolder.primaryAddress.censusBlock', updatedAddress.censusBlock);
                    updateWizardData(submissionVM);
                    setAddressStandardized(true);
                    setStdAddress(updatedAddress);
                }
            }
            setAddrStandardizationRan(true);
        }
        // **Note: The ONLY thing that should trigger Address Standardization is the 'Next' button validation
    };

    const dateChecker = useCallback(() => {
        const isMobile = (breakpoint === 'phone');
        if (dateOfBirthMobile !== undefined && dateOfBirthMobile !== '' && isMobile) {
            const dob = dateOfBirthMobile.split('/');
            _.set(dateOfBirth, 'month', dob[0]);
            _.set(dateOfBirth, 'day', dob[1]);
            _.set(dateOfBirth, 'year', dob[2]);
        }
        if (isMobile) {
            if (dateOfBirthMobile === undefined || dateOfBirthMobile === '') {
                setDateErrorMessage('Please enter a date of birth');
                return true;
            }
        }
        if ((dateOfBirth.day === undefined || dateOfBirth.day === '')
            || (dateOfBirth.month === undefined || dateOfBirth.month === '')
            || (dateOfBirth.year === undefined && dateOfBirth.year === '')) {
            setDateErrorMessage('Please enter a date of birth');
            return true;
        }
        const newMonth = (dateOfBirth.month.toString().length === 1) ? '0'.concat(dateOfBirth.month) : dateOfBirth.month;
        const day = (dateOfBirth.day.toString().length === 1) ? '0'.concat(dateOfBirth.day) : dateOfBirth.day;
        const { year } = dateOfBirth;

        const dateToValidate = ''.concat(newMonth, '/', day, '/', year);
        const currentDate = _.get(submissionVM, 'baseData.sysDate.value');
        if ((year < 1900) || (moment(dateToValidate).isAfter(currentDate))) {
            setDateErrorMessage('Please enter a valid date of birth');
            return true;
        }

        const isDateValid = moment(dateToValidate, 'MM/DD/YYYY', true).isValid();

        if (!isDateValid) {
            setDateErrorMessage('Please enter a valid date of birth');
            return true;
        }
        if (isDateValid) {
            const ageToValidate = moment().diff(moment(dateToValidate, 'MM/DD/YYYY'), 'years');
            if (ageToValidate <= 15) {
                setDateErrorMessage('Primary Named Insured\'s Date of Birth is ineligible to continue online.');
                return true;
            }
        }
        setDateErrorMessage('');

        const adjustMonth = dateOfBirth.month - 1;
        _.set(submissionVM, 'baseData.accountHolder.dateOfBirth.value.day', day);
        _.set(submissionVM, 'baseData.accountHolder.dateOfBirth.value.month', adjustMonth);
        _.set(submissionVM, 'baseData.accountHolder.dateOfBirth.value.year', year);
        return false;
    }, [breakpoint, dateOfBirthMobile, dateOfBirth, submissionVM]);

    const captchaSuccess = (captchaIsGood) => {
        // console.log('Setting captchaValid: ', captchaIsGood);
        if (!captchaIsGood) {
            setValidated(false);
        }
        setCaptchaDone(captchaIsGood);
        return captchaIsGood;
    };

    const validate = useCallback(() => {
        setShowError(true);
        const isDateError = dateChecker(false);
        setShowDateError(isDateError);
        const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
        if (address.aspects.valid && address.aspects.subtreeValid && !addressStandardized) {
            // The following will trigger the address standardization in the child component
            console.log('YourInfo: Trigger Address Standardization');
            setTriggerStdProcess(true);
        }
        const captchaOK = captchaEnabled ? captchaDone : true;
        const accountHolder = _.get(submissionVM, 'baseData.accountHolder');
        const valid = accountHolder.aspects.valid && accountHolder.aspects.subtreeValid
            && !isDateError && captchaOK;
        setValidated(valid);

        return submissionVM;
    }, [dateChecker, submissionVM, addressStandardized, captchaEnabled, captchaDone]);

    const showInvalidZip = (invalidZip) => {
        setInvalidZip(invalidZip);
    };

    const validateMobile = useCallback(() => {
        setShowError(true);
        const isDateError = dateChecker(true);
        setShowDateError(isDateError);
        const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
        if (address.aspects.valid && address.aspects.subtreeValid && !addressStandardized) {
            // The following will trigger the address standardization in the child component
            console.log('YourInfo: Trigger Address Standardization');
            setTriggerStdProcess(true);
        }
        const captchaOK = captchaEnabled ? captchaDone : true;
        const accountHolder = _.get(submissionVM, 'baseData.accountHolder');
        const valid = accountHolder.aspects.valid && accountHolder.aspects.subtreeValid
            && !isDateError && captchaOK;
        setValidated(valid);

        return submissionVM;
    }, [dateChecker, submissionVM, addressStandardized, captchaEnabled, captchaDone]);

    const checkForPOBoxAddress = useCallback(() => {
        const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
        const poBoxRegex = new RegExp('(.*)?(P(OST)?\\.?\\s*O(FF)?(ICE)?\\.?\\s*BOX)(.*)', 'i'); // Same Regex as PolicyCenter-> AddressLookupHandler.gs
        if (address.addressLine1 !== undefined && address.addressLine1 !== null) {
            if (address.addressLine1.value !== undefined) {
                if (address.addressLine1.value.match(poBoxRegex)) {
                    return true;
                }
            }
        }
        if (address.addressLine2 !== undefined && address.addressLine2 !== null) {
            if (address.addressLine2.value !== undefined) {
                if (address.addressLine2.value.match(poBoxRegex)) {
                    return true;
                }
            }
        }
        return false;
    }, [submissionVM]);

    const debuggingAddrStd = true;
    const [showCounty, setShowCounty] = useState(false);
    const standardizeAddress = useCallback(async () => {
        let address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
        const AddressRequestDTO = {
            addressLine1: address.addressLine1.value,
            addressLine2: address.addressLine2.value,
            postalCode: address.postalCode.value,
            state: address.state.value.code,
            city: address.city.value,
            addressType: 'home'
        };

        if (debuggingAddrStd) {
            console.log('Attempting Address Standardization:');
            console.log('Address:');
            console.log(address);
            console.log('AddressRequestDTO:');
            console.log(AddressRequestDTO);
        }

        let suggestedAddress = [];
        let addressDidStandardize = false; // temp variable - to avoid state changes till the end
        let addressCannotStandardize;
        // Call Alfa's address standardization service
        const AddressResponses = await AddressStandardizationService.requestStandardizedAddress(AddressRequestDTO, debuggingAddrStd);
        if (debuggingAddrStd) {
            console.log('Response From Service:');
            console.log(AddressResponses);
        }
        // Redircting to custom contact us page when address is not standardised when service is up
        if (AddressResponses && AddressResponses.errorCode !== '003' && AddressResponses.addressStandardizeResponseStatus.mailability_Score === '0' && address.county.county) {
            window.location = '/quote-and-buy/contact-us';
        }
        // Check for PO Box Address Error
        if ((AddressResponses.errorCode !== undefined) && (AddressResponses.errorCode === '002')) {
            // PO Box Address was entered
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.standardizedType', 'NotStandardized');
            addressDidStandardize = false;
            setShowError(true);
            setShowPOBoxError(true);
            if (debuggingAddrStd) { console.log('attempted Standardization with PO Box address'); }
            return AddressResponses.errorCode;
        }
        if (AddressResponses.addressStandardizeResponseStatus !== undefined) {
            if (AddressResponses.addressStandardizeResponseStatus.mailability_Score !== undefined) {
                if (AddressResponses.addressStandardizeResponseStatus.mailability_Score === '0') {
                    addressCannotStandardize = true;
                } else {
                    addressCannotStandardize = false;
                }
            } else {
                addressCannotStandardize = true;
            }
        } else {
            addressCannotStandardize = true;
        }
        if (addressCannotStandardize) {
            // Address Standardization could not standardize the address
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.standardizedType', 'NotStandardized');
            addressDidStandardize = false;
            // Show the County field if address cannot be standardized
            setShowCounty(true);
            setShowError(true);
            if (debuggingAddrStd) { console.log('Address could not be standardized'); }
            return AddressResponses.errorCode;
        }
        // Address Standardization Call was successful and returned a standardized address
        let showModal = false;
        if ((AddressResponses.errorCode !== undefined) && (AddressResponses.errorCode === '003')) {
            suggestedAddress = [];
            showModal = true;
        } else if (AddressResponses.errorCode === undefined) {
            // capture the County and Census Block values from the suggested address returned by the Address Standardization Service
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.county', AddressResponses.standardizedAddress.county);
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.censusBlock', AddressResponses.standardizedAddress.censusBlock);
            const spatialPointObj = {
                latitude: AddressResponses.standardizedAddress.latitude,
                longitude: AddressResponses.standardizedAddress.longitude
            };
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.spatialPoint', spatialPointObj);
            // grab the suggested address
            suggestedAddress = [
                {
                    addressLine1: AddressResponses.standardizedAddress.addressLine1,
                    addressLine2: AddressResponses.standardizedAddress.addressLine2,
                    city: AddressResponses.standardizedAddress.city,
                    state: AddressResponses.standardizedAddress.state,
                    postalCode: AddressResponses.standardizedAddress.postalCode
                }
            ];
            showModal = true;
        }
        if (AddressUtil.addressesMatch(AddressRequestDTO, suggestedAddress[0])) {
            // Existing fields match suggested (standardized) fields - mark as standardized
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.county', AddressResponses.standardizedAddress.county);
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.censusBlock', AddressResponses.standardizedAddress.censusBlock);
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.standardizedType', 'Standardized');
            addressDidStandardize = true;
            showModal = false;
        }
        if (showModal) {
            const addressesToSend = [];
            addressesToSend.push(AddressRequestDTO);
            if (suggestedAddress.length > 0) {
                addressesToSend.push(suggestedAddress[0]);
            }
            const componentProps = { addressSuggestions: addressesToSend };
            const stdModalProps = { backdropDismiss: false };
            const popOverResult = await ModalService.showPopover(AddressStdPopover, componentProps, stdModalProps).result.then((result) => {
                address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
                const addressUpdated = (AddressUtil.addressesMatch(result.selectedAddress, address) === false);
                if (addressUpdated) {
                    _.set(submissionVM, 'baseData.accountHolder.primaryAddress.addressLine1', result.selectedAddress.addressLine1);
                    if ((result.selectedAddress.addressLine2 === undefined) || (result.selectedAddress.addressLine2 === '')) {
                        _.set(submissionVM, 'baseData.accountHolder.primaryAddress.addressLine2', '');
                    } else {
                        _.set(submissionVM, 'baseData.accountHolder.primaryAddress.addressLine2', result.selectedAddress.addressLine2);
                    }
                    _.set(submissionVM, 'baseData.accountHolder.primaryAddress.city', result.selectedAddress.city);
                    _.set(submissionVM, 'baseData.accountHolder.primaryAddress.postalCode', result.selectedAddress.postalCode.substring(0, 5));

                    // mark as standardized
                    _.set(submissionVM, 'baseData.accountHolder.primaryAddress.standardizedType', 'Standardized');
                    addressDidStandardize = true;
                } else {
                    // user selected their address 'as entered' from the popover
                    _.set(submissionVM, 'baseData.accountHolder.primaryAddress.standardizedType', 'NotStandardized');
                    addressDidStandardize = false;
                    return false;
                }
                return result;
            }).catch((err) => { console.error(err); });
        }
        return '000';
        // process results & update state
    }, [debuggingAddrStd, submissionVM]);

    const onNext = useCallback(async () => {
        dataLayer.push({ 'event': 'gtm.click', 'gtm.elementId': 'qb_next' });
        setShowPOBoxError(false);

        // Check for PO Box error (this is also done first in Angular version)
        const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');

        // Check for required fields
        const isDateError = dateChecker();
        const accountHolderVM = _.get(submissionVM, 'baseData.accountHolder');
        const valid = accountHolderVM.aspects.valid && accountHolderVM.aspects.subtreeValid && !invalidZip
            && !isDateError;

        if (!valid) {
            setShowError(true);
            setShowDateError(isDateError);
            return false;
        }
        setShowDateError(false);
        // Standardize Address
        const didAddrStandardize = await standardizeAddress();

        if (didAddrStandardize === '003') {
            setShowCounty(true);
        }

        // const isPOBoxAddress = checkForPOBoxAddress();
        if (didAddrStandardize === '002') {
            return false;
        }

        // If the address could not be standardized, the county field will not be set, so stop and ask user to provide it
        const county = _.get(submissionVM, 'baseData.accountHolder.primaryAddress.county.value');
        if (county === undefined || county === null) { return false; }
        if (county.length === 0) { return false; }

        // Ensure the censusBlock is set, if it is not (such as when the Address Standardization service is down) use the default value)
        const defaultCensusBlock = '999999999999999'; //from PolicyCenter - AddressStandardizeUtil.gs, CENSUS_BLOCK_DEFAULT_VALUE
        const censusBlock = _.get(submissionVM, 'baseData.accountHolder.primaryAddress.censusBlock.value');
        if (censusBlock === undefined || censusBlock === null) {
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.censusBlock', defaultCensusBlock);
        } else if (censusBlock.length === 0) {
            _.set(submissionVM, 'baseData.accountHolder.primaryAddress.censusBlock', defaultCensusBlock);
        }

        // Update the displayname of the AH Primary Address
        const AHPrimAddr = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
        let addrDisplayName = '';
        addrDisplayName = addrDisplayName.concat(AHPrimAddr.addressLine1.value, ', ');
        if (AHPrimAddr.addressLine2 !== null && AHPrimAddr.addressLine2 !== undefined) {
            if (AHPrimAddr.addressLine2.value !== null && AHPrimAddr.addressLine2.value !== undefined) {
                if (AHPrimAddr.addressLine2.value.length > 0) {
                    addrDisplayName = addrDisplayName.concat(AHPrimAddr.addressLine2.value, ', ');
                }
            }
        }
        addrDisplayName = addrDisplayName.concat(AHPrimAddr.city.value, ', ', AHPrimAddr.state.value.code, ' ', AHPrimAddr.postalCode.value);
        _.set(submissionVM, 'baseData.accountHolder.primaryAddress.displayName', addrDisplayName);

        // set the Policy Address to be the Account Holder Primary Address
        const acctHolderPrimaryAddr = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
        _.set(submissionVM, 'baseData.policyAddress', acctHolderPrimaryAddr);

        _.unset(submissionVM.value, 'bindData');
        // If accountHolder driver exists and accountHolder is changed,
        // the update draft call will fail if the driver is not changed as well
        if (driverIndex !== -1) {
            const pathToDriver = `lobData.personalAuto.coverables.drivers.children[${driverIndex}]`;
            _.set(submissionVM, 'baseData.accountHolder.value.tempId', EntityUtil.nextId());
            const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value', {});
            _.set(submissionVM, `${pathToDriver}.dateOfBirth`, accountHolder.dateOfBirth);
            _.set(submissionVM, `${pathToDriver}.isPolicyHolder`, true);
            _.set(submissionVM, `${pathToDriver}.person`, accountHolder);
        }
        // This  is a temperory fix for the NullPointer Exception  we get for updateCoverables api
        // call.Need a fix from the Backend team
        const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
        const vehicleArr = [];
        if (!_.isUndefined(vehicles)) {
            vehicles.map((vehicleObj, index) => {
                if (_.isUndefined(vehicleObj.costNew)) {
                    const updatevehicle = Object.assign(vehicleObj, { costNew: {} });
                    console.log(updatevehicle);
                }
                vehicleArr.push(vehicleObj);
                return vehicleArr;
            });
            _.set(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', vehicleArr);
            console.log(submissionVM.value);
            console.log(vehicleArr);
        }
        submissionVM.value = await LoadSaveService.updateDraftSubmissionAndPrimaryLocation(
            submissionVM.value
        );
        const quoteId = _.get(submissionVM, 'quoteID.value');
        const discounts = await LoadSaveService.getQuoteDiscounts(quoteId);
        _.set(submissionVM, 'baseData.discount', discounts);
        _.set(submissionVM, "baseData.sysDate", date);
        return submissionVM;
    }, [submissionVM, checkForPOBoxAddress, dateChecker, captchaEnabled, captchaDone, invalidZip, showPOBoxError, standardizeAddress, driverIndex]);

    const onFileUpload = useCallback(
        async (file) => {
            const accountHolderPath = 'baseData.accountHolder';
            const driverLicenseData = await DriverLicenseService.uploadBarcode(file);
            const accountHolderVM = _.get(submissionVM, accountHolderPath);
            Object.entries(driverLicenseData).forEach(([key, value]) => {
                if (_.has(accountHolderVM, key)) {
                    _.set(accountHolderVM, `${key}.value`, value);
                }
            });
            writeValue(accountHolderVM.value, `${accountHolderPath}.value`);
        },
        [submissionVM, writeValue]
    );

    const moveArrayItem = (fromIndex, toIndex, arrayVal) => {
        const element = arrayVal[fromIndex];
        arrayVal.splice(fromIndex, 1);
        arrayVal.splice(toIndex, 0, element);
    }

    const getSuffixList = () => {
        const suffix = _.get(submissionVM, 'baseData.accountHolder.suffix.aspects.availableValues');
        let suffixList = [...suffix];
        moveArrayItem(5, 0, suffixList);
        moveArrayItem(5, 0, suffixList);
        suffixList = suffixList.map((key) => {
            return {
                code: key.code,
                name: translator({ id: key.name, defaultMessage: key.code })
            };
        });
        return suffixList;
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
        },
        scanDriverLicenseBarcodeContainer: {
            visible: breakpoint === 'phone' || breakpoint === 'tablet'
        },
        poBoxMessageDiv: {
            visible: showError && checkForPOBoxAddress()
        },
        nameComponent: {
            // className: breakpoint === 'phone' ? 'displayBlock' : ''
        },
        scanDriverLicenseBarcode: {
            onValueChange: undefined,
            maxFileSize: config.maxFileUploadSize
        },
        firstNameComponent: {
            // className: breakpoint === 'phone' ? 'digitalFullWidth' : ''
        },
        rentBundleSave: {
            visible: submissionVM.lobData.personalAuto.ownOrRent.value === 'rent'
        },
        ownBundleSave: {
            visible: submissionVM.lobData.personalAuto.ownOrRent.value === 'Own'
        },
        tooltipDiv: {
            content: HomeRentersTooltip()
        },
        primaryAddress: {
            // Use custom onValueChange handler to avoid state comparison issue
            // in AddressLookup component due to the use of _.set() in Jutro onFieldValueChange,
            onValueChange: writeAddressValue,
            showErrors: showError,
            triggerStdProcess: triggerStdProcess,
            updateStandardizationRan: updateStandardizationRan,
            lockAddressState: true,
            showCountyField: showCounty,
            showInvalidZip: showInvalidZip,
            currentStateName: stateName,
            restriction: true
        },
        suffix: {
            availableValues: getSuffixList(),
            hideLabel: true,
            labelPosition: 'top',
            className: 'smallBlock'
        },
        mobileDateComponent: {
            onBlur: dateChecker
        },
        dateOfBirth: {
            labelPosition: 'top',
            onValueChange: writeDateValue,
            dateCheck: dateChecker,
            showErrors: showDateError,
            errorMessage: dateErrorMessage,
            prevDate: dateOfBirth
        },
        dateOfBirthMobile: {
            onValueChange: writeMobileDateValue,
            showErrors: showDateError,
            errorMessage: dateErrorMessage,
            requiredFieldValidationMessage: [dateErrorMessage]
        },
        dateError: {
            content: dateErrorMessage,
            visible: dateErrorMessage && dateOfBirthMobile !== ''
        },
        firstName: {
            showErrors: showError
        },
        lastName: {
            showErrors: showError
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFileUpload: onFileUpload,
            onValidate: onValidate,
            writeDateValue: writeDateValue,
            writeMobileDateValue: writeMobileDateValue,
            writeAddressValue: writeAddressValue,
            checkForPOBoxAddress: checkForPOBoxAddress
        },
        resolveComponentMap: {
            tooltipcomponent: YourInfoTooltip,
            addressLookupComponent: AddressLookupComponent
        }
    };

    const skipStep = useCallback(() => {
        const indexOfSuccessPage = _.findIndex(
            steps,
            ({ path }) => path === '/success'
        );
        jumpToSuccessPage(indexOfSuccessPage);
    }, [jumpToSuccessPage, steps]);

    const yourInfoSkip = () => {
        const vehicle = submissionVM.value.lobData.personalAuto.coverables.vehicles[0];
        const vinMockData = MockUpUtil.getMockData('lobData.personalAuto.coverables.vehicles.vehicle.vin');

        return vehicle && (vehicle.vin !== vinMockData || vehicle.isSelectedVehicle);
    };

    const skipMyStep = () => {
        if (submissionVM.value.confirmationNumber) {
            return skipStep;
        }
        return yourInfoSkip;
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            skipWhen={skipMyStep()}
            nextLabel={nextStep}
            cancelLabel="Back"
        >

            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

YourInfoPage.propTypes = wizardProps;
export default YourInfoPage;