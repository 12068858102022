import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Chevron } from '@jutro/components';
import styles from './DriverHeaderComponent.module.scss';

class DriverHeaderComponent extends Component {
    /**
     * @memberof gw-components-platform-react.DriverHeaderComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.DriverName - name of Driver
     * @prop {string} propTypes.path - path of Driver
     * @prop {func} propTypes.onEditDriver - callback to edit Driver
     * @prop {bool} propTypes.isAccordionOpen - is accordion open
     * @prop {bool} propTypes.isEditable - is it editable
     * @prop {func} propTypes.onRemoveDriver - remove Driver callback
     */

    static propTypes = {
        driverName: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        isAccordionOpen: PropTypes.bool,
        isEditable: PropTypes.bool,
        driverIndex: PropTypes.number,
        isPrefill: PropTypes.bool,
        isSelected: PropTypes.bool,
        isPolicyHolder: PropTypes.bool,
        onRemoveDriver: PropTypes.func,
        onEditDriver: PropTypes.func,
        closeOtherTiles: PropTypes.func
    };

    static defaultProps = {
        isAccordionOpen: false,
        isEditable: true,
        driverIndex: undefined,
        isPrefill: false,
        isSelected: false,
        isPolicyHolder: false,
        onRemoveDriver: undefined,
        onEditDriver: undefined,
        closeOtherTiles: undefined
    };

    state = {
        disableClose: false
    };

    removeDriver = () => {
        const { onRemoveDriver, path } = this.props;
        onRemoveDriver(path);
    };

    editDriver = () => {
        const { onEditDriver, path, driverIndex, isAccordionOpen } = this.props;
        const valueState = onEditDriver(path, driverIndex, isAccordionOpen);
        this.setState({ disableClose: valueState });
    };

    closeAllTiles = () => {
        const { closeOtherTiles, driverIndex, path } = this.props;
        closeOtherTiles(path, driverIndex);
    };

    render() {
        const {
            path,
            driverName,
            isAccordionOpen,
            isEditable,
            driverIndex,
            isPrefill,
            isSelected,
            isPolicyHolder
        } = this.props;
        const { disableClose } = this.state;

        const accordionStyles = () => {
          let accordStyle = "";
          if (isSelected) {
            accordStyle = isAccordionOpen
              ? classNames(
                  styles.selectedDriverHeader,
                  styles.selectedDriverHeaderOpen
                )
              : classNames(
                  styles.selectedDriverHeader,
                  styles.selectedDriverHeaderClosed
                );
          } else {
            accordStyle = isAccordionOpen
              ? classNames(styles.newDriverHeader, styles.newDriverHeaderOpen)
              : classNames(
                  styles.newDriverHeader,
                  styles.newDriverHeaderClosed
                );
          }
          return accordStyle;
        };
        
        return (
            <div className={styles.driverHeaderContainer} id={driverIndex} onClick={this.closeAllTiles}>
                <div className={styles.driverDetails}>
                    <div>
                        {/* <Chevron isOpen={isAccordionOpen} className={styles.chevron} /> */}
                        {(isSelected || (!isSelected && !isPrefill)) ? (
                            <div className={accordionStyles()}>
                                <div className={styles.selectedDriverHeaderTitle}/*  onClick={this.editDriver} */>
                                    {isSelected ? (
                                        <div className={styles.checkMark}>
                                            <i className="fa fa-check" aria-hidden="true" role="img" aria-label="Driver Added" title="Driver Added" />
                                        </div>
                                    )
                                        : <i className="fa fa-plus" aria-hidden="true" role="img" aria-label="Add Driver" title="Add Driver" />
                                    }
                                    <div className={driverName === "Add Driver" ? styles.driverHeadingColumnNewDriver: styles.driverHeadingColumn}>
                                        <span>
                                            {driverName}
                                        </span>
                                    </div>
                                </div>
                                {isSelected ? (
                                    <div className={styles.editRemoveLink}>
                                        <a onClick={this.editDriver}>
                                            Edit
                                        </a>
                                        {!isPolicyHolder ? (
                                            <a onClick={this.removeDriver}>
                                               | Remove
                                            </a>
                                        ) : null
                                        }
                                    </div>
                                ) : null
                                }
                            </div>
                            )
                            : (
                                <div className={isAccordionOpen? styles.driverHeader + ' ' + styles.driverHeaderOpen: styles.driverHeader + ' ' + styles.driverHeaderClosed}>
                                    <div className={styles.driverHeaderTitle}>
                                        <i className="fa fa-plus" aria-hidden="true" role="img" aria-label="Add Driver" title="Add Driver" />
                                        <div className={styles.driverHeadingColumn}>
                                            <span>
                                                {driverName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                    </div>
                </div>
            </div>
        );
    }
}

export default DriverHeaderComponent;
