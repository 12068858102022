import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { WizardContext } from '../../WizardContext';
import styles from './PAMobileWizardSteps.module.scss';

const buildSteps = (steps, currentStepIndex) => {
    if (['/policy-information', '/payment-details', '/success'].includes(steps[currentStepIndex].path)) {
        return steps.map((step) => {
            if (step.path === '/policy-information' || step.path === '/payment-details' || step.path === '/success') {
                return step;
            }
            return undefined;
        });
    }
    if (steps[currentStepIndex].path === '/driverhistory') {
        return steps.map((step, index) => {
            if (index === currentStepIndex || (step.path !== '/drivers' && step.path !== '/moreinfo' && step.path !== '/addl-information' && step.path !== '/policy-information' && step.path !== '/payment-details'
                && step.path !== '/success')) {
                return step;
            }
            return undefined;
        });
    }
    if (steps[currentStepIndex].path === '/moreinfo') {
        return steps.map((step, index) => {
            if (index === currentStepIndex || (step.path !== '/quote' && step.path !== '/driverhistory' && step.path !== '/policy-information' && step.path !== '/payment-details'
                && step.path !== '/success')) {
                return step;
            }
            return undefined;
        });
    }
    return steps.map((step) => {
        if (step.path !== '/moreinfo' && step.path !== '/driverhistory' && step.path !== '/policy-information' && step.path !== '/payment-details' && step.path !== '/success') {
            return step;
        }
        return undefined;
    });
};

function renderSteps(steps, currentStepIndex) {
    const updatedSteps = buildSteps(steps, currentStepIndex);
    return updatedSteps.map((step, index) => {
        if (step) {
            const liClassName = classNames('', {
                [styles.active]: index === currentStepIndex,
                [styles.notVisited]: index > currentStepIndex,
                [styles.visited]: index < currentStepIndex
            });
            return (
                <li className={liClassName} key={step.id} />
            );
        }
        return '';
    });
}

function PAMobileWizardSteps(props) {
    const wizardContext = useContext(WizardContext);
    const { toggleSideBar } = props;
    const { steps, currentStepIndex } = wizardContext;
    const isSecondGroupWiz = ['/policy-information', '/payment-details', '/success'].includes(steps[currentStepIndex].path);

    const progressbarStyle = () => {
        if (['/policy-information', '/payment-details'].includes(steps[currentStepIndex].path)) {
            return classNames(styles.progressbar, styles.progressbarStyle);
        }
        return classNames(styles.progressbar);
    }

    return (
        <div className={isSecondGroupWiz ? styles.containerSecondWiz : styles.container}>
            <ul className={progressbarStyle()}>
                {renderSteps(steps, currentStepIndex)}
            </ul>
        </div>
    );
}

PAMobileWizardSteps.propTypes = {
    toggleSideBar: PropTypes.func.isRequired
};
export default PAMobileWizardSteps;
