import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { DropdownSelectField, Money, Loader } from '@jutro/components';
import { IntlContext, TranslatorContext } from '@jutro/locale';
import styles from './ClauseComponent.module.scss';
import messages from './ClauseComponentVM.messages';

class ClauseComponent extends Component {
    /**
     * @memberof gw-components-platform-react.ClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     * @prop {bool} propTypes.showAmount - determine to show amount next to displayName
     */

    static contextType = IntlContext;

    static contextType = TranslatorContext;

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        containerClassName: PropTypes.string,
        amount: PropTypes.arrayOf(PropTypes.shape({})),
        showAmount: PropTypes.bool,
        hasTerms: PropTypes.bool,
        publicID: PropTypes.string.isRequired,
        showError: PropTypes.bool
    };

    static defaultProps = {
        readOnly: false,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        description: undefined,
        containerClassName: undefined,
        amount: undefined,
        showAmount: true,
        hasTerms: false,
        showError: false
    };

    handleChange = (value) => {
        const { onValueChange, path, readOnly } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    };

    getLabelNameWithAmount = (labelName, labelAmount) => {
        const { showAmount } = this.props;

        if (!_.isUndefined(labelAmount) && showAmount) {
            const intl = this.context;
            const formatterCurrency = intl.formatNumber(
                labelAmount.amount,
                {
                    style: 'currency',
                    currency: labelAmount.currency,
                    currencyDisplay: 'code'
                }
            );
            return `${labelName} ${formatterCurrency}`;
        }
        return labelName;
    };

    getAdditionalLabel(displayName, publicID, columnData) {
        let addLabel;
        for (let data of columnData) {
            if (data && (data.code === 'PABasicGA') && publicID === 'PAUninsuredMotoristCommon_alfa') {
                addLabel = <p className={styles.labelStyle}><br />{`${displayName} Deductible`}</p>;
                break;
            } else {
                addLabel = '';
            }
        }
        return addLabel;
    }

    getUpdatedLabel(displayName, publicID, columnData) {
        let updatedLabel;
        for (let data of columnData) {
            if (data && data.code === 'PABasicGA' && publicID === 'PAUninsuredMotoristCommon_alfa') {
                updatedLabel = `${displayName} Reduced or Added On`;
            } else if (data && data.code === 'PABasicMS' && publicID === 'PAUninsuredMotoristCommon_alfa') {
                updatedLabel = `${displayName} Deductible`;
            } else {
                updatedLabel = displayName;
            }
        }
        return updatedLabel;
    }

    getEmptyValue(publicID, columnData) {
        let updatedEmptyValue = '$0.00';
        for (let data of columnData) {
            if (data && (data.code === 'PABasicGA' || data.code === 'PABasicMS') && publicID === 'PAUninsuredMotoristCommon_alfa') {
                updatedEmptyValue = '';
            }
        }
        return updatedEmptyValue;
    }

    renderEditableValue() {
        const {
            displayName,
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            hasTerms,
            description,
            containerClassName,
            amount,
            publicID,
            showError,
            columnData
        } = this.props;

        const hasChildren = !_.isNil(children);
        const clauseContainerStyles = classNames(styles.clause, containerClassName);
        /**
         * const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });
        **/
        const translator = this.context;

        const termValue = value ? 'Included' : '$0.00';
        const emptyValue = publicID === 'PAAccidentWaiver_alfa' ? termValue : this.getEmptyValue(publicID, columnData);
        const messagePath = 'policycommon.directives.coverages.';
        const message = `${messagePath}${publicID}`;
        const displayAmount = termValue === 'Not Included' ? emptyValue : amount;
        const errorMessages = translator(_.find(messages, { id: message }));

        const showDropDown = ['PAAccidentWaiver_alfa', 'PAADD_alfa', 'PATowingLaborCov', 'PALeaseLoan_alfa', 'PALossOfIncome_alfa'];
        const hasDropDown = showDropDown.indexOf(publicID) !== -1;
        let convertedAmount = '';
        if (amount !== undefined) {
            const amtString = `$${amount.amount}`;
            const decimalPoint = amtString.split('.')[1];
            if (_.isEmpty(decimalPoint)) {
                convertedAmount = `${amtString}.00`;
            } else if (!_.isEmpty(decimalPoint) && decimalPoint.length === 1) {
                convertedAmount = `${amtString}0`;
            } else {
                convertedAmount = `${amtString}`;
            }
        }

        return (
            <div className={clauseContainerStyles}>
                {isLoading ? (
                    <Loader loaded={!isLoading} text={loadingMessage} />
                ) : (
                        <div>
                            <div className={styles.paClauseGrid}>
                                <span className={styles.paClauseGridSlotLeft}>
                                    <p>{this.getUpdatedLabel(displayName, publicID, columnData)}</p>
                                    {this.getAdditionalLabel(displayName, publicID, columnData)}
                                </span>
                                {hasDropDown
                                    ? (
                                        <span className={styles.paClauseGridSlotMiddle}>
                                            <DropdownSelectField
                                                autoTrim={false}
                                                availableValues={[
                                                    {
                                                        displayName: {
                                                            defaultMessage: 'Included',
                                                            id: 'policycommon.directives.coverages.Included'
                                                        },
                                                        id: 'true'
                                                    },
                                                    {
                                                        displayName: {
                                                            defaultMessage: 'Not Included',
                                                            id: 'policycommon.directives.coverages.Not Included'
                                                        },
                                                        id: 'false'
                                                    }
                                                ]}
                                                path={path}
                                                disabled={false}
                                                hideLabel
                                                id={id}
                                                value={value}
                                                onValueChange={this.handleChange}
                                                className={styles.clause_term}
                                                readOnly={false}
                                                required={false}
                                                schemaRequired={false}
                                                searchable={false}
                                                showErrors={false}
                                                showValidationIcon={false}
                                            />
                                        </span>
                                    ) : null}
                                {!hasDropDown
                                    ? (
                                        <span className={styles.paClauseGridSlotMiddle}>
                                            {children}
                                        </span>
                                    ) : null}

                                {amount !== undefined && termValue !== 'Not Included' ? (
                                    <span className={styles.paClauseGridSlotRight}>
                                        {convertedAmount}
                                    </span>
                                ) : <span className={styles.paClauseGridSlotRight}>{emptyValue}</span>

                                }


                            </div>
                            {showError
                                ? (
                                    <span className={styles.quoteError}>{errorMessages}</span>
                                ) : null}
                        </div>

                    )}
            </div>
        );
    }

    renderReadOnlyValue() {
        const { displayName, children, amount } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <Money
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined}
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>{children}</div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;
        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default ClauseComponent;
