import _ from 'lodash';
import { formatShortDate } from '@jutro/locale';

export const valueTypeMap = {
    TYPEKEY: 'typeCodeValue',
    STRING: 'stringValue',
    INTEGER: 'integerValue',
    LOCATION: 'locationValue',
    DATE: 'dateValue',
    POLICYCONTACT: 'policyContactValue'
};

export const valueTypeDatatypeMap = {
    TYPEKEY: 'select',
    STRING: 'string',
    INTEGER: 'number',
    DATE: 'date'
};

export const valueTypePropDatatypeMap = {
    TYPEKEY: 'string',
    INTEGER: 'number',
    DATE: 'string'
};

export function formatAddress(address) {
    const addressValues = [
        address.addressLine1,
        address.addressLine2,
        address.addressLine3,
        address.city,
        address.postalCode,
        address.state
    ].filter((addressValue) => !_.isUndefined(addressValue));

    return address ? addressValues.join(', ') : undefined;
}

export function getFormattedValue(value, valueType, propertyInfo) {
    if (valueType === 'TYPEKEY') {
        const availableValue = propertyInfo.availableTypeListValues.find(
            (av) => av.code === value || av.displayKey === value
        );
        return _.get(availableValue, 'displayKey');
    }
    if (valueType === 'LOCATION') {
        const address = _.get(value, 'address', {});
        return formatAddress(address);
    }
    if (valueType === 'DATE') {
        return formatShortDate(new Date(value));
    }
    if (valueType === 'POLICYCONTACT') {
        return _.get(value, 'person.displayName');
    }
    return value;
}

export function getComponentDataType(propertyInfo) {
    const { valueType } = propertyInfo;

    if (valueType === 'INTEGER' && _.has(propertyInfo, 'currency')) {
        return 'currency';
    }

    return _.get(valueTypeDatatypeMap, valueType);
}

export function getPropDataType({ valueType }) {
    return _.has(valueTypePropDatatypeMap, valueType)
        ? _.get(valueTypePropDatatypeMap, valueType) : undefined;
}

export function processModalData(modalData = {}) {
    const data = modalData;
    if (data.policyContact) {
        delete data.policyContact;
    } else if (data.location) {
        delete data.location;
    }
    return data;
}
