import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteChangeTitle: {
        id: 'gw-portals-policydiff-angular.components.policy-diff-directive.Policy Changes',
        defaultMessage: 'Policy Changes'
    },
    quoteChangeItem: {
        id: 'gw-portals-policydiff-angular.components.policy-diff-directive.ITEM',
        defaultMessage: 'ITEM'
    },
    quoteChangeExisting: {
        id: 'gw-portals-policydiff-angular.components.policy-diff-directive.EXISTING POLICY',
        defaultMessage: 'EXISTING POLICY'
    },
    quoteChangeNew: {
        id: 'gw-portals-policydiff-angular.components.policy-diff-directive.NEW CHANGES',
        defaultMessage: 'NEW CHANGES'
    }
});
