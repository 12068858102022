import React from 'react';
import {
    BrowserRouter as Router, Switch, Route, Redirect
} from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FieldComponent, ModalService } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { withValidation, validationPropTypes } from 'gw-portals-validation-react';
import { AddressLookupService } from 'gw-capability-address';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { AddressStandardizationService } from 'gw-capability-addressstandardization_alfa';
import { AddressStdPopover } from 'gw-capability-addressstdpopover-react_alfa';
import StringUtil from 'gw-portals-util-js/StringUtil';
import { loadGoogleMapsAPI, GeoCodeService } from 'gw-portals-google-maps-js';
import { ServiceManager } from '@jutro/services';
import AddressUtil from '../../../Utils/AddressUtil';
import ErrorHandlingUtil from '../../../../pages/Utils/ErrorHandlingUtil';
import metadata from './AddressLookupComponent.metadata.json5';
import styles from './AddressLookupComponent.module.scss';
import messages from './AddressLookupComponent.messages';
import metadataGarage from './AddressLookupComponentGarage.metadata.json5';
import style from './AddressLookupComponentGarage.module.scss';
import mobilestyle from './AddressLookupComponentGarageMobile.module.scss';
import metadataGarageMobile from './AddressLookupComponentGarageMobile.metadata.json5';

class AddressLookupComponent extends FieldComponent {
    constructor(props) {
        super(props);
        this.addrLookUpRef = React.createRef();
    }

    static contextType = TranslatorContext;

    /**
     * @memberof gw-capability-address-react.AddressLookupComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.postalCode - the postal code entered in the landing page
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {function} propTypes.onValidate - returns if the values are valid
     */
    static propTypes = {
        value: PropTypes.shape({ postalCode: PropTypes.string }),
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onValidate: PropTypes.func,
        onValueChange: PropTypes.func,
        label: PropTypes.string,
        labelPosition: PropTypes.string,
        required: PropTypes.bool,
        authHeader: PropTypes.shape({}).isRequired,
        showErrors: PropTypes.bool,
        addressError: PropTypes.string,
        withPOBox: PropTypes.bool,
        showPOBoxError: PropTypes.bool,
        fromVehiclePage: PropTypes.bool,
        syncAddress: PropTypes.func,
        showInvalidZip: PropTypes.func,
        enableStateZip: PropTypes.bool,
        sameStateZip: PropTypes.bool,
        currentStateName: PropTypes.string,
        restriction: PropTypes.bool,
        showCountyField: PropTypes.bool,
        ...FieldComponent.propTypes,
        ...validationPropTypes
    };

    static defaultProps = {
        onValidate: undefined,
        value: undefined,
        onValueChange: undefined,
        layout: 'full-width',
        hideLabel: true,
        label: undefined,
        labelPosition: undefined,
        required: false,
        showErrors: false,
        addressError: undefined,
        withPOBox: true,
        showPOBoxError: true,
        enableStateZip: false,
        syncAddress: undefined,
        sameStateZip: false,
        showInvalidZip: undefined,
        currentStateName: '',
        restriction: false,
        showCountyField: false,
        fromVehiclePage: false
    };

    state = {
        errorText: '',
        matches: [],
        formData: {},
        lastStandardizedAddr: {},
        emptiedSearchField: false,
        enableSearchButton: undefined,
        manualAddressOverride: false,
        addrStandardized: false,
        showAddressLabel: false,
        showAddressError: false,
        googlePlaceSelected: false,
        debuggingAddrStd: true,
        stateAvailableValues: [],
        stateTypelist: [],
        addrStateReadOnly: false,
        isPrimaryAddress: false,
        isGaragingAddress: false,
        isMailingAddress: false,
        isBillingAddress: false,
        addrCouldNotStandardize: false,
        showInvalidZipForState: false,
        deviceType : false
    };

    componentDidMount() {
        const {
            id, onValidate, isComponentValid, registerComponentValidation, value: addressData, lockAddressState, sameStateZip ,
        } = this.props;

        registerComponentValidation(this.isFieldsValid);
        const localeService = ServiceManager.getService('locale-service');

        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        if(navigator.userAgent !== undefined && navigator.userAgent !== ""){
           let type = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            if(type){
                    this.setState({ deviceType: true })
                }
        }
       
        if (this.isFieldsValid()) {
            this.setState({ manualAddressOverride: true });
        }
        if (addressData.postalCode.value !== '' && sameStateZip) {
            this.validatePostalCode(addressData.postalCode.value);
        }
        const stateTypeListValues = addressData.state.aspects.availableValues;
        let stateDropDownValues = [];
        stateTypeListValues.forEach((item) => {
            const dropListItem = {
                code: item.code,
                name: item.code
            };
            stateDropDownValues.push(dropListItem);
        });

        this.setState({
            stateTypelist: stateTypeListValues,
            stateAvailableValues: stateDropDownValues
        });

        this.setState({ addrStateReadOnly: lockAddressState });

        if (id === 'garageAddress') {
            this.setState({ isGaragingAddress: true });
        }

        if (id === 'mailingAddress') {
            this.setState({
                isMailingAddress: true,
                addrStateReadOnly: false
            });

        }

        if (id === 'billingAddress') {
            this.setState({ isBillingAddress: true, addrStateReadOnly: false });
        }

        if (id === 'primaryAddress') {
            this.setState({ isPrimaryAddress: true });
        }

        const options = {
            componentRestrictions: {
                country: localeService.getDefaultCountryCode()
            }
        };

        loadGoogleMapsAPI().then((response) => {
            const googleMapsApi = response;
            const autocomplete = new googleMapsApi.places.Autocomplete(
                this.searchInputRef,
                options
            );

            googleMapsApi.event.addListener(autocomplete, 'place_changed', () => {
                this.selectedGoogleDropdown = true;
                const place = autocomplete.getPlace();
                const postalCodeComponent = _.find(place.address_components, (addressComponent) => {
                    return _.includes(addressComponent.types, 'postal_code');
                });

                if (postalCodeComponent !== undefined) {
                    this.mapGooglePlacesAddressToDTO(place);

                    // turn off the googlePlaceSelected trigger that updates floatComponent fields
                    this.setState({ googlePlaceSelected: false });
                }
            });
        });
    }

    mapGooglePlacesAddressToDTO = (place) => {
        const { value: addressData, syncAddress, sameStateZip } = this.props;
        const { formData, addrStateReadOnly } = this.state;
        // update the addressData fields and the formData fields with the values returned from Google Place selection
        // using _.set(formData...  so that a state change is not triggered, until all fields are updated

        // Clear out address line 2.  If there is a value from google places, it will repopulate using the google place data
        addressData.addressLine2.value = '';
        _.set(formData, 'addressLine2', addressData.addressLine2.value);

        place.address_components.forEach((addressComponent) => {
            if (addressComponent.types.includes('street_number')) {
                addressData.addressLine1.value = addressComponent.long_name;
                _.set(formData, 'searchText', addressData.addressLine1.value);
            }
            if (addressComponent.types.includes('route')) {
                const addrLine1Elements = addressData.addressLine1.value.split(' ');
                const streetNumber = addrLine1Elements[0];
                addressData.addressLine1.value = `${streetNumber} ${addressComponent.long_name}`;
                _.set(formData, 'searchText', addressData.addressLine1.value);
            }
            if (
                addressComponent.types.includes('neighborhood')
                && addressComponent.types.includes('political')
            ) {
                addressData.addressLine2.value = addressComponent.long_name;
                _.set(formData, 'addressLine2', addressData.addressLine2.value);
            }
            if (
                addressComponent.types.includes('locality')
                && addressComponent.types.includes('political')
            ) {
                addressData.city.value = addressComponent.long_name;
                _.set(formData, 'city', addressData.city.value);
            }
            if (
                addressComponent.types.includes('sublocality_level_1')
                && addressComponent.types.includes('sublocality')
                && addressComponent.types.includes('political')
            ) {
                addressData.city.value = addressComponent.long_name;
                _.set(formData, 'city', addressData.city.value);
            }
            if (addressComponent.types.includes('postal_code')) {
                const zip5Value = addressComponent.long_name.substring(0, 5);
                addressData.postalCode.value = zip5Value;
                _.set(formData, 'postalCode', zip5Value);
                if (zip5Value !== '' && sameStateZip) {
                    this.validatePostalCode(zip5Value);
                }

            }
            if (
                addressComponent.types.includes('administrative_area_level_1')
                && addressComponent.types.includes('political')
            ) {
                const stateAbbreviation = addressComponent.short_name;
                if (!addrStateReadOnly) {
                    addressData.state.value = this.getStateTypeKey(stateAbbreviation);
                    _.set(formData, 'state', stateAbbreviation);
                }
            }
            if (
                addressComponent.types.includes('administrative_area_level_2')
                && addressComponent.types.includes('political')
            ) {
                const rawCountyName = addressComponent.short_name;
                const lastSpaceIndex = rawCountyName.lastIndexOf(' ');
                const countyName = rawCountyName.substring(0, lastSpaceIndex);
                addressData.county.value = countyName;
                _.set(formData, 'county', countyName);
            }
        });
        if (_.isEmpty(addressData.addressLine1.value) || _.isEmpty(addressData.city.value)) {
            ModalService.showError({
                title: messages.unsuccessfulMappingTitle,
                message: messages.unsuccessfulMappingMessage
            });
        }
        if (formData.AddressLine2 === undefined) {
            _.set(formData, 'addressLine2', '');
        }
        // change one of the state variable that trigger the floatComponent fields to update their displayed values
        if (syncAddress) {
            syncAddress();
        }
        this.setState({ formData, googlePlaceSelected: true });
    };

    componentDidUpdate(prevProps) {
        const {
            id,
            onValidate,
            isComponentValid,
            hasValidationChanged,
            value: newAddressData,
            triggerStdProcess
        } = this.props;
        const { value: oldAddressData } = prevProps;
        const isSameAddress = _.isEqual(newAddressData.value, oldAddressData.value);
        const {
            formData,
            isGaragingAddress,
            isBillingAddress
        } = this.state;

        if ((!isSameAddress || hasValidationChanged) && onValidate) {
            onValidate(isComponentValid, id);
        }

        if (isGaragingAddress || isBillingAddress) {
            const stateAbbreviation = newAddressData.value.state;
            _.set(formData, 'state', stateAbbreviation);
        }

        if (triggerStdProcess) {
            // trigger was set by parent component
            const standardizationResult = this.standardizeAddress();
        }
    }

    isFieldsValid = () => {
        const { value: address } = this.props;
        return address.aspects.valid && address.aspects.subtreeValid;
    };

    isFieldValid = (path) => {
        const { value: address } = this.props;
        const field = _.get(address, path);
        const result = field.aspects.valid && field.aspects.subtreeValid;
        if (path === 'county') {
            if (field.value === undefined) { return false; }
            if (field.value === null) { return false; }
            if (field.value === '') { return false; }
        }
        return result;
    };

    getStateTypeKey = (stateVal) => {
        const { debuggingAddrStd, stateAvailableValues, stateTypelist} = this.state;

        if (stateVal === undefined || stateVal === null) {
            if (debuggingAddrStd) { console.log('ERROR: No State Code Specified!'); }
            return null;
        }
        const stateAbbrev = (stateVal.code === undefined) ? stateVal : stateVal.code;
        if (debuggingAddrStd) { console.log('getStateTypeKey for:'.concat(' ', stateAbbrev)); }


        const stateIndexValue = _.findIndex(stateAvailableValues, (item) => item.code === stateAbbrev);
        if ((stateIndexValue === -1) && debuggingAddrStd) { console.log('ERROR: Cound not find typekey for:'.concat(' ', stateAbbrev)); }
        const locatedStateTypeKey = stateTypelist[stateIndexValue];
        return locatedStateTypeKey;
    }

    getIndexOfStateAbbrev = (stateVal) => {
        const { debuggingAddrStd, stateAvailableValues } = this.state;

        if (stateVal === undefined || stateVal === null) {
            if (debuggingAddrStd) { console.log('ERROR: No State Code Specified!'); }
            return -1;
        }
        const stateAbbrev = (stateVal.code === undefined) ? stateVal : stateVal.code;
        if (debuggingAddrStd) { console.log('get index for:'.concat(' ', stateAbbrev)); }

        const stateIndexValue = _.findIndex(stateAvailableValues, (item) => item.code === stateAbbrev);
        return stateIndexValue;
    }

    handleChange = (value, changedPath) => {
        const { path, onValueChange } = this.props;
        const { matches, formData } = this.state;
        if (onValueChange && changedPath === 'selectedAddress') {
            onValueChange(_.get(matches, value).address, `${path}`);
        } else if (onValueChange && changedPath === 'searchText') {
            onValueChange(value, `${path}.addressLine1`);
            _.set(formData, changedPath, value);
        } else if (onValueChange && changedPath === 'state') {
            const stateTC = this.getStateTypeKey(value);
            onValueChange(stateTC, `${path}.${changedPath}`);
            _.set(formData, changedPath, value);
        } else {
            onValueChange(value, `${path}.${changedPath}`);
            _.set(formData, changedPath, value);
        }
        this.setState({ formData });
    };

    validatePostalCode = (value) => {
        const { showInvalidZipForState } = this.state;
        const { sameStateZip ,  value: addressData , showInvalidZip} = this.props;
        if (sameStateZip) {
            if (addressData.postalCode.value === '') {
                this.setState({ showInvalidZipForState: false });
            } else {
                GeoCodeService.getGeoCodeStateBasedOnPostalCode(addressData.postalCode.value)
                    .then((address) => {

                        if (address.stateCode !== addressData.state.value.code) {
                            /* if (address.stateCode !== addressData.state.value.code && (address.stateCode != 'AL' && address.stateCode !== 'MS' && address.stateCode !== 'GA')) { */
                            this.setState({
                                showInvalidZipForState: true,
                                showErrors: true
                            });
                            showInvalidZip(true);
                        } else {
                            this.setState({
                                showInvalidZipForState: false,
                                showErrors: false
                            });
                            showInvalidZip(false);
                        }
                    });
            }
        }
    };

    handleValueChange = (newValue, path) => {
        const { restriction } = this.props;
        let normalisedValue = newValue;

        if (restriction) {
            normalisedValue = newValue.replace(/[^A-Za-z0-9\s-:()]/ig, '');
        }
        this.writeValue(normalisedValue, path);
    };


    writeValue = (value, path) => {
        const { formData, emptiedSearchField } = this.state;
        const clonedFormData = _.cloneDeep(formData);
        _.set(clonedFormData, path, value);
        let showAddrError = false;
        if (path === 'searchText' && value.length > 60) {
            showAddrError = true;
        }

        this.setState({
            formData: clonedFormData,
            emptiedSearchField: path === 'searchText' && _.isEmpty(value),
            enableSearchButton: path === 'searchText' && emptiedSearchField,
            showAddressError: showAddrError
        });
        this.handleChange(value, path);
    };

    handleStateDropDownSelection = (selection) => {
        const { value: addressData } = this.props;
        const { formData } = this.state;

        if (selection === undefined) {
            addressData.state.value = undefined;
            _.set(formData, 'state', '');
        } else {
            const stateAbbrev = selection;
            addressData.state.value = this.getStateTypeKey(stateAbbrev);
            _.set(formData, 'state', stateAbbrev);
            this.writeValue(stateAbbrev, 'state');
            this.validatePostalCode(undefined);
        }
    }

    clearTextField = () => {
        const { value: address, path, onValueChange } = this.props;
        const clonedValues = _.clone(address.value);
        clonedValues.addressLine1 = undefined;
        clonedValues.addressLine2 = undefined;
        clonedValues.addressLine3 = undefined;
        clonedValues.city = undefined;
        onValueChange(clonedValues, path);
        this.setState({
            formData: {
                searchText: '',
                selectedAddress: undefined
            },
            matches: [],
            errorText: ''
        });
    };

    setupSearchInputRef = (ref) => {
        if (!_.isNull(ref)) {
            this.searchInputRef = ref.querySelector('#addresslookupSearch');
        }
    };

    renderErrorsMessage = () => {
        const { errorText } = this.state;
        return [errorText];
    };

    renderAddressErrorsMessage = () => {
        const { label, addressError, fromVehiclePage } = this.props;
        let errorMessage = null;
        const { formData, showAddressError } = this.state;
        let errorLabel = _.get(label, "defaultMessage") !== undefined ? (fromVehiclePage ? _.get(label, 'defaultMessage') : _.get(label, 'defaultMessage').toLowerCase()) : '';
        if (_.isEmpty(formData.searchText)) {
            errorMessage = `Please enter a ${errorLabel}`;
        }
        if (showAddressError) {
            errorMessage = _.get(addressError, 'defaultMessage');
        }
        return [errorMessage];
    };

    enterManualAddress = () => {
        this.setState({ manualAddressOverride: true });
    };

    enterAddressLookup = () => {
        this.setState({ manualAddressOverride: false });
    };

    onSearchAddress = () => {
        const { formData } = this.state;
        const { value: addressData, authHeader } = this.props;

        AddressLookupService.lookupAddressUsingStringAndFilterByPostalCode(
            formData.searchText,
            _.get(addressData, 'postalCode.value'),
            authHeader
        ).then((addresses) => {
            this.setState({
                enableSearchButton: !_.isEmpty(addresses.matches),
                matches: addresses.matches,
                errorText: addresses.errorDescription
            });
        });
    };

    standardizeAddress = async () => {
        const { updateStandardizationRan, path, value: propsAddress } = this.props;
        const {
            debuggingAddrStd,
            formData,
            isGaragingAddress,
            isBillingAddress,
            isPrimryAddress,
            isMailingAddress
        } = this.state;
        const clonedFormData = _.cloneDeep(formData);

        // Initialize the DTO with formdata (contains changed/updated values)
        const AddressRequestDTO = {
            addressLine1: clonedFormData.searchText,
            addressLine2: clonedFormData.addressLine2,
            postalCode: clonedFormData.postalCode,
            state: clonedFormData.state,
            city: clonedFormData.city,
        };
        // If any values are missing, fill them in.
        if (AddressRequestDTO.addressLine1 === undefined) { AddressRequestDTO.addressLine1 = propsAddress.addressLine1.value; }
        if (AddressRequestDTO.addressLine2 === undefined) { AddressRequestDTO.addressLine2 = propsAddress.addressLine2.value; }
        if (AddressRequestDTO.addressLine2 === undefined) { AddressRequestDTO.addressLine2 = ''; }
        if (AddressRequestDTO.postalCode === undefined) { AddressRequestDTO.postalCode = propsAddress.postalCode.value; }
        if (AddressRequestDTO.state === undefined) { AddressRequestDTO.state = propsAddress.state.value && propsAddress.state.value.code; }
        if (AddressRequestDTO.city === undefined) { AddressRequestDTO.city = propsAddress.city.value; }
        if (isMailingAddress) {
            AddressRequestDTO.addressType = 'mailing';
        } else if (isBillingAddress) {
            AddressRequestDTO.addressType = 'billing';
        } else if (propsAddress.addressType !== undefined) {
            AddressRequestDTO.addressType = propsAddress.addressType.value && propsAddress.addressType.value.code;
        } else {
            AddressRequestDTO.addressType = 'contact'; // default to 'contact' if not otherwise specified
        }

        if (debuggingAddrStd) { console.log('Attempting Address Standardization:'); }
        if (debuggingAddrStd) { console.log('FormData:'); }
        if (debuggingAddrStd) { console.log(formData); }
        if (debuggingAddrStd) { console.log('AddressRequestDTO:'); }
        if (debuggingAddrStd) { console.log(AddressRequestDTO); }

        let suggestedAddress = [];
        let addressDidStandardize = false; // temp variable - to avoid state changes till the end

        let addressCannotStandardize;
        // Call Alfa's address standardization service
        const AddressResponses = await AddressStandardizationService.requestStandardizedAddress(AddressRequestDTO, debuggingAddrStd);
        if (debuggingAddrStd) { console.log('Response From Service:'); }
        if (debuggingAddrStd) { console.log(AddressResponses); }

        // Check for PO Box Address Error
        if ((AddressResponses.errorCode !== undefined) && (AddressResponses.errorCode === '002')) {
            // PO Box Address was entered
            _.set(clonedFormData, 'standardizedType', 'NotStandardized');
            addressDidStandardize = false;
            addressCannotStandardize = true;
        }

        if (AddressResponses.addressStandardizeResponseStatus !== undefined) {
            if (AddressResponses.addressStandardizeResponseStatus.mailability_Score !== undefined) {
                if (AddressResponses.addressStandardizeResponseStatus.mailability_Score === '0') {
                    addressCannotStandardize = true;
                } else {
                    addressCannotStandardize = false;
                }
            } else {
                addressCannotStandardize = true;
            }
        } else {
            addressCannotStandardize = true;
        }

        if (AddressResponses && AddressResponses.errorCode !== '003' && AddressResponses.addressStandardizeResponseStatus.mailability_Score === '0') {
            //need to show custome contact-us page
            window.location = '/quote-and-buy/contact-us';
        }

        if (addressCannotStandardize) {
            // Address Standardization could not standardize the address OR PO Box Error was returned from Address Standardization Service
            _.set(clonedFormData, 'standardizedType', 'NotStandardized');
            addressDidStandardize = false;
            const county = !_.isEmpty(AddressResponses.standardizedAddress) && !_.isEmpty(AddressResponses.standardizedAddress.county) ? AddressResponses.standardizedAddress.county : clonedFormData.county;
            const nonStdAddress = {
                addressLine1: AddressRequestDTO.addressLine1,
                addressLine2: AddressRequestDTO.addressLine2,
                city: AddressRequestDTO.city,
                state: AddressRequestDTO.state,
                postalCode: AddressRequestDTO.postalCode,
                county: county,
                standardizedType: clonedFormData.standardizedType,
                stateTC: this.getStateTypeKey(AddressRequestDTO.state)
            };
            updateStandardizationRan(true, nonStdAddress); // turns OFF the standardization trigger in the Parent component
            this.writeValue(clonedFormData.standardizedType, 'standardizedType');
            this.handleBadAddress();
        } else {
            // Address Standardization Call was successful and returned a standardized address
            let showModal = false;
            if ((AddressResponses.errorCode !== undefined) && (AddressResponses.errorCode === '003')) {
                suggestedAddress = [];
                showModal = true;
            } else if (AddressResponses.errorCode === undefined) {
                // capture the County and Census Block values from the suggested address returned by the Address Standardization Service
                _.set(clonedFormData, 'county', AddressResponses.standardizedAddress.county);
                _.set(clonedFormData, 'censusBlock', AddressResponses.standardizedAddress.censusBlock);
                _.set(clonedFormData, 'latitude', AddressResponses.standardizedAddress.latitude);
                _.set(clonedFormData, 'longitude', AddressResponses.standardizedAddress.longitude);
                // grab the suggested address
                suggestedAddress = [
                    {
                        addressLine1: AddressResponses.standardizedAddress.addressLine1,
                        addressLine2: AddressResponses.standardizedAddress.addressLine2,
                        city: AddressResponses.standardizedAddress.city,
                        state: AddressResponses.standardizedAddress.state,
                        postalCode: AddressResponses.standardizedAddress.postalCode
                    }
                ];
                showModal = true;
            }
            if (AddressUtil.addressesMatch(AddressRequestDTO, suggestedAddress[0])) {
                // Existing fields match suggested (standardized) fields - mark as standardized
                _.set(clonedFormData, 'county', AddressResponses.standardizedAddress.county);
                _.set(clonedFormData, 'city', AddressResponses.standardizedAddress.city);
                _.set(clonedFormData, 'postalCode', AddressResponses.standardizedAddress.postalCode.substring(0, 5));
                _.set(clonedFormData, 'censusBlock', AddressResponses.standardizedAddress.censusBlock);
                _.set(clonedFormData, 'standardizedType', 'Standardized');
                addressDidStandardize = true;
                showModal = false;
            }
            if (showModal) {
                const addressesToSend = [];
                addressesToSend.push(AddressRequestDTO);
                if (suggestedAddress.length > 0) {
                    addressesToSend.push(suggestedAddress[0]);
                }
                const componentProps = { addressSuggestions: addressesToSend };
                const stdModalProps = { backdropDismiss: false };
                const popOverResult = await ModalService.showPopover(AddressStdPopover, componentProps, stdModalProps).result.then((result) => {
                    const addressUpdated = (AddressUtil.addressesMatch(result.selectedAddress, clonedFormData) === false);
                    if (addressUpdated) {
                        _.set(clonedFormData, 'searchText', result.selectedAddress.addressLine1);
                        if ((result.selectedAddress.addressLine2 === undefined) || (result.selectedAddress.addressLine2 === '')) {
                            _.set(clonedFormData, 'addressLine2', '');
                        } else {
                            _.set(clonedFormData, 'addressLine2', result.selectedAddress.addressLine2);
                        }
                        _.set(clonedFormData, 'city', result.selectedAddress.city);
                        _.set(clonedFormData, 'state', result.selectedAddress.state);
                        _.set(clonedFormData, 'postalCode', result.selectedAddress.postalCode.substring(0, 5));

                        // mark as standardized
                        _.set(clonedFormData, 'standardizedType', 'Standardized');
                        addressDidStandardize = true;
                    } else {
                        // user selected their address 'as entered' from the popover
                        _.set(clonedFormData, 'standardizedType', 'NotStandardized');
                        addressDidStandardize = false;
                    }
                    return result;
                }).catch((err) => { console.error(err); });
            } else if ((AddressResponses.errorCode !== undefined) && (AddressResponses.errorCode === '002')) {
                const poBoxErrorMessage = AddressResponses.errorDescription;
                _.set(clonedFormData, 'standardizedType', 'NotStandardized');
                addressDidStandardize = false;
            }

            // process results & update state
            const updatedAddress = {
                addressLine1: clonedFormData.searchText,
                addressLine2: clonedFormData.addressLine2,
                city: clonedFormData.city,
                state: clonedFormData.state,
                postalCode: clonedFormData.postalCode,
                county: clonedFormData.county,
                standardizedType: clonedFormData.standardizedType,
                stateTC: this.getStateTypeKey(clonedFormData.state),
                spatialPoint: {
                    latitude: clonedFormData.latitude,
                    longitude: clonedFormData.longitude
                },
                censusBlock: clonedFormData.censusBlock
                // latitude: clonedFormData.selectedAddress.spatialPoint.latitude,
                // longitude: clonedFormData.selectedAddress.spatialPoint.longitude

                // submissionVm.baseData.accountHolder.primaryAddress.spatialPoint.latitude = result.selectedAddress.spatialPoint.latitude;
                // submissionVm.baseData.accountHolder.primaryAddress.spatialPoint.longitude = result.selectedAddress.spatialPoint.longitude;
            };
            updateStandardizationRan(true, updatedAddress); // turns OFF the standardization trigger in the Parent component
            this.setState({
                addrStandardized: addressDidStandardize,
                formData: clonedFormData,
                lastStandardizedAddr: clonedFormData,
                addrCouldNotStandardize: false
            });
            this.writeValue(clonedFormData.censusBlock, 'censusBlock');
            this.writeValue(clonedFormData.county, 'county');
            this.writeValue(clonedFormData.searchText, 'searchText');
            this.writeValue(clonedFormData.addressLine2, 'addressLine2');
            this.writeValue(clonedFormData.city, 'city');
            this.writeValue(clonedFormData.postalCode, 'postalCode');
            this.writeValue(clonedFormData.standardizedType, 'standardizedType');
            //this.writeValue(clonedFormData.state, 'state');
        }
        // this.forceUpdate();
    };

    showFocus = () => {
        this.setState({ showAddressLabel: true });
    };

    disableFocus = () => {
        this.setState({ showAddressLabel: false });
    };

    handleBadAddress = async () => {
        console.log('Address could not be standardized');
        // Per Vijay 9/25/2020 - Don't show "Invalid Address popup" and don't navigate to "Contact Us" page
        /*
        const modalResult = await ModalService.showError({
            title: messages.invalidAddressTitle,
            message: messages.invalidAddressMessage
        }).result.then(() => {
            // Redirect to "ContactUs" page
            return ErrorHandlingUtil.getErrorFromWizard(new Error('We are unable to standardize your address.'));
        }); */
        this.setState({
            addrCouldNotStandardize: true
        });
    }

    renderControl() {
        const {
            formData,
            matches,
            errorText,
            enableSearchButton,
            showAddressLabel,
            showAddressError,
            addrStandardized,
            googlePlaceSelected,
            addrCouldNotStandardize,
            showInvalidZipForState,
            isGaragingAddress,
            isPrimaryAddress,
            deviceType
        } = this.state;
        const {
            value: address, label, labelPosition, required, showErrors, withPOBox, showPOBoxError, fromVehiclePage, triggerStdProcess, enableStateZip, showCountyField, sameStateZip, currentStateName
        } = this.props;
        const dataForComponent = {
            ...formData,
            ...address
        };

        let postalDivClassName = ''

        if(fromVehiclePage){
            if(deviceType){
                postalDivClassName = sameStateZip && showInvalidZipForState && showErrors ? "zipBlock addressClass floatInputError" : "zipBlock addressClass";
            }else{
                postalDivClassName = sameStateZip && showInvalidZipForState && showErrors ? "zipBlock addressClass floatInputError" : "zipBlock addressClass";
            }    
        } else {
             postalDivClassName = sameStateZip && showInvalidZipForState && showErrors ? "zipBlock floatInputError" : "zipBlock";
            }
        const errorLabel = _.get(label, 'defaultMessage') !== undefined ? _.get(label, 'defaultMessage').toLowerCase() : '';
        const errorMessage = `Please enter a ${errorLabel}`;
        let displayLabel = _.get(label, 'defaultMessage');
        if (withPOBox) {
            displayLabel = ''.concat(_.get(label, 'defaultMessage'), ' (No P.O. Box)');
        }
        const translator = this.context;
        let stateName = '';
        if (address && address.state && address.state.value && address.state.value.name && address.state.value.code) {
            stateName = translator({ id: address.state.value.name, defaultMessage: address.state.value.code });
        }
        const zipErrorMessage = `Please enter a valid ${stateName} zip code`;
        const displayName = displayLabel;

        document.getElementById("stateReadOnlyDiv") !== null && !this.state.addrStateReadOnly
          ? (document.getElementById("stateReadOnlyDiv").style.display = "none")
          : "";
        
        const overrideProps = {
            '@field': {
                showOptional: true,
                labelPosition: labelPosition
            },

            addresslookupSearch: {
                onValueChange: this.handleValueChange,
                label: '',
                placeholder: _.isEmpty(formData.searchText) && !showAddressLabel ? displayName : '',
                required: true,
                labelPosition: 'top',
                onFocus: this.showFocus,
                onBlur: this.disableFocus,
                defaultValue: address.addressLine1 && address.addressLine1.value,
                validationMessages: this.renderAddressErrorsMessage(),
                showErrors: showErrors && (_.isEmpty(formData.searchText) || showAddressError),
                className: showErrors && (_.isEmpty(formData.searchText) || showAddressError) ? 'floatInput floatInputError' : 'floatInput overrideError'
            },
            addressLookupText: {
                content: (!_.isEmpty(formData.searchText) || showAddressLabel) ? displayName : ''
            },
            poBoxMessageDiv: {
                visible: this.state.isGaragingAddress && showPOBoxError
            },
            addressLine2: {
                onValueChange: this.writeValue,
                defaultValue: address.addressLine2 && address.addressLine2.value,
                valueChanged: addrStandardized || googlePlaceSelected || true
            },
            city: {
                onValueChange: this.writeValue,
                required: true,
                defaultValue: address.city && address.city.value,
                requiredFieldValidationMessage: [errorMessage],
                showErrors: showErrors,
                className: showErrors && !this.isFieldValid('city') ? 'floatInput floatInputError' : 'floatInput',
                valueChanged: addrStandardized || googlePlaceSelected || true
            },
            stateReadOnlyDiv: {
                visible: this.state.addrStateReadOnly
            },
            garageDiv: {
                visible: fromVehiclePage
            },
            stateReadOnly: {
                visible: this.state.addrStateReadOnly,
                onValueChange: this.writeValue,
                readOnly: true
            },
            stateDropDiv: {
                visible: !this.state.addrStateReadOnly
            },
            stateDropDown: {
                visible: !this.state.addrStateReadOnly,
                onValueChange: this.handleStateDropDownSelection,
                availableValues: this.state.stateAvailableValues,
                value: this.state.stateAvailableValues[this.getIndexOfStateAbbrev(address.state.value.code)],
                requiredFieldValidationMessage: [errorMessage],
                showErrors: showErrors && !this.isFieldValid('state'),
            },
            postalCode: {
                onValueChange: this.writeValue,
                required: true,
                defaultValue: address.postalCode && address.postalCode.value,
                requiredFieldValidationMessage: [errorMessage],
                showErrors: showErrors,
                className: showErrors && !this.isFieldValid('postalCode') ? 'floatInput floatInputError' : 'floatInput',
                valueChanged: addrStandardized || googlePlaceSelected || true,
                disableFloat: !enableStateZip
            },
            postalDiv: {
                className: postalDivClassName
            },
            otherStateZipError: {
                visible: sameStateZip && showInvalidZipForState && address.postalCode && !_.isUndefined(address.postalCode.value),
                content: zipErrorMessage
            },
            countyDiv: {
            },
            county: {
                visible: (showErrors && !addrStandardized && !triggerStdProcess && addrCouldNotStandardize) || showCountyField || isGaragingAddress || this.state.isMailingAddress || this.state.isBillingAddress,
                onValueChange: this.writeValue,
                defaultValue: address.county && address.county.value,
                required: true,
                requiredFieldValidationMessage: 'Please enter a county',
                showErrors: showErrors && !this.isFieldValid('county'),
                className: showErrors && !this.isFieldValid('county') ? 'floatInput floatInputError' : 'floatInput',
                valueChanged: addrStandardized || googlePlaceSelected || true
            },
            censusBlockDiv: {
                visible: false
            },
            censusblock: {
                onValueChange: this.writeValue,
                required: false,
                valueChanged: addrStandardized || googlePlaceSelected || true
            },
            addressLookupInfo: {
                // content: (!_.isEmpty(formData.searchText) || showAddressLabel) && withPOBox ? ' (No P.O. Box)' : ''
            },
            clearButtonIcon: {
                visible: !_.isEmpty(formData.searchText)
            },
            addressesFound: {
                showErrors: errorText !== '',
                validationMessages: this.renderErrorsMessage(),
                availableValues: matches.map((singleAddress, index) => {
                    return {
                        code: `${index}`,
                        name: `${singleAddress.address.addressLine1}, ${singleAddress.address.city}, ${singleAddress.address.state}, ${singleAddress.address.postalCode}`
                    };
                })
            },
            searchButton: {
                disabled:
                    enableSearchButton || _.isEmpty(formData) || _.isEmpty(formData.searchText)
            }
        };
        const resolvers = {
            resolveClassNameMap: fromVehiclePage ? (deviceType ? mobilestyle : style) : styles,
            resolveCallbackMap: {
                onSearchAddress: this.onSearchAddress,
                onCleartextField: this.clearTextField,
                enterManualAddress: this.enterManualAddress,
                enterAddressLookup: this.enterAddressLookup,
                showFocus: this.showFocus,
                disableFocus: this.disableFocus,
                validatePostalCode: this.validatePostalCode,
                onValidate: this.onValidate
            }
        };

        return (
            <div ref={this.setupSearchInputRef}>
                <ViewModelForm
                    uiProps={fromVehiclePage ? (deviceType ? metadataGarageMobile.componentContent : metadataGarage.componentContent ) : metadata.componentContent}
                    model={dataForComponent}
                    overrideProps={overrideProps}
                    onValueChange={this.handleChange}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    ref={this.addrLookUpRef}
                />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

export default withAuthenticationContext(withValidation(AddressLookupComponent));
