import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class AddCoverablesService {
    static addVehicle(vehicleDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'addVehicle',
            [vehicleDTO],
            additionalHeaders
        );
    }

    static removeVehicle(vehicleDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'removeVehicle',
            [vehicleDTO],
            additionalHeaders
        );
    }

    static addDriver(addDriverRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'addDriver',
            [addDriverRequestDTO],
            additionalHeaders
        );
    }

    static removeDriver(addDriverRequestDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'removeDriver',
            [addDriverRequestDTO],
            additionalHeaders
        );
    }

    static updateCoverables(submissionDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'updateCoverables',
            [submissionDTO],
            additionalHeaders
        );
    }

    static updateDraftSubmission(submissionDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'updateDraftSubmission',
            [submissionDTO],
            additionalHeaders
        );
    }

    static orderDriverMVRData(submissionNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'orderDriverMVRData',
            [submissionNumber],
            additionalHeaders
        );
    }

    static searchContacts(searchCriteria, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'searchContacts',
            [searchCriteria],
            additionalHeaders
        );
    }
}
