import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './SuccessNotification.metadata.json5';
import styles from './SuccessNotification.module.scss';

const ActionMessageButton = (props) => {
    const translator = useContext(TranslatorContext);
    const {
        id, value, onClick, type, className
    } = props;
    return (
        <Button id={id} className={className} type={type} onClick={onClick}>
            {translator(value)}
        </Button>
    );
};
ActionMessageButton.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired
};
const SuccessNotification = (props) => {
    const {
        onContinueQuote,
        onWithdrawQuote,
        notificationContent,
        showSubmissionButton,
        type
    } = props;
    if (_.isEmpty(notificationContent.infoMessageTitle)) {
        return null;
    }
    const overrides = {
        continueQuote: {
            visible: _.get(showSubmissionButton, 'continueSubmission') || false
        },
        withdrawQuote: {
            visible: _.get(showSubmissionButton, 'withdrawnSubmission') || false
        },
        successContainerId: {
            className: cx(styles.gwAlert, {
                [styles.gwAlertInfo]: type === 'info',
                [styles.gwAlertWarning]: type === 'warning'
            }),
            columns:
                !_.get(showSubmissionButton, 'continueSubmission')
                && !_.get(showSubmissionButton, 'withdrawnSubmission')
                    ? ['1fr']
                    : ['3fr', '2fr']
        },
        infoIcon: {
            icon: type === 'info' ? 'mi-info' : 'mi-warning'
        },
        alertActionsInfo: {
            visible: !(
                !_.get(showSubmissionButton, 'continueSubmission')
                && !_.get(showSubmissionButton, 'withdrawnSubmission')
            )
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleWithdrawQuote: onWithdrawQuote,
            handleContinueQuote: onContinueQuote
        },
        resolveComponentMap: {
            actionmessagecomponent: ActionMessageButton
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={notificationContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

SuccessNotification.propTypes = {
    notificationContent: PropTypes.shape({
        infoMessageTitle: PropTypes.string
    }).isRequired,
    onContinueQuote: PropTypes.func.isRequired,
    onWithdrawQuote: PropTypes.func.isRequired,
    showSubmissionButton: PropTypes.shape({
        continueSubmission: PropTypes.bool.isRequired,
        withdrawnSubmission: PropTypes.bool.isRequired
    }).isRequired,
    type: PropTypes.string
};
SuccessNotification.defaultProps = {
    type: 'info'
};
export default SuccessNotification;
