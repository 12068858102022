import { defineMessages } from 'react-intl';

export default defineMessages({
    contactUs: {
        id: 'quoteandbind.views.contact-us.Contact Us',
        defaultMessage: 'Contact Us',
    },
    contactUsWarning: {
        id: 'quoteandbind.views.contact-us.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    contactUsSolution: {
        id: 'quoteandbind.views.contact-us.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.contact-us.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.contact-us.Reference Number',
        defaultMessage: ' ',
    },
    contactUsTitleOne: {
        id: 'quoteandbind.views.contact-us.Oops! We\'ve hit a bump in the road.',
        defaultMessage: 'Oops! We\'ve hit a bump in the road.'
    },
    contactUsTitleDraft: {
        id: 'quoteandbind.views.contact-us.It Looks Like Your Quote Needs Special Attention',
        defaultMessage: 'It Looks Like Your Quote Needs Special Attention'
    },
    contactSubtitleOne: {
        id: 'quoteandbind.views.contact-us.It looks like we\'re experiencing technical difficulties. Contact us to get back on track.',
        defaultMessage: 'It looks like we\'re experiencing technical difficulties. Contact us to get back on track.'
    },
    contactSubtitleDraft: {
        id: 'quoteandbind.views.contact-us.We need a bit more information from you.',
        defaultMessage: 'We need a bit more information from you.'
    },
    contactSubtitleDraftOne: {
        id: 'quoteandbind.views.contact-us-draftone.We need a bit more information from you.',
        defaultMessage: 'We need a bit more information '
    },
    contactSubtitleDraftTwo: {
        id: 'quoteandbind.views.contact-us-drafttwo.We need a bit more information from you.',
        defaultMessage: 'from you. To complete your quote,'
    },
    contactSubtitleDraftThree: {
        id: 'quoteandbind.views.contact-us-draftthree.We need a bit more information from you.',
        defaultMessage: 'contact us at:'
    },
    contactSubtitleQuotedDraft: {
        id: 'quoteandbind.views.contact-us.Let\'s talk and get a bit more information.',
        defaultMessage: 'Let\'s talk and get a bit more information.'
    },
    contactSubtitleQuotedDraftOne: {
        id: 'quoteandbind.views.contact-usDraftOne.Let\'s talk and get a bit more information.',
        defaultMessage: 'Let\'s talk and get a bit more '
    },
    contactSubtitleQuotedDraftTwo: {
        id: 'quoteandbind.views.contact-usDraftTwo.Let\'s talk and get a bit more information.',
        defaultMessage: 'information'
    },
    contactSubtitleTwo: {
        id: 'quoteandbind.views.contact-us.To complete your quote, contact us at:',
        defaultMessage: 'To complete your quote, contact us at:'
    },
    docuSignTitle: {
        id: 'quotebind.views.contact-us.It looks like your policy needs more attention',
        defaultMessage: 'It looks like your policy needs more attention'
    },
    docuSignSubTitleOne: {
        id: 'quotebind.views.contact-us.We need your signature on a few documents to process your policy.',
        defaultMessage: 'We need your signature on a few documents to process your policy.'
    },
    docuSignSubTitleTwoPart1: {
        id: 'quotebind.views.contact-us.You should receive an email with the requested documents to sign. If you do not',
        defaultMessage: 'You should receive an email with the requested documents to sign. If you do not '
    },
    docuSignSubTitleTwoPart2: {
        id: 'quotebind.views.contact-us.receive an email, reach out to your agent below or call a representative at 1-800-964-2532 for assistance.',
        defaultMessage: 'receive an email, reach out to your agent below or call a representative at'
    },
    docuSignSubTitleTwoPart3: {
        id: 'quotebind.views.contact-us.1-800-964-2532 for assistance.',
        defaultMessage: '1-800-964-2532 for assistance.'
    },
    docuSignSubTitleTwoMobilePart1: {
        id: 'quotebind.views.contact-us.You should receive an email with the',
        defaultMessage: 'You should receive an email with the'
    },
    docuSignSubTitleTwoMobilePart2: {
        id: 'quotebind.views.contact-us.requested documents to sign. If you do not',
        defaultMessage: 'requested documents to sign. If you do not'
    },
    docuSignSubTitleTwoMobilePart3: {
        id: 'quotebind.views.contact-us.receive an email, reach out to your agent',
        defaultMessage: 'receive an email, reach out to your agent'
    },
    docuSignSubTitleTwoMobilePart4: {
        id: 'quotebind.views.contact-us.below or call a representative at',
        defaultMessage: 'below or call a representative at'
    },
    docuSignSubTitleThree: {
        id: 'quotebind.views.contact-us.If your signatures are not received, your policy will be cancelled.',
        defaultMessage: 'If your signatures are not received, your policy will be cancelled.'
    },
    docuSignSubTitleThreeMobile1: {
        id: 'quotebind.views.contact-us.If your signatures are not received, your',
        defaultMessage: 'If your signatures are not received, your'
    },
    docuSignSubTitleThreeMobile2: {
        id: 'quotebind.views.contact-us.your policy will be cancelled.',
        defaultMessage: 'policy will be cancelled.'
    }
});
