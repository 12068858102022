import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@jutro/uiconfig';
import { useValidation } from 'gw-portals-validation-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';

import metadata from './NumberInputComponent.metadata.json5';
import styles from './NumberInputComponent.module.scss';


function NumberInputComponent(props) {
    const {
        value: modelVM,
        path,
        id,
        showErrors,
        fullLength,
        onValidate,
        onValueChange,
        midLength,
        label,
        type
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);

    const [formData, updateFormData] = useState({});


    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        if (!_.isEmpty(modelVM) && modelVM.length === fullLength) {
            const prevformData = {
                inputCodeFirst: modelVM.slice(0, 3),
                inputCodeSec: midLength === 2 ? modelVM.slice(3, 5) : modelVM.slice(3, 6),
                inputCodeThird: midLength === 2 ? modelVM.slice(5) : modelVM.slice(6)
            };
            _.set(formData, 'inputCodeFirst', prevformData.inputCodeFirst);
            _.set(formData, 'inputCodeSec', prevformData.inputCodeSec);
            _.set(formData, 'inputCodeThird', prevformData.inputCodeThird);
        }
    }, [id, onValidate, isComponentValid]);

    const writeValue = useCallback((newValue, formPath) => {
        let normalisedValue = newValue;
        if (!_.isNil(newValue)) {
            normalisedValue = newValue.replace(/[^0-9]/ig, '');
        }
        const nextFormData = _.cloneDeep(formData);
        if (onValueChange) {
            if (normalisedValue !== _.get(formData, formPath)) {
                _.set(nextFormData, formPath, normalisedValue);
                updateFormData(nextFormData);
            }
        }
        switch (formPath) {
            case 'inputCodeFirst':
                if (normalisedValue.length === 3) {
                    inputCodeSec.focus();
                }
                break;
            case 'inputCodeSec':
                if (normalisedValue.length === midLength) {
                    inputCodeThird.focus();
                }
                break;
            default: break;
        }
        const firstInput = nextFormData.inputCodeFirst !== undefined ? nextFormData.inputCodeFirst : '';
        const secondInput = nextFormData.inputCodeSec !== undefined ? nextFormData.inputCodeSec : '';
        const thirdInput = nextFormData.inputCodeThird !== undefined ? nextFormData.inputCodeThird : '';
        const combinedValue = `${firstInput}${secondInput}${thirdInput}`;
        if (combinedValue !== _.get(modelVM, path)) {
            onValueChange(combinedValue, path);
        }
    }, [formData, modelVM, path, onValueChange, midLength]);

    const overrideProps = {
        '@field': {
        },
        inputLabel: {
            content: label
        },
        inputCodeFirst: {
            onValueChange: writeValue,
            className: showErrors ? 'floatInput componentInput floatInputError' : 'floatInput componentInput',
            inputType: type
        },
        inputCodeSec: {
            onValueChange: writeValue,
            showErrors: false,
            placeholder: midLength === 2 ? '99' : '999',
            maxLength: midLength,
            className: showErrors ? 'floatInput componentInput floatInputError' : 'floatInput componentInput',
            inputType: type
        },
        inputCodeThird: {
            onValueChange: writeValue,
            className: showErrors ? 'floatInput componentInput floatInputError' : 'floatInput componentInput'
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, formData, fieldId, fieldPath, overrideProps
        );
    }, [formData, overrideProps]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };


    return (
        <ViewModelForm
            model={formData}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />

    );
}

NumberInputComponent.propTypes = {
    value: PropTypes.shape({}),
    midLength: PropTypes.number,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    fullLength: PropTypes.number,
    label: PropTypes.string,
    type: PropTypes.string,
};
NumberInputComponent.defaultProps = {
    value: {},
    midLength: 2,
    path: undefined,
    showErrors: false,
    fullLength: 9,
    label: '',
    type: undefined
};
export default NumberInputComponent;
