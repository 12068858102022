import { defineMessages } from 'react-intl';

export default defineMessages({
    paFirstCaption: {
        id: 'quoteandbind.pa.directives.more-Info.paFirstCaption',
        defaultMessage: 'By providing my email address, I understand and agree this is where I will receive a copy of my quote and other related communications. If you do not want to provide this information, please call an Alfa agent or 1-800-964-2532 to get a quote. Please see the {link} for additional information.',
    },
    phoneArea:{
        id: 'quoteandbind.pa.directives.more-Info.phoneArea',
        defaultMessage: '999'
    },
    phoneHypen: {
        id: 'quoteandbind.pa.directives.more-Info.phoneHypen',
        defaultMessage: '-'
    },
    emailError: {
        id: 'quoteandbind.pa.directives.more-Info.emailError',
        defaultMessage: 'Please enter a valid email address'
    },
    phoneError: {
        id: 'quoteandbind.pa.directives.more-Info.phoneError',
        defaultMessage: 'Please enter a valid 10 digit phone number'
    },
    paMoreInfoPageTitle: {
        id: 'quoteandbind.views.more-Info.quote',
        defaultMessage: 'Your Quote is Ready!'
    },
    paMoreInfoPageSubTitle: {
        id: 'quoteandbind.views.more-Info.contactInfo',
        defaultMessage: 'Enter your contact info to get your quote'
    },
    emailErrorNew: {
        id: 'quoteandbind.views.more-Info.emailError',
        defaultMessage: 'Please enter a valid email address'
    },
    email: {
        id: 'quoteandbind.views.more-Info.emailAddress',
        defaultMessage: 'Email Address'
    },
    phoneError: {
        id: 'quoteandbind.views.more-Info.phoneError',
        defaultMessage: 'please Enter a valid Phone Number'
    },
    mobileNumber: {
        id: 'quoteandbind.views.more-Info.phoneNumber',
        defaultMessage: 'Phone Number'
    },

});
