import React, { useContext, useCallback } from 'react';

import { BreakpointTrackerContext } from '@jutro/layout';

import { PAWizardPageTemplate, pageTemplateProps } from 'gw-portals-wizard-react';
import { WizardSingleErrorComponent } from 'gw-components-platform-react';

import { QuoteInfoComponentContext } from './QuoteInfoComponentContext';
import CommonQuoteInfo from './CommonQuoteInfo/CommonQuoteInfo';

function QuoteWizardPageTemplate(props) {
    const quoteInfoComponent = useContext(QuoteInfoComponentContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phone';
    const {
        children, acknowledgeError, errorsForStep, ...otherProps
    } = props;

    const renderQuoteInfoBar = useCallback(
        (submissionVM) => {
            if (isPhone) {
                return <CommonQuoteInfo submissionVM={submissionVM} />;
            }
            // We need a variable with Pascal case in order to render it with React
            // however if we named the variable to respect casing
            // the hooks linter would fail to recognize it as a dependency.
            // To not disable the rule, and circumvent the issue, we alias the variable;
            const QuoteInfo = quoteInfoComponent;
            return <QuoteInfo submissionVM={submissionVM} />;
        },
        [isPhone, quoteInfoComponent]
    );

    return (
        <PAWizardPageTemplate
            renderContextComponent={renderQuoteInfoBar}
            // renderWidgets={renderMediaSidebar}
            errorsForStep={errorsForStep}
            {...otherProps}
        >
            <WizardSingleErrorComponent
                acknowledgeError={acknowledgeError}
                issuesList={errorsForStep}
            />
            {children}
        </PAWizardPageTemplate>
    );
}

QuoteWizardPageTemplate.propTypes = pageTemplateProps;
export default QuoteWizardPageTemplate;
