import React, {
    useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';

import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ModalService, Tooltip, IconButton } from '@jutro/components';

import PropTypes from 'prop-types';

import policycoveragePopUp from './QuotePopover/PolicyCoveragePopUp';
import vehiclecoveragePopUp from './QuotePopover/VehicleCoveragePopover';
import BrowserUtil from "../../../../applications/quote-and-buy/src/pages/Utils/BrowserUtil";


import styles from './BasicQuote.module.scss';
import metadata from './BasicQuote.metadata.json5';
import './BasicQuote.messages';

let propsData;

function BasicQuoteTooltip(id) {
    let tooltipMessage = '';
    if (id.id === 'policyCoverageToolTip') {
        tooltipMessage = 'Click to expand Policy Coverage description';
    } else if (id.id === 'vehicleCoverageToolTip') {
        tooltipMessage = 'Click to expand Vehicle Coverage description';
    } else {
        tooltipMessage = '';
    }
    const loadPopup = useCallback(() => {
        const comp = { propsData: propsData };

        let element = document.getElementsByClassName('tippy-popper')[0];
        if(element != undefined){
            element.style.visibility = 'hidden';
        }
        
        if (id.id === 'policyCoverageToolTip') {
            return ModalService.showPopover(policycoveragePopUp, comp);
        }
        // ToDo Remove this logic and the changes made to VehicleCoveragePopover once 1/7/2023 has passed.{}
        let currentDate = new Date();
        let raALMSDate = new Date(2022, 11, 12);
        let raGADate = new Date(2023, 0, 7);
        if (currentDate < raALMSDate || (currentDate < raGADate && propsData === 'PABasicGA')) {
            comp.flag = false;
            return ModalService.showPopover(vehiclecoveragePopUp, comp);
        }
        comp.flag = true;
        //End of ToDo}
        return ModalService.showPopover(vehiclecoveragePopUp, comp);
    }, []);
  
    let mobileView = false

    if(navigator.userAgent !== undefined && navigator.userAgent !== ""){
        mobileView = BrowserUtil.isMobile() || BrowserUtil.isTablet() || BrowserUtil.isIPadPro();
    }

    return (
        <Tooltip
            animation="fade"
            content={tooltipMessage}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick
            id="tooltip"
            showOnInit={false}
            sticky
            trigger = {(mobileView ? "" : "mouseenter")}
        >
            <IconButton icon="fa-info-circle" onClick={loadPopup} aria-haspopup="true" className={styles.gwToolTip}>mock</IconButton>

        </Tooltip>
    );
}

function BasicQuote(props) {
    const { submissionVM } = props;

    const checkCoverage = ['PAComprehensiveCov', 'PACollisionCov', 'PALeaseLoan_alfa', 'PATowingLaborCov', 'PALossOfUseCov_alfa', 'PALossOfIncome_alfa'];
    let lineCoverage = ['PABI_alfa', 'PAPropertyDamage_alfa', 'PAMedicalPayments_alfa', 'PAUninsuredMotoristBI_alfa', 'PAADD_alfa', 'PAAccidentWaiver_alfa'];
    
    const preparePolicyCovProps = () => {
        let stateCategory = '';
        for (let data of submissionVM.lobData.personalAuto.offerings.value) {
            if (data.branchCode === 'PABasic') {
                stateCategory = 'PABasic';
                break;
            } else if (data.branchCode === 'PABasicGA') {
                stateCategory = 'PABasicGA';
                break;
            } else if (data.branchCode === 'PABasicMS') {
                stateCategory = 'PABasicMS';
                break;
            }
        }
        return stateCategory;
    }
    propsData = preparePolicyCovProps();
    const convertToAmount = (amount) => {
        if (!_.isEmpty(amount)) {
            const decimalPoint = amount.split('.')[1];
            if (!_.isEmpty(decimalPoint) && decimalPoint.length === 1) {
                return `$${amount}0`;
            }
        }
        return `$${amount}`;
    };
    const getVehicleCoverage = useCallback(() => {
        const vehicleMockArray = [];
        submissionVM.lobData.personalAuto.offerings.value.map((object) => {
            if ((object.branchCode === 'PABasic' || object.branchCode === 'PABasicGA' || object.branchCode === 'PABasicMS') && object.branchName === 'Basic Program') {
                object.coverages.vehicleCoverages.map((vehicleObject) => {
                    const eachVehicleArray = [];
                    vehicleObject.coverages.map((coverage,) => {
                        if (checkCoverage.includes(coverage.publicID)) {
                            let term = '';
                            if (!_.isEmpty(coverage.terms)) {
                                const isRoadSideAsstCov = coverage.publicID !== 'PATowingLaborCov' ? coverage.terms[0].chosenTermValue : 'Included';
                                term = !coverage.terms[0].chosenTermValue ? 'Not Included' : isRoadSideAsstCov;
                            } else {
                                term = 'Not Included';
                            }
                            const a = {
                                pid: coverage.publicID,
                                name: coverage.name,
                                term: term,
                                amount: coverage.amount !== undefined ? convertToAmount(`${coverage.amount.amount}`) : '$0.00'
                            };
                            if (!eachVehicleArray.includes(a)) {
                                eachVehicleArray.push(a);
                            }
                        }
                        return eachVehicleArray;
                    });
                    if (!_.isEmpty(eachVehicleArray)) {
                        eachVehicleArray.sort((a, b) => {
                            return checkCoverage.indexOf(a.pid) - checkCoverage.indexOf(b.pid);
                        });

                        const vehicleObj = {
                            name: vehicleObject.vehicleName,
                            coverages: eachVehicleArray
                        };
                        vehicleMockArray.push(vehicleObj);
                    }
                    return vehicleMockArray;
                });
            }
        });

        return vehicleMockArray;
    }, [submissionVM]);

    const vehicleCoverage = getVehicleCoverage();
    const [tableData, updateTableData] = useState(vehicleCoverage);
    useEffect(() => {

    }, []);


    const getCell = (items, index, property) => {
        return items[property.id];
    };

    const getVehicleLinecoverage = (columnData) => {
        if (columnData) {
            let vehicleLineCoverage = [];
            for (let data = 0; data < columnData.length; data++) {
                if(columnData[data].branchCode === 'PABasic') {
                    vehicleLineCoverage = ['PABI_alfa', 'PAPropertyDamage_alfa',
                    'PAMedicalPayments_alfa', 'PAUninsuredMotoristBI_alfa',
                    'PAADD_alfa', 'PAAccidentWaiver_alfa'];
                    break;
                } else if(columnData[data].branchCode === 'PABasicGA' || columnData[data].branchCode === 'PABasicMS') {
                    vehicleLineCoverage = [
                        'PABI_alfa', 'PAPropertyDamage_alfa',
                        'PAMedicalPayments_alfa', 'PAUninsuredMotoristBI_alfa',
                        'PAUninsuredMotoristPD_alfa',
                        'PAUninsuredMotoristCommon_alfa',
                        'PAADD_alfa', 'PAAccidentWaiver_alfa',
                    ];
                    break;
                }
            }
            lineCoverage = vehicleLineCoverage;
        }
    }

    const getCoverageLabel = (name, termObj) => {
        if (termObj.name.includes('Reduced or Added On')) {
            return `${name} Reduced or Added On`;
        }
        return `${name} Deductible`;
    }

    const getPolicyCoverage = useCallback(() => {
        getVehicleLinecoverage(submissionVM.lobData.personalAuto.offerings.value);
        const lineMockArray = [];
        submissionVM.lobData.personalAuto.offerings.value.map((object) => {
            if (object.branchCode === 'PABasic' || object.branchCode === 'PABasicGA' || object.branchCode === 'PABasicMS') {
                object.coverages.lineCoverages.map((coverage) => {
                    if (lineCoverage.includes(coverage.publicID)) {
                        let term = '';
                        if (!_.isEmpty(coverage.terms)) {
                            term = coverage.terms[0].chosenTermValue !== '' ? coverage.terms[0].chosenTermValue : 'Not Included';
                        } else {
                            term = 'Not Included';
                        }
                        const a = {
                            pid: coverage.publicID,
                            name: coverage.name,
                            term: term,
                            amount: coverage.amount !== undefined ? convertToAmount(`${coverage.amount.amount}`) : '$0.00'
                        };
                        const exist = lineMockArray.filter((l) => l.name === a.name);
                        if (_.isEmpty(exist)) {
                            lineMockArray.push(a);
                        }
                    }
                    return lineMockArray;
                });
                object.coverages.vehicleCoverages.map((vehicleObject) => {
                    vehicleObject.coverages.map((coverage,) => {
                        if (lineCoverage.includes(coverage.publicID)) {
                            if (coverage.publicID !== 'PAUninsuredMotoristCommon_alfa') {
                                let term = '';
                                if (!_.isEmpty(coverage.terms)) {
                                    term = coverage.terms[0].chosenTermValue !== '' ? coverage.terms[0].chosenTermValue : 'Not Included';
                                } else {
                                    term = 'Not Included';
                                }
                                const a = {
                                    pid: coverage.publicID,
                                    name: coverage.name,
                                    term: term,
                                    amount: coverage.amount !== undefined ? convertToAmount(`${coverage.amount.amount}`) : '$0.00'
                                };
                                const exist = lineMockArray.filter((l) => l.name === a.name);
                                if (_.isEmpty(exist)) {
                                    lineMockArray.push(a);
                                }
                            } else {
                                _.forEach(coverage.terms, (termObj) => {                                
                                    let term = '';
                                    if (!_.isEmpty(coverage.terms)) {
                                        term = termObj.chosenTermValue !== '' ? termObj.chosenTermValue : 'Not Included';
                                    } else {
                                        term = 'Not Included';
                                    }
                                    const a = {
                                        pid: coverage.publicID,
                                        name: getCoverageLabel(coverage.name, termObj),
                                        term: term,
                                        amount: coverage.amount !== undefined ? convertToAmount(`${coverage.amount.amount}`) : '$0.00'
                                    };
                                    lineMockArray.push(a);
                                });
                            }
                        }
                        return lineMockArray;
                    });
                    return lineMockArray;
                });
            }
            return lineMockArray;
        });
        if (lineMockArray !== undefined) {
            lineMockArray.sort((a, b) => {
                return lineCoverage.indexOf(a.pid) - lineCoverage.indexOf(b.pid);
            });
        }
        return lineMockArray;
    }, []);

    const generateVehicleOverrides = useCallback(() => {
        const overrides = tableData.map((tableInfo, index) => ({
            [`vehicleCoverageTableGrid${index}`]: {
                data: tableInfo.coverages
            },
            [`vehicleName${index}`]: {
                content: tableInfo.name
            }

        }));

        return Object.assign({}, ...overrides);
    }, [tableData]);


    const overrideProps = {
        policyCoverageTableGrid: {
            data: getPolicyCoverage()
        },
        vehicleContainer: {
            data: tableData
        },
        ...generateVehicleOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell: getCell
        },
        resolveComponentMap: {
            tooltipcomponent: BasicQuoteTooltip
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

BasicQuote.propTypes = {
    submissionVM: PropTypes.shape({})
};
BasicQuote.defaultProps = {
    submissionVM: undefined
};
export default BasicQuote;
