import GooglePlacesLookupComponent from './GooglePlacesLookupComponent/GooglePlacesLookupComponent';

const GooglePlacesLookupComponentMap = {
    GooglePlacesLookupComponent: { component: 'GooglePlacesLookupComponent' }
};

const GooglePlacesLookupComponents = {
    GooglePlacesLookupComponent
};

export {
    GooglePlacesLookupComponentMap,
    GooglePlacesLookupComponents,
    // export single component
    GooglePlacesLookupComponent
};
