import React, { Component } from 'react';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ModalService, Loader } from '@jutro/components';
import cookie from 'js-cookie';
import { AvailabilityService } from 'gw-capability-policycommon';
import { LoadSaveService } from 'gw-capability-quoteandbind';
import metadata from './LandingPage.metadata.json5';
import styles from './LandingPage.module.scss';
import messages from './LandingPage.messages';
import moment from "moment";

class LandingPage extends Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    state = {
        formData: {
            lobCategory: 'personal'
        },
        landingPageState: 'startQuote',
        cookieMessageVisible: true,
        resetFromCMS: undefined,
        showLoader: false,
        zipCode: undefined
    };

    componentDidMount() {
        // eslint-disable-next-line react/prop-types
        const { location: { state }, hideQuoteId } = this.props;
        if (state && (state.isSessionTimedOut || state.retrieveQbQuote)) {
            this.setState({ landingPageState: 'retrieveQuote' });
        }
        if (!_.isEmpty(cookie.get('quoteID')) && cookie.get('quoteID') !== 'undefined') {
            this.setState({ landingPageState: 'retrieveQuote' });
        }
        hideQuoteId();
        sessionStorage.clear();
    }

    handleValueChange = (value, path) => {
        const { formData } = this.state;
        _.set(formData, path, value);
        this.setState({ formData });
    };

    readValue = (id, path) => {
        const { formData } = this.state;
        return _.get(formData, path);
    };

    showCookieMessage = () => {
        this.setState({ cookieMessageVisible: true });
    }

    dismissCookieMessage = () => {
        this.setState({ cookieMessageVisible: false });
    }

    clearApplicationCookies = () => {
        cookie.remove('quoteID');
        cookie.remove('agentId');
        cookie.remove('cviscore');
        cookie.remove('primaryDriverState');
        cookie.remove('agentName')
    }

    startQuote = (data, wizardLink) => {
        const { history } = this.props;
        //this.clearApplicationCookies();
        if (data === 'QuickQuote' || data === 'Guidance') {
            history.push(wizardLink);
        } else {
            AvailabilityService.productAvailableBasedOnPostalCode(data).then((response) => {
                if (response.isAvailable) {
                    _.set(response.addressCompletion, 'county', undefined);
                    const nextState = {
                        address: response.addressCompletion,
                        sysDate: moment(response.sysDate).format("YYYY-MM-DD"),
                        stateCode: data.stateCode,
                        agentId: data.agentId,
                        agentUrl_alfa: data.agentUrl_alfa
                    };
                    history.push(wizardLink, nextState);
                }
            });
        }
    };

    retrieveQuote = async (data, setQuoteNotFound) => {
        const { history } = this.props;
        setQuoteNotFound(false);
        this.setState({
            showLoader: true
        });
        await LoadSaveService.retrieveSubmission(data)
            .then(async (submission) => {
                const wizardLink = this.getWizardLink(submission.baseData.productCode);
                history.push(wizardLink, { submission });
                const gaState = _.get(submission, 'baseData.accountHolder.primaryAddress.state');
                localStorage.setItem('gaState', gaState);
                const policyStartDate = _.get(submission, 'baseData.periodStartDate');
                const month = policyStartDate.month + 1;
                const dateString = ''.concat(month, '/', policyStartDate.day, '/', policyStartDate.year);
                const formattedPolicyStartDate = new Date(dateString);
                const datePC = _.get(submission, 'baseData.sysDate').substring(0, 10).replaceAll('-', '/');
                const sysDate = new Date(datePC);
                if (formattedPolicyStartDate.getTime() < sysDate.getTime()) {
                    const newPolicyDate = {
                        year: sysDate.getFullYear(),
                        month: sysDate.getMonth(),
                        day: sysDate.getDate()
                    };
                    _.set(submission, 'baseData.periodStartDate', newPolicyDate);
                    if (submission.baseData.periodStatus === 'Quoted') {
                        await LoadSaveService.saveAndQuoteSubmission(submission);
                    }
                }
            })
            .catch(() => {
                setQuoteNotFound(true);
                this.setState({
                    showLoader: false
                });
            });
        this.setState({
            showLoader: false
        });
    };

    togglePageState = () => {
        const { landingPageState } = this.state;
        
        if (landingPageState === 'startQuote') {
            this.setState({ landingPageState: 'retrieveQuote' });
        } else {

            this.clearApplicationCookies();

            localStorage.removeItem('gaState');

            this.setState({
                landingPageState: 'startQuote',
                resetFromCMS: true
            });
        }
    };

    showRetrieveQuote = () => {
        this.setState({ landingPageState: 'retrieveQuote' });
    };

    getWizardLink = (lobCode) => {
        const lobWizardLinks = {
            BusinessOwners: '/quote-bop',
            Homeowners: '/quote-ho',
            HOPHomeowners: '/quote-ho',
            PersonalAuto: '/quote-pa'
        };
        return lobWizardLinks[lobCode];
    };

    updateZipCode = (zipCode) => {
        this.setState({ zipCode: zipCode });
    }

    render() {
        const { submitted, formData, landingPageState, cookieMessageVisible, resetFromCMS, showLoader, agentUrl_alfa } = this.state;
        // eslint-disable-next-line react/prop-types
        const { location: { state } } = this.props;
        const { zipCode } = this.state;
        const overrideProps = {
            '@field': {
                // apply to all fields
                onValueChange: this.handleValueChange,
                showErrors: submitted
            },
            personalContainer: {
                visible: formData.lobCategory === 'personal'
            },
            businessContainer: {
                visible: formData.lobCategory === 'business'
            },
            startQuoteContainer: {
                visible: landingPageState === 'startQuote',
                className: state && state.fromCMS && resetFromCMS === undefined ? 'displayNone' : ''
            },
            retrieveQuoteContainer: {
                visible: landingPageState === 'retrieveQuote'
            },
            personalAutoLOB: {
                fromCMS: state && state.fromCMS && resetFromCMS === undefined,
                postalCode: state && state.postalCode || zipCode,
                cmsAgentId: state && state.cmsAgentId,
                className: state && state.fromCMS && resetFromCMS === undefined ? 'displayNone' : '',
                agentUrl_alfa: state && state.agentUrl_alfa
            },
            retrieveQuote: {
                // eslint-disable-next-line react/prop-types
                isSessionTimedOut: state && state.isSessionTimedOut,
                retrieveQbQuote: state && state.retrieveQbQuote,
                // eslint-disable-next-line react/prop-types
                quoteId: state && state.quoteId,
                postalCode: state && state.postalCode,
                ssn: state && state.ssn,
                updateZipCode: this.updateZipCode
            },
            cookieMessageContainer: {
                visible: cookieMessageVisible === true
            }
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onRetrieveQuote: this.retrieveQuote,
                onToggleState: this.togglePageState,
                onEnterFlow: this.startQuote,
                onGetWizardLink: this.getWizardLink,
                showCookieMessage: this.showCookieMessage,
                dismissCookieMessage: this.dismissCookieMessage,
                onShowRetrieveQuote: this.showRetrieveQuote
            }
        };

        return showLoader ?
            (
                <Loader loaded={!showLoader} />
            ) : (
                <div className={styles.landingPageContainer}>
                    {renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers)}
                </div>
            );
    }
}
export const LandingPageComponent = LandingPage;
export default withRouter(LandingPage);
