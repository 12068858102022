import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';

import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';

import { readViewModelValue } from 'gw-jutro-adapters-react';

import { formatShortDate, formatLongDate, TranslatorContext } from '@jutro/locale';
import { Tooltip, IconButton } from '@jutro/components';


// eslint-disable-next-line import/no-unresolved

import { LoadSaveService } from 'gw-capability-quoteandbind';
import styles from './DriverSummary.module.scss';
import metadata from './DriverSummary.metadata.json5';
import messages from './DriverSummary.messages';


function DriverHistoryTooltip(messageType) {
    const translator = useContext(TranslatorContext);
    const messagePath = 'quoteandbind.pa.views.pa-driver-history.';
    const modmessage = 'quoteandbind.pa.views.pa-driver-history.claimDescriptionToolTip';
    let message = '';
    if (messageType.id !== 'driverHistoryTitleToolTip') {
        message = modmessage;
    } else {
        message = `${messagePath}${messageType.id}`;
    }


    return (
        <Tooltip
            animation="fade"
            content={translator(_.find(messages, { id: message }))}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton icon="fa-info-circle" aria-haspopup="true" className={styles.gwToolTip} aria-hidden="true" aria-label="Information" />

        </Tooltip>
    );
}

function DriverSummary(props) {
    const { wizardData: submissionVM, jumpTo, updateWizardData } = props;
    const [showError, updateShowError] = useState(false);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('DriverSummary');
    const [errorArr, updateerrorArr] = useState([]);
    let initial = () => Promise.resolve(false);
    let skipPage = false;

    useEffect(() => {
        async function discountRibbon() {
            const quoteId = _.get(submissionVM, 'quoteID.value');
            const disco = await LoadSaveService.getQuoteDiscounts(quoteId);
            _.set(submissionVM, 'baseData.discount', disco);
        }
        discountRibbon();
    }, []);

    const onNext = useCallback(async () => {
        const quoteId = submissionVM.quoteID.value;
        dataLayer.push({'event':'gtm.click', 'gtm.elementId': 'qb_next'});
        const driverHistoryValue = submissionVM.lobData.personalAuto.coverables.driverHistory.value;
        const claimDesError = [];
        driverHistoryValue.map((object, index) => {
            if (_.isUndefined(object.claimDescription) || object.claimDescription === '') {
                claimDesError.push(index);
                updateerrorArr(claimDesError);
                updateShowError(true);
            }
            return claimDesError;
        });
        if (!_.isEmpty(claimDesError)) {
            return false;
        }

        const updateDriverHistoryDTO = {
            submissionNumber: quoteId,
            driverHistory: driverHistoryValue
        };
        updateWizardData(submissionVM);

        await LoadSaveService.updateClueReport(updateDriverHistoryDTO);


        const discounts = await LoadSaveService.getQuoteDiscounts(quoteId);
        _.set(submissionVM, 'baseData.discount', discounts);


        return submissionVM;
    }, [submissionVM]);



    const generateOverrides = useCallback(() => {
        let cost = '';
        const clue = submissionVM.lobData.personalAuto.coverables.driverHistory.value;
        if (!_.isUndefined(clue) && clue.length > 0) {
            const claimDesc = [
                { code: 'hitWhileParked_alfa', name: 'Hit while parked' },
                { code: 'hitAndRun_alfa', name: 'Hit & Run' },
                { code: 'hitByUninsuredMotorist_alfa', name: 'Hit by Uninsured Motorist' },
                { code: 'other_alfa', name: 'Other' }
            ];
            cost = clue
                .map((object, index) => {
                    let claimAmt = undefined;
                    if (!_.isUndefined(object.claimAmount)) {
                        claimAmt = `$${object.claimAmount}`;
                        if (object.claimAmount.indexOf('.') === -1) {
                            claimAmt += '.00';
                        }
                    }
                    let driverName = '';
                    let vehMakeModel = '';
                    if (object.displayName) {
                        driverName = `${object.displayName} - `;
                    }
                    if (object.make && object.model) {
                        vehMakeModel = object.make + ' '+ object.model;
                    }
                    const vehicleDriverName = driverName + vehMakeModel;
                    const injuryType = _.find(object.claimDetail, (detail) => {
                        return (detail.claimType === 'Collision');
                    });

                    const month = object.claimDate.month + 1;
                    const dateString = ''.concat(month, '/', object.claimDate.day, '/', object.claimDate.year);
                    const claimDate = formatLongDate(new Date(dateString));
                    const finalDate = claimDate.substring(0, claimDate.indexOf(' ')).substring(0, 3) + claimDate.substring(claimDate.indexOf(' '));
                    let errorFlag = false;
                    if (errorArr.includes(index)) {
                        errorFlag = true;
                    }


                    const shortclaimDate = formatShortDate(new Date(dateString));


                    return {
                        [`costIncurred${index}`]: {
                            content: claimAmt,
                            visible: claimAmt !== undefined
                        },
                        [`mobileCostIncurred${index}`]: {
                            content: claimAmt,
                            visible: claimAmt !== undefined
                        },
                        [`vehicleDriverName${index}`]: {
                            content: vehicleDriverName
                        },
                        [`injuryType${index}`]: {
                            content: injuryType.claimType
                        },
                        [`mobileInjuryType${index}`]: {
                            content: injuryType.claimType
                        },
                        [`dateOfIncident${index}`]: {
                            content: finalDate,
                        },
                        [`claimDescription${index}`]: {
                            availableValues: claimDesc,
                            showErrors: errorFlag,
                            required: errorFlag

                        },
                    };
                });
        } else {
            initial = () => Promise.resolve(true);
            skipPage = true;
        }
        return Object.assign({}, ...cost);
    }, [showError, errorArr]);


    const overrideProps = {

        ...generateOverrides(),

    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onValidate
        },
        resolveComponentMap: {
            tooltipcomponent: DriverHistoryTooltip
        }
    };

    const skipDriverHistoryStep = () => {
        const driverHistoryDone = submissionVM.value.lobData.personalAuto.coverables.hasDrivingHistory;
        if ((submissionVM.value.baseData.periodStatus === 'Quoted') || !driverHistoryDone) {
            return true;
        }
        if (skipPage) {
            return initial;
        }
        return false;
    };

    return (
        <WizardPage onNext={onNext} skipWhen={skipDriverHistoryStep} userSkip={skipPage} showCancel={false} previousLabel="Back" nextLabel="Next : Quote">

            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

DriverSummary.propTypes = wizardProps;
export default DriverSummary;
