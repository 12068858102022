import { defineMessages } from 'react-intl';

export default defineMessages({

    costIncurred: {
        id: 'quoteandbind.pa.views.pa-driver-history.costIncurred',
        defaultMessage: 'cost',
    },
    vehicleName: {
        id: 'quoteandbind.pa.views.pa-driver-history.vehicleName',
        defaultMessage: 'vehicle',
    },
    injuryType: {
        id: 'quoteandbind.pa.views.pa-driver-history.InjuryType',
        defaultMessage: 'injury',
    },
    dateOfIncident: {
        id: 'quoteandbind.pa.views.pa-driver-history.DateOfIncident',
        defaultMessage: 'date',
    },
    claimDescription: {
        id: 'quoteandbind.pa.views.pa-driver-history.claimDescription',
        defaultMessage: 'Claim Description',

    },
    claimDescriptionToolTip: {
        id: 'quoteandbind.pa.views.pa-driver-history.claimDescriptionToolTip',
        defaultMessage: 'Additional information is required for this claim. Please select a description to continue.',

    },
    driverHistoryTitleToolTip: {
        id: 'quoteandbind.pa.views.pa-driver-history.driverHistoryTitleToolTip',
        defaultMessage: 'The information listed below has been pre-populated based on consumer reports and the information you provided.',

    },
    driverSummaryInfoPageSubTitle: {
        id: 'quoteandbind.pa.views.pa-driver-history.driverSummaryInfoPageSubTitle',
        defaultMessage: 'We need some additional information on the incident(s) below. Please select a description to continue.'
    }


});
