import { defineMessages } from 'react-intl';

export default defineMessages({
    driversLicenseNumber: {
        id: 'quoteandbind.pa.views.pa-confirmation.Drivers License Number',
        defaultMessage: 'Driver\'s License Number'
    },
    licenseState: {
        id: 'quoteandbind.pa.views.pa-confirmation.License State',
        defaultMessage: 'License State'
    },
    yearFirstLicensed: {
        id: 'quoteandbind.pa.views.pa-confirmation.Year First Licensed',
        defaultMessage: 'Year First Licensed'
    },
    carMakeTable: {
        id: 'quoteandbind.pa.views.pa-confirmation.Make',
        defaultMessage: 'Make'
    },
    carModelTable: {
        id: 'quoteandbind.pa.views.pa-confirmation.Model',
        defaultMessage: 'Model'
    },
    carLicensePlateTable: {
        id: 'quoteandbind.pa.views.pa-confirmation.License Plate',
        defaultMessage: 'License Plate'
    },
    coverageTable: {
        id: 'quoteandbind.pa.views.pa-confirmation.Coverage',
        defaultMessage: 'Coverage'
    },
    limitsTable: {
        id: 'quoteandbind.pa.views.pa-confirmation.Limits',
        defaultMessage: 'Limits'
    },
    firstName: {
        id: 'quoteandbind.pa.views.pa-confirmation.FirstName',
        defaultMessage: 'First Name'
    },
    lastName: {
        id: 'quoteandbind.pa.views.pa-confirmation.LastName',
        defaultMessage: 'Last Name'
    },
    phone: {
        id: 'quoteandbind.pa.views.pa-confirmation.Phone',
        defaultMessage: 'Phone'
    },
    email: {
        id: 'quoteandbind.pa.views.pa-confirmation.Email',
        defaultMessage: 'Email'
    },
    address: {
        id: 'quoteandbind.pa.views.pa-confirmation.Address',
        defaultMessage: 'Address'
    },
    driverDetails: {
        id: 'quoteandbind.pa.views.pa-confirmation.Driver Details',
        defaultMessage: 'Driver Details'
    },
    contactInformationDetails: {
        id: 'quoteandbind.pa.views.pa-confirmation.Contact Information Details',
        defaultMessage: 'Contact Information Details'
    },
    vehicleDetails: {
        id: 'quoteandbind.pa.views.pa-confirmation.Vehicle Details',
        defaultMessage: 'Vehicle Details'
    },
    coveragePremiumDetails: {
        id: 'quoteandbind.pa.views.pa-confirmation.Coverage Premium Details',
        defaultMessage: 'Coverage Premium Details'
    },
});
