import React, { useContext } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import CommonQuoteInfo from './CommonQuoteInfo/CommonQuoteInfo';

const quoteInfoComponentContext = React.createContext(CommonQuoteInfo);

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withQuoteInfoComponent(WrappedComponent) {
    function WithQuoteInfoComponent(props) {
        const currentContext = useContext(quoteInfoComponentContext);
        return <WrappedComponent {...currentContext} {...props} />;
    }
    hoistNonReactStatic(WithQuoteInfoComponent, WrappedComponent);
    WithQuoteInfoComponent.displayName = `WithQuoteInfoComponent(${getDisplayName(
        WrappedComponent
    )})`;
    return WithQuoteInfoComponent;
}

export const QuoteInfoComponentContext = quoteInfoComponentContext;
