import { defineMessages } from 'react-intl';

export default defineMessages({
    protectCar: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.HelpText.Personal Auto',
        defaultMessage: 'Protect your car with the right coverages for you.',
    },
    getQuote: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.QuickQuote',
        defaultMessage: 'Get a quote for your car insurance in three quick steps.',
    },
    buyingHome: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.Buying Home',
        defaultMessage: 'Buying home insurance that meets your needs has never been easier.',
    },
    protectFromRisks: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.HelpText.Business Owners',
        defaultMessage: 'Protect your business from a variety of risks.',
    },
    protectBusiness: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.HelpText.Guidance To Product',
        defaultMessage: 'Find the right protection for your business.',
    },
    business: {
        id: 'quoteandbind.views.Binary Choice - Business',
        defaultMessage: 'Business',
    },
    personal: {
        id: 'quoteandbind.views.Binary Choice - Personal',
        defaultMessage: 'Personal',
    },
    getGuidance: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.Get Guidance',
        defaultMessage: 'Get Guidance',
    },
    buisinessownerName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Businessowners',
        defaultMessage: 'Businessowners',
    },
    homeownerName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Homeowners',
        defaultMessage: 'Homeowners',
    },
    personalAutoName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Products.Name.Personal Auto',
        defaultMessage: 'Personal Auto',
    },
    quickQuoteName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.Name.Quick Quote',
        defaultMessage: 'Quick Quote',
    },
    guidanceToProductName: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.Features.Name.Guidance To Product',
        defaultMessage: 'Guidance To Product',
    },
    errorRetrievingQuoteModalBody: {
        id: 'quoteandbind.controllers.QuoteEntryCtrl.Sorry, but we can\'t seem to locate a quote with that number and that ZIP code. Please try again.',
        defaultMessage: 'Sorry, but we can\'t seem to locate a quote with that number and that ZIP code. Please try again.',
    },
    errorRetrievingQuoteModalTitle: {
        id: 'quoteandbind.controllers.QuoteEntryCtrl.Error retrieving submission',
        defaultMessage: 'Error retrieving submission',
    },
    errorRetrievingQuoteModalCloseButton: {
        id: 'quoteandbind.controllers.QuoteEntryCtrl.Close',
        defaultMessage: 'Close'
    },
    pageTitle: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.PageTitle.The Road to Savings Starts Here',
        defaultMessage: 'The Road to Savings Starts Here'
    },
    pageSubTitle: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.PageTitle.Start now with a free quote',
        defaultMessage: 'Start now with a free quote'
    },
    alfaCarInsuranceImage: {
        id: 'quoteandbind.controllers.ZipCodeCtrl.imageComponent.Alfa Car Insurance',
        defaultMessage: 'Alfa Car Insurance'
    },
    closeCookieMessage: {
        id: 'quoteandbind.closeCookieMessage',
        defaultMessage: ' '
    },
    cookieMessageText: {
        id: 'quoteandbind.cookeMessageText',
        defaultMessage: 'Like most websites, we use cookies and similar technologies to understand how people use our site, provide you with marketing relevant to your interests and offer you an exceptional online experience. To learn more, check out our  '
    },
    cookiePrivacyPoliyLink: {
        id: 'quoteandbind.privacyPolicyLink',
        defaultMessage: 'Privacy Policy'
    },
    cookieMessagePeriod: {
        id: 'quoteandbind.cookieMessagePeriod',
        defaultMessage: '.'
    }
});
