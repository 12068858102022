/* eslint-disable class-methods-use-this */
import { service } from '@jutro/services';
import { LocationSegmentation as LocationSegmentationJS } from 'gw-portals-segmentation-js';

export const LOCATION_SEGMENT_SERVICE_ID = 'LocationSegmentation';

export default class LocationSegmentationImpl {
    retrieveExperimentVariant({ compareBy }) {
        return LocationSegmentationJS.getExperimentValue(compareBy);
    }
}

export const LocationSegmentation = (
    service(LOCATION_SEGMENT_SERVICE_ID)(LocationSegmentationImpl)
);
