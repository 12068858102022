import { defineMessages } from 'react-intl';

export default defineMessages({
    retrieveQuote: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.Retrieve Quote',
        defaultMessage: 'Retrieve your saved quote',
    },
    retrieveQuoteDescription: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.Retrieve A Quote Help Text',
        defaultMessage: 'Please enter your ZIP code and the quote ID you have received by email to retrieve your quote.',
    },
    enterZipCode: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Enter ZIP Code',
        defaultMessage: 'Enter ZIP Code',
    },
    enterQuoteID: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Enter Quote ID',
        defaultMessage: 'Enter Quote ID',
    },
    continue: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Continue',
        defaultMessage: 'Continue',
    },
    cancel: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Cancel',
        defaultMessage: 'Cancel',
    },
    welcomeBack_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.WelcomeBack_alfa',
        defaultMessage: 'Welcome Back',
    },
    happyToSeeYouAgain_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.HappyToSeeYouAgain_alfa',
        defaultMessage: 'We\'re happy to see you again',
    },
    retrieveQuoteDescription1_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.retrieveQuoteDescription1_alfa',
        defaultMessage: 'Retrieve a quote by ',
    },
    retrieveQuoteByQuoteIdLink_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.retrieveQuoteByQuoteIdLink_alfa',
        defaultMessage: 'Quote ID',
    },
    retrieveQuoteDescription2_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.retrieveQuoteDescription2_alfa',
        defaultMessage: ' or '
    },
    retrieveQuoteByEmailLink_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.retrieveQuoteByEmailLink_alfa',
        defaultMessage: 'Email Address',
    },
    enterQuoteID_Alfa: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.enterQuoteId_alfa',
        defaultMessage: 'Quote ID',
    },
    enterZipCode_Alfa: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.enterZipCode_alfa',
        defaultMessage: 'ZIP Code',
    },
    cannotFindQuote_Alfa: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.cannotFindQuote_alfa',
        defaultMessage: 'We\'re Sorry! We can\'t find a quote with this information. Please try again or contact us at 1-800-964-2532',
    },
    enterEmailAddress_Alfa: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.enterEmailAddress_alfa',
        defaultMessage: 'Email Address',
    },
    enterDateOfBirth_Alfa: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.enterDateOfBirth_alfa',
        defaultMessage: 'Date of Birth',
    },
    enterLast4Ssn_Alfa: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.enterLast4Ssn_alfa',
        defaultMessage: 'Last 4 digits of SSN',
    },
    reCaptchaVaidationMsg_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.retrieveQuoteCaptchaValidationMsg',
        defaultMessage: 'Please resolve the Captcha to continue',
    },
    continue_Alfa: {
        id: 'quoteandbind.directives.ProductZipCodeContainer.Continue_alfa',
        defaultMessage: 'Retrieve Quote',
    },
    getNewQuoteLink_Alfa: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.getNewQuoteLink_alfa',
        defaultMessage: 'Get new quote',
    },
    foundQuotesTitle: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.foundQuotesTitle_alfa',
        defaultMessage: 'Here are the quotes we found',
    },
    foundQuotesDescription: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.foundQuotesDescription_alfa',
        defaultMessage: 'Please select a quote to continue',
    },
    foundQuotesBackLink: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.foundQuotesBackLink_alfa',
        defaultMessage: '< Back',
    },
    multiQuoteQuoteIdColHeader: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.multiQuoteQuoteIdColumnHeader_alfa',
        defaultMessage: 'Quote ID',
    },
    multiQuoteDateColHeader: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.multiQuoteDateColumnHeader_alfa',
        defaultMessage: 'Date',
    },
    multiQuoteAmountColHeader: {
        id: 'quoteandbind.directives.AvailableProductsAndFeatures.multiQuoteAmountColumnHeader_alfa',
        defaultMessage: 'Amount',
    },
    sessionExpiredTitle: {
        id: 'quoteandbind.directives.session.Your Session Has Expired',
        defaultMessage: 'Your Session Has Expired'
    },
    sessionExpiredSubTitle: {
        id: 'quoteandbind.directives.session.It\'s easy to pick up where you left off',
        defaultMessage: 'It\'s easy to pick up where you left off'
    },
    ssnErrorMsg: {
        id: 'quoteandbind.directives.retrieveQuote.Invalid. Please re-type a valid 4-digit SSN.',
        defaultMessage: 'Invalid. Please re-type a valid 4-digit SSN.'
    }
});
