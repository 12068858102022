import { defineMessages } from 'react-intl';

export default defineMessages({

    basicQuotePageTitle: {
        id: 'quoteandbind.pa.views.pa-basic-quote.basicQuotePageTitle',
        defaultMessage: 'Policy Coverage',
    },
    basicTableCaption: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Quote Table Caption',
        defaultMessage: 'Not all coverage options, limits and deductibles are shown online. Please contact us if you do not see options that fit your needs.'
    }

});
