module.exports = function (e) {
  var r = {};
  function t(n) {
    if (r[n]) return r[n].exports;
    var o = r[n] = {
      i: n,
      l: !1,
      exports: {}
    };
    return e[n].call(o.exports, o, o.exports, t), o.l = !0, o.exports;
  }
  return t.m = e, t.c = r, t.d = function (e, r, n) {
    t.o(e, r) || Object.defineProperty(e, r, {
      enumerable: !0,
      get: n
    });
  }, t.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, t.t = function (e, r) {
    if (1 & r && (e = t(e)), 8 & r) return e;
    if (4 & r && "object" == typeof e && e && e.__esModule) return e;
    var n = Object.create(null);
    if (t.r(n), Object.defineProperty(n, "default", {
      enumerable: !0,
      value: e
    }), 2 & r && "string" != typeof e) for (var o in e) t.d(n, o, function (r) {
      return e[r];
    }.bind(null, o));
    return n;
  }, t.n = function (e) {
    var r = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return t.d(r, "a", r), r;
  }, t.o = function (e, r) {
    return Object.prototype.hasOwnProperty.call(e, r);
  }, t.p = "", t(t.s = "./jutro/packages/jutro-logger/index.js");
}({
  "./jutro/packages/jutro-logger/index.js": function _jutro_packages_jutroLogger_indexJs(e, r, t) {
    "use strict";

    t.r(r);
    t("core-js/modules/es6.symbol"), t("core-js/modules/web.dom.iterable");
    var n = t("loglevel"),
      o = t("loglevel-plugin-prefix"),
      u = t.n(o),
      i = t("@jutro/config");
    function l(e, r) {
      var t = Object.keys(e);
      if (Object.getOwnPropertySymbols) {
        var n = Object.getOwnPropertySymbols(e);
        r && (n = n.filter(function (r) {
          return Object.getOwnPropertyDescriptor(e, r).enumerable;
        })), t.push.apply(t, n);
      }
      return t;
    }
    function c(e, r, t) {
      return r in e ? Object.defineProperty(e, r, {
        value: t,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[r] = t, e;
    }
    function f(e, r) {
      for (var t = 0; t < r.length; t++) {
        var n = r[t];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
      }
    }
    var a = {
      level: Object(i.getConfigValue)("JUTRO_LOGGER_LEVEL", "WARN"),
      prefixTemplate: "%l:",
      loggerName: "jutro-logger"
    };
    var s = function () {
      function e(r) {
        !function (e, r) {
          if (!(e instanceof r)) throw new TypeError("Cannot call a class as a function");
        }(this, e);
        var t = r || a;
        this.setConfig(t);
      }
      var r, t, o;
      return r = e, (t = [{
        key: "setConfig",
        value: function value(e, r) {
          var t = r ? e : function (e) {
              for (var r = 1; r < arguments.length; r++) {
                var t = null != arguments[r] ? arguments[r] : {};
                r % 2 ? l(Object(t), !0).forEach(function (r) {
                  c(e, r, t[r]);
                }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : l(Object(t)).forEach(function (r) {
                  Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
                });
              }
              return e;
            }({}, a, {}, e),
            o = t.level,
            i = t.prefixTemplate,
            f = t.loggerName;
          u.a.reg(n), u.a.apply(n, {
            template: i
          }), this.log = n.getLogger(f), this.log.setLevel(o);
        }
      }, {
        key: "resetConfig",
        value: function value() {
          this.setConfig(a);
        }
      }, {
        key: "trace",
        value: function value(e) {
          this.log.trace(e);
        }
      }, {
        key: "debug",
        value: function value(e) {
          this.log.debug(e);
        }
      }, {
        key: "info",
        value: function value(e) {
          this.log.info(e);
        }
      }, {
        key: "warning",
        value: function value(e) {
          this.log.warn(e);
        }
      }, {
        key: "error",
        value: function value(e) {
          this.log.error(e);
        }
      }]) && f(r.prototype, t), o && f(r, o), e;
    }();
    var g = new s(),
      p = function p(e) {
        g.trace(e);
      },
      b = function b(e) {
        g.debug(e);
      },
      d = function d(e) {
        g.info(e);
      },
      y = function y(e) {
        g.warning(e);
      },
      j = function j(e) {
        g.error(e);
      };
    t.d(r, "Logger", function () {
      return s;
    }), t.d(r, "log", function () {
      return g;
    }), t.d(r, "trace", function () {
      return p;
    }), t.d(r, "debug", function () {
      return b;
    }), t.d(r, "info", function () {
      return d;
    }), t.d(r, "warning", function () {
      return y;
    }), t.d(r, "error", function () {
      return j;
    });
  },
  "@jutro/config": function jutro_config(e, r) {
    e.exports = require("@jutro/config");
  },
  "core-js/modules/es6.symbol": function coreJs_modules_es6Symbol(e, r) {
    e.exports = require("core-js/modules/es6.symbol");
  },
  "core-js/modules/web.dom.iterable": function coreJs_modules_webDomIterable(e, r) {
    e.exports = require("core-js/modules/web.dom.iterable");
  },
  loglevel: function loglevel(e, r) {
    e.exports = require("loglevel");
  },
  "loglevel-plugin-prefix": function loglevelPluginPrefix(e, r) {
    e.exports = require("loglevel-plugin-prefix");
  }
});