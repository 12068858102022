import React, { Component } from 'react';
import { Panel, withModal } from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import metadata from './PaymentPopup.metadata.json5';
import messages from './PaymentPopup.messages';
import styles from './PaymentPopup.module.scss';


class PaymentPopup extends Component {
    state = {
        amountCheckBoxSelected: true
    };

    componentDidMount() {
        const { amountCheckBoxValue } = this.props;
        this.setState({ amountCheckBoxSelected: amountCheckBoxValue });
    }

    handleDismiss = (evt) => {
        // eslint-disable-next-line react/prop-types
        const { dismissAction } = this.props;
        evt.stopPropagation();
        dismissAction();
    };

    getSpanTextOne = () => {
        const { translator } = this.props;
        return (
            <div className={styles.mobileDivStyle}>
                <b>{translator(messages.popupSpanTitleOne)}</b>
                {' '}
                {translator(messages.popupSpanTextOne)}
                <br />
                <br />
                <b>{translator(messages.popupSpanTitleTwo)}</b>
                {' '}
                {translator(messages.popupSpanTextTwo)}
            </div>
        );
    };

    getSpanTextTwo = () => {
        const { translator } = this.props;
        return (
            <div>
                <b>{translator(messages.popupSpanTitleTwo)}</b>
                {' '}
                {translator(messages.popupSpanTextTwo)}
            </div>
        );
    };

    getClosebutton = () => {
        return (
            <button type="button" className={styles.closeButtonStyle}>
                <i className="fa fa-close" onKeyDown={this.handleDismiss} onClick={(e) => this.handleDismiss(e)} role="button" tabIndex="0" />
            </button>
        );
    }

    setAmount = (value) => {
        this.setState({ amountCheckBoxSelected: value });
    }

    confirmAmount = () => {
        const { amountCheckBoxSelected } = this.state;
        const { setAmount, dismissAction } = this.props;
        setAmount(amountCheckBoxSelected);
        dismissAction();
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const { amountCheckBoxSelected } = this.state;
        const overrides = {
            popupPageSpan: {
                content: this.getSpanTextOne()
            },
            popupCloseButton: {
                content: this.getClosebutton()
            },
            amountCheckBox: {
                value: amountCheckBoxSelected
            }
        };
        const resolvers = {
            resolveCallbackMap: {
                setAmount: this.setAmount,
                confirmAmount: this.confirmAmount
            },
            resolveClassNameMap: styles
        };

        return (
            <Panel id="standardizedPanel" className={styles.panelStyle}>
                {renderContentFromMetadata(metadata.pageContent, overrides, resolvers)}
            </Panel>
        );
    }
}

PaymentPopup.propTypes = {
    amountCheckBoxValue: PropTypes.bool,
    setAmount: PropTypes.func,
    dismissAction: PropTypes.func,
    translator: PropTypes.func
};

PaymentPopup.defaultProps = {
    amountCheckBoxValue: () => undefined,
    setAmount: () => undefined,
    dismissAction: true,
    translator: () => undefined
};

export default withModal(PaymentPopup);
