import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import { WizardContext } from '../WizardContext';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, InlineLoader, Tooltip } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { AgentInfoComponent } from 'gw-capability-agentinfo-react';
import { SessionTimeoutComponent } from 'gw-capability-sessiontimeout-react';
import { pageTemplateProps } from '../prop-types/wizardPropTypes';
import styles from './PADigitalWizardPageTemplate.module.scss';
import PAWizardActionbar from '../components/PAWizardActionbar';
import CRCInfoComponent from '../components/CRCInfoComponent';
import PAMobileWizardSteps from '../components/MobileWizardSteps/PAMobileWizardSteps';
let basicInfoPage = false;

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function PADigitalWizardPageTemplate(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const wizardContext = useContext(WizardContext);
    const { steps, currentStepIndex } = wizardContext;
    const isPhone = breakpoint === 'phone';
    const [showSidebar, setShowSidebar] = useState(false);
    const paths = ['/your-info'];
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        customMessageModal
    } = props;

    useEffect(() => {
        switch (breakpoint) {
            case 'phone':
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const toggleSideBar = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar]);

    const contextComponent = useCallback(() => {
        return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    }, [renderContextComponent, wizardSnapshot]);

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
    ), [showCancel, showNext, showPrevious]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const wizardClassName = classNames(styles.wizardMainStyle, {
        [styles.wizardMainShowSidebar]: showSidebar && isPhone
    });
    

    const nextButton = useMemo(() => (
        <Button
            id="gw-wizard-Next"
            icon="fa fa-arrow-right"
            iconPosition="right"
            className={styles.nextbutton}
            disabled={disableNext || isPerformingTransiton}
            onClick={onNext}
        >
            {translator(nextLabel)}
            {renderLoader(isLoadingNext)}
        </Button>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, onNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isPhone
    });
    
    basicInfoPage = paths.includes(steps[currentStepIndex].path) ? true : false;
    return (
        <div className={styles.digitalWizard}>
            {showContextComponent && contextComponent()}
            <div className={wizardClassName}>
                <div className={styles.wizardNavigation}>
                    {!isPhone || showSidebar ? (
                        <PAWizardActionbar customMessageModal={customMessageModal} />
                    ) : null}
                </div>
                <div className={styles.wizardContent}>
                    {isPhone ? <PAMobileWizardSteps toggleSideBar={toggleSideBar} /> : null}
                    {showWizardHeader && contextComponent()}
                    {content}
                    <div className={classNames({ [styles.navButtons]: shouldShowButtons })}>
                        {showCancel && (
                            <Button
                                id="gw-wizard-cancel"
                                disabled={disableCancel || isPerformingTransiton}
                                icon="fa fa-angle-left"
                                iconPosition="left"
                                type="tertiary"
                                onClick={onCancel}
                                className={styles.previousButton}
                            >
                                {translator(cancelLabel)}
                                {renderLoader(isLoadingCancel)}
                            </Button>
                        )}
                        <div className={buttonContainerClassName}>
                            {showPrevious && (
                                <Button
                                    id="qb_back"
                                    icon="fa fa-angle-left"
                                    iconPosition="left"
                                    disabled={disablePrevious || isPerformingTransiton}
                                    type="secondary"
                                    onClick={onPrevious}
                                    className={styles.previousButton}
                                >
                                    {translator(previousLabel)}
                                    {renderLoader(isLoadingPrevious)}
                                </Button>
                            )}
                            {showNext && renderNextButton}
                        </div>
                        
                    </div>
                </div>
                { basicInfoPage ?
                    <span class="yourInfoPageImgClass">
                        <img class={styles.infoPageImg} src="alfa-images/yourInfoImg.svg"/>
                    </span> : ''
                }                   
                <div className={styles.wizardWidgets}>
                    {showSidebar ? renderWidgets(wizardData) : null}
                </div>
            </div>
            <div >
            <AgentInfoComponent />
            </div>
            <SessionTimeoutComponent />
            <CRCInfoComponent />            
        </div>
    );
}

PADigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string
};

PADigitalWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    nextButtonTooltip: ''
};

export default PADigitalWizardPageTemplate;
