import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@jutro/uiconfig';
import { useValidation } from 'gw-portals-validation-react';

import metadata from './DateComponent.metadata.json5';
import styles from './DateComponent.module.scss';


function DateComponent(props) {
    const {
        value: dateVM,
        path,
        id,
        showErrors,
        errorMessage,
        onValidate,
        onValueChange,
        prevDate,
        showFocus,
        dateCheck,
        clearDate
    } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [formData, updateFormData] = useState(prevDate);
    const [disableError, setDisableError] = useState(false);
    if (clearDate) {
        _.set(formData, 'month', prevDate.month);
        _.set(formData, 'day', prevDate.day);
        _.set(formData, 'year', prevDate.year);
    }


    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, dateVM]);

    const readValue = useCallback((formPath) => _.get(formData, formPath), [formData]);

    const writeValue = useCallback((newValue, formPath) => {
        let normalisedValue = newValue;

        if (!_.isNil(newValue)) {
            normalisedValue = newValue.replace(/[^0-9]/ig, '');
        }

        const nextFormData = _.cloneDeep(formData);
        if (onValueChange) {
            if (normalisedValue !== _.get(formData, formPath)) {
                _.set(nextFormData, formPath, normalisedValue);
                updateFormData(nextFormData);
            }
        }
        if (showFocus) {
            switch (formPath) {
                case 'month':
                    if (normalisedValue.length === 2) {
                        if(month !== document.activeElement) {
                            const monthIndex = _.findIndex(month, (element) => {return element === document.activeElement});
                            if(monthIndex !== -1) {
                                day[monthIndex].focus();
                            }
                        } else {
                            day.focus();
                        }
                    }
                    break;
                case 'day':
                    if (normalisedValue.length === 2) {
                        if(day !== document.activeElement) {
                            const dayIndex = _.findIndex(day, (element) => {return element === document.activeElement});
                            if(dayIndex !== -1) {
                                year[dayIndex].focus();
                            }
                        } else {
                            year.focus();
                        }
                    }
                    break;
                default: break;
            }
        }
        onValueChange(nextFormData, path);
        if (formPath === 'year' && normalisedValue.length === 4 && !_.isNil(dateCheck)) {
            const error = dateCheck(false);
            setDisableError(!error);
        }
    }, [formData, path, onValueChange, showFocus, setDisableError]);

    const overrideProps = {
        '@field': {
        },
        day: {
            onValueChange: writeValue,
            className: !disableError && showErrors ? 'floatInput dateInput floatInputError' : 'floatInput dateInput'
        },
        month: {
            onValueChange: writeValue,
            showErrors: false,
            className: !disableError && showErrors ? 'floatInput dateInput floatInputError' : 'floatInput dateInput'
        },
        year: {
            onValueChange: writeValue,
            className: !disableError && showErrors ? 'floatInput dateInput floatInputError' : 'floatInput dateInput'
        },
        errorLabel: {
            showErrors: showErrors,
            content: errorMessage,
            visible: showErrors
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };


    return (
        <ViewModelForm
            model={formData}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />

    );
}

DateComponent.propTypes = {
    value: PropTypes.shape({}),
    prevDate: PropTypes.shape({}),
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    errorMessage: PropTypes.string,
    showFocus: PropTypes.bool,
    dateCheck: PropTypes.func,
    clearDate: PropTypes.bool
};
DateComponent.defaultProps = {
    value: {},
    prevDate: {},
    path: undefined,
    showErrors: false,
    errorMessage: '',
    showFocus: false,
    dateCheck: undefined,
    clearDate: false
};
export default DateComponent;
