module.exports = function (e) {
  var t = {};
  function r(n) {
    if (t[n]) return t[n].exports;
    var i = t[n] = {
      i: n,
      l: !1,
      exports: {}
    };
    return e[n].call(i.exports, i, i.exports, r), i.l = !0, i.exports;
  }
  return r.m = e, r.c = t, r.d = function (e, t, n) {
    r.o(e, t) || Object.defineProperty(e, t, {
      enumerable: !0,
      get: n
    });
  }, r.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, r.t = function (e, t) {
    if (1 & t && (e = r(e)), 8 & t) return e;
    if (4 & t && "object" == typeof e && e && e.__esModule) return e;
    var n = Object.create(null);
    if (r.r(n), Object.defineProperty(n, "default", {
      enumerable: !0,
      value: e
    }), 2 & t && "string" != typeof e) for (var i in e) r.d(n, i, function (t) {
      return e[t];
    }.bind(null, i));
    return n;
  }, r.n = function (e) {
    var t = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return r.d(t, "a", t), t;
  }, r.o = function (e, t) {
    return Object.prototype.hasOwnProperty.call(e, t);
  }, r.p = "", r(r.s = "./jutro/packages/jutro-services/index.js");
}({
  "./jutro/packages/jutro-services/ServiceManager.js": function _jutro_packages_jutroServices_ServiceManagerJs(e, t, r) {
    "use strict";

    (function (e) {
      r("core-js/modules/es7.symbol.async-iterator"), r("core-js/modules/es6.symbol");
      var n = r("@jutro/logger"),
        i = r("./jutro/packages/jutro-services/ServiceRegistry.js");
      function o(e, t) {
        for (var r = 0; r < t.length; r++) {
          var n = t[r];
          n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
        }
      }
      var s = function () {
        function e() {
          !function (e, t) {
            if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
          }(this, e), this.registry = new i.a();
        }
        var t, r, s;
        return t = e, (r = [{
          key: "register",
          value: function value(e, t, r) {
            this.registry.register(e, t, r);
          }
        }, {
          key: "getService",
          value: function value(e) {
            if (this.registry.hasRegistration(e)) return this.registry.getRegistration(e).getService();
            n.log.warning("No service available for id '".concat(e, "'"));
          }
        }, {
          key: "getServiceByContract",
          value: function value(e, t) {
            var r = this.registry.getImplementorsOf(e);
            if (0 === r.length) return;
            if (!t) return r.length > 1 && n.log.warning("Contract \"".concat(e, "\" has multiple implementations, but no resolver provided")), this.getService(r[0].id);
            var i = r.map(function (e) {
              return e.getMetadata();
            }).find(t);
            if (void 0 !== i) return this.getService(i.id);
            n.log.warning("Resolver callback did not find any implementations");
          }
        }, {
          key: "listRegistrations",
          value: function value() {
            n.log.info("Currently registered services: ".concat(this.registry.getRegistrationIds().join(", ")));
          }
        }]) && o(t.prototype, r), s && o(t, s), e;
      }();
      var a = Symbol.for("jutro.services.serviceManager");
      -1 === Object.getOwnPropertySymbols(e).indexOf(a) && (e[a] = new s()), t.a = e[a];
    }).call(this, r("./node_modules/webpack/buildin/global.js"));
  },
  "./jutro/packages/jutro-services/ServiceRegistry.js": function _jutro_packages_jutroServices_ServiceRegistryJs(e, t, r) {
    "use strict";

    r("core-js/modules/web.dom.iterable");
    var n = r("lodash/merge"),
      i = r.n(n),
      o = r("@jutro/logger");
    function s(e, t) {
      for (var r = 0; r < t.length; r++) {
        var n = t[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
      }
    }
    var a = function () {
      function e(t) {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, e), this.options = t;
      }
      var t, r, n;
      return t = e, (r = [{
        key: "onOption",
        value: function value(e, t) {
          var r = this.options[e];
          void 0 !== r && t(r);
        }
      }]) && s(t.prototype, r), n && s(t, n), e;
    }();
    function u(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function c(e, t) {
      for (var r = 0; r < t.length; r++) {
        var n = t[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
      }
    }
    function l(e, t, r) {
      return t && c(e.prototype, t), r && c(e, r), e;
    }
    r.d(t, "a", function () {
      return g;
    });
    var f = function () {
        function e(t, r, n) {
          u(this, e), this.id = t, this.name = r.name || r.constructor.name, this.attributes = {}, n ? this.init = r : this.service = new r();
        }
        return l(e, [{
          key: "associate",
          value: function value(e) {
            this.attributes = i()(this.attributes, e);
          }
        }, {
          key: "getMetadata",
          value: function value() {
            return i()({
              id: this.id,
              name: this.name
            }, this.attributes);
          }
        }, {
          key: "isLazy",
          value: function value() {
            return !!this.init;
          }
        }, {
          key: "initializeService",
          value: function value() {
            if (this.init) {
              var _e = this.init;
              this.service = new _e(), this.init = null;
            } else o.log.warning("Service is already instantiated");
          }
        }, {
          key: "getService",
          value: function value() {
            return this.isLazy() && this.initializeService(), this.service;
          }
        }]), e;
      }(),
      g = function () {
        function e() {
          u(this, e), this.registrations = {}, this.implementations = {};
        }
        return l(e, [{
          key: "register",
          value: function value(e, t) {
            var _this = this;
            var r = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
            if (this.registrations[e]) o.log.warning("Service with id '".concat(e, "' has already been registered"));else {
              var _n = new f(e, t, r.lazy),
                _i = new a(r);
              _i.onOption("implements", function (e) {
                (Array.isArray(e) ? e : [e]).forEach(function (e) {
                  if (!e.implementedBy(t)) throw new Error("Service ".concat(t.name || t.constructor.name, " does not implement: ").concat(e));
                  var r = e.hash();
                  _this.implementations[r] || (_this.implementations[r] = []), _this.implementations[r].push(_n);
                });
              }), _i.onOption("attributes", function (e) {
                return _n.associate(e);
              }), this.registrations[e] = _n;
            }
          }
        }, {
          key: "hasRegistration",
          value: function value(e) {
            return !!this.registrations[e];
          }
        }, {
          key: "getRegistration",
          value: function value(e) {
            return this.registrations[e];
          }
        }, {
          key: "getRegistrationIds",
          value: function value() {
            return Object.keys(this.registrations);
          }
        }, {
          key: "getImplementorsOf",
          value: function value(e) {
            var t = this.implementations[e.hash()];
            return (!t || t.length <= 0) && o.log.warning("No implementors found for: ".concat(e)), t || [];
          }
        }]), e;
      }();
  },
  "./jutro/packages/jutro-services/index.js": function _jutro_packages_jutroServices_indexJs(e, t, r) {
    "use strict";

    r.r(t);
    var n = r("./jutro/packages/jutro-services/ServiceManager.js"),
      i = function i(e) {
        var t = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        return function (r) {
          n.a.register(e, r, t);
        };
      };
    r.d(t, "ServiceManager", function () {
      return n.a;
    }), r.d(t, "service", function () {
      return i;
    });
  },
  "./node_modules/webpack/buildin/global.js": function _node_modules_webpack_buildin_globalJs(e, t) {
    var r;
    r = function () {
      return this;
    }();
    try {
      r = r || new Function("return this")();
    } catch (e) {
      "object" == typeof window && (r = window);
    }
    e.exports = r;
  },
  "@jutro/logger": function jutro_logger(e, t) {
    e.exports = require("@jutro/logger");
  },
  "core-js/modules/es6.symbol": function coreJs_modules_es6Symbol(e, t) {
    e.exports = require("core-js/modules/es6.symbol");
  },
  "core-js/modules/es7.symbol.async-iterator": function coreJs_modules_es7SymbolAsyncIterator(e, t) {
    e.exports = require("core-js/modules/es7.symbol.async-iterator");
  },
  "core-js/modules/web.dom.iterable": function coreJs_modules_webDomIterable(e, t) {
    e.exports = require("core-js/modules/web.dom.iterable");
  },
  "lodash/merge": function lodash_merge(e, t) {
    e.exports = require("lodash/merge");
  }
});