import Segmentation from './Segmentation';
import SegmentationOption from './SegmentationOption';

const segmentationMap = {
    Segmentation: { component: 'Segmentation' },
    SegmentationOption: { component: 'SegmentationOption' }
};
const segmentationComponentMap = {
    Segmentation,
    SegmentationOption
};
export {
    segmentationMap,
    segmentationComponentMap,
    // Export singularly
    Segmentation,
    SegmentationOption
};
