import {
    useState, useCallback, useRef, useEffect
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import {
    parseErrors,
    mergeErrors,
    ErrorType,
    ErrorLevel,
    UWBlockingPoint
} from 'gw-portals-edge-validation-js';

/* eslint-disable max-len */
/**
 * @typedef {import('gw-portals-edge-validation-js/errors').GenericUIIssue} GenericUIIssue
 * @typedef {import('gw-portals-edge-validation-js/errors').ValidationIssueUI} ValidationIssueUI
 * @typedef {import('gw-portals-edge-validation-js/errors').UnderwritingIssueUI} UnderwritingIssueUI
 * @typedef {import('gw-portals-edge-validation-js/errors').FieldValidationIssueUI} FieldValidationIssueUI
 * @typedef {import('gw-portals-edge-validation-js/errors').EdgeErrorsAndWarnings} EdgeErrorsAndWarnings
 */
/* eslint-enable max-len */

/**
 * Returns whether or not a certain step (which specification is passed as an argument)
 * contains references to the specified validation step
 * @param {Object} stepDetails the specification of the content of a step
 * @param {String} validationStep the name of the PAS validation step
 * @returns {boolean}
 */
function stepMatchesFlowStepId(stepDetails, validationStep) {
    if (_.isArray(stepDetails.pasFlowStepId)) {
        return stepDetails.pasFlowStepId.includes(validationStep);
    }
    return stepDetails.pasFlowStepId === validationStep;
}

/**
 * Returns whether or not a certain step (which content specification is passed as an argument)
 * contains references to the specified entity type
 * @param {Array} stepContent the specification of the content of a step
 * @param {String} entityType the name of the entity type
 * @returns {boolean}
 */
function stepContainsEntity(stepContent, entityType) {
    return stepContent.some((item) => item.entityType === entityType);
}
/**
 * Maps a validation issue to the step IDs that are affected by it
 * @param {ValidationIssueUI} error the validation issue
 * @param {Object} wizardStepToFieldMapping the mapping of fields included in each step
 * @returns {Array<String>}
 */
function mapValidationIssue(error, wizardStepToFieldMapping) {
    const { validationStep, relatedEntity } = error;
    return Object.entries(wizardStepToFieldMapping)
        .filter(([, stepDetails]) => {
            if (validationStep) {
                return stepMatchesFlowStepId(stepDetails, validationStep);
            }
            return stepContainsEntity(stepDetails.content, relatedEntity.type);
        })
        .map(([stepId]) => stepId);
}

/**
 * Returns whether or not a certain step (which content specification is passed as an argument)
 * contains references to the specified DTO and field name
 * @param {Array} stepContent the specification of the content of a step
 * @param {String} dtoName the name of the DTO
 * @param {String} dtoFieldName the name of the field
 * @returns {boolean}
 */
function stepContainsDtoField(stepContent, dtoName, dtoFieldName) {
    return stepContent
        .some((item) => item.dtoName === dtoName && item.fields.includes(dtoFieldName));
}

/**
 * Maps a field validation issue to the step IDs that are affected by it
 * @param {FieldValidationIssueUI} error the field validation issue
 * @param {Object<String,Object>} wizardStepToFieldMapping the mapping of fields
 *                                  included in each step
 * @returns {Array<String>}
 */
function mapFieldValidationIssue(error, wizardStepToFieldMapping) {
    const { relatedEntity } = error;
    const { dtoName, dtoFieldName } = relatedEntity;
    return Object.entries(wizardStepToFieldMapping)
        .filter(([, stepDetails]) => {
            return stepContainsDtoField(stepDetails.content, dtoName, dtoFieldName);
        })
        .map(([stepId]) => stepId);
}

/**
 *
 * @param {GenericUIIssue} error
 * @param {Object} wizardStepToFieldMapping
 * @returns {Array<String>} an array of step IDs
 */
function mapErrorToSteps(error, wizardStepToFieldMapping) {
    switch (error.type) {
        case ErrorType.GENERIC_VALIDATION:
            return mapValidationIssue(error, wizardStepToFieldMapping);
        case ErrorType.FIELD_VALIDATION:
            return mapFieldValidationIssue(error, wizardStepToFieldMapping);
        default:
            return [];
    }
}

/**
 * Returns a Object which keys are the stepIds with errors and the corresponding
 * values are the errors
 * **Only steps already submitted will have related errors.**
 *
 * @param {Array<GenericUIIssue>} errors errors to be displayed in the UI
 * @param {Object<String,Object>} wizardStepToFieldMapping the mapping of fields
 *                                  included in each step
 * @param {Array<String>} submittedSteps the list of submitted steps
 *                                       for which errors should be evaluated
 * @returns {Object<String, Array>}
 */
function getStepsWithErrors(errors, wizardStepToFieldMapping, submittedSteps) {
    /*
        error: error1, steps: [step 2, step6]
        error: error2, steps: [step1, step2]
     */
    const errorsAndSteps = errors
        .filter((error) => !error.isHidden) // only visible errors
        .map((error) => ({
            error,
            steps: mapErrorToSteps(error, wizardStepToFieldMapping)
        }));

    /*
        {
            step1: [error1]
            step2: [error1, error2]
            step3: [error2]
        }
     */
    const errorsByStep = _(errorsAndSteps)
        .flatMap(({ error, steps }) => {
            return steps.map((stepId) => ({ stepId, error }));
        })
        .value()
        .filter((stepAndError) => {
            return submittedSteps.includes(stepAndError.stepId);
        })
        .reduce((stepsAndErrors, currentStepAndError) => {
            const { stepId, error } = currentStepAndError;
            const existingErrorsOnStep = stepsAndErrors[stepId] || [];
            return {
                ...stepsAndErrors,
                [stepId]: existingErrorsOnStep.concat(error)
            };
        }, {});
    return errorsByStep;
}

/**
 * Returns whether an error can be acknowledged without being fixed
 * @param {GenericUIIssue} error
 * @returns {boolean}
 */
function canAcknowledgeError(error) {
    if (error.type === ErrorType.UNDERWRITING_ISSUE) {
        return false;
    }
    return error.level === ErrorLevel.LEVEL_WARN;
}

/**
 * Toggles the hidden status for the error
 * @param {GenericUIIssue} error the error to hide
 * @returns {GenericUIIssue} the error with the hidden flag
 */
function hideError(error) {
    return {
        ...error,
        isHidden: true
    };
}

/**
 * Returns a list of errors for which those with an ID in `idsToAcknowledge`
 * will be acknowledged
 * @param {Array<GenericUIIssue>} wizardErrors the initial errors
 * @param {Array<String>} idsToAcknowledge the IDs for the errors to acknowledge
 * @returns {Array<GenericUIIssue>}
 */
function acknowledgeErrors(wizardErrors, idsToAcknowledge) {
    return wizardErrors
        .map((error) => {
            if (!_.includes(idsToAcknowledge, error.id)) {
                return error;
            }
            if (!canAcknowledgeError(error)) {
                return error;
            }
            return hideError(error);
        });
}

const isUnderwritingIssue = (err) => err.type === ErrorType.UNDERWRITING_ISSUE;
const isKnockOutError = (err) => {
    return isUnderwritingIssue(err) && err.currentBlockingPoint === UWBlockingPoint.REJECTED;
};

/**
 * @callback ReportErrorsCallback
 * @param {EdgeErrorsAndWarnings} errorsAndWarnings the errors to report
 * @param {Array<String>} idsToClear the ids for the errors that should be cleared
 *
 * @typedef {Object} WizardErrorsHook
 * @property {Object<String,Array>} stepsWithErrors the steps and the errors related to them
 * @property {Array<UnderwritingIssueUI>} underwritingIssues the underwriting issues
 * @property {Array<UnderwritingIssueUI>} knockOutErrors the knockoutErrors
 * @property {Function} acknowledgeError a function to acknowledge errors
 * @property {boolean} hasNewErrors is set to *true* if the last reportErrors identified new issues
 * @property {ReportErrorsCallback} reportErrors a function to report errors
 */

/**
 * Returns data and callbacks related to the errors in a wizard
 * @param {Object<String,Object>} wizardStepToFieldMapping the specification of the
 *                                                         content of the wizard steps
 * @param {Array<String>} submittedSteps the list of submitted steps (errors
 *                                       will be surfaced only for these)
 * @returns {WizardErrorsHook}
 */
function useWizardErrors(wizardStepToFieldMapping, submittedSteps) {
    const { flowStepsValidationEnabled } = appConfig;
    const [wizardErrors, updateWizardErrors] = useState([]);
    const previousErrors = useRef({});
    const stepsWithErrors = getStepsWithErrors(
        wizardErrors,
        wizardStepToFieldMapping,
        submittedSteps
    );
    const hasNewErrors = !_.isEmpty(stepsWithErrors)
        && !_.isEqual(previousErrors.current, stepsWithErrors);

    useEffect(() => {
        previousErrors.current = stepsWithErrors;
    }, [stepsWithErrors, wizardErrors]);

    const reportErrors = useCallback(
        (errorsAndWarnings, idsToClear = []) => {
            const newErrors = parseErrors(errorsAndWarnings)
                .filter((issue) => !idsToClear.includes(issue.id));
            const mergedErrors = mergeErrors(newErrors, wizardErrors);

            updateWizardErrors(mergedErrors);

            return !_.isEmpty(
                getStepsWithErrors(mergedErrors, wizardStepToFieldMapping, submittedSteps)
            );
        },
        [wizardErrors, wizardStepToFieldMapping, submittedSteps]
    );

    const acknowledge = useCallback(
        (ids) => {
            const idsToAcknowledge = _.isArray(ids) ? ids : [ids];
            const newErrors = acknowledgeErrors(wizardErrors, idsToAcknowledge);
            updateWizardErrors(newErrors);
        },
        [wizardErrors]
    );

    return {
        stepsWithErrors: getStepsWithErrors(wizardErrors, wizardStepToFieldMapping, submittedSteps),
        underwritingIssues: wizardErrors.filter(isUnderwritingIssue),
        knockOutErrors: wizardErrors.filter(isKnockOutError),
        acknowledgeError: flowStepsValidationEnabled ? acknowledge : _.noop,
        reportErrors: flowStepsValidationEnabled ? reportErrors : _.stubFalse,
        hasNewErrors
    };
}

export default useWizardErrors;
