import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverFirstName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.First Name',
        defaultMessage: 'First Name',
    },
    paDriverLastName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Last Name',
        defaultMessage: 'Last Name',
    },
    paDriverSuffix: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Suffix',
        defaultMessage: 'Suffix',
    },
    paDriverDateOfBirth: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Date of Birth',
        defaultMessage: 'Date of Birth',
    },
    paDriverGender: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Gender',
        defaultMessage: 'Gender',
    },
    paDriverGenderError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Gender Error',
        defaultMessage: 'Please select a gender',
    },
    paDriverMarital: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Marital Status',
        defaultMessage: 'Marital Status',
    },
    paDriverRelationship: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.RelationshipToPNI',
        defaultMessage: 'Relationship To You',
    },
    paDriverOccupation: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Occupation',
        defaultMessage: 'Occupation',
    },
    paDriverDontWish: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Donot Wish to Insure',
        defaultMessage: 'I do not wish to insure this driver',
    },
    paDriverExcluded: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.This driver will be excluded',
        defaultMessage: 'This driver will be excluded',
    },
    paDriverHasInsurance: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Have auto insurance',
        defaultMessage: 'Have you had auto insurance coverage continuously for the past 6 months? '
    },
    tooltipHasInsurance: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipHasInsurance',
        defaultMessage: 'If yes, you will be required to provide proof of insurance to your agent upon purchase.'
    },
    tooltipCourse: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipCourse',
        defaultMessage: 'Click Me!'
    },
    tooltipOtherInsurance: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipOtherInsurance',
        defaultMessage: "To qualify for this discount the driver must be age 16-20, must take Alfa's online test and score a 70 or above and have no chargeable accidents or violations in the past 3 years"
    },
    currentInsurancyCmpy: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.CurrentInsurancyCmpy',
        defaultMessage: 'Current Insurance Company'
    },
    currentInsurancyCmpyError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Please select current insurance company',
        defaultMessage: 'Please select current insurance company'
    },
    driverHonor: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Is Honor Student',
        defaultMessage: 'Honor Student'
    },
    driverHonorError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Please select Honor Student',
        defaultMessage: 'Please select Honor Student'
    },
    driverSelect: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Select',
        defaultMessage: 'Select'
    },
    driverCommodities: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Commodities',
        defaultMessage: 'Commodities (Select up to 3)'
    },
    driverCommoditiesError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Commodities Error',
        defaultMessage: 'Please select atleast one commodity'
    },
    driverTrainingError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Please select completed driver training',
        defaultMessage: 'Please select completed driver training'
    },
    driverYouthDefenseError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Please select youth defensive driver',
        defaultMessage: 'Please select youth defensive driver'
    },
    driverDefenseError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Please select defensive driver',
        defaultMessage: 'Please select defensive driver'
    },
    tooltipHonor: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipHonor',
        defaultMessage: 'A youthful driver, aged 16-24, who is a full-time student with a 3.0 GPA or higher may qualify for this discount.'
    },
    otherInsurancyCmpy: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Other Insurance Company',
        defaultMessage: 'Other Insurance Company'
    },
    driverTraining: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Has Driver Training Course',
        defaultMessage: 'Driving Training Course'
    },
    tooltipTraining: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipTraining',
        defaultMessage: 'Drivers between the ages of 16 to 20 who have completed an acceptable driver education course may qualify for this discount. May not be combined with Youth Defensive Driver Discount.'
    },
    youthSafetyLabel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.youthSafetyDiscount',
        defaultMessage: 'Youth Safety Discount'
    },
    tooltipTrainingGa: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipTrainingGa',
        defaultMessage: 'Drivers between the ages of 16 to 20 who have completed an acceptable driver education course may qualify for this discount. May not be combined with Youth Defensive Driver Discount.'
    },
    tooltipYouth: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipYouth',
        defaultMessage: 'Click Me!.'
    },
    courseError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.courseError',
        defaultMessage: 'Please select completed course'
    },
    youthSafetyError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.youthSafetyError',
        defaultMessage: 'Please select Youth Safety Discount'
    },
    driverTrainingLabelGa: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Has the Driver completed a Training Course',
        defaultMessage: 'Has the driver completed a training course?'
    },
    driverYouthDefense: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Youth Defensive Driver',
        defaultMessage: 'Youth Defensive Driver'
    },
    tooltipYouthDefense: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipYouthDefense',
        defaultMessage: 'This discount is available for drivers who have no at-fault accidents or moving violations and have passed the Youth Defensive Driver Test. The test can be accessed Here (https://www.proprofs.com/quiz-school/story.php?title=youth-defensive-driver-discount-al) and must be passed to keep the discount after you purchase your policy.'
    },
    driverDefense: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Defensive Driver',
        defaultMessage: 'Defensive Driver'
    },
    tooltipDefense: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipDefense',
        defaultMessage: 'This discount is available for drivers who have no at-fault accidents or moving violations and have taken a qualified Motor Vehicle Accident Prevention Course. Documentation of the course must be provided once you purchase your policy in order to keep the discount.'
    },
    driverTicketsViolation: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Has Tickets or Violations',
        defaultMessage: 'Tickets/Violations in the last 3 years',
    },
    driverMajorViolation: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Major Violations',
        defaultMessage: 'Major Violations'
    },
    driverMajorViolationInfo: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Major Violations Info',
        defaultMessage: '(Reckless driving, DUI, speeding 25 mph or more over the limit, etc.)'
    },
    tooltipMajorViolation: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipMajorViolation',
        defaultMessage: 'Eluding police, speeding 25 mph or more over the limit, failure to stop after accident, racing or participating in a race, reckless driving, DUI, DWI, vehicular manslaughter.'
    },
    driverMinorViolation: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Minor Violations',
        defaultMessage: 'Minor Violations'
    },
    driverViolationError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Please add Major Minor violations',
        defaultMessage: 'Please add Major/Minor violations'
    },
    driverMinorViolationInfo: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Minor Violations Info',
        defaultMessage: '(Speeding less than 25 mph over the limit, failure to yield, running a stop sign, etc.)'
    },
    tooltipMinorViolation: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.tooltipMinorViolation',
        defaultMessage: 'Speeding less than 25 mph over the limit, improper turn, failure to yield right of way, running a stop sign or traffic signal, following too closely.'
    },
    paDriverGenderPlaceholder: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Driver Gender --',
        defaultMessage: '-- Choose Driver Gender --',
    },
    paDriversLicenseNumber: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Drivers License Number',
        defaultMessage: 'Drivers License Number',
    },
    paDriverLicenceState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.License State',
        defaultMessage: 'License State',
    },
    paDriverYearFirstLicensed: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Year First Licensed',
        defaultMessage: 'Year First Licensed',
    },
    paDriverNumberOfAccidents: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Accidents',
        defaultMessage: 'Number Of Accidents',
    },
    paDriverNumberOfViolations: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Violations',
        defaultMessage: 'Number Of Violations',
    },
    paDriverLicenseYear: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License Year --',
        defaultMessage: '-- Choose License Year --',
    },
    paDriverLicenseState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License State --',
        defaultMessage: '-- Choose License State --',
    },
    driverMaritalError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Marital Status Error',
        defaultMessage: 'Please select a marital status',
    }
});
