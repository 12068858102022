module.exports = function (e) {
  var o = {};
  function t(r) {
    if (o[r]) return o[r].exports;
    var n = o[r] = {
      i: r,
      l: !1,
      exports: {}
    };
    return e[r].call(n.exports, n, n.exports, t), n.l = !0, n.exports;
  }
  return t.m = e, t.c = o, t.d = function (e, o, r) {
    t.o(e, o) || Object.defineProperty(e, o, {
      enumerable: !0,
      get: r
    });
  }, t.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, t.t = function (e, o) {
    if (1 & o && (e = t(e)), 8 & o) return e;
    if (4 & o && "object" == typeof e && e && e.__esModule) return e;
    var r = Object.create(null);
    if (t.r(r), Object.defineProperty(r, "default", {
      enumerable: !0,
      value: e
    }), 2 & o && "string" != typeof e) for (var n in e) t.d(r, n, function (o) {
      return e[o];
    }.bind(null, n));
    return r;
  }, t.n = function (e) {
    var o = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return t.d(o, "a", o), o;
  }, t.o = function (e, o) {
    return Object.prototype.hasOwnProperty.call(e, o);
  }, t.p = "", t(t.s = "./jutro/packages/jutro-translator/index.js");
}({
  "./jutro/packages/jutro-translator/index.js": function _jutro_packages_jutroTranslator_indexJs(e, o, t) {
    "use strict";

    t.r(o), function (e) {
      t.d(o, "setTranslations", function () {
        return g;
      }), t.d(o, "hasDisplayKey", function () {
        return y;
      }), t.d(o, "addTranslations", function () {
        return m;
      }), t.d(o, "translateDisplayKey", function () {
        return h;
      }), t.d(o, "translateDisplayKeyWithFallback", function () {
        return _;
      });
      t("core-js/modules/web.dom.iterable"), t("core-js/modules/es6.regexp.replace"), t("core-js/modules/es7.symbol.async-iterator"), t("core-js/modules/es6.symbol");
      var r = t("lodash/keys"),
        n = t.n(r),
        s = t("./jutro/packages/jutro-translator/node_modules/lodash/isObject.js"),
        a = t.n(s),
        u = t("./jutro/packages/jutro-translator/node_modules/lodash/isString.js"),
        l = t.n(u),
        c = t("@jutro/logger");
      function i(e, o) {
        var t = Object.keys(e);
        if (Object.getOwnPropertySymbols) {
          var r = Object.getOwnPropertySymbols(e);
          o && (r = r.filter(function (o) {
            return Object.getOwnPropertyDescriptor(e, o).enumerable;
          })), t.push.apply(t, r);
        }
        return t;
      }
      function d(e) {
        for (var o = 1; o < arguments.length; o++) {
          var t = null != arguments[o] ? arguments[o] : {};
          o % 2 ? i(Object(t), !0).forEach(function (o) {
            j(e, o, t[o]);
          }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : i(Object(t)).forEach(function (o) {
            Object.defineProperty(e, o, Object.getOwnPropertyDescriptor(t, o));
          });
        }
        return e;
      }
      function j(e, o, t) {
        return o in e ? Object.defineProperty(e, o, {
          value: t,
          enumerable: !0,
          configurable: !0,
          writable: !0
        }) : e[o] = t, e;
      }
      var p = Symbol.for("jutro.translator.displayKeys"),
        f = function f() {
          return e[p] || {};
        },
        b = function b(e, o) {
          var t = f();
          return t.hasOwnProperty(e) ? t[e] : o || e;
        },
        g = function g(o) {
          e[p] = d({}, o);
        },
        y = function y(o) {
          return e[p] && e[p].hasOwnProperty(o);
        },
        m = function m(o) {
          e[p] = d({}, f(), {}, o);
        },
        h = function h(e) {
          return b(e, "displaykey not found");
        },
        _ = function _(e, o) {
          return Object(c.warning)('The function "translateDisplayKeyWithFallback" has been deprecated - please use TranslatorProvider instead.'), k(e) ? l()(e) ? O(e, null, o) : O(e.id, e.defaultMessage, o) : e;
        },
        O = function O(e, o) {
          var r = b(e, o);
          for (var _len = arguments.length, t = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
            t[_key - 2] = arguments[_key];
          }
          return t.length && t.forEach(function (e, o) {
            a()(e) ? n()(e).forEach(function (o) {
              r = r.replace("{".concat(o, "}"), e[o]);
            }) : r = r.replace("{_".concat(o, "}"), e);
          }), r;
        },
        k = function k(e) {
          return l()(e) || v(e);
        },
        v = function v(e) {
          return a()(e) && l()(e.id) && l()(e.defaultMessage);
        };
      o.default = _;
    }.call(this, t("./node_modules/webpack/buildin/global.js"));
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/_Symbol.js": function _jutro_packages_jutroTranslator_node_modules_lodash__SymbolJs(e, o, t) {
    var r = t("./jutro/packages/jutro-translator/node_modules/lodash/_root.js").Symbol;
    e.exports = r;
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/_baseGetTag.js": function _jutro_packages_jutroTranslator_node_modules_lodash__baseGetTagJs(e, o, t) {
    var r = t("./jutro/packages/jutro-translator/node_modules/lodash/_Symbol.js"),
      n = t("./jutro/packages/jutro-translator/node_modules/lodash/_getRawTag.js"),
      s = t("./jutro/packages/jutro-translator/node_modules/lodash/_objectToString.js"),
      a = r ? r.toStringTag : void 0;
    e.exports = function (e) {
      return null == e ? void 0 === e ? "[object Undefined]" : "[object Null]" : a && a in Object(e) ? n(e) : s(e);
    };
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/_freeGlobal.js": function _jutro_packages_jutroTranslator_node_modules_lodash__freeGlobalJs(e, o, t) {
    (function (o) {
      var t = "object" == typeof o && o && o.Object === Object && o;
      e.exports = t;
    }).call(this, t("./node_modules/webpack/buildin/global.js"));
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/_getRawTag.js": function _jutro_packages_jutroTranslator_node_modules_lodash__getRawTagJs(e, o, t) {
    var r = t("./jutro/packages/jutro-translator/node_modules/lodash/_Symbol.js"),
      n = Object.prototype,
      s = n.hasOwnProperty,
      a = n.toString,
      u = r ? r.toStringTag : void 0;
    e.exports = function (e) {
      var o = s.call(e, u),
        t = e[u];
      try {
        e[u] = void 0;
        var r = !0;
      } catch (e) {}
      var n = a.call(e);
      return r && (o ? e[u] = t : delete e[u]), n;
    };
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/_objectToString.js": function _jutro_packages_jutroTranslator_node_modules_lodash__objectToStringJs(e, o) {
    var t = Object.prototype.toString;
    e.exports = function (e) {
      return t.call(e);
    };
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/_root.js": function _jutro_packages_jutroTranslator_node_modules_lodash__rootJs(e, o, t) {
    var r = t("./jutro/packages/jutro-translator/node_modules/lodash/_freeGlobal.js"),
      n = "object" == typeof self && self && self.Object === Object && self,
      s = r || n || Function("return this")();
    e.exports = s;
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/isArray.js": function _jutro_packages_jutroTranslator_node_modules_lodash_isArrayJs(e, o) {
    var t = Array.isArray;
    e.exports = t;
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/isObject.js": function _jutro_packages_jutroTranslator_node_modules_lodash_isObjectJs(e, o) {
    e.exports = function (e) {
      var o = typeof e;
      return null != e && ("object" == o || "function" == o);
    };
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/isObjectLike.js": function _jutro_packages_jutroTranslator_node_modules_lodash_isObjectLikeJs(e, o) {
    e.exports = function (e) {
      return null != e && "object" == typeof e;
    };
  },
  "./jutro/packages/jutro-translator/node_modules/lodash/isString.js": function _jutro_packages_jutroTranslator_node_modules_lodash_isStringJs(e, o, t) {
    var r = t("./jutro/packages/jutro-translator/node_modules/lodash/_baseGetTag.js"),
      n = t("./jutro/packages/jutro-translator/node_modules/lodash/isArray.js"),
      s = t("./jutro/packages/jutro-translator/node_modules/lodash/isObjectLike.js");
    e.exports = function (e) {
      return "string" == typeof e || !n(e) && s(e) && "[object String]" == r(e);
    };
  },
  "./node_modules/webpack/buildin/global.js": function _node_modules_webpack_buildin_globalJs(e, o) {
    var t;
    t = function () {
      return this;
    }();
    try {
      t = t || new Function("return this")();
    } catch (e) {
      "object" == typeof window && (t = window);
    }
    e.exports = t;
  },
  "@jutro/logger": function jutro_logger(e, o) {
    e.exports = require("@jutro/logger");
  },
  "core-js/modules/es6.regexp.replace": function coreJs_modules_es6RegexpReplace(e, o) {
    e.exports = require("core-js/modules/es6.regexp.replace");
  },
  "core-js/modules/es6.symbol": function coreJs_modules_es6Symbol(e, o) {
    e.exports = require("core-js/modules/es6.symbol");
  },
  "core-js/modules/es7.symbol.async-iterator": function coreJs_modules_es7SymbolAsyncIterator(e, o) {
    e.exports = require("core-js/modules/es7.symbol.async-iterator");
  },
  "core-js/modules/web.dom.iterable": function coreJs_modules_webDomIterable(e, o) {
    e.exports = require("core-js/modules/web.dom.iterable");
  },
  "lodash/keys": function lodash_keys(e, o) {
    e.exports = require("lodash/keys");
  }
});