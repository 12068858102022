import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicleVIN: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.VIN',
        defaultMessage: 'VIN',
    },
    paVehicleMake: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Make',
        defaultMessage: 'Make',
    },
    paVehicleModel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Model',
        defaultMessage: 'Model',
    },
    paVehicleYear: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Year',
        defaultMessage: 'Year',
    },
    paVehicleVinTooltip: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.paVehicleVinTooltip',
        defaultMessage: 'A VIN is a vehicle identification number that is specific to each vehicle. A VIN is composed of 17 characters and can usually be found on the driver\'s-side interior dash, on the title or on the vehicle registration.',
    },
    yearError: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.yearError',
        defaultMessage: 'If you\'re vehicle was made before 1981, please call us at 1-800-964-2532 to complete your quote'
    },
    paVehicleVehicleType: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Vehicle Type',
        defaultMessage: 'Vehicle Type',
    },
    paVehicleYearPlaceholder: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.-- Choose Vehicle Year --',
        defaultMessage: '-- Choose Vehicle Year --',
    },
    representativeImage: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vehiclePhotoMsg',
        defaultMessage: 'This is a representative image of a vehicle of the same year, make, and model as yours. Details, such as color can be different.',
    },
    representativeScreenReader: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Vehicle Image',
        defaultMessage: 'Vehicle Image',
    },
    representativeImagePrefix: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Note:',
        defaultMessage: 'Note:',
    },
    vinInfoText: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.The vehicle identification number (VIN) is unique to each car and contains information about its specific features, brand, and manufacturer.',
        defaultMessage: 'The vehicle identification number (VIN) is unique to each car and contains information about its specific features, brand, and manufacturer.'
    },
    leaseRent: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Do you own, lease or finance your vehicle?',
        defaultMessage: 'Do you own, lease or finance your vehicle?'
    },
    garageAddressQuestion: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Is this vehicle primarily kept at',
        defaultMessage: 'Is this vehicle primarily kept at'
    },
    garageAddress: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Garaging Address',
        defaultMessage: 'Garaging Address'
    },
    firstVinSection: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Search by',
        defaultMessage: 'Search by '
    },
    ymmLink: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.YMM',
        defaultMessage: 'Year, Make, Model'
    }
});
