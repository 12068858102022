import React, {
    useState, useCallback, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Panel, Button, withModal } from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { Grid } from '@jutro/layout';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';
import generateSubComponents from './ScheduleItemTypes/ScheduleItemTypes';
import messages from './ScheduleItemsComponent.messages';
import styles from './ScheduleItemModalPopover.module.scss';

function ScheduleItemModalPopover(props) {
    const {
        propertyInfo,
        viewModelService,
        scheduleData,
        dismissAction,
        closeAction,
        scheduleItem,
        labelPosition,
        id,
        isNew
    } = props;
    const translator = useContext(TranslatorContext);
    const localeService = ServiceManager.getService('locale-service');
    const [modalData, setModalData] = useState(scheduleData);
    const { isComponentValid, onValidate } = useValidation(id);

    const modalOverrides = {
        '@field': {
            labelPosition,
            showOptional: true
        }
    };

    const handleChange = useCallback(
        (value, path) => {
            const clonedData = _.cloneDeep(modalData);
            const newPath = ViewModelUtil.getNonVMPath(path);
            _.set(clonedData, newPath, value);
            setModalData(clonedData);
        },
        [modalData]
    );

    const orderedPropertyInfo = useMemo(() => _.sortBy(propertyInfo, 'order'), [propertyInfo]);
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const subComponents = orderedPropertyInfo.flatMap((info) => generateSubComponents({
        info,
        modalData,
        viewModelService,
        translator,
        defaultCountryCode,
        scheduleItem,
        onValueChange: handleChange,
        onValidate,
        parentOverrides: modalOverrides,
        isNew
    }));

    const handleAdd = useCallback(() => {
        closeAction(modalData);
    }, [closeAction, modalData]);

    const modalOkLabel = useMemo(() => {
        return isNew ? messages.scheduleAdd : messages.save;
    }, [isNew]);

    const handleDismiss = useCallback(
        (evt) => {
            evt.stopPropagation();
            dismissAction();
        },
        [dismissAction]
    );

    return (
        <Panel id={id}>
            <h2 className="wizardTitle">{scheduleItem.displayName}</h2>
            <Grid tag="div">
                {subComponents}
                <div className={styles.buttonContainer}>
                    <Button onClick={handleDismiss} type="secondary">
                        {messages.scheduleCancel}
                    </Button>
                    <Button onClick={handleAdd} disabled={!isComponentValid}>
                        {modalOkLabel}
                    </Button>
                </div>
            </Grid>
        </Panel>
    );
}

ScheduleItemModalPopover.propTypes = {
    propertyInfo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    scheduleData: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    id: PropTypes.string.isRequired,
    dismissAction: PropTypes.func,
    closeAction: PropTypes.func,
    scheduleItem: PropTypes.shape({
        displayName: PropTypes.string
    }).isRequired,
    viewModelService: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool.isRequired
};
ScheduleItemModalPopover.defaultProps = {
    scheduleData: {},
    labelPosition: 'left',
    dismissAction: undefined,
    closeAction: undefined
};
export default withModal(ScheduleItemModalPopover);
