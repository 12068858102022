
import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class RecaptchaService {
    static validateCaptchaWithBackend(frontendToken, debugging, additionalHeaders = {}) {
        if (debugging) { console.log('*** validating recaptcha token with the backend ***'); }
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'validateCaptcha',
            [frontendToken],
            additionalHeaders
        );
    }
}
