import React from 'react';
import PropTypes from 'prop-types';

import { AuthProvider, SecureRoute, AuthContext } from '@jutro/auth';
import { Loader } from '@jutro/components';
import { AuthContext as DigitalAuthContext } from './AuthenticationContext';

import contextTransformer from './contextTransformer';

class DigitalAuthContextWrapper extends React.Component {
    state = {
        digitalAuthContext: {}
    }

    componentWillReceiveProps = (nextProps) => {
        const { jutroAuthContext = {} } = nextProps;
        const { jutroAuthContext: prevJutroAuthContext = {}, onAuthDataCreation } = this.props;
        if (jutroAuthContext.authenticated !== prevJutroAuthContext.authenticated) {
            contextTransformer.getDigitalAuthContext(jutroAuthContext)
                .then(async (digitalAuthContext) => {
                    const authUserData = await onAuthDataCreation(
                        digitalAuthContext,
                        digitalAuthContext.authHeader
                    );
                    const combinedAuthContext = Object.assign({ authUserData }, digitalAuthContext);

                    this.setState({ digitalAuthContext: combinedAuthContext });
                }).catch((e) => {
                    console.error('AccessTokenError', e);
                });
        }
    }

    render() {
        const { digitalAuthContext = {} } = this.state;
        const { children } = this.props;
        if (digitalAuthContext && digitalAuthContext.isLoggedIn) {
            return (
                <DigitalAuthContext.Provider value={digitalAuthContext}>
                    {children}
                </DigitalAuthContext.Provider>
            );
        }

        return <Loader />;
    }
}

DigitalAuthContextWrapper.propTypes = {
    jutroAuthContext: PropTypes.shape({
        authenticated: PropTypes.bool.isRequired,
        userInfo: PropTypes.shape({
            sub: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            email_verified: PropTypes.bool.isRequired,
            updated_at: PropTypes.number.isRequired
        }),
        getAccessToken: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired
    }).isRequired,
    onAuthDataCreation: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
};

function CloudAuthContextProvider({ children, onAuthDataCreation }) {
    return (
        <AuthProvider>
            <SecureRoute
                path="/"
                render={() => (
                    <AuthContext.Consumer>
                        {(jutroAuthContext) => {
                            return (
                                <DigitalAuthContextWrapper
                                    jutroAuthContext={jutroAuthContext}
                                    onAuthDataCreation={onAuthDataCreation}
                                >
                                    {children}
                                </DigitalAuthContextWrapper>
                            );
                        }}
                    </AuthContext.Consumer>
                )}
            />
        </AuthProvider>
    );
}

CloudAuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    onAuthDataCreation: PropTypes.func.isRequired,
};

export default CloudAuthContextProvider;
