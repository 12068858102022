import React, {
    useEffect, useCallback, useRef, useContext
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { ModalService } from '@jutro/components';
import PropTypes from 'prop-types';
import { WizardContext } from 'gw-portals-wizard-react';
import ErrorHandlingUtil from '../../../../pages/Utils/ErrorHandlingUtil';
import messages from './SessionTimeoutComponent.messages';
import styles from './SessionTimeoutComponent.module.scss';

const SessionTimeoutComponent = (props) => {
    const {
        history, signDocuments, docuSignInd, submissionObj
    } = props;
    const wizardContext = useContext(WizardContext);
    const wizardData = _.get(wizardContext, 'wizardData') ? _.get(wizardContext, 'wizardData') : {};
    const signoutTime = 1 * 60 * 1000;
    const warningTime = 19 * 60 * 1000;
    const sessionModal = useRef({});
    const events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress',
        'wheel'
    ];
    let warnTimeout;
    let logoutTimeout;
    let clearTimeouts;
    let setTimeouts;
    let logout;
    let page = 'Payment Confirmation Page';

    const warn = () => {
        logoutTimeout = setTimeout(logout, signoutTime);
        const modal = ModalService.showWarning({
            title: messages.sessionTimeout,
            message: messages.warningAboutToExpire,
            messageProps: {
                ok: messages.continueSession,
                close: messages.cancelSession
            }
        });
        sessionModal.current = modal;
        modal.result.then(() => {
            clearTimeouts();
            setTimeouts();
            if (docuSignInd) {
                signDocuments();
            }
        }).catch((errorResponse) => {
                if (docuSignInd) {
                    const error = {
                    docuSignInd: docuSignInd,
                    quoteStatus: 'docusign',
                    errorDescription: errorResponse === 'logout' ? 'Session Timeout' : 'Session abandoned by user'
                    };
                    ErrorHandlingUtil.processErrorResponse(props, error, submissionObj, page);
                } else {
                    history.push({
                        pathname: '/',
                        state: {
                            isSessionTimedOut: true,
                            quoteId: _.get(wizardData, 'quoteID.value') || props.quoteID
                        }
                    });
                }
            
        });
        return modal;
    };

    const resetTimeout = useCallback(() => {
        clearTimeouts();
        setTimeouts();
    }, [clearTimeouts, setTimeouts]);

    logout = () => {
        sessionModal.current.dismiss('logout');
    };

    setTimeouts = () => {
        warnTimeout = setTimeout(warn, warningTime);
    };

    clearTimeouts = () => {
        if (warnTimeout) clearTimeout(warnTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(events[event], resetTimeout);
        });
        setTimeouts();
        return () => {
            events.forEach((event) => {
                window.removeEventListener(events[event], resetTimeout);
                clearTimeouts();
            });
        };
    }, [clearTimeouts, events, resetTimeout, setTimeouts]);

    return <div />;
};
SessionTimeoutComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.shape({})
    }),
    docuSignInd: PropTypes.bool,
    signDocuments: PropTypes.func,
    submissionObj: PropTypes.shape({}),
    quoteID: PropTypes.string
};
SessionTimeoutComponent.defaultProps = {
    history: undefined,
    docuSignInd: undefined,
    signDocuments: undefined,
    submissionObj: undefined,
    quoteID: undefined
};

export default withRouter(SessionTimeoutComponent);
