import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class EmailQuoteService {
    static emailQuote(quoteEmailDTO, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'emailQuote',
            [quoteEmailDTO],
            additionalHeaders
        );
    }

    static getPaymentusToken(submissionID, pacIncluded, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('paymentus'),
            'getPaymentusToken',
            [submissionID, pacIncluded],
            additionalHeaders
        );
    }

    static retrieveSubmissionConfirmation(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'retrieveSubmissionConfirmation',
            [policyNumber],
            additionalHeaders
        );
    }

    static validateTransaction(quoteId, transactionId, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('paymentus'),
            'validateTransaction',
            [quoteId, transactionId],
            additionalHeaders
        );
    }

    static getAgentInfo(inputParam, state, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('searchAgent'),
            'searchAgent',
            [inputParam, state],
            additionalHeaders
        );
    }

    static updateAgent(request, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('searchAgent'),
            'updateAgent',
            [request],
            additionalHeaders
        );
    }

    static sendQuoteBindEmail(submission, additionalHeaders = {})  {

        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'sendQuoteBindEmail',
            [submission],
            additionalHeaders
        );
    }
}
