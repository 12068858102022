import AgentInfoComponent from './AgentInfoComponent/AgentInfoComponent';

const AgentInfoComponentMap = {
    AgentInfoComponent: { component: 'AgentInfoComponent' }
};

const AgentInfoComponents = {
    AgentInfoComponent
};

export {
    AgentInfoComponentMap,
    AgentInfoComponents,
    // export single component
    AgentInfoComponent
};
export default AgentInfoComponent;
