import React, { Component } from 'react';
import { Panel, withModal } from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import _ from 'lodash';
import metadata from './AddressStdPopover.metadata.json5';
import styles from './AddressStdPopover.module.scss';
import './AddressStdPopover.messages';

class AddressStdPopover extends Component {
    state = {
        selectedAddress: {}
    };

    componentDidMount() {
        const { addressSuggestions } = this.props;
        const selectedAddress = addressSuggestions[1];
        this.setState({ selectedAddress: selectedAddress });
    }

    handleDismiss = (evt) => {
        // eslint-disable-next-line react/prop-types
        const { dismissAction } = this.props;
        evt.stopPropagation();
        dismissAction();
    }

    handleSelect = () => {
        const { closeAction } = this.props;
        closeAction(this.state);
    }

    handleChange = (value) => {
        // eslint-disable-next-line react/prop-types
        const { addressSuggestions } = this.props;
        let selectionKey;
        if (value === 'AsEntered') {
            selectionKey = 0;
        } else {
            selectionKey = 1;
        }
        const selectedAddress = addressSuggestions[selectionKey];
        this.setState({ selectedAddress: selectedAddress });
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const { addressSuggestions } = this.props;
        const { selectedAddress } = this.state;

        const NoStandardAddress = (addressSuggestions.length < 2) ? true : false;

        let valIndexForRadio2 = 1;
        if (NoStandardAddress) {
            valIndexForRadio2 = 0;
        }

        // determine which radio button to set:
        let valueForAsEntered; // deselects both radio buttons
        let valueForStandard; // deselect both radio buttons
        if (selectedAddress === addressSuggestions[0]) {
            valueForAsEntered = 'AsEntered'; // select AsEntered option
        } else {
            valueForStandard = 'Standardized'; // select Standardized option
        }

        const overrides = {
            alfaHeader: {
            },
            addrPopoverHeaderIconSpan: {
            },
            addrPopoverTitle: {
            },
            instructions: {
            },
            addressAsEntered: {
                // eslint-disable-next-line react/prop-types
                availableValues: [addressSuggestions[0]].map((singleAddress, index) => {
                    return {
                        code: 'AsEntered',
                        name: <p className={styles.labelSpace}>{singleAddress.addressLine1}<br/>{singleAddress.city}, {singleAddress.state}<br/>{singleAddress.postalCode}</p>
                    };
                }),
                hideLabel: false,
                onValueChange: this.handleChange,
                value: valueForAsEntered
            },
            standardizedAddress: {
                // eslint-disable-next-line react/prop-types
                availableValues: [addressSuggestions[valIndexForRadio2]].map((singleAddress, index) => {
                    return {
                        code: 'Standardized',
                        name: NoStandardAddress ? <p className={styles.labelSpace}>We are unable to standardize your address. Please select address entered.</p>: <p className={styles.labelSpace}>{singleAddress.addressLine1}<br/>{singleAddress.city}, {singleAddress.state}<br/>{singleAddress.postalCode}</p>
                    };
                }),
                hideLabel: false,
                onValueChange: this.handleChange,
                label: NoStandardAddress ? 'No Address Found' : 'Suggested Address',
                disabled: NoStandardAddress ? true : false,
                defaultvalue: 'Standardized',
                value: valueForStandard
            },
            cancel: {
            },
            select: {
                disabled: _.isEmpty(selectedAddress)
            },

        };
        const resolvers = {
            resolveCallbackMap: {
                onCancel: this.handleDismiss,
                handleSelect: this.handleSelect
            },
            resolveClassNameMap: styles
        };
        return (
            <Panel id="standardizedPanel" className={styles.alfaAddStdModal}>
                {renderContentFromMetadata(metadata.componentContent, overrides, resolvers)}
            </Panel>
        );
    }
}

export default withModal(AddressStdPopover);
