import { DemoGuidewireExperiment as DemoGuidewireExperimentJS } from 'gw-portals-segmentation-js';
import { service } from '@jutro/services';

export const DEMO_GW_EXPERIMENT_SERVICE_ID = 'DemoGuidewireExperiment';

export default class DemoGuidewireExperimentImpl {
    constructor() {
        this.DemoGuidewireExperiment = new DemoGuidewireExperimentJS();
    }

    getExperimentValue(experimentID = 'guidewireExperiment') {
        return this.DemoGuidewireExperiment.getExperimentValue(experimentID);
    }
}

export const DemoGuidewireExperiment = (
    service(DEMO_GW_EXPERIMENT_SERVICE_ID)(DemoGuidewireExperimentImpl)
);
