import React, {
    useContext, useCallback, useEffect, useState, useRef
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { TranslatorContext, formatShortDate } from '@jutro/locale';
import {
    Tooltip, IconButton, Loader, ModalService
} from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { MockUpUtil } from 'gw-portals-util-js';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { VehicleInfoLookupService } from 'gw-capability-vehicleinfo';
import { VinPopUp } from 'gw-custom-widgets-alfa';
import { AddressLookupComponent } from 'gw-capability-address-react';
import moment from 'moment';
import { AddCoverablesService } from 'gw-capability-policycommon-alfa';
import ErrorHandlingUtil from '../../../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil';
import PolicyInfoHeaderComponent from '../../components/PolicyInfoHeaderComponent/PolicyInfoHeaderComponent';
import metadata from './PolicyInformationPage.metadata.json5';

import CommonUtil from "../../../../../applications/quote-and-buy/src/pages/Utils/CommonUtil";

import styles from './PolicyInformationPage.module.scss';
import messages from './PolicyInformationPage.messages';

function removeInitialData(submissionVM) {
    // eslint-disable-next-line no-param-reassign
    submissionVM.value = MockUpUtil.cleanUpMockedProperties(
        _.get(submissionVM, 'value'),
        'quote.pa',
        'bindData.contactPhone',
        'bindData.contactEmail'
    );
    return submissionVM;
}

function initialiseVM(submissionVM) {
    const vm = removeInitialData(submissionVM);
    vm.bindData.value = vm.bindData.value || {};
    return vm;
}

function PolicyInformationTooltip(messageType) {
    const translator = useContext(TranslatorContext);
    const messagePath = 'quoteandbind.pa.views.pa-policy-info.';
    let message = `${messagePath}${messageType.id}`;
    let toolTipStyle = styles.gwToolTip;
    if (messageType.id.includes('paVinTooltip')) {
        message = `${messagePath}paVinTooltip`;
        toolTipStyle = styles.gwToolTipVin;
    }
    if (messageType.id.includes('paClaimDescTooltip')) {
        message = `${messagePath}paClaimDescTooltip`;
    }

    return (
        <Tooltip
            animation="fade"
            content={translator(_.find(messages, { id: message }))}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            placement="top"
            showOnInit={false}
            sticky
            trigger="mouseenter"
        >
            <IconButton icon="fa-info-circle" aria-haspopup="true" className={toolTipStyle} aria-hidden="true" aria-label="Information" />
        </Tooltip>
    );
}

function PolicyInformationPage(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM, updateWizardData, updateWizardSnapshot, jumpTo, editInfo,
        setEditInfo, isPremiumChanged, setIsPremiumChanged, quoteCalled, setQuoteCalled,
        oldPremium, setOldPremium, chosenQuote, setChosenQuote
    } = props;
    const localeService = ServiceManager.getService('locale-service');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate } = useValidation('PolicyInformationPage');
    const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children');
    const drivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
    const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress');
    const driverState = address.state.value.code;
    const number = _.get(submissionVM, 'baseData.accountHolder.cellNumber.value');

    const vehicleDamage = _.find(vehicles, (v) => {
        if (!_.isUndefined(v.dontHaveDamage.value) && !_.isNull(v.dontHaveDamage.value) && !_.isUndefined(v.isWithinCityLimit.value)) {
            return v;
        }
        return undefined;
    });
    const vehicleInPossession = _.find(vehicles, (v) => {
        if (!_.isUndefined(v.allVehiclesInPossession.value) && !_.isNull(v.allVehiclesInPossession.value)  && !_.isUndefined(v.isWithinCityLimit.value)) {
            return v;
        }
        return undefined;
    });
    const vehicleInRegistration = _.find(vehicles, (v) => {
        if (!_.isUndefined(v.allVehiclesRegToPNIOrSpouse.value) && !_.isNull(v.allVehiclesRegToPNIOrSpouse.value)  && !_.isUndefined(v.isWithinCityLimit.value)) {
            return v;
        }
        return undefined;
    });

    const noDamage = vehicleDamage !== undefined ? (driverState === 'GA' ? !vehicleDamage.dontHaveDamage.value 
                                                                        : vehicleDamage.dontHaveDamage.value)
                                                     : undefined
    const noPossession = vehicleInPossession !== undefined ? vehicleInPossession.allVehiclesInPossession.value : undefined;
    const noRegistration = vehicleInRegistration !== undefined ? vehicleInRegistration.allVehiclesRegToPNIOrSpouse.value : undefined;
    const [dontHaveDamage, updateDontHaveDamage] = useState(noDamage);
    const [vehicleRegister, updateVehicleRegister] = useState(noRegistration);
    const [vehiclePossession, updateVehiclePossession] = useState(noPossession);
    const [currentTile, updateCurrentTile] = useState('vehicle');
    const [showVinError, setShowVinError] = useState(false);
    const [dateErrorMessage, updateDateErrorMessage] = useState('');
    const [mvrServiceDown, updateMvrServiceDown] = useState(false);
    const [mvrEnable, updateMvrEnable] = useState(false);
    const [numDriverException, updateNumDriverException] = useState(false);
    const currentDate = new Date(submissionVM.baseData.sysDate.value);
    const today = ''.concat(currentDate.getMonth() + 1, '/', currentDate.getDate(), '/', currentDate.getFullYear());
    const [startDateValue, updateStartDateValue] = useState({});
    const maxDate = currentDate.setMonth(currentDate.getMonth() + 6);
    const [isLoading, updateIsLoading] = useState(false);
    const [hadBlackoutsOrSeizures, updateHadBlackoutsOrSeizures] = useState(undefined);
    const [hadArrestedLast5yrs, updateHadArrestedLast5yrs] = useState(undefined);
    const [triggerStdProcess, setTriggerStdProcess] = useState(false);
    const [addressStandardized, setAddressStandardized] = useState(false);
    const [mailingAddrStdProcessRan, setMailingAddrStdProcessRan] = useState(false);
    const [leaseCompanyError, setLeaseCompanyError] = useState(false);
    const [triggerValidation, setTriggerValidation] = useState(false);
    const [stdAddress, setStdAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        county: '',
        standardizedType: ''
    });
    const steps = {
        vehicleDone: false,
        driverDone: false,
        contactDone: false,
        historyDone: false
    };
    const [policySteps, updatePolicySteps] = useState(steps);
    const [vehicleSteps, updateVehicleSteps] = useState(false);
    const [driverSteps, updateDriverSteps] = useState(false);
    const [contactSteps, updateContactSteps] = useState(false);
    const [showAllTiles, updateShowAllTiles] = useState(false);
    const [isVinBtnDisabled, updateIsVinBtnDisabled] = useState([]);
    const [isManualVinTriggered, updateIsManualVinTriggered] = useState([]);
    const [isVinVerified, updateIsVinVerified] = useState([]);
    const [phoneNumber, updatePhoneNumber] = useState(number);
    const contactOnloadCount = useRef(0);
    const driverErrorMessage = 'Please enter a license number';
    const noVin = [];
    const addresses = [];
    _.forEach(vehicles, (vehicle) => {
        if (vehicle.vin.value === undefined) {
            noVin.push(vehicle.publicID.value);
        }
        const checkAddress = _.find(addresses, (address) => {
            return ((address.garageAddress.value.addressLine1 === vehicle.garagingAddress.value.addressLine1)
                && (address.garageAddress.value.addressLine2 === vehicle.garagingAddress.value.addressLine2)
                && (address.garageAddress.value.state === vehicle.garagingAddress.value.state)
                && (address.garageAddress.value.city === vehicle.garagingAddress.value.city));
        });

        if (checkAddress === null || checkAddress === undefined) {
            const garageAddress = {
                value: vehicle.garagingAddress.value
            };
            addresses.push({ garageAddress, vehicleId: vehicle.publicID.value });
        }
    });
    const [prefillLicense, updatePrefillLicense] = useState([]);
    const [prefillSsn, updatePrefillSsn] = useState([]);
    const [noVinVehicle, updateNoVinVehicle] = useState(noVin);
    const [garageAddresses, updateGarageAddresses] = useState(addresses);
    const vinCurrentError = useRef([]);
    const [vinError, updateVinError] = useState([]);
    const [mailAddressDif, setMailAddressDif] = useState(false);
    const [tempLicenseNumber, setTempLicenseNumber] = useState('');
    const [disableMask, setDisableMask] = useState([]);
    const [mailAddressError, setMailAddressError] = useState(false);
    const [addressPopulated, setAddressPopulated] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const minDate = new Date(_.get(submissionVM, 'baseData.sysDate.value').substring(0, 10).replaceAll('-', '/'));
    const maxPolicyDate = new Date(minDate);
    maxPolicyDate.setDate(minDate.getDate() + 60);
    const currentPage = "Policy Information Page";


    let initial = () => Promise.resolve(false);
    let skipPage = false;
    const [invalidZip, setInvalidZip] = useState(false);
    useEffect(() => {
        updateStartDateValue(_.get(submissionVM, 'baseData.periodStartDate'));
    }, []);

    useEffect(() => {
        let vm = viewModelService.clone(submissionVM);
        vm = initialiseVM(vm);
        if (!_.isEqual(vm.value, submissionVM.value)) {
            updateWizardData(vm);
        }
        const driverPath = 'lobData.personalAuto.coverables.drivers.children';
        const license = [];
        const ssn = [];
        const maskArray = [];
        _.forEach(drivers, (driver, index) => {
            maskArray.push(false);
            license.push({ publicId: driver.publicID.value, license: (!_.isUndefined(driver.licenseNumber.value)) });

            if (driver.ssnNumber.value !== undefined && (driver.ssnNumber.value === '000000000' || driver.ssnNumber.value.substring(driver.ssnNumber.value.length - 4) === '0000')) {
                _.set(submissionVM, `${driverPath}.${index}.ssnNumber`, undefined);
            }
            ssn.push({ publicId: driver.publicID.value, ssn: driver.ssnNumber.value !== undefined, number: driver.ssnNumber.value });
            let validLicense;
            if (!_.isUndefined(driver.validUSLicense.value)) {
                validLicense = driver.validUSLicense.value;
            } else if (driver.dontWishToAddDriver.value && !_.isEmpty(driver.licenseReason.value)) {
                validLicense = false;
            } else if (!_.isUndefined(driver.driverAge.value) && driver.driverAge.value < 16) {
                validLicense = false;
            } else {
                validLicense = true;
            }
            _.set(submissionVM, `${driverPath}.${index}.validUSLicense`, validLicense);
            _.set(submissionVM, `${driverPath}.${index}.licenseState`, !_.isEmpty(driver.licenseState.value) ? driver.licenseState.value : driverState);
        });
        setDisableMask(maskArray);
        if (prefillLicense.length === 0) {
            updatePrefillLicense(license);
        }
        if (prefillSsn.length === 0) {
            updatePrefillSsn(ssn);
        }

        const driverBlackout = _.find(drivers, (d) => { return d.hadAnyBlackoutSeizures.value !== undefined; });
        const driverArrested = _.find(drivers, (d) => { return d.hadArrestedLast5yrs.value !== undefined; });

        const blackoutsOrSeizures = driverBlackout !== undefined ? driverBlackout.hadAnyBlackoutSeizures.value : undefined;
        const arrestedLast5yrs = driverArrested !== undefined ? driverArrested.hadArrestedLast5yrs.value : undefined;
        updateHadBlackoutsOrSeizures(blackoutsOrSeizures);
        updateHadArrestedLast5yrs(arrestedLast5yrs);

        const isMailAddress = _.get(submissionVM, 'baseData.accountHolder.isMailingAddressSame.value');
        const isMailingSame = isMailAddress === undefined ? true : isMailAddress;
        _.set(submissionVM, 'baseData.accountHolder.isMailingAddressSame', isMailingSame);

        if (policySteps.contactDone) {
            updateContactSteps(true);
            updateVehicleSteps(true);
            updateDriverSteps(true);
        }
        if ((policySteps.contactDone && !mvrEnable) || (mvrEnable && policySteps.historyDone)) {
            updateShowAllTiles(true);
        }
        if (mvrEnable && !policySteps.historyDone) {
            updateShowAllTiles(false);
        }
        if (!mvrEnable) {
            submissionVM.lobData.personalAuto.coverables.mvrIncidents.value = [];
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // policySteps, mvrEnable, mailAddressDif
    }, [policySteps, mvrEnable, mailAddressDif]);


    const renderCellContent = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        if (path === 'licenseState') {
            return translator({
                id: `typekey.State.${data.licenseState}`,
                defaultMessage: data.licenseState
            });
        }
        return _.get(data, path);
    }, [translator]);

    const editTile = useCallback(
        (tileId, isOpen) => {
            updateCurrentTile(tileId);
            if (!isOpen) {
                updateShowAllTiles(false);
            }
        }, [updateShowAllTiles, updateCurrentTile]
    );

    const setVinCurrentError = (error) => {
        vinCurrentError.current = error;
    };

    const callCaptchaErrorHandler = useCallback((error) => {
        if (!_.isUndefined(error.baseError) && error.baseError.includes('Invalid Recaptcha')) {
            ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, currentPage);
        }
    });

    const validateVin = useCallback(async (path) => {
        const vehicle = _.get(submissionVM, path);
        const vinVehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.children', []);
        let currentVinError;
        if (!_.isEmpty(vehicle.vin.value) && vehicle.vin.value.length === 17) {
            const vehicleMatch = _.find(vinVehicles, (v) => {
                if (!_.isEmpty(v.vin.value) && v.publicID.value !== vehicle.publicID.value) {
                    return ((v.vin.value === vehicle.vin.value) && (v.isSelectedVehicle.value === true));
                }
                return undefined;
            });

            if (!_.isEmpty(vehicleMatch)) {
                currentVinError = {
                    publicId: vehicle.publicID.value,
                    vinErrorCode: '010',
                    vinErrorMessage: 'This VIN is already added for a vehicle'
                };
                const btnManual = isManualVinTriggered;
                const vehicleIndex = isManualVinTriggered.indexOf(vehicle.publicID.value);
                delete btnManual[vehicleIndex];
                updateIsManualVinTriggered(btnManual);
            } else {
                await VehicleInfoLookupService.lookupVehicleInfoBasedOnVin(vehicle.vin.value, submissionVM.quoteID.value, authHeader)
                    .then((vehicleInfo) => {
                        const errorCode = _.get(vehicleInfo, 'errorCode');
                        if (errorCode === null || errorCode === undefined) {
                            const vinResponse = vehicleInfo.vinLookupResponse;
                            if (!_.isEmpty(vinResponse)) {
                                _.set(vehicle, 'make', vinResponse.make);
                                _.set(vehicle, 'model', vinResponse.model);
                                _.set(vehicle, 'year', vinResponse.year);
                                _.set(vehicle, 'vehicleType', vinResponse.alfaVehicleType);
                                _.set(vehicle, 'bodyType', vinResponse.bodyStyle);
                                _.set(vehicle, 'isProhibited_alfa', vinResponse.alfaProhibited);
                                _.set(vehicle, 'isPotentialProhibited_alfa', vinResponse.alfaPotentialProhibited);
                                _.set(vehicle, 'rateSymbolCollision_alfa', vinResponse.isocollisionSymbol);
                                if (!_.isEmpty(vinResponse.rapaResult)) {
                                    const { rapaResult } = vinResponse;
                                    _.set(vehicle, 'rapaResult', rapaResult);
                                }

                                if (!_.isEmpty(vinResponse.ratingCostSymbol)) {
                                    _.set(vehicle, 'rateSymbol_alfa', vinResponse.ratingCostSymbol);
                                } else {
                                    _.set(vehicle, 'rateSymbol_alfa', vinResponse.isocomprehensiveSymbol);
                                }
                            }
                            if (!isVinBtnDisabled.includes(vehicle.publicID.value)) {
                                const btnDisable = isVinBtnDisabled;
                                btnDisable.push(vehicle.publicID.value);
                                updateIsVinBtnDisabled(btnDisable);
                            }
                            if (!isVinVerified.includes(vehicle.publicID.value)) {
                                const vinVerified = isVinVerified;
                                vinVerified.push(vehicle.publicID.value);
                                updateIsVinVerified(vinVerified);
                            }
                            const removeError = vinCurrentError.current.filter((q) => q.publicId !== vehicle.publicID.value);
                            setVinCurrentError(removeError);
                            updateVinError(vinCurrentError.current);
                        } else {
                            currentVinError = {
                                publicId: vehicle.publicID.value,
                                vinErrorCode: vehicleInfo.errorCode,
                                vinErrorMessage: vehicleInfo.errorDescription
                            };
                        }
                    })
                    .catch((error) => {
                        callCaptchaErrorHandler(error);
                    });
            }
        } else if (!_.isEmpty(vehicle.vin.value) && vehicle.vin.value !== '') {
            currentVinError = {
                publicId: vehicle.publicID.value,
                vinErrorCode: '008',
                vinErrorMessage: 'We\'re sorry. We\'re unable to verify VIN.'
            };
        } else if (vehicle.vin.value === null || vehicle.vin.value === '' || vehicle.vin.value === undefined) {
            currentVinError = {
                publicId: vehicle.publicID.value,
                vinErrorCode: '000',
                vinErrorMessage: 'Please enter a VIN'
            };
        }
        if (currentVinError !== undefined) {
            const error = vinCurrentError.current.filter((q) => q.publicId !== vehicle.publicID.value);
            error.push(currentVinError);
            setVinCurrentError(error);
            updateVinError(vinCurrentError.current);
            if (!isVinBtnDisabled.includes(vehicle.publicID.value)) {
                const btnDisabled = isVinBtnDisabled;
                btnDisabled.push(vehicle.publicID.value);
                updateIsVinBtnDisabled(btnDisabled);
            }
            setShowVinError(true);
            return false;
        }
        return true;
    }, [submissionVM, isManualVinTriggered, authHeader, isVinBtnDisabled, isVinVerified, callCaptchaErrorHandler]);

    const handleVinChange = useCallback(
        (newValue, path) => {
            const normalisedValue = _.isNil(newValue)
                ? undefined
                : newValue.replace(/[^A-Za-z0-9]/ig, '');

            if (normalisedValue !== _.get(submissionVM, path)) {
                _.set(submissionVM, path, normalisedValue);
                updateWizardData(submissionVM);
                setShowVinError(false);
            }
            const currentPath = path.split('.vin')[0];
            const vehicleID = _.get(submissionVM, `${currentPath}.publicID.value`);
            if (normalisedValue !== undefined && normalisedValue.length === 17) {
                if (isVinBtnDisabled.includes(vehicleID)) {
                    const btnDisable = isVinBtnDisabled;
                    const vehicleIndex = isVinBtnDisabled.indexOf(vehicleID);
                    delete btnDisable[vehicleIndex];
                    updateIsVinBtnDisabled(btnDisable);
                }

                if (!isManualVinTriggered.includes(vehicleID)) {
                    const manualTrg = isManualVinTriggered;
                    manualTrg.push(vehicleID);
                    updateIsManualVinTriggered(manualTrg);
                    validateVin(currentPath);
                }
            } else if (isVinBtnDisabled.includes(vehicleID)) {
                const btnDisable = isVinBtnDisabled;
                const vehicleIndex = isVinBtnDisabled.indexOf(vehicleID);
                delete btnDisable[vehicleIndex];
                updateIsVinBtnDisabled(btnDisable);
                const error = vinCurrentError.current.filter((q) => q.publicId !== vehicleID);
                setVinCurrentError(error);
                updateVinError(vinCurrentError.current);
            }
        },
        [updateVinError, submissionVM, updateWizardData, isVinBtnDisabled, updateIsVinBtnDisabled, isManualVinTriggered, updateIsManualVinTriggered, validateVin]
    );

    const callModelPopup = () => {
        return ModalService.showPopover(VinPopUp, {
        }).result.then((result) => { });
    };

    const getDivTooltipText = () => {
        return (
            <div className={styles.tooltipDivStyle} role="button" tabIndex="0" onKeyDown={callModelPopup} onClick={() => callModelPopup()}>
                <Tooltip
                    animation="fade"
                    placement="top"
                    delay={[
                        0,
                        40
                    ]}
                    flipBehavior={[
                        'right',
                        'bottom',
                        'top',
                        'left',
                        'right'
                    ]}
                    followCursor={false}
                    hideOnClick
                    id="tooltip"
                    showOnInit={false}
                    sticky
                    trigger="mouseenter"
                />
                <IconButton icon="fa-info-circle" aria-haspopup="true" iconClassName={styles.iconFontStyle} aria-hidden="true" aria-label="Information" />
            </div>
        );
    };

    const handleLeaseChange = useCallback(
        (newValue, path) => {
            const normalisedValue = _.isNil(newValue)
                ? undefined
                : newValue.replace(/[^A-Za-z0-9]/ig, '');

            if (normalisedValue !== _.get(submissionVM, path)) {
                _.set(submissionVM, path, normalisedValue);
                updateWizardData(submissionVM);
            }
        },
        [submissionVM, updateWizardData]
    );

    const getPhoneNumber = useCallback(
      (value) => {
        let normalisedValue = value;
        if (!_.isNil(value)) {
          normalisedValue = value.replace(/[^0-9]/gi, "");
        }
        updatePhoneNumber(normalisedValue);
        _.set(submissionVM, "baseData.accountHolder.cellNumber", value);
        updateWizardData(submissionVM);
        if (normalisedValue.length === 10) {
          setMobileError(false);
        }
      },
      [submissionVM, updateWizardData, setMobileError]
    );

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
            if (path === 'baseData.accountHolder.cellNumber') {
                setMobileError(false);
            }
        },
        [submissionVM, updateWizardData, setMobileError]
    );
    const writeAdditionalValue = useCallback(
        (path) => {
            _.set(submissionVM, `${path}.hasSecondCompany`, true);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const cancelSecondary = useCallback((path) => {
        _.set(submissionVM, `${path}.hasSecondCompany`, false);
        _.set(submissionVM, `${path}.secCompany`, undefined);
        _.set(submissionVM, `${path}.secCompanyID`, undefined);
        _.set(submissionVM, `${path}.isOtherSecondCmpy`, false);
        _.set(submissionVM, `${path}.otherSecondCmpy`, undefined);
        if (_.get(submissionVM, `${path}.otherSecondCmpyAddress.value`) !== undefined) {
            _.set(submissionVM, `${path}.otherSecondCmpyAddress.addressLine1`, undefined);
            _.set(submissionVM, `${path}.otherSecondCmpyAddress.addressLine2`, undefined);
            _.set(submissionVM, `${path}.otherSecondCmpyAddress.city`, undefined);
            _.set(submissionVM, `${path}.otherSecondCmpyAddress.state`, undefined);
            _.set(submissionVM, `${path}.otherSecondCmpyAddress.country`, undefined);
            _.set(submissionVM, `${path}.otherSecondCmpyAddress.postalCode`, undefined);
            _.set(submissionVM, `${path}.otherSecondCmpyAddress.isOtherSecondCmpy`, false);
        }
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const writeMailAddressValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            if (!value) {
                if (policySteps.driverDone) {
                    const addAddress = _.get(submissionVM, 'baseData.accountHolder.additionalAddresses.value');
                    let hasMailAddress = false;
                    if (addAddress !== undefined && addAddress.length > 0) {
                        hasMailAddress = true;
                    }
                    if (!hasMailAddress) {
                        const addressDTO = {
                            addressLine1: undefined,
                            addressLine2: undefined,
                            city: undefined,
                            state: driverState,
                            postalCode: '',
                            county: null,
                            country: localeService.getDefaultCountryCode()
                        };
                        const { _dtoName, _xCenter } = submissionVM.baseData.accountHolder.additionalAddresses;
                        const mailAddress = viewModelService.create(addressDTO, _xCenter, _dtoName);
                        submissionVM.baseData.accountHolder.additionalAddresses.pushElement(mailAddress);
                        setMailAddressDif(true);
                    }
                }
            } else {
                const addAddress = _.get(submissionVM, 'baseData.accountHolder.additionalAddresses.value');
                if (addAddress !== undefined && addAddress.length > 0) {
                    _.set(submissionVM, 'baseData.accountHolder.additionalAddresses.value', []);
                }
            }
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, policySteps, localeService, viewModelService]
    );

    const writePolicyDateValue = useCallback(
        (value, path) => {
            let month = value.month + 1;
            month = (month.toString().length === 1) ? '0'.concat(month) : month;
            const day = (value.day.toString().length === 1) ? '0'.concat(value.day) : value.day;
            const { year } = value;
            const dateToValidate = ''.concat(month, '/', day, '/', year);
            const isDateValid = moment(dateToValidate, 'MM/DD/YYYY', true).isValid();
            if (!isDateValid) {
                updateStartDateValue(value);
                // _.set(submissionVM, 'baseData.periodStartDate.value', value);
                updateDateErrorMessage('Please enter a valid date');
            }
            if (isDateValid && moment(dateToValidate).isBefore(today)) {
                updateStartDateValue(value);
                // _.set(submissionVM, 'baseData.periodStartDate.value', value);
                updateDateErrorMessage('Policy start date cannot be in the past');
            }
            if (isDateValid && moment(dateToValidate).isAfter(maxDate)) {
                updateStartDateValue(value);
                // _.set(submissionVM, 'baseData.periodStartDate.value', value);
                updateDateErrorMessage('Policy start date cannot be more than 60 days in the future');
            }
            if (isDateValid && !(moment(dateToValidate).isBefore(today)) && !(moment(dateToValidate).isAfter(maxDate))) {
                // updateStartDateValue(value);
                // _.set(submissionVM, 'baseData.periodStartDate.value', value);
                updateDateErrorMessage('');
                updateStartDateValue(value);
                _.set(submissionVM, path, value);
            }
            if (dateErrorMessage === '') {
                _.set(submissionVM, path, value);
            }
            updateWizardData(submissionVM);
        }, [submissionVM, updateWizardData, dateErrorMessage, updateDateErrorMessage, maxDate, today, updateStartDateValue]
    );

    const writeDamageValue = useCallback(
        (value, path) => {
            updateDontHaveDamage(value);
        },
        []
    );
    const writePossessionValue = useCallback(
        (value, path) => {
            updateVehiclePossession(value);
        },
        []
    );
    const writeRegistrationValue = useCallback(
        (value, path) => {
            updateVehicleRegister(value);
        },
        []
    );
    const writeBlackoutValue = useCallback(
        (value, path) => {
            updateHadBlackoutsOrSeizures(value);
        },
        []
    );
    const writeArrestedValue = useCallback(
        (value, path) => {
            updateHadArrestedLast5yrs(value);
        },
        []
    );

    const addVehicle = useCallback(() => {
        const vehiclesPath = 'lobData.personalAuto.coverables.vehicles';
        const currentVehicles = _.get(submissionVM, `${vehiclesPath}.children`, []);
        const cityLimits = [];
        _.forEach(currentVehicles, (vehicle, index) => {
            if (_.find(garageAddresses, { vehicleId: vehicle.publicID.value }) !== undefined) {
                const withinCity = _.get(submissionVM, `${vehiclesPath}.children.${index}.isWithinCityLimit`);
                if (withinCity.value !== undefined) {
                    cityLimits.push({ publicId: vehicle.publicID.value, isWithinCity: withinCity.value });
                } else {
                    cityLimits.push({ publicId: vehicle.publicID.value, isWithinCity: true });
                    _.set(submissionVM, `${vehiclesPath}.children.${index}.isWithinCityLimit`, true);
                }
            }
        });
        let errorFlag = false;

        updateVinError([]);
        setShowVinError(false);
        _.forEach(currentVehicles, (vehicle, index) => {
            const vehiclePath = `${vehiclesPath}.children.${index}`;
            const address = _.find(garageAddresses, (a) => {
                return (a.vehicleId !== vehicle.publicID.value && (a.garageAddress.value.addressLine1 === vehicle.garagingAddress.value.addressLine1)
                    && (a.garageAddress.value.addressLine2 === vehicle.garagingAddress.value.addressLine2)
                    && (a.garageAddress.value.state === vehicle.garagingAddress.value.state)
                    && (a.garageAddress.value.city === vehicle.garagingAddress.value.city));
            });
            if (address !== null && address !== undefined) {
                const hasCityLimit = _.find(cityLimits, { publicId: address.vehicleId });
                const withinCityLimit = hasCityLimit !== undefined ? hasCityLimit.isWithinCity : true;
                _.set(submissionVM, `${vehiclePath}.isWithinCityLimit`, withinCityLimit);
            }
            _.set(submissionVM, `${vehiclePath}.costNew`, {});

            if (['GA'].includes(driverState)) {
                _.set(submissionVM, `${vehiclePath}.dontHaveDamage`, !dontHaveDamage);
            } else {
            _.set(submissionVM, `${vehiclePath}.dontHaveDamage`, dontHaveDamage);
            }

            _.set(submissionVM, `${vehiclePath}.allVehiclesRegToPNIOrSpouse`, vehicleRegister);
            _.set(submissionVM, `${vehiclePath}.allVehiclesInPossession`, vehiclePossession);
            if (noVinVehicle.includes(vehicle.publicID.value)) {
                const success = validateVin(vehiclePath);
                if (!success) {
                    errorFlag = true;
                    setShowVinError(true);
                }
            }
            if (vinError.length > 0) {
                errorFlag = true;
            }

            if (vehicle.leaseOrRent.value) {
                setTriggerValidation(true);
            }

            if (vehicle.leaseOrRent.value && vehicle.leasingCompany.value === undefined) {
                setLeaseCompanyError(true);
                errorFlag = true;
            }
        });

        if (errorFlag) {
            return false;
        }

        const validateVehicle = _.find(currentVehicles, (v) => {
            if (!v.aspects.subtreeValid) {
                return v;
            }
            return null;
        });

        if (!_.isEmpty(validateVehicle)) {
            return true;
        }
        const cellNumber = _.get(submissionVM, 'baseData.accountHolder.cellNumber');
        if (cellNumber === '') {
            _.set(submissionVM, 'baseData.accountHolder.cellNumber', undefined);
        }

        const policyDrivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');
        const date = _.get(submissionVM, "baseData.sysDate.value");
        _.set(
          submissionVM,
          "baseData.sysDate",
          moment(date).format("YYYY-MM-DD")
        );
        updateIsLoading(true);
        AddCoverablesService.updateDraftSubmission(submissionVM.value).then(
            (response) => {
                _.set(submissionVM, 'baseData.selectedVersion', response.baseData.selectedVersion);
                _.set(
                  submissionVM,
                  "baseData.sysDate",
                  moment(response.baseData.sysDate).format("YYYY-MM-DD")
                );
                const policyStep = {
                    vehicleDone: true,
                    driverDone: false,
                    contactDone: false,
                    historyDone: false
                };
                updatePolicySteps(policyStep);
                updateVehicleSteps(true);
                _.forEach(policyDrivers, (driver, index) => {
                    _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.isPolicyInfo`, true);
                });
                updateCurrentTile('driver');
                window.scrollTo(0, 0);
                updateIsLoading(false);
            }, (error) => {
                _.forEach(policyDrivers, (driver, index) => {
                    _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.isPolicyInfo`, true);
                });
                updateIsLoading(false);
                callCaptchaErrorHandler(error);
            }
        );
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, garageAddresses, dontHaveDamage, noVinVehicle, vinError.length, validateVin, callCaptchaErrorHandler]);

    const addDriver = useCallback(async () => {
        const driverPath = 'lobData.personalAuto.coverables.drivers';
        const currentDrivers = _.get(submissionVM, `${driverPath}.children`, []);
        const cellNumber = _.get(submissionVM, 'baseData.accountHolder.cellNumber.value');

        if (_.isUndefined(cellNumber) || (cellNumber.length < 10 && cellNumber.length > 0)) {
            setMobileError(true);
        }
        _.forEach(currentDrivers, (driver, index) => {
            _.set(submissionVM, `${driverPath}.children.${index}.hadAnyBlackoutSeizures`, hadBlackoutsOrSeizures);
            _.set(submissionVM, `${driverPath}.children.${index}.hadArrestedLast5yrs`, hadArrestedLast5yrs);
            if (!driver.validUSLicense.value) {
                _.set(submissionVM, `${driverPath}.children.${index}.dontWishToAddDriver`, true);
            } else {
                _.set(submissionVM, `${driverPath}.children.${index}.dontWishToAddDriver`, false);
            }
            if (driver.isPolicyHolder.value || driver.relationshipToPNI.value.code === 'SP') {
                const ssnData = _.find(prefillSsn, { publicId: driver.publicID.value, ssn: false });
                if (!_.isUndefined(ssnData) && !_.isUndefined(driver.ssnNumber.value) && driver.ssnNumber.value !== ssnData.number ) {
                    _.set(submissionVM, 'lobData.personalAuto.coverables.triggerCreditScore', true); 
                }
            }
        });
        let driverResponse = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
        let excludeCount = 0;
        _.forEach(driverResponse, async (driverObj) => {
            if ((!_.isUndefined(driverObj.licenseReason) && !driverObj.validUSLicense)
                || (!_.isUndefined(driverObj.dontWishToAddDriver) && driverObj.dontWishToAddDriver)) {
                excludeCount += 1;
            }
        });
        if (excludeCount > 2) {
            await ErrorHandlingUtil.processErrorResponse(props, {errorDescription: 'No of drivers excluded are greater than 2'}, submissionVM.value, currentPage);
        }
        const date = _.get(submissionVM, "baseData.sysDate.value");
        _.set(submissionVM,"baseData.sysDate",moment(date).format("YYYY-MM-DD"));
        updateIsLoading(true);
        await AddCoverablesService.updateDraftSubmission(submissionVM.value).then(
            (response) => {
                _.set(submissionVM, 'baseData.selectedVersion', response.baseData.selectedVersion);
                _.set(submissionVM, 'lobData.personalAuto.coverables.triggerCreditScore', false); 
                _.set(submissionVM, 'baseData.sysDate',moment(response.baseData.sysDate).format("YYYY-MM-DD"));
                const policyStep = {
                    vehicleDone: true,
                    driverDone: true,
                    contactDone: false,
                    historyDone: false
                };
                updatePolicySteps(policyStep);
                updateVehicleSteps(true);
                updateDriverSteps(true);
                updateCurrentTile('contact');
                writePolicyDateValue(_.get(submissionVM, 'baseData.periodStartDate.value'), 'baseData.periodStartDate');
                window.scrollTo(0, 0);
                updateIsLoading(false);
            }, (error) => {
                updateIsLoading(false);
                callCaptchaErrorHandler(error);
            }
        );

        driverResponse = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
        // Find index of a driver without prefill license number.
        const shouldCallClue = _.findIndex(driverResponse, (driver) => {
            const license = _.find(prefillLicense, { publicId: driver.publicID });
            const driverPrefillLicense = license !== undefined ? license.license : true;
            return driver.driverAge >= 16 && !driverPrefillLicense;}) !== -1;
        if(shouldCallClue) {
            await LoadSaveService.getClueReport(submissionVM.quoteID.value, true).then((response) => {
                if (!_.isUndefined(response.clueReports) && response.clueReports.length > 0) {
                    _.set(submissionVM, 'lobData.personalAuto.coverables.driverHistory.value', response.clueReports);
                }
            }), (error) => {
                callCaptchaErrorHandler(error);
            };
        }
    }, [submissionVM, hadBlackoutsOrSeizures, hadArrestedLast5yrs, callCaptchaErrorHandler]);

    const writeAddressValue = useCallback((value, path) => {
        if (path !== 'baseData.accountHolder.additionalAddresses.children.0.state') {
            if (addressStandardized) { // If the address was standardized, and were address changes, reset Address Standardization flag 
                if (path === 'baseData.accountHolder.additionalAddresses.children.0.addressLine1') {
                    if (value !== stdAddress.addressLine1) { setAddressStandardized(false); }
                }
                if (path === 'baseData.accountHolder.additionalAddresses.children.0.addressLine2') {
                    if (value !== stdAddress.addressLine2) { setAddressStandardized(false); }
                }
                if (path === 'baseData.accountHolder.additionalAddresses.children.0.city') {
                    if (value !== stdAddress.city) { setAddressStandardized(false); }
                }
                if (path === 'baseData.accountHolder.additionalAddresses.children.0.postalCode') {
                    if (value !== stdAddress.postalCode) { setAddressStandardized(false); }
                }
                if (path === 'baseData.accountHolder.additionalAddresses.children.0.county') {
                    if (value !== stdAddress.county) { setAddressStandardized(false); }
                }
            }
            _.set(submissionVM, path, value);

            const mailAddr = _.get(submissionVM, 'baseData.accountHolder.isMailingAddressSame.value');
            const address = !mailAddr ? _.get(submissionVM, 'baseData.accountHolder.additionalAddresses.children.0') : undefined;
            if (!mailAddr && !addressStandardized) {
                if (address.aspects.valid && address.aspects.subtreeValid) {
                    setAddressPopulated(true);
                } else {
                    setAddressPopulated(false);
                }
            }
            updateWizardData(submissionVM);
        }
    }, [addressStandardized, stdAddress, submissionVM, updateWizardData, setAddressPopulated]);

    const syncAddress = useCallback(() => {
        const mailAddr = _.get(submissionVM, 'baseData.accountHolder.isMailingAddressSame.value');
        const address = !mailAddr ? _.get(submissionVM, 'baseData.accountHolder.additionalAddresses.children.0') : undefined;
        if (!mailAddr && !addressStandardized) {
            if (address.aspects.valid && address.aspects.subtreeValid) {
                setAddressPopulated(true);
            } else {
                setAddressPopulated(false);
            }
        }
    }, [submissionVM, setAddressPopulated, addressStandardized]);    

    const validatePhone = () => {
        const cellNumber = _.get(submissionVM, 'baseData.accountHolder.cellNumber.value');

        if (_.isUndefined(cellNumber) || cellNumber.length < 10 || !submissionVM.baseData.accountHolder.cellNumber.aspects.subtreeValid) {
            setMobileError(true);
        } else {
            setMobileError(false);
        }
    };

    const saveAndQuote = useCallback(async () => {
        // Calling policy info save and quote
        updateIsLoading(true);
        _.set(submissionVM, 'baseData.isPolicyInfoSaveAndQuote', true);

        /** TODO Code placeholder for Quote Update**/
        if (!editInfo) {
            _.forEach(submissionVM.quoteData.offeredQuotes.value, (offerQuote) => {
                if (offerQuote.publicID === submissionVM.bindData.chosenQuote.value) {
                    _.set(submissionVM, 'baseData.oldPremium', offerQuote.premium.total.amount);
                    _.set(submissionVM, 'baseData.chosenQuote', offerQuote.branchName);
                    setOldPremium(offerQuote.premium.total.amount);
                    setChosenQuote(offerQuote.branchName);
                }
            });
        } else {
            submissionVM.baseData.oldPremium.value = oldPremium;
            submissionVM.baseData.chosenQuote.value = chosenQuote;
        }

        await LoadSaveService.saveAndQuoteSubmission(submissionVM.value).then((quoteChange) => {
            _.set(submissionVM, 'quoteData', quoteChange.quoteData);
            _.set(submissionVM, 'baseData.isPremiumChanged', quoteChange.baseData.isPremiumChanged);
            if (!_.isEmpty(quoteChange.baseData.agentInfo)) {
                _.set(submissionVM, 'baseData.agentInfo', quoteChange.baseData.agentInfo);
            }
            _.set(submissionVM, 'lobData.personalAuto.offerings', quoteChange.lobData.personalAuto.offerings);
            setIsPremiumChanged(submissionVM.baseData.isPremiumChanged.value);
            if (!mvrEnable) {
                const policyStep = {
                    vehicleDone: true,
                    driverDone: true,
                    contactDone: true,
                    historyDone: false
                };

                updatePolicySteps(policyStep);
            }
            updateDriverSteps(true);
            updateContactSteps(true);
            if (mvrEnable) {
                updatePolicySteps({
                    vehicleDone: true,
                    driverDone: true,
                    contactDone: true,
                    historyDone: true
                });
                updateContactSteps(true);
            }
            updateIsLoading(false);
        }, (error) => {
            if (error !== undefined && error[0] !== undefined) {
                const quoteError = error[0];
                updateIsLoading(false);
                if (quoteError !== undefined && quoteError.baseError.error !== undefined && (quoteError.baseError.error.data.appData.includes('NUM DRIVER') || (quoteError.baseError.error.data.appErrorCode === 700 && quoteError.baseError.error.data.appError === 'GW_ENTITY_VALIDATION_WARNING'))) {
                    updateNumDriverException(true);
                    const policyStep = {
                        vehicleDone: true,
                        driverDone: true,
                        contactDone: true,
                        historyDone: false
                    };
                    updatePolicySteps(policyStep);
                    updateContactSteps(true);
                    if (mvrEnable) {
                        updatePolicySteps({
                            vehicleDone: true,
                            driverDone: true,
                            contactDone: true,
                            historyDone: true
                        });
                    }
                }
            }
            updateIsLoading(false);
        });
    }, [submissionVM, editInfo, LoadSaveService, setOldPremium, setChosenQuote, oldPremium, chosenQuote, setIsPremiumChanged, mvrEnable]);

    const doAddContactWork = useCallback(() => {
        // Do the work needed for 'addContact' - placed here so that it could be invoked
        // if address standardization is not needed, OR after address standardization finishes
        const mailAddr = _.get(submissionVM, 'baseData.accountHolder.isMailingAddressSame.value');
        const address = !mailAddr ? _.get(submissionVM, 'baseData.accountHolder.additionalAddresses.children.0') : undefined;
        if (dateErrorMessage === '') {
            _.set(submissionVM, 'baseData.periodStartDate', startDateValue);
        }
        const date = _.get(submissionVM, "baseData.sysDate.value");
        _.set(submissionVM,"baseData.sysDate",moment(date).format("YYYY-MM-DD"));
        updateIsLoading(true);
        const servicePromise = AddCoverablesService.updateDraftSubmission(submissionVM.value);
        servicePromise.then((response) => {
            _.set(submissionVM, 'baseData.selectedVersion', response.baseData.selectedVersion);
            _.set(submissionVM,"baseData.sysDate",moment(response.baseData.sysDate).format("YYYY-MM-DD"));
            AddCoverablesService.orderDriverMVRData(submissionVM.quoteID.value).then((incidents) => {
                updateIsLoading(false);
                if (incidents.errorCode !== undefined && incidents.errorCode === '002') {
                    updateMvrServiceDown(true);
                    const policyStep = {
                        vehicleDone: true,
                        driverDone: true,
                        contactDone: true,
                        historyDone: false
                    };
                    updatePolicySteps(policyStep);
                    saveAndQuote();
                    ErrorHandlingUtil.processErrorResponse(props, incidents, submissionVM.value, currentPage);
                } else {
                    const existingMvr = _.get(submissionVM, 'lobData.personalAuto.coverables.mvrIncidents.value');
                    if (existingMvr === undefined || (existingMvr !== undefined && existingMvr.length === 0)) {
                        _.forEach(incidents.mvrIncidents, (mvr) => {
                            const mvrDTO = {
                                standardDescription: mvr.standardDescription,
                                convictionDate: mvr.convictionDate,
                                violationDate: mvr.violationDate,
                                incidentType: mvr.incidentType,
                                incidentNumber: mvr.incidentNumber,
                                firstName: mvr.firstName,
                                lastName: mvr.lastName,
                                mvrOrderStatus: mvr.mvrOrderStatus,
                                isPolicyInfo: true
                            };

                            const { _dtoName, _xCenter } = submissionVM.lobData.personalAuto.coverables.mvrIncidents;
                            const mvrIncident = viewModelService.create(mvrDTO, _xCenter, _dtoName);
                            submissionVM.lobData.personalAuto.coverables.mvrIncidents.pushElement(mvrIncident);
                        });
                        updateWizardData(submissionVM);
                    }
                    const mvrIncidents = _.get(submissionVM, 'lobData.personalAuto.coverables.mvrIncidents.children');
                    let mvrNum = 0;
                    _.forEach(mvrIncidents, (mvr) => {
                        if (!_.isEmpty(mvr.incidentNumber.value)) {
                            mvrNum += 1;
                        }
                    });
                    if (mvrNum > 0 && mvrNum === mvrIncidents.length) {
                        updateMvrEnable(true);
                        const policyStep = {
                            vehicleDone: true,
                            driverDone: true,
                            contactDone: true,
                            historyDone: false
                        };
                        updateCurrentTile('history');
                        updatePolicySteps(policyStep);
                    } else {
                        updatePolicySteps({
                            vehicleDone: true,
                            driverDone: true,
                            contactDone: true,
                            historyDone: false
                        });
                        saveAndQuote();
                    }
                }
                updatePolicySteps({
                    vehicleDone: true,
                    driverDone: true,
                    contactDone: true,
                    historyDone: false
                });
            }, (error) => {
                console.log(error);
                callCaptchaErrorHandler(error);
                updateMvrServiceDown(true);
                updateIsLoading(false);
                saveAndQuote();
            });
            window.scrollTo(0, 0);
        }, (error) => {
            callCaptchaErrorHandler(error);
        });
    }, [submissionVM, saveAndQuote, props, updateWizardData, viewModelService, callCaptchaErrorHandler]);

    const updateStandardizationRan = (stdProcessDidRun, updatedAddress) => { // called by AddressLookupComponent once Standardization process has run
        if (stdProcessDidRun === true) {
            setTriggerStdProcess(false); // turn off the Address Standardization trigger
            setMailingAddrStdProcessRan(true);

            // Check results
            if (updatedAddress !== undefined || updatedAddress !== null) {
                if (updatedAddress.standardizedType === 'Standardized') {
                    setAddressStandardized(true);
                    setStdAddress(updatedAddress);
                }
                // Address Standardization did run - do the work of the addContact function
                // whether the address could be standardized or not
                doAddContactWork();
            }
        }
    };

    const addContact = useCallback(() => {
        const mailAddr = _.get(submissionVM, 'baseData.accountHolder.isMailingAddressSame.value');
        const address = !mailAddr ? _.get(submissionVM, 'baseData.accountHolder.additionalAddresses.children.0') : undefined;
        if (!mailAddr && !addressStandardized && mailingAddrStdProcessRan === false) {
            if (address.aspects.valid && address.aspects.subtreeValid) {
                // The following will trigger the address standardization in the child component
                // setAddressStandardized(false);
                setTriggerStdProcess(true);
                return false;
            }
        }
        if (mailAddr || (!mailAddr && address.standardizedType.value === 'Standardized') || (!mailAddr && mailingAddrStdProcessRan === true)) {
            doAddContactWork();
        }
        return true;
    }, [submissionVM, addressStandardized, mailingAddrStdProcessRan, doAddContactWork]);


    const addHistory = useCallback(() => {
        updateIsLoading(true);
        const date = _.get(submissionVM, "baseData.sysDate.value");
        _.set(submissionVM,"baseData.sysDate",moment(date).format("YYYY-MM-DD"));
        AddCoverablesService.updateDraftSubmission(submissionVM.value).then((response) => {
            updateIsLoading(false);
            _.set(submissionVM, 'baseData.selectedVersion', response.baseData.selectedVersion);
            _.set(submissionVM,"baseData.sysDate",moment(response.baseData.sysDate).format("YYYY-MM-DD"));
            updateWizardData(submissionVM);
            saveAndQuote();
            updatePolicySteps({
                vehicleDone: true,
                driverDone: true,
                contactDone: true,
                historyDone: true
            });
            updateShowAllTiles(true);
            window.scrollTo(0, 0);
        }, (error) => {
            updateIsLoading(false);
            callCaptchaErrorHandler(error);
        });
    }, [submissionVM, updateWizardData, saveAndQuote, callCaptchaErrorHandler]);

    const checkPremium = useCallback(async () => {
        await LoadSaveService.checkUWIssues(submissionVM.quoteID.value)
            .then((checkResponse) => {
                if (!_.isEmpty(checkResponse) && checkResponse) {
                    if (!submissionVM.baseData.isPremiumChanged.value) {
                        LoadSaveService.getTwoDownVersion(submissionVM.value).then((payment) => {
                            submissionVM.value = payment;

                            updateWizardData(submissionVM);
                            updateIsLoading(false);
                        });
                } else {
                    console.log('Premium Changed');
                    submissionVM.baseData.editInfo = editInfo;
                    submissionVM.baseData.skipQuote = true;
                    updateWizardData(submissionVM);
                    updateIsLoading(false);
                }
                }
            }).catch((error) => {
                ErrorHandlingUtil.processErrorResponse(props, error, submissionVM.value, currentPage);
            });
    }, [LoadSaveService, submissionVM, updateWizardData, editInfo, props]);

    const tileStatus = (type) => {
        let complete = false;
        switch (type) {
            case 'vehicle':
                complete = policySteps.vehicleDone;
                break;
            case 'driver':
                complete = policySteps.driverDone;
                break;
            case 'contact':
                complete = policySteps.contactDone;
                break;
            case 'history':
                complete = policySteps.historyDone;
                break;
            default: break;
        }
        return complete;
    };

    const onPrevious = useCallback(() => {

        if (policySteps.vehicleDone && !policySteps.driverDone) {
            const policyDrivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.children');

            _.forEach(policyDrivers, (driver, index) => {
                _.set(submissionVM, `lobData.personalAuto.coverables.drivers.children.${index}.isPolicyInfo`, false);
            });
            updateWizardSnapshot(submissionVM);
        }
    }, [submissionVM, updateWizardSnapshot, policySteps]);

    const onNext = useCallback(async () => {
        dataLayer.push({'event':'gtm.click', 'gtm.elementId': 'qb_next'});
        const driverResponse = _.get(submissionVM.value, 'lobData.personalAuto.coverables.drivers');
        let count = 0;
        let licenseReason;
        _.forEach(driverResponse, async (driverObj) => {
            if (!_.isUndefined(driverObj.licenseReason) && !driverObj.validUSLicense) {
                count += 1;
                if (_.isUndefined(licenseReason) && driverObj.licenseReason === 'nonuslicensed_alfa') {
                    licenseReason = driverObj.licenseReason;
                }
            }
        });
        if (licenseReason) {
            await ErrorHandlingUtil.processErrorResponse(props, {errorDescription: "Non-US licensed driver"}, submissionVM.value, currentPage);
        }
        if (count > 2) {
            await ErrorHandlingUtil.processErrorResponse(props, {errorDescription: 'No of inValid US licensed drivers are greater than 2'}, submissionVM.value, currentPage);
        }
        if (!policySteps.contactDone) {
            if (!submissionVM.baseData.accountHolder.aspects.subtreeValid || dateErrorMessage !== '' || mobileError) {
                setMailAddressError(true);
                return false;
            }
            setMailAddressError(false);
            addContact();
        }
        if (!vehicleSteps || !driverSteps || !policySteps.contactDone
            || (mvrEnable && !policySteps.historyDone)) {
            return false;
        }
        const quoteId = _.get(submissionVM, 'quoteID.value');
        const discounts = await LoadSaveService.getQuoteDiscounts(quoteId);
        _.set(submissionVM, 'baseData.discount', discounts);
        updateWizardData(submissionVM);
        await checkPremium();

        if (isPremiumChanged || editInfo) {
            setQuoteCalled(true);
            jumpTo(5);
        }
        return submissionVM;
    }, [submissionVM, policySteps.contactDone, policySteps.historyDone, vehicleSteps, driverSteps, mobileError, mvrEnable, checkPremium, isPremiumChanged, editInfo, props, dateErrorMessage, addContact, setQuoteCalled, jumpTo]);

    const renderAccordionHeader = useCallback(
        (tileName, tileID, isSelected) => {
            return (isOpen) => (
                <div className={styles.accordionHeaderContainer}>
                    <PolicyInfoHeaderComponent
                        tileName={tileName}
                        tileID={tileID}
                        isAccordionOpen={isOpen}
                        isSelected={isSelected}
                        onEditTile={editTile}
                    />
                </div>
            );
        }, [editTile]
    );

    const searchItems = async (userInput) => {
        let results = [];
        if (userInput.length >= 5) {
            try {
                const response = await AddCoverablesService.searchContacts(userInput);
                results = [];
                _.forEach(response, (contact) => {
                    results.push(contact);
                });
                results.push({ addressBookUID: 'Other', companyName: 'Other', companyAddress: '' });
            } catch (error) {
                // eslint-disable-next-line no-console
                callCaptchaErrorHandler(error);
                console.log(error);
            }
        }
        return results;
    };

    const handleFirstUserSelection = useCallback((company, path) => {
        // eslint-disable-next-line prefer-destructuring
        const currentPath = path.split('leasingCompany')[0];
        if (company !== undefined) {
            setTriggerValidation(false);
            if (company.addressBookUID !== 'Other') {
                _.set(submissionVM, path, company.companyName);
                _.set(submissionVM, `${currentPath}leasingCompanyID`, company.addressBookUID);
                _.set(submissionVM, `${currentPath}isOtherFirstCmpy`, false);
            } else {
                _.set(submissionVM, path, 'Other');
                _.set(submissionVM, `${currentPath}leasingCompanyID`, 'Other');
                _.set(submissionVM, `${currentPath}isOtherFirstCmpy`, true);
                const baseState = _.get(submissionVM, 'baseData.accountHolder.primaryAddress.state.value');
                const addressDTO = {
                    addressLine1: undefined,
                    addressLine2: undefined,
                    city: undefined,
                    state: baseState,
                    postalCode: undefined,
                    county: undefined,
                    country: localeService.getDefaultCountryCode()
                };
                _.set(submissionVM, `${currentPath}otherFirstCmpyAddress`, {});
                const firstAddress = viewModelService.create(addressDTO, 'pc', 'alfa.edge.capabilities.address.dto.AddressDTO');
                _.set(submissionVM, `${currentPath}otherFirstCmpyAddress`, firstAddress.value);
                _.set(submissionVM, `${currentPath}otherFirstCmpyAddress.isOtherFirstCmpy`, true);
            }
        } else {
            _.set(submissionVM, path, undefined);
            _.set(submissionVM, `${currentPath}leasingCompanyID`, undefined);
        }
        updateWizardData(submissionVM);
    }, [updateWizardData, submissionVM, localeService, viewModelService]);

    const handleSecUserSelection = useCallback((company, path) => {
        // eslint-disable-next-line prefer-destructuring
        const currentPath = path.split('secCompany')[0];
        if (company !== undefined) {
            setTriggerValidation(false);
            if (company.addressBookUID !== 'Other') {
                _.set(submissionVM, path, company.companyName);
                _.set(submissionVM, `${currentPath}secCompanyID`, company.addressBookUID);
                _.set(submissionVM, `${currentPath}isOtherSecondCmpy`, false);
            } else {
                _.set(submissionVM, path, 'Other');
                _.set(submissionVM, `${currentPath}secCompanyID`, 'Other');
                _.set(submissionVM, `${currentPath}isOtherSecondCmpy`, true);
                const baseState = _.get(submissionVM, 'baseData.accountHolder.primaryAddress.state.value');
                const addressDTO = {
                    addressLine1: undefined,
                    addressLine2: undefined,
                    city: undefined,
                    state: baseState,
                    postalCode: undefined,
                    county: undefined,
                    country: localeService.getDefaultCountryCode()
                };
                _.set(submissionVM, `${currentPath}otherSecondCmpyAddress`, {});
                const secAddress = viewModelService.create(addressDTO, 'pc', 'alfa.edge.capabilities.address.dto.AddressDTO');
                _.set(submissionVM, `${currentPath}otherSecondCmpyAddress`, secAddress.value);
                _.set(submissionVM, `${currentPath}otherSecondCmpyAddress.isOtherSecondCmpy`, true);
            }
        } else {
            _.set(submissionVM, path, undefined);
            _.set(submissionVM, `${currentPath}secCompanyID`, undefined);
        }
        updateWizardData(submissionVM);
    }, [updateWizardData, submissionVM, localeService, viewModelService]);

    const getAddressName = (address) => {
        let addressFirstLabel = `${address.addressLine1}`;
        if (address.addressLine2 !== undefined) {
            addressFirstLabel = `${address.addressLine1} ${address.addressLine2}`;
        }
        const addressSecLabel = `${address.city}, ${address.state} ${address.postalCode}`;
        return (
            <div>
                <span className={styles.addressName}>
                    Is &nbsp;
                    <span className={styles.addressNameLabel}>
                        {addressFirstLabel}
                    &nbsp;
                        {addressSecLabel}
                    </span>
                    &nbsp;
                    within city limits?
                </span>
                <span className={styles.addressNameMobile}>
                    Is &nbsp;
                    <span className={styles.addressNameLabel}>
                        {addressFirstLabel}
                        {addressSecLabel}
                    </span>
                    <br />
                        within city limits?
                </span>
            </div>
        );
    };

    const getMailingAddressName = () => {
        const address = _.get(submissionVM, 'baseData.accountHolder.primaryAddress.value');
        let addressFirstLabel = `${address.addressLine1}`;
        if (address.addressLine2 !== undefined) {
            addressFirstLabel = `${address.addressLine1} ${address.addressLine2}`;
        }
        const addressSecLabel = `${address.city}, ${address.state}, ${address.postalCode} ?`;
        return (
            <div>
                <span className={styles.mailingAddressName}>
                    Is your mailing address
                    <br />
                    {addressFirstLabel}
                    <br />
                    {addressSecLabel}
                </span>
            </div>
        );
    };

    const getDriverName = useCallback((driver) => {
        const driverName = ''.concat(driver.person.firstName, ' ', driver.person.lastName);
        const hasExclude = driver.licenseReason !== undefined && driver.licenseReason !== 'NotOfAgeToDrive' && !driver.validUSLicense;
        return (
            <div className={styles.driverName}>
                <span>
                    {driverName}
                </span>
                {hasExclude
                    ? (<span>&nbsp;-&nbsp;Excluded</span>
                    ) : null}
            </div>
        );
    }, []);

    const getMvrName = (mvr) => {
        const driverName = ''.concat(mvr.firstName, ' ', mvr.lastName);
        const month = mvr.violationDate.month + 1;
        const dateString = ''.concat(month, '/', mvr.violationDate.day, '/', mvr.violationDate.year);
        const shortclaimDate = formatShortDate(new Date(dateString));
        return (
            <div className={styles.mvrDriverName}>
                <span>
                    {driverName}
                </span>
                <span className={styles.mvrDate}>{shortclaimDate}</span>
            </div>
        );
    };

    const getMvrNameOnly = (mvr) => {
        const driverName = ''.concat(mvr.firstName, ' ', mvr.lastName);
        return (
            <div className={styles.mvrDriverName}>
                <span>
                    {driverName}
                </span>
            </div>
        );
    };

    const handleLicenseNumber = useCallback((value, path) => {
        const index = path.replace(/[^\d]+/gi, '');
        const diasbleArray = _.cloneDeep(disableMask);
        const maskedLicense = value.replace('*', '');
        if (maskedLicense === value) {
            _.set(submissionVM, path, value);
            updateWizardData(submissionVM);
        }
        diasbleArray[index] = true;
        setDisableMask(diasbleArray);
    }, [disableMask, submissionVM, updateWizardData]);


    const getAvailableList = useCallback((type, index, model) => {
        const field = _.get(submissionVM, `lobData.personalAuto.coverables.${type}.children.${index}.${model}.aspects.availableValues`);
        const availableList = field.map((key) => {
            return {
                code: key.code,
                name: translator({ id: key.name, defaultMessage: key.code })
            };
        });
        if (model === 'licenseState') {
            const licenseList = field.map((key) => {
                return {
                    code: key.code,
                    name: translator({ id: key.code, defaultMessage: key.code })
                };
            });
            return licenseList;
        }
        return availableList;
    });

    const checkListValidity = (currentPath) => {
        const path = `lobData.personalAuto.coverables.${currentPath}.children`;
        const items = _.get(submissionVM, path, []);
        const checkDisable = _.find(items, (v) => {
            if (!v.aspects.subtreeValid) {
                return v;
            }
            if (!_.get(v, 'vin.value') || _.get(v, 'vin.value').length < 17) {
                return v;
            }
            return undefined;
        });

        if ((!_.isEmpty(checkDisable) && !_.isEmpty(checkDisable.driverAge) && checkDisable.driverAge.value !== undefined && checkDisable.driverAge.value >= 16) || (showVinError || !_.isUndefined(checkDisable) && !_.isUndefined(checkDisable.vin) && (_.isUndefined(checkDisable.vin.value) || checkDisable.vin.value.length !== 17))) {
            return true;
        }else if (vehiclePossession == undefined || vehicleRegister == undefined || dontHaveDamage ==  undefined){
            return true;
        }
        return false;
    };

    const inValidSSN = () => {
        const path = 'lobData.personalAuto.coverables.drivers.value';
        const items = _.get(submissionVM, path, []);
        const checkDisable = _.find(items, (d) => {
            if (d.ssnNumber !== undefined && d.ssnNumber.length < 9) {
                return d;
            }
            return undefined;
        });

        if (!_.isEmpty(checkDisable)) {
            return true;
        }
        return false;
    };


    const generateOverrides = useCallback(() => {
        if ((editInfo && quoteCalled)) {
            skipPage = true;
            initial = () => Promise.resolve(true);
        }
    }, []);
    const generateVehicleOverrides = useCallback(() => {
        const policyVehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value', []);
        const allVehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles', []);
        // damageVehicleGroupContainer
        const overrides = policyVehicles.map((vehicle, index) => {
            let vehicleBaseName = ''.concat(vehicle.year, ' ', vehicle.make, ' ', vehicle.model);
            if (vehicle.bodyType !== undefined) {
                vehicleBaseName = `${vehicleBaseName} ${vehicle.bodyType}`;
            }
            if (vehicle.vehicleTrim !== undefined) {
                vehicleBaseName = `${vehicleBaseName} / ${vehicle.vehicleTrim}`;
            }
            const vehicleName = vehicleBaseName;
            const displayAddress = _.find(garageAddresses, { vehicleId: vehicle.publicID });
            const currentPath = `lobData.personalAuto.coverables.vehicles.children.${index}`;
            const error = _.find(vinError, { publicId: vehicle.publicID });
            return {
                [`vinAddSelection${index}`]: {
                    visible: vehicle.isSelectedVehicle && !vehicle.isPrefillVehicle && noVinVehicle.includes(vehicle.publicID)
                },

                [`vinText${index}`]: {
                    content: vehicleName,
                    visible: (vehicle.isSelectedVehicle && !vehicle.isPrefillVehicle && noVinVehicle.includes(vehicle.publicID)) || vehicle.leaseOrRent
                },
                [`hrVin${index}`]: {
                    visible: vehicle.isSelectedVehicle && !vehicle.isPrefillVehicle && noVinVehicle.includes(vehicle.publicID)
                },
                [`tooltipDiv${index}`]: {
                    content: getDivTooltipText()
                },
                [`firstLeaseContainer${index}`]: {
                    visible: vehicle.leaseOrRent
                },
                [`firstLeaseError${index}`]: {
                    content: vehicle.leasingCompany === undefined && leaseCompanyError ? 'Please enter a Finance/Leasing Company.' : '',
                    visible: leaseCompanyError && _.isEmpty(vehicle.leasingCompany)
                },
                [`firstLeaseCmpyComponent${index}`]: {
                    visible: vehicle.isOtherFirstCmpy !== undefined && vehicle.isOtherFirstCmpy
                },
                [`firstOtherLeaseAddrContainer${index}`]: {
                    visible: vehicle.isOtherFirstCmpy !== undefined && vehicle.isOtherFirstCmpy
                },
                [`firstOtherLeaseAddr${index}`]: {
                    value: allVehicles.children[index].otherFirstCmpyAddress,
                    showErrors: triggerValidation && vehicle.isOtherFirstCmpy !== undefined && vehicle.isOtherFirstCmpy
                },
                [`secOtherLeaseAddr${index}`]: {
                    value: allVehicles.children[index].otherSecondCmpyAddress,
                    showErrors: triggerValidation && vehicle.isOtherSecondCmpy !== undefined && vehicle.isOtherSecondCmpy
                },
                [`firstOtherLease${index}`]: {
                    onValueChange: handleLeaseChange,
                    placeholder: _.isEmpty(vehicle.otherFirstCmpy) ? 'Other Financing/Leasing Company' : '',
                },
                [`firstOtherLeaseError${index}`]: {
                    content: _.isEmpty(vehicle.otherFirstCmpy) ? 'Please enter a other financing/leasing company' : '',
                    visible: triggerValidation && vehicle.isOtherFirstCmpy !== undefined && vehicle.isOtherFirstCmpy && _.isEmpty(vehicle.otherFirstCmpy)
                },
                [`firstLeaseCmpyText${index}`]: {
                    content: !_.isEmpty(vehicle.otherFirstCmpy) ? 'Other Financing/Leasing Company' : ''
                },
                [`firstLeaseLookupText${index}`]: {
                    content: !_.isEmpty(vehicle.leasingCompany) ? 'Finance/Leasing Company' : ''
                },
                [`firstLeaseLookupDiv${index}`]: {
                    data: !_.isEmpty(vehicle.leasingCompany) ? vehicle.leasingCompany : ''
                },
                [`secLeaseLookupText${index}`]: {
                    content: !_.isEmpty(vehicle.secCompany) ? 'Secondary Company' : ''
                },
                [`addAdditionalCompany${index}`]: {
                    visible: !vehicle.hasSecondCompany,
                    content: '+  Add Additional Company',
                    onClick: () => writeAdditionalValue(currentPath)
                },
                [`secondLeaseContainer${index}`]: {
                    visible: vehicle.hasSecondCompany !== undefined && vehicle.hasSecondCompany
                },
                [`secondLeaseLookupDiv${index}`]: {
                    data: !_.isEmpty(vehicle.secCompany) ? vehicle.secCompany : ''
                },
                [`secOtherLease${index}`]: {
                    onValueChange: handleLeaseChange,
                    placeholder: _.isEmpty(vehicle.otherSecondCmpy) ? 'Other Financing/Leasing Company' : ''
                },
                [`secOtherLeaseError${index}`]: {
                    content: _.isEmpty(vehicle.otherSecondCmpy) ? 'Please enter a other financing/leasing company' : '',
                    visible: triggerValidation && vehicle.isOtherSecondCmpy !== undefined && vehicle.isOtherSecondCmpy && _.isEmpty(vehicle.otherSecondCmpy)
                },
                [`secOtherLeaseText${index}`]: {
                    content: !_.isEmpty(vehicle.otherSecondCmpy) ? 'Other Financing/Leasing Company' : ''
                },
                [`leaseButtonIcon${index}`]: {
                    visible: vehicle.hasSecondCompany !== undefined && vehicle.hasSecondCompany,
                    onClick: () => cancelSecondary(currentPath)
                },
                [`secOtherLeaseContainer${index}`]: {
                    visible: vehicle.isOtherSecondCmpy !== undefined && vehicle.isOtherSecondCmpy
                },
                [`secOtherLeaseAddrContainer${index}`]: {
                    visible: vehicle.isOtherSecondCmpy !== undefined && vehicle.isOtherSecondCmpy
                },
                [`vehicleVin${index}`]: {
                    onValueChange: handleVinChange,
                    placeholder: _.isEmpty(vehicle.vin) ? 'VIN' : ''
                },
                [`vinButton${index}`]: {
                    disabled: isVinBtnDisabled.includes(vehicle.publicID),
                    content: !isVinVerified.includes(vehicle.publicID) ? 'Verify VIN' : 'Re-verify VIN',
                    onClick: () => validateVin(currentPath)
                },
                [`vehicleVinText${index}`]: {
                    content: !_.isEmpty(vehicle.vin) ? 'VIN' : ''
                },
                [`vinError${index}`]: {
                    content: error !== undefined ? error.vinErrorMessage : '',
                    visible: showVinError || error !== undefined
                },
                [`garageAddrDiv${index}`]: {
                    content: getAddressName(vehicle.garagingAddress),
                },
                [`vehicleAddressToggle${index}`]: {
                    defaultValue: true
                },
                [`vehicleAddress${index}`]: {
                    visible: displayAddress !== undefined
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [submissionVM, handleVinChange, handleLeaseChange, vinError, showVinError, triggerValidation, leaseCompanyError, isVinBtnDisabled, isVinVerified, cancelSecondary, garageAddresses, getDivTooltipText, noVinVehicle, validateVin, writeAdditionalValue]);


    const generateDriverOverrides = useCallback(() => {
        const policyDrivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value', []);
        const overrides = policyDrivers.map((driver, index) => {
            const ssn = _.find(prefillSsn, { publicId: driver.publicID });
            const license = _.find(prefillLicense, { publicId: driver.publicID });
            const driverPrefillLicense = license !== undefined ? license.license : true;
            const overrideModel = driver.licenseState;
            let licenseError = false;
            let LicenseTemp = '';
            let driverPrefillSsn = false;
            if (ssn !== undefined && ssn.number !== undefined) {
                if (ssn.number.substring(ssn.number.length - 4) !== '0000') {
                    driverPrefillSsn = ssn.ssn;
                }
            }
            if (!_.isUndefined(driver.licenseNumber)) {
                const licenseLength = driver.licenseNumber.length;

                licenseError = driver.licenseNumber.length === 0;
                if (!_.isEmpty(disableMask) && disableMask[index]) {
                    LicenseTemp = driver.licenseNumber;
                } else {
                    const mask = '*';
                    const maskedLicense = mask.repeat(licenseLength >= 4 && licenseLength - 4)
                        + driver.licenseNumber.substring(licenseLength >= 4 && licenseLength - 4);
                    LicenseTemp = maskedLicense;
                }
            }
            return {
                [`ssnGroupDiv${index}`]: {
                    content: getDriverName(driver),
                    visible: (driver.driverAge >= 16 && !driverPrefillLicense) || (!driverPrefillSsn && (driver.isPolicyHolder || driver.relationshipToPNI === 'SP'))
                },
                [`ssnInput${index}`]: {
                    visible: !driverPrefillSsn && (driver.isPolicyHolder || driver.relationshipToPNI === 'SP')
                },
                [`licenseGroupContainer${index}`]: {
                    visible: driver.driverAge >= 16 && !driverPrefillLicense
                },
                [`driverLicenseReason${index}`]: {
                    availableValues: getAvailableList('drivers', index, 'licenseReason')
                },
                [`licenseNumberText${index}`]: {
                    content: !_.isEmpty(driver.licenseNumber) ? 'License Number' : ''
                },
                [`mobileLicenseNumberText${index}`]: {
                    content: !_.isEmpty(driver.licenseNumber) ? 'License Number' : ''
                },
                [`driverState${index}`]: {
                    availableValues: getAvailableList('drivers', index, 'licenseState'),
                },
                [`driverLicenseNumber${index}`]: {
                    placeholder: _.isEmpty(driver.licenseNumber) ? 'License Number' : ''
                },
                [`mobileDriverLicenseNumber${index}`]: {
                    placeholder: _.isEmpty(driver.licenseNumber) ? 'License Number' : ''
                },
                [`driverHr${index}`]: {
                    visible: (driver.driverAge >= 16 && !driverPrefillLicense) || (!driverPrefillSsn && (driver.isPolicyHolder || driver.relationshipToPNI === 'SP'))
                },
                [`driverLicenseReasonDropDown${index}`]: {
                    visible: !driver.validUSLicense
                },
                [`excludedLabel${index}`]: {
                    visible: driver.licenseReason !== undefined && driver.licenseReason !== 'NotOfAgeToDrive' && !driver.validUSLicense
                },
                [`driverLicenseGroup${index}`]: {
                    visible: driver.validUSLicense
                },
                [`mobileLicenseNumberComponent${index}`]: {
                    visible: driver.driverAge >= 16 && !driverPrefillLicense
                },
                [`moreDriverGroupDiv${index}`]: {
                    content: getDriverName(driver),
                    visible: (driver.driverAge >= 16 && !driverPrefillLicense) || (driver.person.ssnNumber !== undefined && (driver.isPolicyHolder))
                },
                [`mvrSsnInput${index}`]: {
                    visible: driver.person.ssnNumber !== undefined && (driver.isPolicyHolder || driver.relationshipToPNI === 'SP')
                },
                [`mvrLicenseGroupContainer${index}`]: {
                    visible: driver.driverAge >= 16
                },
                [`mvrGroupNameDiv${index}`]: {
                    content: getMvrNameOnly(driver),
                    visible: driver.driverAge >= 16 && driverPrefillLicense
                },
                [`mvrlicenseToggle${index}`]: {
                    visible: driver.driverAge >= 16 && !driverPrefillLicense
                },
                [`mvrlicenseGroupLabel${index}`]: {
                    visible: driver.driverAge >= 16 && !driverPrefillLicense
                },

                [`mvrdriverLicenseNumber${index}`]: {
                    value: LicenseTemp,
                },
                [`licenseNumberErrorText${index}`]: {
                    visible: licenseError,
                    content: driverErrorMessage
                },
                [`moreDriverHr${index}`]: {
                    visible: (driver.driverAge >= 16 && !driverPrefillLicense) || (driver.person.ssnNumber !== undefined && (driver.isPolicyHolder || driver.relationshipToPNI === 'SP'))
                },
            };
        });
        
        return Object.assign({}, ...overrides);
    }, [submissionVM, prefillSsn, prefillLicense, getDriverName, getAvailableList, disableMask]);

    const generateIncidentOverrides = useCallback(() => {
        const mvrIncidents = _.get(submissionVM, 'lobData.personalAuto.coverables.mvrIncidents.value', []);
        const overrides = mvrIncidents.map((mvr, index) => {
            return {
                [`mvrGroupDiv${index}`]: {
                    content: getMvrName(mvr),
                },
                [`mvrDamageLessGroupContainer${index}`]: {
                    visible: mvr.isDamageLessThan900_alfa !== undefined && mvr.isDamageLessThan900_alfa
                },
                [`driverAccidentDiv${index}`]: {
                    visible: mvr.driverAtFault_alfa !== undefined && !mvr.driverAtFault_alfa
                },
                [`driverAccident${index}`]: {
                    availableValues: getAvailableList('mvrIncidents', index, 'accidentDetails_alfa')
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [submissionVM, getAvailableList]);

    const getOpenTileName = (type) => {
        const defaultTileName = 'AccordionCard';
        if (type === currentTile && !showAllTiles) {
            return `${type}${defaultTileName}`;
        }
        return null;
    };

    const getContactClass = () => {
        if (currentTile === 'contact' && contactOnloadCount.current !== 0 && !showAllTiles) {
            return 'blockCollapse';
        }
        contactOnloadCount.current += 1;
        return 'allowCollapse';
    };

    const showInvalidZip = (invalidZip) => {
        setInvalidZip(invalidZip);
    };

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true
        },
        phoneNumber: {
            countryCode: localeService.getDefaultPhoneCountry()
        },
        numberPhone:{
             value: phoneNumber.length <= 10 ? CommonUtil.formatPhoneNumber(phoneNumber) : phoneNumber,
             showErrors: mobileError,
             controlClassName: mobileError ? styles.mockError : styles.mock
        },
        vehicleList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
        },
        driverList: {
            data: _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value')
        },
        vehicleAccordionCard: {
            header: renderAccordionHeader(
                'Vehicle Details',
                'vehicle',
                tileStatus('vehicle')
            ),
            collapseClassName: currentTile === 'vehicle' ? 'blockCollapse' : 'allowCollapse'
        },
        policyVehicleAccordionSet: {
            defaultOpenedId: getOpenTileName('vehicle'),
            visible: currentTile === 'vehicle' || showAllTiles
        },
        driverAccordionCard: {
            header: renderAccordionHeader(
                'Driver Details',
                'driver',
                tileStatus('driver')
            ),
            collapseClassName: currentTile === 'driver' ? 'blockCollapse' : 'allowCollapse'
        },
        policyDriverAccordionSet: {
            defaultOpenedId: getOpenTileName('driver'),
            visible: currentTile === 'driver' || showAllTiles
        },
        contactAccordionCard: {
            header: renderAccordionHeader(
                'Policy Details',
                'contact',
                tileStatus('contact')
            ),
            collapseClassName: getContactClass()
        },
        policyContactAccordionSet: {
            defaultOpenedId: getOpenTileName('contact'),
            visible: currentTile === 'contact' || showAllTiles
        },
        vehicleDamageToggle: {
            value: dontHaveDamage,
            onValueChange: writeDamageValue
        },
        existingVehicleDamageToggle: {
            value: dontHaveDamage,
            onValueChange: writeDamageValue
        },
        vehiclePossessioneToggle: {
            value: vehiclePossession,
            onValueChange: writePossessionValue
        },
        vehicleRegistrationeToggle: {
            value: vehicleRegister,
            onValueChange: writeRegistrationValue
        },
        phoneDiv: {
            onBlur: validatePhone,
            tabIndex: 0
        },
        phoneError: {
            visible: mobileError
        },
        emailError: {
            visible: !submissionVM.baseData.accountHolder.emailAddress1.aspects.subtreeValid
        },
        policyDateError: {
            content: dateErrorMessage
        },
        addVehicleButton: {
            disabled: checkListValidity('vehicles')
        },
        driverBlackoutToggle: {
            value: hadBlackoutsOrSeizures,
            onValueChange: writeBlackoutValue
        },
        driverArrestedToggle: {
            value: hadArrestedLast5yrs,
            onValueChange: writeArrestedValue
        },
        mailingAddressToggle: {
            onValueChange: writeMailAddressValue
        },
        addDriverButton: {
            disabled: !submissionVM.lobData.personalAuto.coverables.drivers.aspects.subtreeValid || hadBlackoutsOrSeizures == undefined || hadArrestedLast5yrs == undefined || inValidSSN()
        },
        addMoreButton: {
            disabled: !submissionVM.lobData.personalAuto.coverables.drivers.aspects.subtreeValid
                || !submissionVM.lobData.personalAuto.coverables.mvrIncidents.aspects.subtreeValid || inValidSSN()
        },
        mailingAddrDiv: {
            content: getMailingAddressName()
        },
        mailAddrMainContainer: {
            visible: !_.get(submissionVM, 'baseData.accountHolder.isMailingAddressSame.value')
        },
        mailingAddress: {
            onValueChange: writeAddressValue,
            triggerStdProcess: triggerStdProcess,
            addressStandardized: addressStandardized,
            showErrors: mailAddressError,
            updateStandardizationRan: updateStandardizationRan,
            syncAddress: syncAddress,
            lockAddressState: true,
            showInvalidZip: showInvalidZip
        },
        startDate: {
            onValueChange: writePolicyDateValue,
            value: startDateValue,
            minDate: minDate,
            maxDate: maxPolicyDate
        },
        addContactButton: {
            disabled: (!_.get(submissionVM, 'baseData.accountHolder.isMailingAddressSame.value') && !addressPopulated) || !submissionVM.baseData.accountHolder.aspects.subtreeValid || dateErrorMessage !== '' || mobileError || invalidZip
        },
        historyAccordionSet: {
            defaultOpenedId: getOpenTileName('history'),
            visible: mvrEnable && (currentTile === 'history' || showAllTiles)
        },
        historyAccordionCard: {
            header: renderAccordionHeader(
                'More Details',
                'history',
                tileStatus('history')
            ),
            collapseClassName: currentTile === 'history' && !showAllTiles ? 'blockCollapse' : 'allowCollapse'
        },
        ...generateOverrides(),
        ...generateVehicleOverrides(),
        ...generateDriverOverrides(),
        ...generateIncidentOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRenderCell: renderCellContent,
            addVehicle: addVehicle,
            writeValue: writeValue,
            addDriver: addDriver,
            addContact: addContact,
            addHistory: addHistory,
            handleFirstUserSelection: handleFirstUserSelection,
            handleSecUserSelection: handleSecUserSelection,
            searchItems: searchItems,
            handleLicenseNumber: handleLicenseNumber,
            getPhoneNumber:getPhoneNumber
        },
        resolveComponentMap: {
            tooltipcomponent: PolicyInformationTooltip
        }
    };

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
            <WizardPage onPrevious={onPrevious} onNext={onNext} skipWhen={initial} userSkip={skipPage} showNext={policySteps.driverDone && ((mvrEnable && showAllTiles) || (!mvrEnable))} showCancel={false} previousLabel="Back" nextLabel="Next : Payment">
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </WizardPage>
        );
}

PolicyInformationPage.propTypes = wizardProps;
export default withRouter(PolicyInformationPage);
