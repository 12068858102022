import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FieldComponent, ModalService } from '@jutro/components';
import { withValidation, validationPropTypes } from 'gw-portals-validation-react';
import { ServiceManager } from '@jutro/services';
import { loadGoogleMapsAPI } from 'gw-portals-google-maps-js';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './GooglePlacesLookupComponent.metadata.json5';
import styles from './GooglePlacesLookupComponent.module.scss';
import messages from './GooglePlacesLookupComponent.messages';

class GooglePlacesLookupComponent extends FieldComponent {
    /**
     * @memberof gw-capability-address-react.GooglePlacesLookupComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.postalCode - the postal code entered in the landing page
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {function} propTypes.onValidate - returns if the values are valid
     */
    static propTypes = {
        value: PropTypes.shape({ postalCode: PropTypes.string }),
        path: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onValidate: PropTypes.func,
        onValueChange: PropTypes.func,
        label: PropTypes.string,
        labelPosition: PropTypes.string,
        required: PropTypes.bool,
        ...FieldComponent.propTypes,
        ...validationPropTypes
    };

    static defaultProps = {
        onValidate: undefined,
        value: undefined,
        onValueChange: undefined,
        layout: 'full-width',
        hideLabel: true,
        label: undefined,
        labelPosition: undefined,
        required: false
    };

    searchInputRef = null;

    selectedGoogleDropdown = false;

    state = {
        errorText: '',
        formData: {},
        manualAddressOverride: undefined
    };

    componentDidMount() {
        const {
            registerComponentValidation, isComponentValid, onValidate, id
        } = this.props;
        const localeService = ServiceManager.getService('locale-service');

        registerComponentValidation(this.isFieldsValid);

        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        const { value: addressData } = this.props;

        const options = {
            componentRestrictions: {
                country: localeService.getDefaultCountryCode()
            }
        };

        loadGoogleMapsAPI().then((response) => {
            const googleMapsApi = response;

            const autocomplete = new googleMapsApi.places.Autocomplete(
                this.searchInputRef,
                options
            );

            googleMapsApi.event.addListener(autocomplete, 'place_changed', () => {
                this.selectedGoogleDropdown = true;
                const valueReturnedByGoogle = this.searchInputRef.value;

                const place = autocomplete.getPlace();
                const postalCodeComponent = _.find(place.address_components, (addressComponent) => {
                    return _.includes(addressComponent.types, 'postal_code');
                });

                if (postalCodeComponent !== undefined) {
                    if (addressData.postalCode.value === postalCodeComponent.long_name) {
                        this.mapGooglePlacesAddressToDTO(place);

                        // manually set the value returned by the google API
                        // into the search input field. The value returned is
                        // a string composed by addressLine1, City, State, Cuntry
                        // separated by comma
                        const { formData } = this.state;
                        _.set(formData, 'searchText', valueReturnedByGoogle);
                        this.setState({ formData });
                    } else {
                        ModalService.showError({
                            title: messages.availabilityLookupErrorTitle,
                            message: messages.availabilityLookupErrorMessage
                        });
                        this.clearTextField();
                    }
                }
            });
        });
    }

    mapGooglePlacesAddressToDTO = (place) => {
        const { value: addressData } = this.props;

        place.address_components.forEach((addressComponent) => {
            if (addressComponent.types.includes('street_number')) {
                addressData.addressLine1.value = addressComponent.long_name;
                this.handleChange(addressComponent.long_name, 'addressLine1');
            }
            if (addressComponent.types.includes('route')) {
                const streetNumber = addressData.addressLine1.value;
                addressData.addressLine1.value = `${streetNumber} ${addressComponent.long_name}`;
                this.handleChange(`${streetNumber} ${addressComponent.long_name}`, 'addressLine1');
            }
            if (
                addressComponent.types.includes('neighborhood')
                && addressComponent.types.includes('political')
            ) {
                addressData.addressLine2.value = addressComponent.long_name;
                this.handleChange(addressComponent.long_name, 'addressLine2');
            }
            if (
                addressComponent.types.includes('locality')
                && addressComponent.types.includes('political')
            ) {
                addressData.city.value = addressComponent.long_name;
                this.handleChange(addressComponent.long_name, 'city');
            }
            if (
                addressComponent.types.includes('sublocality_level_1')
                && addressComponent.types.includes('sublocality')
                && addressComponent.types.includes('political')
            ) {
                addressData.city.value = addressComponent.long_name;
                this.handleChange(addressComponent.long_name, 'city');
            }
            if (addressComponent.types.includes('postal_code')) {
                addressData.postalCode.value = addressComponent.long_name;
                this.handleChange(addressComponent.long_name, 'postalCode');
            }
            if (
                addressComponent.types.includes('administrative_area_level_1')
                && addressComponent.types.includes('political')
            ) {
                addressData.state.value = addressComponent.short_name;
                this.handleChange(addressComponent.short_name, 'state');
            }
        });
        if (_.isEmpty(addressData.addressLine1.value) || _.isEmpty(addressData.city.value)) {
            ModalService.showError({
                title: messages.unsuccessfulMappingTitle,
                message: messages.unsuccessfulMappingMessage
            });
        }
    };

    isFieldsValid = () => {
        const { value: address } = this.props;

        return address.aspects.valid && address.aspects.subtreeValid;
    };

    componentDidUpdate(prevProps) {
        const { value: oldAddressData } = prevProps;
        const {
            isComponentValid,
            value: newAddressData,
            hasValidationChanged,
            onValidate,
            id
        } = this.props;

        const isAddressTheSame = _.isEqual(newAddressData.value, oldAddressData.value);

        if (onValidate && (!isAddressTheSame || hasValidationChanged)) {
            onValidate(isComponentValid, id);
        }
    }

    handleChange = (value, changedPath) => {
        const { path, onValueChange } = this.props;
        const { formData } = this.state;

        _.set(formData, changedPath, value);

        onValueChange(value, `${path}.${changedPath}`);

        this.setState({ formData });
    };

    shouldComponentUpdate = () => {
        return true;
    };

    writeValue = (value, path) => {
        const { formData } = this.state;
        _.set(formData, path, value);
        this.setState({
            formData
        });
    };

    clearTextField = () => {
        const { formData } = this.state;
        _.set(formData, 'searchText', '');
        this.selectedGoogleDropdown = false;
        this.setState({ formData });
    };

    renderErrorsMessage = () => {
        const { errorText } = this.state;
        return [errorText];
    };

    enterManualAddress = () => {
        this.setState({ manualAddressOverride: true });
    };

    enterAddressLookup = () => {
        this.setState({ manualAddressOverride: false });
    };

    setupSearchInputRef = (ref) => {
        if (!_.isNull(ref)) {
            this.searchInputRef = ref.querySelector('#addresslookupSearch');
        }
    };

    renderControl() {
        const { formData, manualAddressOverride } = this.state;
        const {
            label, required, labelPosition, value: address, isComponentValid
        } = this.props;

        const dataForComponent = {
            ...formData,
            ...address
        };

        const showManualAddress = !!manualAddressOverride
            || (isComponentValid
                && !this.selectedGoogleDropdown
                && manualAddressOverride === undefined);
        const showLookupAddress = !showManualAddress;

        const overrideProps = {
            '@field': {
                showOptional: true,
                labelPosition: labelPosition
            },
            addresslookupSearch: {
                onValueChange: this.writeValue,
                label: label,
                required: required
            },
            addressManualContainer: {
                visible: showManualAddress
            },
            addressLookupContainer: {
                visible: showLookupAddress
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                enterManualAddress: this.enterManualAddress,
                enterAddressLookup: this.enterAddressLookup
            }
        };

        return (
            <div ref={this.setupSearchInputRef}>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    model={dataForComponent}
                    overrideProps={overrideProps}
                    onValueChange={this.handleChange}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

export default withValidation(GooglePlacesLookupComponent);
