import HeaderLinkComponent from './HeaderLinkComponent/HeaderLinkComponent';
import ClauseComponent from './Clauses/ClauseComponent';
import ClausesComponentVM from './Clauses/ClausesComponentVM';
import SingleClauseComponentVM from './Clauses/SingleClauseComponentVM';
import './platform-styles.scss';
import ImageComponent from './ImageComponent/ImageComponent';
import FaqItemComponent from './FaqItemComponent/FaqItemComponent';
import FaqSectionComponent from './FaqSectionComponent/FaqSectionComponent';
import FieldLinkComponent from './FieldLinkComponent/FieldLinkComponent';
import PaymentList from './PaymentList/PaymentList';
import MediaSideBar from './MediaSidebarComponent/MediaSideBar';
import WizardSingleErrorComponent from './WizardSingleErrorComponent/WizardSingleErrorComponent';
import TabSetComponent from './TabSetComponent/TabSetComponent';
import TypeAheadComponent from './TypeAheadComponent/TypeAheadComponent';
import QuestionSetComponent from './QuestionSetComponent/QuestionSetComponent';
import KnockOutPage from './KnockOutPageComponent/KnockOutPage';
import TranslatedText from './TranslatedText/TranslatedText';
import CapabilityRoute from './CapabilityRoute';
import CarouselComponent from './CarouselComponent/CarouselComponent';
import CreditCardNumberComponent from './CreditCardNumberComponent/CreditCardNumberComponent';
import CreditCardExpiryDate from './CreditCardExpiryDate/CreditCardExpiryDate';
import DateRangeComponent from './DateRangeComponent/DateRangeComponent';
import TileComponent from './TileComponent/TileComponent';
import FacebookShare from './FacebookShare/FacebookShare';
import QuoteClauseTable from './QuoteClauseTable/QuoteClauseTable';
import ScheduleItemsComponent from './ScheduleItemsComponent/ScheduleItemsComponent';
import LinkComponent from './LinkComponent/LinkComponent';
import CustomizedHeaderComponent from './CustomizedHeaderComponent/CustomizedHeaderComponent';
import PaymentComponent from './PaymentComponent/PaymentComponent';
import UnderwritingIssues from './UnderwritingIssues/UnderwritingIssues';
import EntryPageTemplate from './EntryPageTemplate/EntryPageTemplate';
import SuccessNotification from './SuccessNotification/SuccessNotification';
import uwIssuesMessages from './UnderwritingIssues/UnderwritingIssues.messages';

const platformComponentMap = {
    carousel: { component: 'carousel' },
    ClauseComponent: { component: 'ClauseComponent' },
    ClausesComponentVM: { component: 'ClausesComponentVM' },
    CreditCardExpiryDate: { component: 'CreditCardExpiryDate' },
    CreditCardNumberComponent: { component: 'CreditCardNumberComponent' },
    DateRangeComponent: { component: 'DateRangeComponent' },
    FacebookShare: { component: 'FacebookShare' },
    faqItem: { component: 'FaqItemComponent' },
    faqSection: { component: 'FaqSectionComponent' },
    fieldLinkComponent: { component: 'FieldLinkComponent' },
    headerLinkComponent: { component: 'HeaderLinkComponent' },
    ImageComponent: { component: 'ImageComponent' },
    link: { component: 'link' },
    mediaSidebar: { component: 'MediaSideBar' },
    PaymentList: { component: 'PaymentList' },
    questionSet: { component: 'QuestionSetComponent' },
    QuoteClauseTable: { component: 'QuoteClauseTable' },
    ScheduleItemsComponent: { component: 'ScheduleItemsComponent' },
    CustomizedHeaderComponent: { component: 'CustomizedHeaderComponent' },
    SingleClauseComponentVM: { component: 'SingleClauseComponentVM' },
    TileComponent: { component: 'TileComponent' },
    TypeAheadComponent: { component: 'TypeAheadComponent' },
    PaymentComponent: { component: 'PaymentComponent' },
    UnderwritingIssues: { component: 'UnderwritingIssues' },
    EntryPageTemplate: { component: 'EntryPageTemplate' },
    SuccessNotification: { component: 'SuccessNotification' },
};

const platformComponents = {
    carousel: CarouselComponent,
    ClauseComponent: ClauseComponent,
    ClausesComponentVM: ClausesComponentVM,
    CreditCardExpiryDate,
    CreditCardNumberComponent,
    DateRangeComponent: DateRangeComponent,
    FacebookShare,
    FaqItemComponent: FaqItemComponent,
    FaqSectionComponent: FaqSectionComponent,
    FieldLinkComponent: FieldLinkComponent,
    HeaderLinkComponent: HeaderLinkComponent,
    ImageComponent,
    link: LinkComponent,
    MediaSideBar,
    PaymentList,
    QuestionSetComponent,
    QuoteClauseTable,
    ScheduleItemsComponent,
    CustomizedHeaderComponent,
    SingleClauseComponentVM,
    TileComponent,
    TypeAheadComponent,
    PaymentComponent,
    UnderwritingIssues,
    EntryPageTemplate,
    SuccessNotification,
};

export {
    CapabilityRoute,
    CarouselComponent,
    ClauseComponent,
    ClausesComponentVM,
    CreditCardExpiryDate,
    CreditCardNumberComponent,
    DateRangeComponent,
    FacebookShare,
    FaqItemComponent,
    FaqSectionComponent,
    FieldLinkComponent,
    HeaderLinkComponent,
    ImageComponent,
    LinkComponent as Link,
    WizardSingleErrorComponent,
    MediaSideBar,
    PaymentList,
    QuestionSetComponent,
    SingleClauseComponentVM,
    TabSetComponent,
    TileComponent,
    TypeAheadComponent,
    TranslatedText,
    CustomizedHeaderComponent,
    PaymentComponent,
    KnockOutPage,
    UnderwritingIssues,
    EntryPageTemplate,
    SuccessNotification,
    // for Jutro
    platformComponentMap,
    platformComponents,
    // messages
    uwIssuesMessages,
};
