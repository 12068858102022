import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { CurrencyField, Chevron } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './SuccessPage.module.scss';
import metadata from './SuccessPage.metadata.json5';
import messages from './SuccessPage.messages';

const ACCORDION_CARDS = [
    'driverAccordion',
    'vehicleAccordion',
    'coverageAccordion',
    'contactAccordion'
];

function renderCellContent(data, index, props) {
    const { path } = props;
    return _.get(data, path);
}

function renderCoverageAmount(row, rowIndex) {
    return _.isEmpty(row.amount) ? null : (
        <CurrencyField
            id={`coverageAmount_${rowIndex}`}
            value={row.amount}
            dataType="object"
            className={styles.currencyStyle}
            showFractions
            readOnly
            hideLabel
        />
    );
}

function renderAccordionHeader(title) {
    const containerClasses = classNames(styles.cardHeader, 'wizardTitle');
    const textClasses = classNames(styles.headerText, 'wizardTitleOnly');
    return (isOpen) => (
        <div className={containerClasses}>
            <Chevron align="right" isOpen={isOpen} chevronIcon="chevron-right" />
            <h2 className={textClasses}>{title}</h2>
        </div>
    );
}

function SuccessPage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const [isPrinting, setPrintingState] = useState(false);
    const { wizardData: submissionVM } = props;

    useEffect(() => {
        if (isPrinting) {
            // The accordion component requires a new rendering cycle to be trigger,
            // this allows us to wait until that has completed
            const timeoutHandle = setTimeout(() => {
                window.print();
                // Timeout added to delay resetting the print state after the print is called,
                // the print state was being reset before the print preview loaded on actual device
                setTimeout(setPrintingState(false), 1000);
            }, 500);
            return () => clearTimeout(timeoutHandle);
        }
        return _.noop;
    }, [isPrinting]);

    const accountNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.accountNumber.value');
    }, [submissionVM]);

    const policyNumber = useMemo(() => {
        return _.get(submissionVM, 'bindData.policyNumber.value');
    }, [submissionVM]);

    const handlePrint = useCallback(() => {
        setPrintingState(true);
    }, []);

    const getCoverageData = useCallback(() => {
        const lineCoverages = _.get(
            submissionVM,
            'lobData.personalAuto.offerings.value[0].coverages.lineCoverages'
        );
        const vehicles = _.get(
            submissionVM,
            'lobData.personalAuto.offerings.value[0].coverages.vehicleCoverages'
        );

        const structuredLineCoverages = lineCoverages.filter((coverage) => coverage.selected);
        const structuredVehicleCoverages = vehicles.flatMap((vehicle) => {
            return vehicle.coverages
                .filter((cov) => cov.selected)
                .map((coverage) => ({
                    name: `${vehicle.vehicleName} - ${coverage.name}`,
                    amount: coverage.amount
                }));
        });

        return [...structuredLineCoverages, ...structuredVehicleCoverages];
    }, [submissionVM]);

    const generateCoverageOverrides = useCallback(() => {
        return {
            coverageAccordion: {
                header: renderAccordionHeader(translator(messages.coveragePremiumDetails))
            },
            coverageTable: { data: getCoverageData() },
            coverageName: { onCell: renderCellContent },
            coverageAmount: { onCell: renderCoverageAmount }
        };
    }, [getCoverageData, translator]);

    const generateVehicleOverrides = useCallback(() => {
        return {
            vehicleAccordion: {
                header: renderAccordionHeader(translator(messages.vehicleDetails))
            },
            vehicleTable: {
                data: _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value')
            },
            vehicleMake: { onCell: renderCellContent },
            vehicleModel: { onCell: renderCellContent },
            vehicleLicensePlate: { onCell: renderCellContent }
        };
    }, [submissionVM, translator]);

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        accountNumberLink: {
            to: `/accounts/${accountNumber}/summary`
        },
        policyNumberLink: {
            to: `/policies/${policyNumber}/summary`
        },
        driverAccordion: {
            header: renderAccordionHeader(translator(messages.driverDetails))
        },
        contactAccordion: {
            header: renderAccordionHeader(translator(messages.contactInformationDetails))
        },
        accordion: {
            closeOthers: !isPrinting,
            accordionStates: isPrinting ? ACCORDION_CARDS : undefined
        },
        ...generateCoverageOverrides(translator),
        ...generateVehicleOverrides(translator)
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onPrint: handlePrint
        }
    };

    return (
        <WizardPage showNext={false} showPrevious={false} showCancel={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

SuccessPage.propTypes = wizardProps;
export default SuccessPage;
