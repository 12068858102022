import { defineMessages } from 'react-intl';

export default defineMessages({
    generalCoverages: {
        id: 'quoteandbind.pa.directives.MultiQuoteView.General Coverages - Apply to all vehicles',
        defaultMessage: 'Policy Coverages',
    },
    vehicleSpecificCoverage: {
        id: 'quoteandbind.pa.directives.MultiQuoteView.Vehicle-',
        defaultMessage: 'Vehicle Coverages',
    },
    paQuoteTitle: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.Thanks Quote',
        defaultMessage: 'Thanks, ',
    },
    paQuoteTitleNext: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.Here your Quote',
        defaultMessage: ' Here\'s Your Quote!',
    },
    paQuoteUpdateTitle: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.We Have Updated Your Rate',
        defaultMessage: 'We Have Updated Your Rate',
    },
    paQuoteUpdateSubHeader: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.Based on information you provided',
        defaultMessage: 'Based on information you provided and the information we received from third-party reports,',
    },
    paQuoteUpdateSecondHeader: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.your rate has been updated',
        defaultMessage: 'your rate has been updated. Please review your quote and continue.',
    },
    paQuoteTooltip: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.paQuoteTooltip',
        defaultMessage: 'We\'ve created this quote to help get you started. You can customize the coverages to fit your needs. Payment options will be provided before purchase.'
    },
    paQuoteCaption: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.Caption',
        defaultMessage: 'Your quote doesn\'t create, bind, or extend coverage. It\'s an estimate for the coverages, limits, discounts, and deductibles offered based on your responses, information we gathered, and assumptions we made based on the date prepared. Your actual premium may be higher or lower based on additional or changed factors or information received upon coverage being bound or at renewal. If you receive a discount based on information provided that is ultimately inaccurate or actions you are to complete prior to policy renewal and those actions are not completed, your discount(s) may be reduced or eliminated at policy renewal.'
    },
    paQuoteUpdateNavigate: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.Edit Information',
        defaultMessage: '< Edit Information'
    },
    quoteCaptionGA: {
        id: 'quoteandbind.pa.directives.templates.pa-quote.CaptionGA',
        defaultMessage: 'Your quote doesn\'t create, bind, or extend coverage. It\'s an estimate for the coverages, limits, discounts, and deductibles offered based on your responses, information we gathered, and assumptions we made based on the date prepared. Your actual premium may be higher or lower based on additional or changed factors or information received upon coverage being bound or at renewal. If you would like to appeal your insurance score or require more information on your rate, please contact your agent. If you receive a discount based on information provided that is ultimately inaccurate or actions you are to complete prior to policy renewal and those actions are not completed, your discount(s) may be reduced or eliminated at policy renewal.'
    }
});
