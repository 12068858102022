import React, { Component } from 'react';
import { Panel, withModal } from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import metadata from './PolicyCoveragePopUp.metadata.json5';
import styles from './PolicyCoveragePopUp.module.scss';


class PolicyCoveragePopUp extends Component {
    state = {
    };

    handleDismiss = (evt) => {
        // eslint-disable-next-line react/prop-types
        const { dismissAction } = this.props;
        evt.stopPropagation();
        dismissAction();
    }


    render() {
        // eslint-disable-next-line react/prop-types
        const { propsData } = this.props;
        const overrides = {

            cancel: {
                content: 'Got It',
                visible: 'true',
            },
            uninsuredCTContainer: {
                visible: propsData === 'PABasicGA'
            },
            uninsuredCTMSContainer: {
                visible: propsData === 'PABasicMS'
            },
            uninsuredContainer: {
                visible: propsData === 'PABasic'
            },
            uninsuredBIContainer: {
                visible: propsData === 'PABasicGA' || propsData === 'PABasicMS'
            }
        };
        const resolvers = {
            resolveCallbackMap: {
                onCancel: this.handleDismiss,

            },
            resolveClassNameMap: styles,

        };

        return (
            <Panel id="standardizedPanel" className={styles.policyCoverageStyle}>
                {renderContentFromMetadata(metadata.componentContent, overrides, resolvers)}
            </Panel>
        );
    }
}

export default withModal(PolicyCoveragePopUp);
