import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from 'gw-digital-auth-react';
import { ProducerInfoService } from 'gw-capability-profileinfo';
import ImageComponent from '../ImageComponent/ImageComponent';

import styles from './ContactComponent.module.scss';

const PUBLIC_ID_FIELD = 'publicID';
function ContactComponent(props) {
    const { id, config } = props;
    const [producerSummary, setProducerSummary] = useState({});
    const { authHeader } = useAuthentication();
    const {
        displayName, userType, city, phoneNumber, email, image
    } = producerSummary;

    const renderCityAndPhoneNumberSection = useCallback(() => {
        const dataFormat = city && phoneNumber ? `${city}, ${phoneNumber}` : city || phoneNumber;
        return <div>{dataFormat}</div>;
    }, [city, phoneNumber]);

    useEffect(() => {
        const fetchData = async () => {
            const summaries = await ProducerInfoService.getProducersContactSummaries(authHeader);
            const allProducerSummariesAreEqual = summaries.every((summary) => {
                return summary[PUBLIC_ID_FIELD] === summaries[0][PUBLIC_ID_FIELD];
            });
            if (summaries.length > 1 && !allProducerSummariesAreEqual) {
                return [];
            }
            const mergeData = _.merge(summaries[0], config);
            if (!config.image) {
                const imageName = mergeData.publicID.replace(':', '-'); // to avoid image file names with ":"
                mergeData.image = `/contact/${imageName}.png`;
            }
            return setProducerSummary(mergeData);
        };
        fetchData();
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!image) {
        return null;
    }

    return (
        <div className={styles.gwContactModule} id={id}>
            <div className={styles.gwContactInformationContainer}>
                {displayName && <div className={styles.gwDisplayName}>{displayName}</div>}
                {userType && <div>{userType}</div>}
                {renderCityAndPhoneNumberSection()}
                {email && (
                    <div>
                        <a href="/#" className={styles.gwEmail}>
                            {email}
                        </a>
                    </div>
                )}
            </div>
            <div className={styles.gwImageContainer}>
                <ImageComponent src={image} alt="contactImage" />
            </div>
        </div>
    );
}

ContactComponent.propTypes = {
    id: PropTypes.string,
    config: PropTypes.shape({
        displayName: PropTypes.string,
        userType: PropTypes.string,
        city: PropTypes.string,
        phoneNumber: PropTypes.string,
        email: PropTypes.string,
        image: PropTypes.string.isRequired
    })
};

ContactComponent.defaultProps = {
    id: undefined,
    config: PropTypes.shape({
        displayName: undefined,
        userType: undefined,
        city: undefined,
        phoneNumber: undefined,
        email: undefined
    })
};

export default ContactComponent;
