import { defineMessages } from 'react-intl';

export default defineMessages({
    sessionTimeout: {
        id: 'auth.components.session-warning.Session Expire Warning',
        defaultMessage: 'Session Expire Warning'
    },
    warningAboutToExpire: {
        id: 'auth.components.session-warning.Your session is about to expire. Would you like to continue working?',
        defaultMessage: 'Your session is about to expire. Would you like to continue working?'
    },
    continueSession: {
        id: 'auth.components.session-warning.Yes',
        defaultMessage: 'Yes'
    },
    cancelSession: {
        id: 'auth.components.session-warning.No',
        defaultMessage: 'No'
    }
});
