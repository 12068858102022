import SessionTimeoutComponent from './SessionTimeoutComponent/SessionTimeoutComponent';

const SessionTimeoutComponentMap = {
    SessionTimeoutComponent: { component: 'SessionTimeoutComponent' }
};

const SessionTimeoutComponents = {
    SessionTimeoutComponent
};

export {
    SessionTimeoutComponentMap,
    SessionTimeoutComponents,
    // export single component
    SessionTimeoutComponent
};
