var _toConsumableArray = require("/home/jenkins/agent/workspace/Guidewire_engage_master/Digital/CE-QB_11.2.0/frontend/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
module.exports = function (e) {
  var t = {};
  function r(n) {
    if (t[n]) return t[n].exports;
    var o = t[n] = {
      i: n,
      l: !1,
      exports: {}
    };
    return e[n].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
  }
  return r.m = e, r.c = t, r.d = function (e, t, n) {
    r.o(e, t) || Object.defineProperty(e, t, {
      enumerable: !0,
      get: n
    });
  }, r.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, r.t = function (e, t) {
    if (1 & t && (e = r(e)), 8 & t) return e;
    if (4 & t && "object" == typeof e && e && e.__esModule) return e;
    var n = Object.create(null);
    if (r.r(n), Object.defineProperty(n, "default", {
      enumerable: !0,
      value: e
    }), 2 & t && "string" != typeof e) for (var o in e) r.d(n, o, function (t) {
      return e[t];
    }.bind(null, o));
    return n;
  }, r.n = function (e) {
    var t = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return r.d(t, "a", t), t;
  }, r.o = function (e, t) {
    return Object.prototype.hasOwnProperty.call(e, t);
  }, r.p = "", r(r.s = "./jutro/packages/jutro-contract/index.js");
}({
  "./jutro/packages/jutro-contract/index.js": function _jutro_packages_jutroContract_indexJs(e, t, r) {
    "use strict";

    r.r(t);
    r("core-js/modules/web.dom.iterable");
    var n = r("lodash/memoize"),
      o = r.n(n),
      i = r("lodash/merge"),
      u = r.n(i),
      s = r("js-sha256"),
      c = r("@jutro/logger");
    function a(e, t) {
      for (var r = 0; r < t.length; r++) {
        var n = t[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
      }
    }
    function l(e) {
      return e.prototype || Object.getPrototypeOf(e);
    }
    var f = function () {
      function e(t) {
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, e), this.instance = t, this.allPrototypes = function (e) {
          var t = l(e);
          var r = [];
          for (; null != t && t !== Object.prototype;) r.push(t), t = l(t);
          return r;
        }(t);
      }
      var t, r, n;
      return t = e, (r = [{
        key: "findPrototypeMember",
        value: function value(e) {
          var t = this.allPrototypes.find(function (t) {
            return void 0 !== t[e];
          });
          return t ? t[e] : void 0;
        }
      }, {
        key: "getMembers",
        value: function value() {
          var e = new Set([].concat(_toConsumableArray(Object.getOwnPropertyNames(this.instance)), _toConsumableArray(this.allPrototypes.map(function (e) {
            return Object.getOwnPropertyNames(e);
          }))).filter(function (e) {
            return "prototype" !== e && "constructor" !== e;
          }));
          return Array.from(e);
        }
      }, {
        key: "getMember",
        value: function value(e) {
          return this.instance[e] || this.findPrototypeMember(e);
        }
      }, {
        key: "hasMember",
        value: function value(e) {
          return void 0 !== this.getMember(e);
        }
      }]) && a(t.prototype, r), n && a(t, n), e;
    }();
    function p(e, t) {
      for (var r = 0; r < t.length; r++) {
        var n = t[r];
        n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
      }
    }
    var y = function () {
      function e(t) {
        var _this = this;
        for (var _len = arguments.length, r = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          r[_key - 1] = arguments[_key];
        }
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, e), this.spec = u().apply(void 0, _toConsumableArray(r.map(function (e) {
          return e.spec;
        })).concat([t])), this.hashFunc = o()(function () {
          var e = Object.keys(_this.spec).sort().join("");
          return Object(s.sha256)(e);
        });
      }
      var t, r, n;
      return t = e, n = [{
        key: "specify",
        value: function value(t) {
          return new e(t);
        }
      }], (r = [{
        key: "implementedBy",
        value: function value(e) {
          var _this2 = this;
          var t = new f(e);
          var r = !0;
          var n = t.getMembers();
          return Object.getOwnPropertyNames(this.spec).forEach(function (e) {
            var o = t.getMember(e);
            o && typeof o === _this2.spec[e] || (c.log.warning(n.includes(e) ? "Invalid type for ".concat(e, ": '").concat(typeof o, "' is not of type '").concat(_this2.spec[e], "'") : "Missing member: ".concat(e)), r = !1);
          }), r;
        }
      }, {
        key: "hash",
        value: function value() {
          return this.hashFunc();
        }
      }, {
        key: "toString",
        value: function value() {
          return "contract {".concat(Object.keys(this.spec).join(","), "}");
        }
      }]) && p(t.prototype, r), n && p(t, n), e;
    }();
    var b = {
      func: "function"
    };
    r.d(t, "Contract", function () {
      return y;
    }), r.d(t, "types", function () {
      return b;
    });
  },
  "@jutro/logger": function jutro_logger(e, t) {
    e.exports = require("@jutro/logger");
  },
  "core-js/modules/web.dom.iterable": function coreJs_modules_webDomIterable(e, t) {
    e.exports = require("core-js/modules/web.dom.iterable");
  },
  "js-sha256": function jsSha256(e, t) {
    e.exports = require("js-sha256");
  },
  "lodash/memoize": function lodash_memoize(e, t) {
    e.exports = require("lodash/memoize");
  },
  "lodash/merge": function lodash_merge(e, t) {
    e.exports = require("lodash/merge");
  }
});