import { defineMessages } from 'react-intl';

export default defineMessages({
    mainDriverInformation: {
        id: 'quoteandbind.views.contact-details.Main Driver Information',
        defaultMessage: 'Main Driver Information',
    },
    paDateOfBirth: {
        id: 'quoteandbind.views.contact-details.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    paFirstNameTooltip: {
        id: 'platform.inputs.contact-details.First Name.tooltip.content',
        defaultMessage: 'Here you can add helpful text related to the field. You can use links too, for example:'
    },
    paLastNameTooltip: {
        id: 'platform.inputs.contact-details.Last Name.tooltip.content',
        defaultMessage: 'Here you can add helpful text related to the field. You can use links too, for example:'
    },
    paTooltipLink: {
        id: 'platform.inputs.contact-details.tooltip.Link',
        defaultMessage: 'Link'
    },
    paPleaseSelect: {
        id: 'quoteandbind.pa.views.common.Please Select',
        defaultMessage: 'Please Select',
    },
    yourInfoTitle: {
        id: 'quoteandbind.views.contact-details.Lets Get Started',
        defaultMessage: 'Let\'s Get Started'
    },
    yourInfoSubTitle: {
        id: 'quoteandbind.views.contact-details.Tell us a little bit about yourself',
        defaultMessage: 'Tell us a little bit about yourself'
    },
    suffix: {
        id: 'quoteandbind.views.contact-details.Suffix',
        defaultMessage: 'Suffix'
    },
    ownHomeQuestion: {
        id: 'quoteandbind.views.contact-details.Do you own your home',
        defaultMessage: 'Do you own or rent your home?'
    },
    bundleAndSave: {
        id: 'quoteandbind.pa.views.contact-details.Bundle and save',
        defaultMessage: 'Bundle & Save'
    },
    homeRentersTooltip: {
        id: 'quoteandbind.views.contact-details.Save money on your auto insurance',
        defaultMessage: 'Save money on your auto insurance with our Relationship Discount when you purchase home or renter’s insurance with us.'
    },
    homeOwnerShip: {
        id: 'quoteandbind.pa.views.contact-details.Homeownership',
        defaultMessage: 'Homeownership',
    },
    bundleAndSaveOwn: {
        id: 'quoteandbind.pa.views.contact-details.Are you interested in bundling with Home Insurance',
        defaultMessage: 'Are you interested in bundling with Home Insurance?',
    },
    bundleAndSaveRenters: {
        id: 'quoteandbind.pa.views.contact-details.Are you interested in bundling with Renters Insurance',
        defaultMessage: 'Are you interested in bundling with Renters Insurance?',
    },
    yourInfoCaption: {
        id: 'quoteandbind.views.contact-details.First Caption',
        defaultMessage: 'For accuracy, you authorize any Alfa® affiliated companies to obtain and use your consumer reports and/or an insurance score from a third party. Please review our '
    },
    yourInfoPrivacyPolicy: {
        id: 'quoteandbind.pa.views.pa-contact-details.Privacy Policy',
        defaultMessage: 'Privacy Policy'
    },
    yourInfoSecondCaption: {
        id: 'quoteandbind.pa.views.pa-contact-details.Second caption',
        defaultMessage: '. To continue, click "Next".'
    },
    addressError: {
        id: 'quoteandbind.pa.views.pa-contact-details.address.error',
        defaultMessage: 'Only 60 characters are allowed for Home Address field'
    },
    homeAddressLabel: {
        id: 'quoteandbind.views.contact-details.address.label',
        defaultMessage: 'Home Address'
    },
    yourInfoTooltip: {
        id: 'quoteandbind.pa.views.pa-contact-details.If you own your home',
        defaultMessage: 'If you own your home with or without a mortgage, you may qualify for a homeowners discount.'
    },
    reCaptchaVaidationMsg_Alfa: {
        id: 'quoteandbind.views.contact-details.captchaValidationMsg',
        defaultMessage: 'Please resolve the Captcha to continue',
    },
    noPOBoxAddressMessage: {
        id: 'quoteandbind.pa.views.contact-details.noPOBoxesMessage',
        defaultMessage: 'P.O. Boxes are not accepted. Please enter a physical address.',
    },
});
