import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalService, Loader } from '@jutro/components';

import { Wizard, WizardContext} from 'gw-portals-wizard-react';
import { LoadSaveService, EmailQuoteService } from 'gw-capability-quoteandbind';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { QuoteInfoComponentContext } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from 'gw-portals-util-js';
import ErrorHandlingUtil from '../../../applications/quote-and-buy/src/pages/Utils/ErrorHandlingUtil'
import { messages as commonMessages } from 'gw-platform-translations';

import wizardStepToFieldMapping from './config/pa-wizard-step-to-field-mapping.json5';

import PaQuoteInfo from './components/PaQuoteInfo/PaQuoteInfo';
import wizardConfig from './config/pa-wizard-config.json5';
import './PAWizard.messages';
import moment from "moment";

const PATH_TO_MOCK_DATA = 'quote.pa';
const MOCK_DATA_TO_SET = [
    'baseData.accountHolder.emailAddress1',
    'baseData.accountHolder.accountHolder',
    'baseData.policyAddress.addressLine1',
    'baseData.policyAddress.city',
    'baseData.productCode',
    'baseData.accountHolder.firstName',
    'baseData.accountHolder.lastName',
    'baseData.accountHolder.subtype',
    'baseData.accountHolder.dateOfBirth.day',
    'baseData.accountHolder.dateOfBirth.month',
    'baseData.accountHolder.dateOfBirth.year',
    'baseData.accountHolder.primaryAddress.addressLine1',
    'baseData.accountHolder.primaryAddress.city',
    'baseData.accountHolder.primaryAddress.country'
];
const MOCK_DATA_TO_REMOVE = [
    'baseData.accountHolder.firstName',
    'baseData.accountHolder.lastName',
    'baseData.accountHolder.dateOfBirth',
    'baseData.accountHolder.primaryAddress.city',
    'baseData.accountHolder.primaryAddress.addressLine1'
];

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        messageProps: {
            ok: commonMessages.yes,
            close: commonMessages.cancelModel
        }
    }
};

function setCoverageStartDate(sysDate) {
    let date = moment(sysDate).format("lll");
    const currentDate = _.isEmpty(sysDate) ? new Date() : new Date(date);
    const coverageStartDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };
    return coverageStartDate;
}

function setPrepData(address, sysDate) {
    const realData = {
        baseData: {
            policyAddress: address,
            periodStartDate: setCoverageStartDate(sysDate)
        }
    };

    return MockUpUtil.setMockData(realData, PATH_TO_MOCK_DATA, ...MOCK_DATA_TO_SET);
}

function removeMockData(submission) {
    return MockUpUtil.cleanUpMockedProperties(
        submission,
        PATH_TO_MOCK_DATA,
        ...MOCK_DATA_TO_REMOVE
    );
}

function createSubmissionOnPartialAddress(address, sysDate, stateCode, agentId, agentUrl_alfa) {
    const data = setPrepData(address, sysDate);
    _.set(data, 'baseData.policyAddress.state', stateCode);
    _.set(data, 'baseData.accountHolder.primaryAddress.state', stateCode);
    _.set(data, 'baseData.accountHolder.primaryAddress.postalCode', address.postalCode);
    _.set(data, "baseData.agentUrl_alfa", agentUrl_alfa);
    if (!_.isUndefined(agentId)) {
        _.set(data, 'baseData.agentId', agentId);
    }
    return LoadSaveService.createSubmission(data);
}

function PAWizard(props) {
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shouldSkipValidSteps, setShouldSkipValidSteps] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        location, history, getSubmissionValue
    } = props;
    const redirectPages = ['/', '/contact-us'];

    useEffect(() => {
        const newValue = { submission: initialSubmission, location: location, history: history, isLoading: isLoading };
        getSubmissionValue(newValue);
    }, [initialSubmission,location,history, isLoading]);

    useEffect(() => {
        const viewModelContext = {
            AccountEmailRequired: true,
            DriverEmailRequired: true,
            AccountDOBRequired: true
        };

        if (!viewModelService) {
            history.push('/');
            return;
        }

        


        let submissionPromise;
        if (location.pathname.includes('payment-detail')) {
            const policy = sessionStorage.getItem('policy');
            EmailQuoteService.retrieveSubmissionConfirmation(policy)
                .then((submissionData) => {
                    ErrorHandlingUtil.processErrorResponse(props, {errorDescription : 'Customer clicked browser back button on Payment Confirmation Page', clickedBack: true}, submissionData, 'Payment Confirmation Page');
                });
            return;
        } else if (_.has(location, 'state.address')) {
            const { address } = location.state;
            const systemDate = (_.has(location, 'state.sysDate')) ? location.state.sysDate : undefined;
            const stateCode = (_.has(location, 'state.stateCode')) ? location.state.stateCode : undefined;
            const agentId = (_.has(location, 'state.agentId')) ? location.state.agentId : undefined;
            const agentUrl_alfa = (_.has(location, 'state.agentUrl_alfa')) ? location.state.agentUrl_alfa : undefined;
            submissionPromise = createSubmissionOnPartialAddress(address, systemDate, stateCode, agentId, agentUrl_alfa);
        } else if (_.has(location, 'state.submission')) {
            const { submission } = location.state;
            setShouldSkipValidSteps(true);
            submissionPromise = Promise.resolve(submission);
        } else if (_.has(location, 'search')) {
            const parsedParms = queryString.parse(location.search);
            submissionPromise = LoadSaveService.retrieveSubmission(parsedParms);
        } else {
            return;
        }

        submissionPromise.then((response) => {
            const submissionVM = viewModelService.create(
                removeMockData(response),
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                viewModelContext
            );

            setInitialSubmission(submissionVM);
            setIsLoading(false);
        });
        // only execute this once per component lifecycle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = useCallback(
        (wizardProps) => {
            const { wizardSnapshot, wizardData, param } = wizardProps;
            const latestSnapshot = wizardData.aspects.subtreeValid && wizardData.aspects.valid
                ? wizardData
                : wizardSnapshot;

            const periodStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code');
            if (redirectPages.includes(param)) {
                return true;
            }
            if (periodStatus === 'Bound') {
                // allow transition once the submission is bound
                return true;
            }
            dataLayer.push({ 'event': 'gtm.click', 'gtm.elementId': 'qb_back' });
            if (_.isEqual(wizardData.value.baseData.accountHolder, wizardSnapshot.value.baseData.accountHolder)) {
                return history.push('/');
            }
            return ModalService.showWarning(ACTION_MSG.cancelAction).result.then(() => {
                let serverCall = LoadSaveService.updateDraftSubmission;

                if (periodStatus === 'Quoted') {
                    serverCall = LoadSaveService.updateQuotedSubmission;
                }

                const wizardDataFromSnapshot = _.get(latestSnapshot, 'value');
                const dataToSave = periodStatus === 'Quoted'
                    ? MockUpUtil.setMockData(wizardDataFromSnapshot, PATH_TO_MOCK_DATA)
                    : MockUpUtil.setMockData(
                        wizardDataFromSnapshot,
                        PATH_TO_MOCK_DATA,
                        ...MOCK_DATA_TO_SET
                    );
                setIsLoading(true);

                serverCall(dataToSave)
                    .then(() => {
                        history.push('/');
                    })
                    .catch(() => {
                        ModalService.showError({
                            title: commonMessages.unableToSaveQuote,
                            message: commonMessages.unableToSaveQuoteMessage
                        });
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }, _.noop);
        },
        [history]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <QuoteInfoComponentContext.Provider value={PaQuoteInfo}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                skipCompletedSteps={shouldSkipValidSteps}
                wizardStepToFieldMapping={wizardStepToFieldMapping}
            />
        </QuoteInfoComponentContext.Provider>
    );
}

PAWizard.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            submission: PropTypes.shape({})
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    getSubmissionValue: PropTypes.func.isRequired,
    isLoadingNext: PropTypes.bool.isRequired,


};

export default PAWizard;
