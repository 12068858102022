import { defineMessages } from 'react-intl';

export default defineMessages({
    yourHome: {
        id: 'quoteandbind.pa.wizard.step.Drivers',
        defaultMessage: 'Drivers'
    },
    construction: {
        id: 'quoteandbind.pa.wizard.step.Vehicles',
        defaultMessage: 'Vehicles'
    },
    getYourQuoteFaster: {
        id: 'quoteandbind.views.contact-details.Get your quote faster!',
        defaultMessage: 'Get your quote faster!',
    },
    scanDriverLicenseDescription: {
        id: 'quoteandbind.views.contact-details.Scan your driver license barcode to automatically enter the necessary information.',
        defaultMessage: 'Scan your driver license barcode to automatically enter the necessary information.',
    },
    scanDriverLicenseBarcode: {
        id: 'platform.documentupload.templates.document-upload.Scan driver license barcode',
        defaultMessage: 'Scan driver license barcode',
    },
    scanDriverLicenseBarcodeAutomaticallyEnterDetails: {
        id: 'quoteandbind.views.contact-details.Scan your driver license barcode to automatically enter the necessary information.',
        defaultMessage: 'Scan your driver license barcode to automatically enter the necessary information.',
    },
});
