import { defineMessages } from 'react-intl';

export default defineMessages({
    /* Contact Fields */
    firstName: {
        id: 'platform.inputs.contact-details.First Name',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'platform.inputs.contact-details.Last Name',
        defaultMessage: 'Last Name',
    },
    companyName: {
        id: 'platform.inputs.company-details.Name',
        defaultMessage: 'Company Name'
    },
    address: {
        id: 'platform.inputs.address-details.Address',
        defaultMessage: 'Address'
    },
    addressLine1: {
        id: 'platform.inputs.address-details.Address Line 1',
        defaultMessage: 'Address Line 1'
    },
    addressLine2: {
        id: 'platform.inputs.address-details.Address Line 2',
        defaultMessage: 'Address Line 2'
    },
    addressLine3: {
        id: 'platform.inputs.address-details.Address Line 3',
        defaultMessage: 'Address Line 3'
    },
    city: {
        id: 'platform.inputs.address-details.City',
        defaultMessage: 'City'
    },
    zipCode: {
        id: 'platform.inputs.address-details.ZIP Code',
        defaultMessage: 'ZIP Code'
    },
    state: {
        id: 'platform.inputs.address-details.State',
        defaultMessage: 'State'
    },
    addressType: {
        id: 'platform.inputs.address-details.Address Type',
        defaultMessage: 'Address Type'
    },
    phone: {
        id: 'platform.contacts.templates.contact-template.Phone',
        defaultMessage: 'Phone'
    },
    workPhone: {
        id: 'platform.contacts.templates.contact-template.Work Phone',
        defaultMessage: 'Work Phone'
    },
    homePhone: {
        id: 'platform.contacts.templates.contact-template.Home Phone',
        defaultMessage: 'Home Phone'
    },
    mobilePhone: {
        id: 'platform.contacts.templates.contact-template.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    email: {
        id: 'platform.contacts.templates.contact-template.Email',
        defaultMessage: 'Email'
    },
    wantToCancel: {
        id: 'platform.flow.angularhelpers.CancelConfirmation.Are you sure you want to cancel?',
        defaultMessage: 'Want to Cancel?'
    },
    areYouSure: {
        id: 'platform.flow.angularhelpers.CancelConfirmation.Are you sure?',
        defaultMessage: 'Are you sure?'
    },
    infoDraft: {
        id: 'platform.flow.angularhelpers.CancelConfirmation.You have unsaved changes',
        defaultMessage: 'You have unsaved changes. Are you sure you want to leave this page?'
    },
    infoWillBeSavedAsDraft: {
        id: 'platform.flow.angularhelpers.CancelConfirmation.The information previously entered will be stored as a draft',
        defaultMessage: 'The information previously entered will be stored as a draft'
    },
    inputPrimaryPhone: {
        id: 'platform.inputs.phone-details.Primary Phone',
        defaultMessage: 'Primary Phone'
    },
    inputHomePhone: {
        id: 'platform.inputs.phone-details.Home Phone',
        defaultMessage: 'Home Phone'
    },
    inputWorkPhone: {
        id: 'platform.inputs.phone-details.Work Phone',
        defaultMessage: 'Work Phone'
    },
    inputMobilePhone: {
        id: 'platform.inputs.phone-details.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    chooseState: {
        id: 'platform.inputs.address-details.Choose State',
        defaultMessage: '--Choose State--'
    },
    wantToJump: {
        id: 'platform.flow.angularhelpers.SnapshotResetConfirmation.Are you sure you want to leave this page?',
        defaultMessage: 'Leave Page?'
    },
    wantToJumpMessage: {
        id: 'platform.flow.angularhelpers.SnapshotResetConfirmation.Changes entered on this page may be lost',
        defaultMessage: 'The changes you have made on this page may be lost.'
    },
    unableToSaveQuote: {
        id: 'platform.flow.config.FlowConfig.Unable to save submission',
        defaultMessage: 'Unable to save submission'
    },
    unableToSaveQuoteMessage: {
        id: 'platform.flow.config.FlowConfig.An error occurred while attempting to save quote submission.',
        defaultMessage: 'An error occurred while attempting to save quote submission.'
    },
    yes: {
        id: 'platform.flow.angularhelpers.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'platform.flow.angularhelpers.Cancel',
        defaultMessage: 'Cancel'
    },
    cancelModel: {
        id: 'platform.modal.ModalService.Cancel',
        defaultMessage: 'Cancel'
    },
    yesModel: {
        id: 'platform.modal.ModalService.Yes',
        defaultMessage: 'Yes'
    },
    noModel: {
        id: 'platform.modal.ModalService.No',
        defaultMessage: 'No'
    },
    close: {
        id: 'platform.modal.ModalService.Close',
        defaultMessage: 'Close'
    },
    errorUploadTitle: {
        id: 'platform.documentupload.DocumentUploadDirective.Error',
        defaultMessage: 'Error'
    },
    errorGenerateUploadToken: {
        id: 'platform.documentupload.templates.document-upload.You seem to be offline. Once your connection is restored, click “Previous” and start the process again.',
        defaultMessage: 'You seem to be offline. Once your connection is restored, click “Previous” and start the process again.'
    },
    uploadFailedMessage: {
        id: 'platform.documentupload.DocumentUploadDirective.Failed to upload file, either you do not have permission or a file exists with the same name.',
        defaultMessage: 'Failed to upload file, either you do not have permission or a file exists with the same name.'
    },
    notANumber: {
        id: 'platform.modelvalidation.DTOValidationService.Please enter numbers only',
        defaultMessage: 'Please enter numbers only'
    },
    notAnInteger: {
        id: 'platform.modelvalidation.DTOValidationService.Value entered must not contain decimal values',
        defaultMessage: 'Value entered must not contain decimal values'
    },
    genericError: {
        id: 'platform.modal.ModalService.error.Error',
        defaultMessage: 'Error'
    },
    genericErrorMessage: {
        id: 'platform.modal.ModalService.error.Something went wrong during this process.',
        defaultMessage: 'Something went wrong during this process.'
    },
    businessOwners: {
        id: 'platform.lobs.BusinessOwners',
        defaultMessage: 'Business Owners'
    },
    businessAuto: {
        id: 'platform.lobs.BusinessAuto',
        defaultMessage: 'Business Auto'
    },
    commercialProperty: {
        id: 'platform.lobs.CommercialProperty',
        defaultMessage: 'Commercial Property'
    },
    generalLiability: {
        id: 'platform.lobs.GeneralLiability',
        defaultMessage: 'General Liability'
    },
    hOPHomeowners: {
        id: 'platform.lobs.HOPHomeowners',
        defaultMessage: 'Homeowners'
    },
    inlandMarine: {
        id: 'platform.lobs.InlandMarine',
        defaultMessage: 'Inland Marine'
    },
    personalAuto: {
        id: 'platform.lobs.PersonalAuto',
        defaultMessage: 'Personal Auto'
    },
    workersComp: {
        id: 'platform.lobs.Workers Comp',
        defaultMessage: 'Workers Compensation'
    }

});
