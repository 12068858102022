import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';

function PaQuoteInfo(props) {
    const { submissionVM } = props;
    const quoteId = _.get(submissionVM, 'quoteID.value');

    return (
        <div />
    );
}

PaQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired
};

export default PaQuoteInfo;
