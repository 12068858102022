import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { ImageComponent } from 'gw-components-platform-react';
import { SessionTimeoutComponent } from 'gw-capability-sessiontimeout-react';
import metadata from './ContactUs.metadata.json5';
import styles from './ContactUs.module.scss';
import messages from './ContactUs.messages';
import cookie from 'js-cookie';

function ContactUs(props) {
    const { location, hideQuoteId } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const quoteID = _.get(location, 'state.quoteID') || _.get(location, 'quoteID') || cookie.get('quoteID');
    const agentInfo = _.get(location, 'state.agentInfo') || _.get(location, 'agentInfo');
    const quoteStatus = _.get(location, 'state.quoteStatus') || _.get(location, 'quoteStatus');
    const customContact = _.get(location, 'state.customContact') || _.get(location, 'customContact');
    const docuSignInd = _.get(location, 'state.docuSignInd') || _.get(location, 'docuSignInd');    

    useEffect(() => {
        const invalidCviScore = cookie.get('cviscore');
        const inValidPrimaryDriverState = cookie.get('primaryDriverState');
        const undefined = "undefined";

        let errorMessage = '';
        let errorEvent = '';
        let inValidCviScores = ['0', '10'];
        let validDriverLicenseStates = ['AL', 'GA', 'MS'];

        // Determine the information for DataLayer
        if (!_.isEmpty(invalidCviScore) && !_.isUndefined(invalidCviScore)
            && invalidCviScore.toLowerCase() !== undefined && inValidCviScores.includes(invalidCviScore)) {
            errorMessage = 'Invalid CVI Score';
            errorEvent = 'InValidCVI_Kickout';
        }
        else if (!_.isEmpty(inValidPrimaryDriverState) && !_.isUndefined(inValidPrimaryDriverState)
            && inValidPrimaryDriverState.toLowerCase() !== undefined && !validDriverLicenseStates.includes(inValidPrimaryDriverState)) {
            errorMessage = 'Driver license issue: ' + inValidPrimaryDriverState;
            errorEvent = 'DL_Kickout';
        }

        // EH-22571: Pass Invalid CVI information to dataLayer
        if (errorMessage !== '') {
            window.dataLayer.push({
                'event': errorEvent,
                'errorMessage': errorMessage,
                'quoteId': quoteID,
                'inValidCviScore': invalidCviScore,
                'inValidPrimaryDriverState': inValidPrimaryDriverState
            });
        }

        if (hideQuoteId) {
            hideQuoteId();
        }
    }, []);

    const getQuoteId = () => {
        if (quoteID) {
            return (
                <span>
                    Please reference your Quote ID:
                    {' '}
                    <b>{quoteID}</b>
                </span>
            );
        }
        return '';
    };

    const buildContactNumber = () => {
        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            return (
                <a href="tel:+1-800-964-2532">
                    <ImageComponent
                        src="/phone.png"
                        alt="Alfa Phone"
                        aria-hidden="true"
                        aria-label="Alfa Phone"
                        title="Alfa Phone"
                        className={styles.imageStyle}
                    />
                </a>
            );
        }
        return (
            <ImageComponent
                src="/phone.png"
                alt="Alfa Phone"
                aria-hidden="true"
                aria-label="Alfa Phone"
                title="Alfa Phone"
                className={styles.imageStyle}
            />
        );
    };

    const getAgentImage = () => {
        if (agentInfo) {
            return (
                <ImageComponent
                    src={agentInfo.imageURL}
                    alt="Alfa Agent"
                    aria-hidden="true"
                    aria-label="Alfa Agent"
                    title="Alfa Agent"
                    className={styles.customImageStyle}
                />
            );
        }
        return '';
    };

    const getAgentName = () => {
        if (agentInfo) {
            return (
                <span className={styles.fontColor}>
                    {agentInfo.firstName}
                    &nbsp;
                    {agentInfo.lastName}
                </span>
            );
        }
        return '';
    };

    const getAgentAddress = () => {
        if (agentInfo) {
            return (
                <div>
                    <span className={styles.spanFontStyle + ' ' + styles.fontColor}>
                        <i className={`fa fa-building ${styles.rightSpanSpace}`} aria-hidden="true" role="img" alt="Alfa Building" aria-label="Alfa Building" title="Alfa Building" />
                        {agentInfo.address.addressLine1}
                        <br />
                    </span>
                    {'  '}
                    <span className={styles.spanFontStyle + ' ' + styles.fontColor}>
                        <i className={`fa fa-building ${styles.rightSpanSpace} ${styles.hideIcon}`} aria-hidden="true" role="img" alt="Alfa Building" aria-label="Alfa Building" title="Alfa Building" />
                        {agentInfo.address.city}
                        {', '}
                        {agentInfo.address.state}
                        {' '}
                        {agentInfo.address.postalCode}
                    </span>
                </div>
            );
        }
        return '';
    };

    const getAgentPhoneNumber = () => {
        if (agentInfo) {
            const classNameSetOne = classNames('fa fa-phone fa-flip-horizontal');
            if (breakpoint === 'phone' || breakpoint === 'tablet') {
                return (
                    <div>
                        <span className={styles.spanFontStyle + ' ' + styles.fontColor}>
                            <a href={`tel:${agentInfo.phoneNumber}`} className={styles.phoneIconColorStyle}>
                                <i className={`${classNameSetOne} ${styles.leftSpanSpace}`} aria-hidden="true" role="img" alt="Alfa Phone" aria-label="Alfa Phone" title="Alfa Phone" />
                            </a>
                            {'  '}
                            {agentInfo.phoneNumber}
                        </span>
                    </div>
                );
            }
            return (
                <div>
                    <span className={styles.spanFontStyle + ' ' + styles.fontColor}>
                        <i className={`${classNameSetOne} ${styles.leftSpanSpace}`} aria-hidden="true" role="img" alt="Alfa Phone" aria-label="Alfa Phone" title="Alfa Phone" />
                        {agentInfo.phoneNumber}
                    </span>
                </div>
            );
        }
        return '';
    };

    const getAgentMail = () => {
        if (agentInfo) {
            return (
                <div>
                    <span className={styles.spanFontStyle + ' ' + styles.fontColor}>
                        <i className={`fa fa-envelope-square ${styles.rightSpanSpace}`} aria-hidden="true" role="img" alt="Alfa Email" aria-label="Alfa Email" title="Alfa Email" />
                        {'  '}
                        {agentInfo.email}
                    </span>
                </div>
            );
        }
        return '';
    };

    const getContactUsTitle = () => {
        let contactUsTitle;
        if (customContact) {
            contactUsTitle = translator(messages.contactUsTitleOne);
        } else if (docuSignInd && !customContact) {
            contactUsTitle = translator(messages.docuSignTitle);
        } else {
            contactUsTitle = translator(messages.contactUsTitleDraft);
        }
        return contactUsTitle;
    };

    const getContactSubtitleOne = () => {
        let contactSubtitleText;
        if (quoteStatus !== 'Draft') {
            if (customContact) {
                if (breakpoint === 'phone') {
                    return (
                        <span>
                            It looks like we&apos;re experiencing
                            <br />
                            technical difficulties.
                            Contact us to get back on track.
                        </span>
                    );
                }
                contactSubtitleText = translator(messages.contactSubtitleOne);
            } else if (!customContact) {
                if (breakpoint === 'phone') {
                    contactSubtitleText = (
                        <>
                            <b>
                                <span>
                                    {translator(messages.contactSubtitleQuotedDraftOne)}
                                    <br />
                                    {translator(messages.contactSubtitleQuotedDraftTwo)}
                                </span>
                            </b>
                        </>
                    );
                } else {
                    contactSubtitleText = <b>{translator(messages.contactSubtitleQuotedDraft)}</b>;
                }
                if (docuSignInd) {
                    contactSubtitleText = (
                        <>
                            <span className={styles.alterFontSize}>
                                {translator(messages.docuSignSubTitleOne)}
                            </span>
                            <br />
                            <br />
                        </>
                    );
                }
            }
        } else if (quoteStatus === 'Draft') {
            if (!customContact) {
                if (breakpoint === 'phone') {
                    contactSubtitleText = (
                        <>
                            <span>
                                {translator(messages.contactSubtitleDraftOne)}
                                <br />
                                {translator(messages.contactSubtitleDraftTwo)}
                                <br />
                                {translator(messages.contactSubtitleDraftThree)}
                            </span>
                        </>
                    );
                } else {
                    contactSubtitleText = translator(messages.contactSubtitleDraft);
                }
                if (docuSignInd) {
                    contactSubtitleText = (
                        <>
                            <span className={styles.alterFontSize}>
                                {translator(messages.docuSignSubTitleOne)}
                            </span>
                            <br />
                            <br />
                        </>
                    );
                }
            } else if (customContact) {
                contactSubtitleText = translator(messages.contactSubtitleOne);
            }
        }
        return contactSubtitleText;
    };

    const getContactSubtitleTwo = () => {
        if (!customContact) {
            if (quoteStatus === 'Quoted' && docuSignInd) {
                if (breakpoint === 'phone') {
                    return (
                        <>
                            <span className={styles.alterFontSize}>
                                {translator(messages.docuSignSubTitleTwoMobilePart1)}
                                <br />
                                {translator(messages.docuSignSubTitleTwoMobilePart2)}
                                <br />
                                {translator(messages.docuSignSubTitleTwoMobilePart3)}
                                <br />
                                {translator(messages.docuSignSubTitleTwoMobilePart4)}
                                <br />
                                {translator(messages.docuSignSubTitleTwoPart3)}
                                <br />
                                <br />
                                {translator(messages.docuSignSubTitleThreeMobile1)}
                                <br />
                                {translator(messages.docuSignSubTitleThreeMobile2)}
                            </span>
                        </>
                    );
                }
                return (
                    <>
                        <span className={styles.alterFontSize}>
                            {translator(messages.docuSignSubTitleTwoPart1)}
                            <br />
                            {translator(messages.docuSignSubTitleTwoPart2)}
                            <br />
                            {translator(messages.docuSignSubTitleTwoPart3)}
                            <br />
                            <br />
                            {translator(messages.docuSignSubTitleThree)}
                        </span>
                    </>
                );
            } else if (quoteStatus === 'Draft' && breakpoint !== 'phone') {
                return translator(messages.contactSubtitleTwo);
            }
        }
        return ' ';
    };

    const getClassesForSubtitleTwo = () => {
        if (!customContact) {
            return classNames(styles.fontStyle, styles.lineStyle, styles.mobileStyle);
        }
        return classNames(styles.fontStyle, styles.lineStyle);
    };

    const getClassesForSubtitleOne = () => {
        if (!customContact && !docuSignInd) {
            return classNames(styles.fontStyle, styles.lineStyle, styles.whiteSpaceContactStyle);
        }
        return classNames(styles.fontStyle, styles.lineStyle);
    };

    const isCustomContact = () => {
        if (customContact) {
            return true;
        }
        return false;
    };

    const isAgentInfoAvailable = () => {
        if (agentInfo) {
            return true;
        }
        return false;
    };

    const getContactSectionStyles = () => {
        if (isAgentInfoAvailable()) {
            return classNames(styles.contactSectionAltStyle);
        }
        return classNames(styles.contactSectionStyle);
    };

    const getString = (strFun) => {
        if (typeof strFun === 'string') {
            return strFun;
        }
        return strFun.props.children;
    }

    const getMainClass = () => {
        const str = getContactUsTitle();
        if (getString(str).includes(translator(messages.contactUsTitleDraft)) && !docuSignInd && breakpoint === 'tablet') {
            return `${styles.contactUsContainer} ${styles.adjustWidth}`;
        }
        if (docuSignInd) {
            if (breakpoint === 'tablet') return `${styles.contactUsContainer} ${styles.docuSignWidth}`;
            if (breakpoint === 'phone') return `${styles.contactUsContainer} ${styles.docuSignMobileWidth}`;
        }
        return styles.contactUsContainer;
    }

    const overrideProps = {
        quoteIDContactUs: {
            content: getQuoteId(),
            className: [docuSignInd ? `${styles.quoteidCustomStyle} ${styles.quoteidCustomMarginStyle}` : styles.quoteidCustomStyle],
            visible: quoteID && quoteID.length > 0
        },
        spanTextContactNumber: {
            content: buildContactNumber()
        },
        customAgentImage: {
            content: getAgentImage(),
        },
        contactNumberSection: {
            visible: !isAgentInfoAvailable()
        },
        customContactSection: {
            visible: isAgentInfoAvailable()
        },
        agentName: {
            content: getAgentName()
        },
        agentAddress: {
            content: getAgentAddress()
        },
        agentPhoneNumber: {
            content: getAgentPhoneNumber()
        },
        agentMail: {
            content: getAgentMail()
        },
        contactUsTitle: {
            content: getContactUsTitle()
        },
        contactSubtitleOne: {
            content: getContactSubtitleOne(),
            className: getClassesForSubtitleOne()
        },
        contactSubtitleTwo: {
            content: getContactSubtitleTwo(),
            className: getClassesForSubtitleTwo()
        },
        contactSection: {
            className: getContactSectionStyles()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <div className={getMainClass()}>
            {renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers)}
            <SessionTimeoutComponent {...{ quoteID: quoteID }} />
        </div>
    );
}

ContactUs.propTypes = {
    location: PropTypes.shape({
        customContact: PropTypes.shape(Boolean),
        quoteStatus: PropTypes.shape(String),
        quoteID: PropTypes.shape(String),
        agentInfo: PropTypes.shape({
            address: PropTypes.shape({
                addressLine1: PropTypes.shape(String),
                state: PropTypes.shape(String),
                city: PropTypes.shape(String),
                postalCode: PropTypes.shape(String)
            }),
            firstName: PropTypes.shape(String),
            lastName: PropTypes.shape(String),
            phoneNumber: PropTypes.shape(String),
            email: PropTypes.shape(String),
            imageURL: PropTypes.shape(String)
        })
    }),
    hideQuoteId: PropTypes.func
};
ContactUs.defaultProps = {
    location: undefined,
    hideQuoteId: undefined
};
export default withRouter(ContactUs);