import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { Chevron, Tooltip, IconButton, ModalService } from '@jutro/components';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { withValidation, validationPropTypes } from 'gw-portals-validation-react';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { publish } from '@jutro/events';
import SingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import BasicQuote from '../BasicQuote/BasicQuote';
import policycoveragePopUp from '../BasicQuote/QuotePopover/PolicyCoveragePopUp';
import vehiclecoveragePopUp from '../BasicQuote/QuotePopover/VehicleCoveragePopover';
import QuoteTableAccordionCardIterableComponent from '../QuoteTableAccordionCardIterableComponent/QuoteTableAccordionCardIterableComponent';
import BrowserUtil from "../../../../applications/quote-and-buy/src/pages/Utils/BrowserUtil";


import metadata from './QuoteClauseTable.metadata.json5';
import styles from './QuoteClauseTable.module.scss';
import messages from './QuoteClauseTable.messages';

const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
const BLOCKS_BIND = 'BlocksBind';
const NON_BLOCKING = 'NonBlocking';
const VEHICLE_COVERAGE = ['PALeaseLoan_alfa',
    'PALossOfIncome_alfa',
    'PALossOfUseCov_alfa',
    'PATowingLaborCov',
    'PACollisionCov',
    'PAComprehensiveCov'];
const LINE_COVERAGE = [
    'PAADD_alfa',
    'PAAccidentWaiver_alfa'];
let VEHICLELINE_COVERAGE = ['PABI_alfa',
    'PAPropertyDamage_alfa',
    'PAMedicalPayments_alfa',
    'PAUninsuredMotoristBI_alfa'];
let propsData;

function getChangedDataPath(changedPath, fullChangedPath, pathToChangeTo) {
    const localisedVMPath = changedPath.replace(fullChangedPath, pathToChangeTo);
    return ViewModelUtil.getNonVMPath(localisedVMPath);
}

function getChangedColumnData(changedPathOfModel, columnData) {
    // onBlur is called from Jutro with a object instead of a path
    const path = _.isString(changedPathOfModel) ? changedPathOfModel : changedPathOfModel.model;
    return columnData.find(({ lob }) => _.includes(path, lob.path));
}

function getChangedClause(path, columnData) {
    const changedObjectPath = ClausesUtil.getObjectPathFromChangedPath(path);
    const changedColumn = getChangedColumnData(path, columnData);
    const changedPath = getChangedDataPath(changedObjectPath, changedColumn.lob.path, 'lob.data');
    return _.get(changedColumn, changedPath);
}

function getCoverageIndex(path) {
    const hasSubCoverages = ClausesUtil.hasSubCoverages(path);

    if (hasSubCoverages) {
        const parentCoveragePath = ClausesUtil.getParentCoveragePath(path);
        if (parentCoveragePath) {
            // Regex to get index of the child element, eg. '2'  for ...children[2]
            //TODO - CHANGE THE REGEX TO WORK IN ALL DEVICES - COMMENTING OUT AS THIS IS NOT WORKING IN IOS DEVICES
            //const regex = '/(?<=\[)(.*?)(?=\])/';
            // const regex = /(?<=\[)(.*?)(?=\])/;
            // const index = regex.exec(parentCoveragePath.split('.').slice(-1)[0]);

            const index = parentCoveragePath.split('[')[1].split(']')[0];

            return index ? `__${index[0]}` : '';
        }
    }

    return '';
}

function selectMetadata(breakpoint) {
    const contentArray = metadata.componentContent.content;
    let metadataToRender = '';

    // (breakpoint === 'phone') {
    metadataToRender = contentArray.filter((item) => item.id === 'desktopTable');
    /** } else {
         metadataToRender = contentArray.filter(
             (item) => item.id !== 'desktopTable' && item.id !== 'mobileAccordionHeader'
         );
     }**/

    const filteredMetdata = {
        ...metadata,
        componentContent: {
            content: metadataToRender
        }
    };

    return filteredMetdata;
}

function getSelectedCoverages(data) {
    // only return the selected base coverages names
    const coveragesObject = _.get(data, 'lob.data.coverages');
    const coverageNames = Object.keys(coveragesObject).filter((name) => {
        return _.includes(name.toLowerCase(), 'coverages');
    });
    const baseCoverageName = _.first(coverageNames);
    const coverages = _.get(coveragesObject, baseCoverageName);
    return coverages.filter((cov) => cov.selected);
}

const PAYMENT_TYPES = {
    monthly: 'monthly',
    annually: 'annually'
};

const moneyPropsTypes = PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.number
});

function ClauseTooltip(messageType) {
    const translator = useContext(TranslatorContext);
    const messagePath = 'quoteandbind.common.directives.common.';
    let tooltipMessage = '';


    if (messageType.id.indexOf('policyCoverageToolTip0') !== -1) {
        tooltipMessage = 'Click to expand Policy Coverage description';
    } else if (messageType.id.indexOf('vehicleCoverageToolTip') !== -1) {
        tooltipMessage = 'Click to expand Vehicle Coverage description';
    } else {
        const message = `${messagePath}${messageType.id}`;
        tooltipMessage = translator(_.find(messages, { id: message }));
    }

    const loadPopup = () => {
        const comp = { propsData: propsData };
        if (messageType.id.indexOf('policyCoverageToolTip0') !== -1) {
            return ModalService.showPopover(policycoveragePopUp, comp);
        }
        // ToDo Remove this logic and the changes made to VehicleCoveragePopover once 1/7/2023 has passed.{}
        let currentDate = new Date();
        let raALMSDate = new Date(2022, 11, 12);
        let raGADate = new Date(2023, 0, 7);
        if (currentDate < raALMSDate || (currentDate < raGADate && propsData === 'PABasicGA')) {
            comp.flag = false;
            return ModalService.showPopover(vehiclecoveragePopUp, comp);
        }
        comp.flag = true;
        // End of ToDo}
        return ModalService.showPopover(vehiclecoveragePopUp, comp);
    };

    let mobileView = false

    if(navigator.userAgent !== undefined && navigator.userAgent !== ""){
        mobileView = BrowserUtil.isMobile() || BrowserUtil.isTablet() || BrowserUtil.isIPadPro();
    }
        
    return (
        <Tooltip
            animation="fade"
            content={tooltipMessage}
            delay={[
                0,
                40
            ]}
            duration={[
                300,
                300
            ]}
            flipBehavior={[
                'right',
                'bottom',
                'top',
                'left',
                'right'
            ]}
            followCursor={false}
            hideOnClick={false}
            id="tooltip"
            showOnInit={false}
            sticky
            trigger = {(mobileView ? "" : "mouseenter")}
        >
            {(messageType.id.indexOf('policyCoverageToolTip0') !== -1 || messageType.id.indexOf('vehicleCoverageToolTip') !== -1)
                ? (<IconButton icon="fa-info-circle" onClick={loadPopup} aria-haspopup="true" className={styles.gwToolTip}>message</IconButton>)

                : (<IconButton icon="fa-info-circle" aria-haspopup="true" className={styles.gwToolTip} aria-hidden="true" aria-label="Information" />)
            }
        </Tooltip>
    );
}

class QuoteClauseTable extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        columnData: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
                quote: PropTypes.shape({
                    path: PropTypes.string,
                    premium: PropTypes.shape({
                        monthlyPremium: moneyPropsTypes,
                        total: moneyPropsTypes
                    })
                })
            })
        ).isRequired,
        tableData: PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string,
                data: PropTypes.arrayOf(
                    PropTypes.shape({
                        publicID: PropTypes.string
                    })
                ),
                tableContent: PropTypes.arrayOf(
                    PropTypes.shape({
                        path: PropTypes.string,
                        clauses: PropTypes.arrayOf(PropTypes.shape({})),
                        code: PropTypes.string
                    })
                )
            })
        ).isRequired,
        quoteError: PropTypes.arrayOf({}),
        underwritingIssues: PropTypes.arrayOf({}),
        filterUWIssuesInCustomOffering: PropTypes.bool,
        quoteID: PropTypes.string.isRequired,
        onBuyNow: PropTypes.func,
        onRecalculate: PropTypes.func,
        onResetQuote: PropTypes.func,
        onChangeSubmissionAndSync: PropTypes.func,
        onChangeSubmission: PropTypes.func,
        onSyncCoverages: PropTypes.func,
        onStaleQuoteBranchCode: PropTypes.func,
        setActiveQuote: PropTypes.func,
        submissionVM: PropTypes.shape({}),
        ...validationPropTypes
    };

    static defaultProps = {
        onBuyNow: undefined,
        onRecalculate: undefined,
        onResetQuote: undefined,
        onChangeSubmissionAndSync: undefined,
        onChangeSubmission: undefined,
        onSyncCoverages: undefined,
        onStaleQuoteBranchCode: undefined,
        setActiveQuote: undefined,
        underwritingIssues: [],
        filterUWIssuesInCustomOffering: true,
        submissionVM: undefined,
        quoteError: []
    };

    state = {
        formData: {},
        loadingClause: undefined,
        staleQuoteBranchCodes: this.getStaleCode(),
        resetBranchCodes: [],
        priceDifference: 0,
        previousColumnData: [],
        readQuote: this.getActiveQuote(),//'PABasic',
        activeQuote: this.getActiveQuote(),//'PABasic',
        viewCoverages: false,
        showInlineLoader: false,
        showCoverageAccordion: false
    };

    getActiveQuote() {
        const { columnData } = this.props;
        let activeQuoteData;
        for (let data = 0; data < columnData.length; data++) {
            if(columnData[data].code === 'PABasic') {
                activeQuoteData = 'PABasic';
                break;
            }else if(columnData[data].code === 'PABasicGA') {
                activeQuoteData = 'PABasicGA';
                break;
            } else if(columnData[data].code === 'PABasicMS') {
                activeQuoteData = 'PABasicMS';
                break;
            }
        }
        return activeQuoteData;
    }

    preparePolicyCovProps() {
        const { columnData } = this.props;
        let stateCategory = '';
        for (let data of columnData) {
            if (data.code === 'PABasic') {
                stateCategory = 'PABasic';
                break;
            } else if (data.code === 'PABasicGA') {
                stateCategory = 'PABasicGA';
                break;
            } else if (data.code === 'PABasicMS') {
                stateCategory = 'PABasicMS';
                break;
            }
        }
        return stateCategory;
    }

    componentDidMount() {
        const { columnData, submissionVM, selectedVersion } = this.props;
        propsData = this.preparePolicyCovProps();
        const resetBranchCodes = columnData.map((item) => item.code);
        this.setState({ resetBranchCodes, previousColumnData: _.cloneDeep(columnData) });
        if (submissionVM.baseData.selectedVersion.value === undefined && selectedVersion !== undefined) {
            this.setState({
                readQuote: selectedVersion,
                activeQuote: selectedVersion
            });
        } else if (submissionVM.baseData.selectedVersion.value !== undefined) {
            const quoteType = submissionVM.baseData.selectedVersion.value;
            this.setState({
                readQuote: quoteType,
                activeQuote: quoteType
            });
        }
    }

    getTableColumnContent(columnData, tableInfo) {
        // const contentDisplay = [];
        const { tableData } = this.props;
        const contentDisplay = tableData.map((info) => {
            return info.tableContent.find((content) => content.code === 'CUSTOM');
        });
        return columnData.map((data, index) => {
            // if (data.code === 'CUSTOM') {
            return {
                id: `quoteTableColumn${index}`,
                type: 'element',
                component: 'tablecolumn',
                componentProps: {
                    cellClassName: 'gwTableCell',
                    headerClassName: 'gwQuoteTableHeader',
                    onCell: this.renderCellContent,
                    visible: data.code === 'CUSTOM',
                    data: tableInfo.tableContent.find((content) => content.code === data.code)
                }
            };
            // }
        });
    }


    getStaleCode() {
        const { onStaleQuoteBranchCode } = this.props;
        let val = [];
        if (onStaleQuoteBranchCode) {
            if (onStaleQuoteBranchCode()) {
                const staleCodes = onStaleQuoteBranchCode();
                if (_.isArray(staleCodes)) {
                    val = staleCodes;
                } else {
                    val.push(staleCodes);
                }
            }
        }
        return val;
    }

    getChangedOfferingPathsAndSetClauseToLoading(path) {
        const { columnData } = this.props;
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;

        const changedColumn = getChangedColumnData(path, columnData);
        const changedClause = getChangedClause(path, columnData);
        const coverageIndex = getCoverageIndex(path);
        const lobPath = changedColumn.lob.path;
        const quotePath = changedColumn.quote.path;
        const updatedBranchCodes = resetBranchCodes.filter((item) => item !== changedColumn.code);

        staleQuoteBranchCodes.push(changedColumn.code);
        const loadingClauseId = changedClause.coveragePublicID ? `${changedClause.coveragePublicID}${coverageIndex}` : `${changedClause.publicID}${coverageIndex}`;
        this.setState({
            loadingClause: {
                clauseID: loadingClauseId,
                quoteCode: changedColumn.code
            },
            staleQuoteBranchCodes,
            resetBranchCodes: updatedBranchCodes
        });

        return [lobPath, quotePath];
    }

    removeStaleQuote = (path) => {
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const selectedColumn = _.get(this.props, path);

        const newStateQuoteArray = staleQuoteBranchCodes.filter(
            (item) => item !== selectedColumn.code
        );
        const newResetBranchArray = resetBranchCodes.filter((item) => item !== selectedColumn.code);
        this.setState({
            staleQuoteBranchCodes: newStateQuoteArray,
            resetBranchCodes: newResetBranchArray
        });
    };

    getClickedChangedPaths = (evt) => {
        const path = evt.currentTarget.attributes.path.value;
        const selectedColumn = _.get(this.props, path);
        const lobPath = selectedColumn.lob.path;
        const quotePath = selectedColumn.quote.path;

        return [path, lobPath, quotePath];
    };

    toggleViewCoverage = (enable) => {
        this.setState({ viewCoverages: enable, showCoverageAccordion: enable });
        if (enable) {
            const eventLabel = 'View Coverages';
            dataLayer.push({'event':'gtm.click', 'gtm.elementId': 'qb_quote_viewcoverages'});
            publish('customizeButtonClicked', {
                eventLabel
            });
        }
    };


    convertToAmount = (amount) => {
        if (!_.isEmpty(amount)) {
            const decimalPoint = amount.split('.')[1];
            if (_.isEmpty(decimalPoint)) {
                return `$${amount}.00`;
            }
            if (!_.isEmpty(decimalPoint) && decimalPoint.length === 1) {
                return `$${amount}0`;
            }
        }
        return `$${amount}`;
    };

    getMonthAmount = (amount) => {
        return (
            (amount !== '')
                ? (
                    <div>
                        <span>{amount}</span>
                        <span className={styles.monthStyle}>/mo</span>
                    </div>
                ) : ''
        );

    };

    renderMobileQuoteHeader = (accordionData, index, isFormValid) => (isOpen) => {
        const {
            formData, staleQuoteBranchCodes, resetBranchCodes, priceDifference
        } = this.state;
        const scheduleStaleCode = this.getStaleCode();
        if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
            this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
        }
        const isAnnually = formData.paymentToggle === PAYMENT_TYPES.annually;
        const isQuoteStale = _.includes(staleQuoteBranchCodes, accordionData.code);
        const isResetBranch = _.includes(resetBranchCodes, accordionData.code);
        const shouldShowPriceDifference = _.includes(priceDifference.columnPath, `[${index}]`)
            && !isQuoteStale
            && priceDifference.difference !== 0;
        const price = isAnnually
            ? accordionData.quote.data.premium.total
            : accordionData.quote.data.premium.monthlyPremium;

        const dataForComponent = {
            price,
            quoteName: accordionData.name,
            selectedCoverages: getSelectedCoverages(accordionData)
        };

        // Due to having a new render content, there may be duplicate ID's
        const overrides = {
            monthlyAmountText: {
                visible: formData.paymentToggle === PAYMENT_TYPES.monthly
            },
            annuallyAmountText: {
                visible: formData.paymentToggle === PAYMENT_TYPES.annually
            },
            buyNowButton: {
                path: `columnData[${index}]`,
                visible: !isQuoteStale,
                onClick: (event) => {
                    event.stopPropagation();
                    this.buyNow(accordionData.quote.path, accordionData.lob.path);
                }
            },
            recalculateButton: {
                path: `columnData[${index}]`,
                visible: isQuoteStale,
                disabled: !isFormValid,
                onClick: (event) => {
                    event.stopPropagation();
                    this.recalculate(
                        `columnData[${index}]`,
                        accordionData.quote.path,
                        accordionData.lob.path
                    );
                }
            },
            resetCoverages: {
                path: `columnData[${index}]`,
                visible: !isResetBranch || isQuoteStale || shouldShowPriceDifference,
                onClick: (event) => {
                    event.stopPropagation();
                    this.resetQuote(
                        `columnData[${index}]`,
                        accordionData.quote.path,
                        accordionData.lob.path
                    );
                }
            },
            selectedCoverages: {
                visible: !isOpen
            },
            moreInfoContainer: {
                visible: !isOpen
            },
            accordionChevron: {
                isOpen
            },
            accordionStickyHeader: {
                className: classNames(styles.accordionStickyHeader, {
                    [styles.mobileStickyHeader]: isOpen
                })
            },

        };

        const resolvers = {
            resolveClassNameMap: styles
        };

        const metadataToRender = metadata.contentForMobileView.content;
        return (
            <ViewModelForm
                uiProps={metadataToRender}
                model={dataForComponent}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    };

    getVehicleLinecoverage() {
        const { columnData } = this.props;
        let vehicleLineCoverage = [];
        for (let data = 0; data < columnData.length; data++) {
            if(columnData[data].code === 'PABasic') {
                vehicleLineCoverage = ['PABI_alfa',
                'PAPropertyDamage_alfa',
                'PAMedicalPayments_alfa',
                'PAUninsuredMotoristBI_alfa'];
                break;
            } else if(columnData[data].code === 'PABasicGA' || columnData[data].code === 'PABasicMS') {
                vehicleLineCoverage = ['PABI_alfa',
                'PAPropertyDamage_alfa',
                'PAMedicalPayments_alfa',
                'PAUninsuredMotoristBI_alfa',
                'PAUninsuredMotoristPD_alfa',
                'PAUninsuredMotoristCommon_alfa'
                ];
                break;
            }
        }
        VEHICLELINE_COVERAGE = vehicleLineCoverage;
    }


    renderCellContent = (clauseCellUniqueID, index, props) => {
        this.getVehicleLinecoverage();
        const { loadingClause } = this.state;
        const { onChangeSubmission, onValidate, quoteError, columnData } = this.props;
        const { path, clauses = [], code, type, fixedId } = props.data;
        // const isVehicleCoverage = path.indexOf('vehicleCoverage') !== -1;
        let clauseIndex = -1;
        switch (type) {
            case 'line':
                clauseIndex = (clauses.findIndex(
                    (clause) => clauseCellUniqueID.coverageUniqueID.split('__')[0] === clause.publicID
                        && LINE_COVERAGE.includes(clause.publicID)
                ));
                break;
            case 'vehicle':
                clauseIndex = (clauses.findIndex(
                    (clause) => clauseCellUniqueID.coverageUniqueID.split('__')[0] === clause.publicID
                        && VEHICLE_COVERAGE.includes(clause.publicID)
                ));
                break;
            case 'vehicleLine':
                clauseIndex = (path.indexOf('vehicleCoverages.children[0]') !== -1) ? (clauses.findIndex(
                    (clause) => clauseCellUniqueID.coverageUniqueID.split('__')[0] === clause.publicID
                        && VEHICLELINE_COVERAGE.includes(clause.publicID)
                )) : -1;
                break;
            default: break;
        }
       
        const clause = clauses[clauseIndex];
        let showError = false;
        if (clause !== undefined && quoteError.length !== 0) {
            if (type === 'vehicle' || type === 'vehicleLine') {
                const checkClause = `${clauseCellUniqueID.coverageUniqueID.split('__')[0]}__${fixedId}`;
                showError = quoteError.includes(checkClause);
            } else {
                showError = quoteError.includes(clauseCellUniqueID.coverageUniqueID);
            }
        }
        const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(loadingClause.clauseID);
        const isClauseLoading = _.get(loadingClause, 'clauseID') === clauseCellUniqueID.coverageUniqueID
            && _.get(loadingClause, 'quoteCode') === code;

        if (clauseIndex !== -1) {
            return (
                <SingleClauseComponentVM
                    value={clause}
                    path={`${path}.children[${clauseIndex}]`}
                    onChangeClause={onChangeSubmission}
                    onSyncCoverages={this.syncCoverages}
                    onChangeSubmissionAndSync={this.changeSubmissionAndSync}
                    loadingClause={isClauseLoading || isQuoteLoading}
                    idPrefex={code}
                    labelTop={false}
                    onValidate={onValidate}
                    showAmount
                    containerClassName={styles.tableClass}
                    showError={showError}
                    columnData={columnData}
                />
            );
        }
        return null;
    };

    writeValue = (value, path) => {
        const { formData } = this.state;
        _.set(formData, path, value);
        this.setState({ formData });
    };

    syncCoverages = (value, path) => {
        const { onSyncCoverages } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                this.setState({ loadingClause: undefined });
            });
        }
    };

    changeSubmissionAndSync = async (value, path) => {
        const { onChangeSubmissionAndSync } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onChangeSubmissionAndSync) {
            const change = await onChangeSubmissionAndSync(value, path, lobPath, quotePath);

            this.setState({ loadingClause: undefined });


        }
    };

    buyNow = (quotePath, lobPath) => {
        const { onBuyNow } = this.props;
        dataLayer.push({'event':'gtm.click', 'gtm.elementId': 'qb_quote_buynow'});
        if (onBuyNow) {
            onBuyNow(lobPath, quotePath);
        }
    };

    recalculate = async (columnPath, quotePath, lobPath) => {
        const { onRecalculate, columnData, quoteErrorLoaderRef } = this.props;
        const { previousColumnData } = this.state;
        const changedColumnData = getChangedColumnData(lobPath, previousColumnData);
        let monthlyPayment = 0;
        if (changedColumnData.quote.data.status === 'Quoted') {
            monthlyPayment = changedColumnData.quote.data.premium.monthlyPremium.amount;
        }

        this.setState({
            /* loadingClause: {
                quoteCode: changedColumnData.code
            } */
            showInlineLoader: quoteErrorLoaderRef && quoteErrorLoaderRef.length <= 0 ? true : false
        });

        if (onRecalculate) {
            const recalc = await onRecalculate(lobPath, quotePath);
            if (recalc) {

                const newMonthlyPayment = recalc.quote.premium.monthlyPremium.amount;
                this.removeStaleQuote(columnPath);
                this.setState({
                    priceDifference: {
                        columnPath: columnPath,
                        difference: newMonthlyPayment - monthlyPayment
                    },
                    previousColumnData: _.cloneDeep(columnData),
                    loadingClause: undefined,
                    readQuote: 'CUSTOM',
                    activeQuote: 'CUSTOM',
                    showInlineLoader: false
                });
            }
        }
    };

    resetQuote = (columnPath, quotePath, lobPath) => {
        const { onResetQuote, columnData } = this.props;
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const changedColumnData = getChangedColumnData(lobPath, columnData);
        this.setState({
            loadingClause: {
                quoteCode: changedColumnData.code
            }
        });
        if (onResetQuote) {
            onResetQuote(lobPath, quotePath).then(() => {
                staleQuoteBranchCodes.push(changedColumnData.code);
                resetBranchCodes.push(changedColumnData.code);
                this.setState({
                    loadingClause: undefined,
                    staleQuoteBranchCodes,
                    resetBranchCodes
                });
            });
        }
    };

    customizeQuote = () => {
        this.setState({ activeQuote: 'CUSTOM' });
        this.setState({
            viewCoverages: true,
            showCoverageAccordion: true
        });
    };

    generatePhoneTableOverrides = () => {
        const { columnData: quoteInformation, tableData } = this.props;

        const overrides = quoteInformation.flatMap((quoteInfo, quoteIndex) => {
            return tableData.map((tableInfo) => ({
                [`quoteTable${quoteIndex}`]: {
                    data: tableInfo.data,
                    title: tableInfo.header
                },
                [`quoteTableColumn${quoteIndex}`]: {
                    onCell: this.renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === quoteInfo.code)
                }
            }));
        });

        return Object.assign({}, ...overrides);
    };


    generateDesktopHeaderOverrides() {
        const { columnData, isComponentValid, tableData, submissionVM, quoteError } = this.props;
        const {
            formData, staleQuoteBranchCodes, resetBranchCodes, activeQuote, readQuote, viewCoverages
        } = this.state;

        const scheduleStaleCode = this.getStaleCode();
        if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
            this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
        }
        let columnPath;
        const quoteData = columnData.find((quote) => quote.code === activeQuote && quote.name !== 'PaidInFull' && quote.name !== 'TwoDownPayment');
        const quoteCoverageData = tableData;
        columnData.forEach((q, index) => {
            if (q.code === activeQuote) {
                columnPath = `columnData[${index}]`;
            }
        });
        const dueTodayAmount = `$${quoteData.quote.data.premium.dueToday}`;
        const savingAmount = this.convertToAmount(`${quoteData.quote.data.premium.savingAmount}`);
        const fullAmount = quoteData.quote.data.premium.paidInFull !== undefined ? this.convertToAmount(`${quoteData.quote.data.premium.paidInFull.amount}`) : '';
        const placeHolderAmount = '$-.--';
        const monthlyAmount = quoteData.quote.data.premium.monthlyPremium !== undefined ? this.convertToAmount(`${quoteData.quote.data.premium.monthlyPremium.amount}`) : '';
        const isQuoteStale = _.includes(staleQuoteBranchCodes, quoteData.code);
        const overrides = {
            buyNowButton: {
                visible: !isQuoteStale && quoteData.quote.data.status !== 'Draft',
                onClick: () => this.buyNow(quoteData.quote.path, quoteData.lob.path),
            },
            recalculateButton: {
                visible: isQuoteStale || quoteData.quote.data.status === 'Draft',
                disabled: !isComponentValid,
                onClick: () => this.recalculate(columnPath, quoteData.quote.path, quoteData.lob.path)
            },
            resetCoverages: {
                visible: activeQuote === 'CUSTOM',
                onClick: () => this.resetQuote(columnPath, quoteData.quote.path, quoteData.lob.path)
            },
            qb_quote_customize: {
                visible: !isQuoteStale && quoteData.quote.data.status !== 'Draft' && (activeQuote === 'PABasic' || activeQuote === 'PABasicGA' || activeQuote === 'PABasicMS'),
                onClick: () => this.customizeQuote()
            },
            monthlyAmount: {
                content: !isQuoteStale ? this.getMonthAmount(monthlyAmount) : this.getMonthAmount(placeHolderAmount)
            },
            savingAmount: {
                content: !isQuoteStale ? savingAmount : placeHolderAmount
            },
            dueToday: {
                content: !isQuoteStale ? dueTodayAmount : placeHolderAmount
            },
            fullAmount: {
                content: !isQuoteStale ? fullAmount : placeHolderAmount
            },
            viewCoverage: {
                visible: !viewCoverages,
                onClick: () => this.toggleViewCoverage(true)
            },
            hideCoverage: {
                visible: viewCoverages,
                onClick: () => this.toggleViewCoverage(false)
            },
            tableContainer: {
                visible: viewCoverages && activeQuote === 'CUSTOM'
            },
            quoteTableCaption: {
                visible: viewCoverages && activeQuote === 'CUSTOM'
            },
            basicQuoteTable: {
                submissionVM: submissionVM
            },
            tableBasicContainer: {
                visible: viewCoverages && (activeQuote === 'PABasic' || activeQuote === 'PABasicGA' || activeQuote === 'PABasicMS'),
            },
            quoteErrorDisplay: {
                visible: quoteError.length > 0
            }
        };

        return overrides;
    }

    generatePhoneHeaderOverrides() {
        const { tableData, columnData, isComponentValid } = this.props;

        const overrides = columnData.map((data, index) => {
            return {
                [`tableContainer${index}`]: {
                    header: this.renderMobileQuoteHeader(data, 0, isComponentValid)
                },
                [`quoteTableAccordionCardIterableComponentContainer${index}`]: {
                    generatePhoneTableOverrides: this.generatePhoneTableOverrides,
                    tableData: tableData.map((tableInfo) => {
                        return {
                            data: tableInfo.tableContent.find(
                                (content) => content.code === data.code
                            ),
                            header: tableInfo.header
                        };
                    }),
                    renderCellContent: this.renderCellContent,
                    onValueChange: this.writeValue
                }
            };
        });

        return Object.assign({}, ...overrides);
    }

    generateDesktopTableOverrides() {
        const { tableData, columnData } = this.props;

        const vehicleHeaderIndex = tableData.findIndex(
            (t) => t.type === 'vehicle'
        );
        const overrides = tableData.map((tableInfo, index) => ({
            [`quoteTable${index}`]: {
                data: tableInfo.data,
                title: tableInfo.type === 'vehicle' ? tableInfo.header.split(':')[1] : '',
                content: this.getTableColumnContent(columnData, tableInfo)
            },
            [`quoteTableHeader${index}`]: {
                content: index <= 1 ? 'Policy Coverages' : 'Vehicle Coverages',
                visible: index === 0 || index === vehicleHeaderIndex
            },
            [`tableSpanDiv${index}`]: {
                className: (index === 0 || index === vehicleHeaderIndex) ? 'quoteTableHeader' : ''
            },
            [`policyCoverageToolTip${index}`]: {
                visible: index === 0
            },
            [`vehicleCoverageToolTip${index}`]: {
                visible: index === vehicleHeaderIndex
            }

        }));
        //   .filter(({ tableInfo }) => code === 'CUSTOM');

        return Object.assign({}, ...overrides);
    }

    generateDesktopOverrides() {
        return {
            ...this.generateDesktopHeaderOverrides(),
            // generateDesktopHeaderOverrides()//,
            ...this.generateDesktopTableOverrides()
        };
    }

    generatePhoneOverrides() {
        return {
            ...this.generatePhoneHeaderOverrides()
        };
    }

    findBlockingPoint(type) {
        const { underwritingIssues } = this.props;
        return underwritingIssues.some((offeringIssue) => {
            if (type === BLOCKS_BIND) {
                return offeringIssue.currentBlockingPoint === BLOCKS_BIND;
            }
            return _.includes(BLOCKS_QUOTES, offeringIssue.currentBlockingPoint);
        });
    }

    getChevron(enable) {
        if (enable) {
            return (
                <span id="downChevron"><i class="fa fa-angle-down" /></span>
            );
        }
        return (
            <span id="upChevron"><i class="fa fa-angle-up" /></span>
        );
    }

    renderCoverageAccordion() {
        const { viewCoverages } = this.state;
        const label = !viewCoverages ? 'View Coverages' : 'Hide Coverages';
        return (
            <div className={styles.textAlignStyle} onClick={() => this.toggleViewCoverage(!viewCoverages)}>
                <span>{label}</span>&nbsp;&nbsp;
                {this.getChevron(!viewCoverages)}
            </div>
        );
    }

    generateOverrides(breakpoint) {
        const { underwritingIssues, quoteID, filterUWIssuesInCustomOffering } = this.props;
        const breakpointOverrides = this.generateDesktopOverrides();/** breakpoint === 'phone'
            ? this.generateDesktopOverrides()
            : this.generateDesktopOverrides();**/
        const { showInlineLoader, showCoverageAccordion } = this.state;
        return {
            '@field': {
                // apply to all fields
                onValueChange: this.writeValue
            },
            underWritingIssues: {
                underwritingIssues: underwritingIssues,
                blockQuote: this.findBlockingPoint(),
                quoteID: quoteID,
                filterUWIssuesInCustomOffering
            },
            paymentToggle: {
                visible: !this.findBlockingPoint()
            },
            tableContainer: {
                visible: !this.findBlockingPoint()
            },
            tableHeader: {
                visible: !this.findBlockingPoint()
            },
            loaderSpanDiv: {
                visible: showInlineLoader
            },
            viewCoverageCard: {
                header: this.renderCoverageAccordion(),
                collapseClassName: showCoverageAccordion ? styles.openAccordion : styles.collapaseAccordion
            },
            dueTodayTooltip: {
                visible: this.getActiveQuote() === 'PABasic'
            },
            ...breakpointOverrides
        };
    }

    renderClauseTable = (breakpoint) => {
        const { setComponentValidation, setActiveQuote, columnData } = this.props;
        const { formData, activeQuote } = this.state;
        setActiveQuote(activeQuote);
        

        const dataForComponent = {
            ...formData,
            ..._.pick(this.props, ['columnData', 'tableData'])
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                chevron: Chevron,
                quotetableaccordioncarditerablecomponent: QuoteTableAccordionCardIterableComponent,
                tooltipcomponent: ClauseTooltip,
                basicquote: BasicQuote,
            },
            /* resolveCallbackMap: {
                viewCoverageCard: this.viewCoverageCard
            } */
        };
        const generatedMetadata = selectMetadata(breakpoint);
        return (
            <ViewModelForm
                uiProps={generatedMetadata.componentContent}
                model={dataForComponent}
                overrideProps={this.generateOverrides(breakpoint)}
                onValueChange={this.writeValue}
                onValidationChange={setComponentValidation}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    };

    render() {
        return (
            <BreakpointTrackerContext.Consumer>
                {this.renderClauseTable}
            </BreakpointTrackerContext.Consumer>
        );
    }
}

export default withValidation(QuoteClauseTable);
