import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { InputNumberField, InputMaskField, Link, Button } from '@jutro/components';
import { getConfigValue } from '@jutro/config';
import cookie from 'js-cookie';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import { loadGoogleMapsAPI, GeoCodeService } from 'gw-portals-google-maps-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import styles from './ProductZipCode.module.scss';
import moment from 'moment'

function translateMessages(translator, messagesToTranslate = []) {
    return messagesToTranslate.map((message) => translator(message));
}

class ProductZipCode extends Component {
    static propTypes = {
        id: PropTypes.string,
        wizardLink: PropTypes.string,
        className: PropTypes.string,
        productCode: PropTypes.string,
        onTogglePageState: PropTypes.func,
        onEnterProductFlow: PropTypes.func,
        startQuoteMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        zipCodePlaceholder: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        retreiveQuoteMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        fromCMS: PropTypes.bool,
        cmsPostalCode: PropTypes.string,
        cmsAgentId: PropTypes.string
    };

    static defaultProps = {
        id: undefined,
        wizardLink: undefined,
        className: undefined,
        productCode: undefined,
        startQuoteMessage: undefined,
        zipCodePlaceholder: undefined,
        onTogglePageState: undefined,
        onEnterProductFlow: undefined,
        retreiveQuoteMessage: undefined,
        fromCMS: false,
        cmsPostalCode: undefined,
        cmsAgentId: undefined
    };

    state = {
        submissionVM: {},
        showErrors: false,
        validZip: true
    };

    updatePostalCode = () => {
        const { submissionVM, validZip } = this.state;
        const { cmsPostalCode } = this.props;
        if (cmsPostalCode && validZip) _.set(submissionVM, 'postalCode', cmsPostalCode);
        this.setState({ submissionVM });
    }

    componentDidMount() {
        const { viewModelService, productCode, fromCMS } = this.props;

        const vm = viewModelService.create(
            {
                 effectiveDate : (moment(new Date().toLocaleString('en-US', {timeZone: 'CST',})).format('YYYY-MM-DD')),
                 productCode
            },
            'pc',
            'edge.capabilities.policycommon.availability.dto.PostalCodeAvailabilityRequestDTO'
        );
        loadGoogleMapsAPI();

        this.setState({ submissionVM: vm });
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latLong = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                GeoCodeService.getGeoCodedAddr(latLong.lat, latLong.lng)
                    .then((address) => {
                        if (address && address.postalCode) {
                            const { submissionVM } = this.state;
                            _.set(submissionVM, 'postalCode', address.postalCode);
                            this.setState({ submissionVM }, () => {
                                this.updatePostalCode();
                            });
                        }
                    });
            });
        }
        if (fromCMS && (_.isEmpty(cookie.get('quoteID')) || cookie.get('quoteID') === 'undefined')) {
            this.handleEnterFlow();
        }
    }

    writeValue = (value, path) => {
        const { submissionVM } = this.state;
        if (path === 'postalCode') {
            if (value && value.length < 5) {
                this.setState({ validZip: false });
            } else {
                this.setState({ validZip: true });
            }
        }
        _.set(submissionVM, path, value);
        this.setState({ submissionVM });
    }

    handleEnterFlow = () => {
        const { submissionVM } = this.state;
        const { fromCMS, cmsPostalCode, cmsAgentId, viewModelService, productCode, agentUrl_alfa } = this.props;
        const redirectFromCMS = fromCMS;
        const isValid = redirectFromCMS ? true : submissionVM.aspects.valid && submissionVM.aspects.subtreeValid;
        const { wizardLink, onEnterProductFlow } = this.props;
        const postalCode = redirectFromCMS ? cmsPostalCode : _.get(submissionVM, 'postalCode.value');
        const invalidZipMSGAUrl = getConfigValue('INVALID_ZIP_MSGA_URL');
        const invalidZipUrl = getConfigValue('INVALID_ZIP_URL');
        const allowedState = ['AL','MS','GA'];

        if (_.isEmpty(postalCode) || postalCode.length < 5) {
            this.setState({ validZip: false });
        }

        this.setState({ showErrors: true });
        if (isValid) {
            GeoCodeService.getGeoCodeStateBasedOnPostalCode(postalCode)
                .then((address) => {
                    
                    if (allowedState.includes(address.stateCode) && onEnterProductFlow) {
                        if (redirectFromCMS) {
                            const vm = viewModelService.create(
                              {
                                postalCode: cmsPostalCode,
                                effectiveDate: moment(
                                  new Date().toLocaleString("en-US", {
                                    timeZone: "CST",
                                  })
                                ).format("YYYY-MM-DD"),
                                productCode,
                              },
                              "pc",
                              "edge.capabilities.policycommon.availability.dto.PostalCodeAvailabilityRequestDTO"
                            );
                            _.set(vm, 'value.stateCode', address.stateCode);
                            _.set(vm, 'value.agentId', cmsAgentId)
                            _.set(vm, 'value.agentUrl_alfa', agentUrl_alfa);
                            onEnterProductFlow(vm.value, wizardLink);
                        } else {
                            _.set(submissionVM, 'value.stateCode', address.stateCode);
                            this.setState({ submissionVM });

                            onEnterProductFlow(submissionVM.value, wizardLink);
                        }
                        localStorage.setItem('gaState', address.stateCode);
                    } else {
                        /**
                         * WebDev provided a temp URL to test until final page is built
                        */
                        // eslint-disable-next-line no-restricted-globals
                        location.replace(invalidZipUrl);
                    }
                });
        }
    };

    renderStartQuoteButton = (translator) => {
        const { id, startQuoteMessage } = this.props;

        return (
            <Button
                id={`${id}_start_quote`}
                className={styles.startQuote}
                onClick={this.handleEnterFlow}
            >
                {translator(startQuoteMessage)}
            </Button>
        );
    };

    handleSubmit = (evt) => {
        evt.preventDefault();
        this.handleEnterFlow();
    };

    handleChangeInProductZipCode = () => {
        const { onTogglePageState } = this.props;
        if (_.isFunction(onTogglePageState)) {
            onTogglePageState();
        }
    };

    getClasses = () => {
        const { validZip } = this.state;
        let className;
        if (!validZip) {
            className = styles.postalCodeContainerRedFont;
        } else {
            className = styles.postalCodeContainer;
        }
        return className;
    };

    render() {
        const { submissionVM, showErrors } = this.state;
        const {
            id, className, zipCodePlaceholder, retreiveQuoteMessage
        } = this.props;

        const validationMessages = _.get(submissionVM, 'postalCode.aspects.validationMessages', []);
        const formStyles = classNames(className, styles.productInputContainer);
        return (
            <TranslatorContext.Consumer>
                {(translator) => (
                    <form onSubmit={this.handleSubmit} className={formStyles}>
                        <InputMaskField
                            id={`${id}_input`}
                            controlClassName={styles.postalCode}
                            className={this.getClasses()}
                            path="postalCode"
                            value={_.get(submissionVM, 'postalCode.value')}
                            onValueChange={this.writeValue}
                            placeholder={translator(zipCodePlaceholder)}
                            layout="full-width"
                            showErrors={showErrors}
                            messageProps={{
                                incompleteInput: translateMessages(translator, validationMessages)
                            }}
                            validationMessages={(showErrors === true) ? translateMessages(translator, validationMessages) : false}
                            hideLabel
                            formatChars={{
                                9: '[0-9]'
                            }}
                            mask="99999"
                            maskChar=""
                            alwaysShowMask={false}

                        />
                        {this.renderStartQuoteButton(translator)}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                            id={`${id}_retrieve_quote`}
                            onClick={this.handleChangeInProductZipCode}
                            className={styles.retrieveQuote}
                        >
                            {translator(retreiveQuoteMessage)}
                        </Link>

                    </form>
                )}
            </TranslatorContext.Consumer>
        );
    }
}

export default withViewModelService(ProductZipCode);
